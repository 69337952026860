import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DownArrow from 'components/Icons/DownArrow';
import UpArrow from 'components/Icons/UpArrow';

const StyledTh = styled.th`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  user-select: none;
`;

function SwitchIcon({ isAsc, ...props }) {
  return isAsc ? <UpArrow {...props} /> : <DownArrow {...props} />;
}
interface StyledContainerProps {
  isCentered?: boolean;
  width: string | number;
}
const StyledDiv = styled.div<StyledContainerProps>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${props => (props.isCentered ? 'center' : 'normal')};
  width: ${props => props.width};
  text-align: ${props => (props.isCentered ? 'center' : 'inherit')};
`;
export function TableHead({
  onClick = null,
  children,
  isCentered = true,
  isSortedBy = false,
  isAsc = false,
  width = 'auto',
  ...props
}) {
  return (
    <StyledTh onClick={onClick} {...props}>
      <StyledDiv isCentered={isCentered} width={width}>
        {isSortedBy && <SwitchIcon isAsc={isAsc} width={10} />}
        &nbsp; {children}
      </StyledDiv>
    </StyledTh>
  );
}

interface StyledDateProps {
  date: moment.Moment;
}
const StyledDateTh = styled.div<StyledDateProps>`
  &::before {
    content: '';
    position: absolute;
    top: 0;
  }
`;

export function DateHead({ date, width = 'auto' }) {
  const momentDate = moment(date, 'YYYY-MM-DD');
  return (
    <th style={{ position: 'relative' }}>
      <StyledDiv width={width}>
        <StyledDateTh date={momentDate}>
          {momentDate.format('DD MMM YY')}
        </StyledDateTh>
      </StyledDiv>
    </th>
  );
}
