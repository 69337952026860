import * as React from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Chart } from 'components/Charts/AmCharts';
import InfoContainer from 'components/InformationBox';
import { getConfig } from './config';

function transformData(data) {
  const colorMapping = {
    0: '#ff1744',
    0.5: '#C30000',
    1: '#ff5252',
    1.5: '#FC9604',
    2: '#ff9e80',
    2.5: '#ffd180',
    3: '#f4ff81',
    3.5: '#ccff90',
    4: '#b2ff59',
    4.5: '#5efc82',
    5: '#00c853',
  };
  return data
    .filter(i => !isEmpty(i))
    .map(item => {
      return {
        ...item,
        config: {
          fill: colorMapping[item.score],
          tooltipText: `{category} Rating - {value.percent.formatNumber('#.0')}% ({value.value} Listings)`,
        },
      };
    });
}

export const ReviewScore = ({ data }) => (
  <div className="box">
    <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
      <h3 className="is-5 title is flex is-justify-content-center">
        <FormattedMessage id="reviews.review_score.title" />
      </h3>
      <InfoContainer>
        <FormattedMessage id="reviews.review_score.info" />
      </InfoContainer>
    </div>
    <div className="px-4" style={{ height: 400 }}>
      <Chart
        chartType="PieChart"
        exportMenu={true}
        config={getConfig(transformData(data))}
        chartId="reviewScore"
      />
    </div>
  </div>
);
