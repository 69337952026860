import { snakeCase, get, isArray } from 'lodash';
import * as React from 'react';
import {
  ListItem,
  withStyles,
  StyledComponentProps,
  ListItemIcon,
  List,
} from '@material-ui/core';
import FilterIcon from '../../FiltersIcons';
import { IFilterDictionary } from '../../../store/filters/types';
import { Filter } from './';
import styles from './styles';
import { ActionMeta } from 'react-select';

interface IFiltersProps {
  availableFilters: IFilterDictionary;
  handleChange: (name: string) => (value: any, action: ActionMeta<any>) => void;
  selectedFilters: IFilterDictionary;
  handleBlur: (event: React.FocusEvent) => void;
}

const AllFilters: React.FunctionComponent<IFiltersProps &
  StyledComponentProps> = (props): any => {
  const availableFilters = Object.keys(props.availableFilters);
  return availableFilters.map(key => {
    const value = props.availableFilters[key];
    const filterName = snakeCase(key);
    const newValues = get(props, ['selectedFilters', key]) || [];
    return (
      <ListItem key={key} className={props.classes.filterContainer}>
        <ListItemIcon
          classes={{ root: props.classes.listItemRoot }}
          className={props.classes.iconContainer}
        >
          <FilterIcon filterName={filterName} />
        </ListItemIcon>
        <Filter
          key={key}
          filterName={filterName}
          isMulti={key !== 'market'}
          options={value}
          handleChange={props.handleChange(key)}
          value={isArray(newValues) ? newValues : [newValues]}
          onBlur={props.handleBlur}
        />
      </ListItem>
    );
  });
};

const Filters: React.SFC<IFiltersProps> = function(props) {
  return (
    <List>
      <AllFilters {...props} />
    </List>
  );
};

export default withStyles(styles)(Filters);
