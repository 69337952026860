import * as React from 'react';
import { truncate } from 'lodash';

import styles from './Reviews.module.scss';
export const ReviewContainer = ({
  date,
  description,
  maxLength,
  title,
  url,
  reviewScore,
}) => {
  const [state, setState] = React.useState({
    isTruncated: true,
    text: truncateDescription(description),
  });

  function truncateDescription(description) {
    return truncate(description, { length: maxLength });
  }

  const handleClick = e => {
    e.preventDefault();
    state.isTruncated
      ? setState({ isTruncated: false, text: description })
      : setState({ isTruncated: true, text: truncateDescription(description) });
  };

  return (
    <div className="has-text-grey">
      <div className={styles.titleContainer}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="is-title has-text-grey   is-size-6"
          title={title}
        >
          <span className="icon">
            <i className="fas fa-home" />
          </span>
          {truncate(title, { length: 80 })}
        </a>
        <span className={`has-text-weight-semibold ${styles.rankingContainer}`}>
          <span className="icon">
            <i className="fas fa-star" />
          </span>
          <span className="is-size-6 has-text-weight-normal">
            {reviewScore}
          </span>
        </span>
      </div>
      <p className={styles.title}>
        <span className="icon">
          <i className="fas fa-calendar-alt" />
        </span>
        {date}
      </p>
      <p>{state.text}</p>
      {description.length > maxLength && (
        <button className={styles.button} onClick={handleClick}>
          {state.isTruncated ? 'See More' : 'See less'}
        </button>
      )}
    </div>
  );
};
