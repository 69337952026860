import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <linearGradient
      id="notification__a"
      gradientUnits="userSpaceOnUse"
      x1={225.812}
      y1={720.719}
      x2={318.592}
      y2={875.349}
      gradientTransform="translate(6.002 -329.505) scale(1.0667)"
    >
      <stop offset={0} stopColor="#ffc200" />
      <stop offset={0.268} stopColor="#fb0" />
      <stop offset={0.659} stopColor="#ffa801" />
      <stop offset={1} stopColor="#ff9102" />
    </linearGradient>
    <circle cx={256} cy={454.5} r={57.5} fill="url(#notification__a)" />
    <linearGradient
      id="notification__b"
      gradientUnits="userSpaceOnUse"
      x1={317.866}
      y1={804.678}
      x2={194.526}
      y2={681.338}
      gradientTransform="translate(6.002 -329.505) scale(1.0667)"
    >
      <stop offset={0} stopColor="#ffc200" stopOpacity={0} />
      <stop offset={0.203} stopColor="#fb0" stopOpacity={0.203} />
      <stop offset={0.499} stopColor="#ffa700" stopOpacity={0.499} />
      <stop offset={0.852} stopColor="#f80" stopOpacity={0.852} />
      <stop offset={1} stopColor="#ff7800" />
    </linearGradient>
    <path
      d="M256 397c-30 0-54.6 22.9-57.3 52.2l62.6 62.6c29.3-2.7 52.2-27.3 52.2-57.3 0-31.8-25.7-57.5-57.5-57.5z"
      fill="url(#notification__b)"
    />
    <linearGradient
      id="notification__c"
      gradientUnits="userSpaceOnUse"
      x1={180.292}
      y1={594.529}
      x2={411.872}
      y2={980.489}
      gradientTransform="translate(6.002 -329.505) scale(1.0667)"
    >
      <stop offset={0} stopColor="#ffc200" />
      <stop offset={0.268} stopColor="#fb0" />
      <stop offset={0.659} stopColor="#ffa801" />
      <stop offset={1} stopColor="#ff9102" />
    </linearGradient>
    <path
      d="M444.5 354.2h-377c-25.7 0-46.6 20.9-46.6 46.6 0 25.7 20.9 46.6 46.6 46.6h376.9c25.7 0 46.6-20.9 46.6-46.6.1-25.7-20.8-46.6-46.5-46.6z"
      fill="url(#notification__c)"
    />
    <linearGradient
      id="notification__d"
      gradientUnits="userSpaceOnUse"
      x1={179.569}
      y1={503.436}
      x2={390.848}
      y2={702.446}
      gradientTransform="translate(6.002 -329.505) scale(1.0667)"
    >
      <stop offset={0} stopColor="#ffc200" />
      <stop offset={0.268} stopColor="#fb0" />
      <stop offset={0.659} stopColor="#ffa801" />
      <stop offset={1} stopColor="#ff9102" />
    </linearGradient>
    <path
      d="M417.5 319v-93.4c0-75.6-51.9-139-122-156.6 4-6.8 6.3-14.7 6.3-23.2C301.8 20.5 281.3 0 256 0s-45.8 20.5-45.8 45.8c0 8.5 2.3 16.4 6.3 23.2-70.1 17.6-122 81-122 156.6V319c0 20.2-16.4 36.6-36.6 36.6h396.2c-20.2.1-36.6-16.3-36.6-36.6zM256 64.1c-10.1 0-18.3-8.2-18.3-18.3 0-10.1 8.2-18.3 18.3-18.3s18.3 8.2 18.3 18.3c0 10.1-8.2 18.3-18.3 18.3z"
      fill="url(#notification__d)"
    />
    <linearGradient
      id="notification__e"
      gradientUnits="userSpaceOnUse"
      x1={431.235}
      y1={688.582}
      x2={-5.505}
      y2={733.653}
      gradientTransform="translate(6.002 -329.505) scale(1.0667)"
    >
      <stop offset={0} stopColor="#ffc200" stopOpacity={0} />
      <stop offset={0.203} stopColor="#fb0" stopOpacity={0.203} />
      <stop offset={0.499} stopColor="#ffa700" stopOpacity={0.499} />
      <stop offset={0.852} stopColor="#f80" stopOpacity={0.852} />
      <stop offset={1} stopColor="#ff7800" />
    </linearGradient>
    <path
      d="M21.5 410.1c3.5 22.3 22.8 39.3 46 37.2h376.9c23.2 2.1 42.5-14.9 46-37.2H21.5z"
      fill="url(#notification__e)"
    />
  </SvgIcon>
);

export default SvgComponent;
