export const START_LOADING_PRICE_VARIATIONS = 'START_LOADING_PRICE_VARIATIONS';
export const SET_PRICE_VARIATIONS = 'SET_PRICE_VARIATIONS';
export const LOAD_PRICE_VARIATIONS_ERROR = 'LOAD_PRICE_VARIATIONS_ERROR';
export const SET_PRICE_VARIATIONS_QUERY = 'SET_PRICE_VARIATIONS_QUERY';

export interface IPriceVariationsItem {
  [key: string]: any;
  priceVariation: number;
  marketPriceVariation: number;
  date: string;
  occupancy: number;
}

export interface IPriceVariationsCalendar {
  [date: string]: IPriceVariationsItem;
}

export interface IPriceVariationsState {
  calendar?: {
    [date: string]: IPriceVariationsItem;
  };
  error?: any;
  isLoading?: boolean;
  query?: string;
}

interface ISetPriceVariations {
  type: typeof SET_PRICE_VARIATIONS;
  payload: IPriceVariationsState;
}

interface IStartLoadPriceVariations {
  type: typeof START_LOADING_PRICE_VARIATIONS;
}

interface ILoadPriceVariationsError {
  type: typeof LOAD_PRICE_VARIATIONS_ERROR;
  payload: IPriceVariationsState;
}

interface ISetPriceVariationsQuery {
  type: typeof SET_PRICE_VARIATIONS_QUERY;
  payload: { query: string };
}

export type PriceVariationsActions =
  | ISetPriceVariations
  | IStartLoadPriceVariations
  | ILoadPriceVariationsError
  | ISetPriceVariationsQuery;
