import * as React from 'react';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { useSelector } from 'react-redux';
import memCache from 'graphql-hooks-memcache';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import { StateProvider } from './store/store';
import Filters from './filters/Filters';
import { ApplicationState } from 'store';
import MetricsLayout from './Layout/MetricsLayout';
import { Route, Switch } from 'react-router';
import PacingLayout from './Layout/PacingLayout';

/*const cache = {};

const cacheConfig = {
  get: key => {
    console.log("KEY", key);
    return cache[key.operation.query + key.operation.variables]
  },
  set: (key, data) => {
    console.log("KEY DATA", key, data)
    cache[JSON.stringify(key)] = data;
    return cache;
  },
  delete: key => !!delete cache[JSON.stringify(key)] || false,
  clear: () => {
    for (let k in Object.keys(cache)) delete cache[k];
  },
  keys: () => Object.keys(cache),
  getInitialState: () => ({}),
};*/
/**
 *
 * We are treating this route as a subapp, the implementation will be isolated in this subfolder and in theory the code
 * should be easily repleaceble and transferable
 */

/**
 * Graphq client ready to handle queries we want to avoid managing a general state for the queries, we are going to use cache
 * and local state for the components using the client and reac-hooks
 *
 */
const SelfReporting: React.FunctionComponent<any> = props => {
  const token = useSelector<ApplicationState, string>(
    state => state?.auth?.token,
  );
  const client = new GraphQLClient({
    url: process.env.REACT_APP_SELF_REPORTING_API,
    cache: memCache(),
    //cache: cacheConfig,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return (
    <Layout>
      <ClientContext.Provider value={client}>
        <StateProvider>
          <Dashboard mainListItems={<Filters />}>
            <Switch>
              <Route path="/pms/pacing">
                <PacingLayout />
              </Route>
              <Route path="/pms">
                <MetricsLayout {...props} />
              </Route>
            </Switch>
          </Dashboard>
        </StateProvider>
      </ClientContext.Provider>
    </Layout>
  );
};

export default SelfReporting;
