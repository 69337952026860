import * as React from 'react';

import {
  Grid,
  Paper,
  withStyles,
  StyledComponentProps,
  Typography,
} from '@material-ui/core';

import styles from './styles';
import { Chart } from 'containers/Charts/index';
import { Loader } from 'components/Layout/index';
import ListingsCount from 'containers/ListingsCount';
import { FormattedMessage } from 'react-intl';

export interface ChartsDisplayProps {
  delay?: boolean;
}

const ChartsDisplay: React.SFC<ChartsDisplayProps &
  StyledComponentProps> = function({ delay, classes }) {
  if (delay)
    return (
      <Grid container={true} spacing={8}>
        <Loader />
      </Grid>
    );

  return (
    <Grid container={true} spacing={2}>
      <ListingsCount xs={12} sm={6} lg={3} classes={classes} />
      <Grid item={true} xs={12} sm={12} lg={6}>
        <Paper className={classes.paper}>
          <Chart
            chartId="byBedroom_listingsByBedrooms"
            dataType="supply_stats"
            metric="count"
            select="COUNT_ALL"
            group="bedrooms"
            vizType="bar"
            options={{
              categoryField: 'bedrooms',
              categoryLabel: 'Bedrooms',
              title: 'Bedrooms',
            }}
            height={250}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            chartId="byBedroom_averageOccupancyPerNight"
            dataType="pricing_series"
            metric="avg"
            select="occupancy"
            vizType="line"
            group="bedrooms"
            height={500}
            isPercentage={true}
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.avg_nightly_occupancy" />
              </Typography>
            }
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              valueLabel: 'Occupancy',
              title: 'Average Nightly Occupancy by Bedroom count',
            }}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            chartId="byBedroom_averagePricePerNight"
            dataType="pricing_series"
            metric="avg"
            select="price"
            vizType="line"
            group="bedrooms"
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.avg_nightly_rate" />
              </Typography>
            }
            formatCurrency={true}
            height={500}
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              valueLabel: 'Nightly Price',
              title: 'Average Nightly Rate by Bedroom count',
            }}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            chartId="byBedroom_listingsByPickup"
            dataType="pricing_series"
            metric="sum"
            select="pickup"
            vizType="line"
            group="bedrooms"
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.pickup_7_days" />
              </Typography>
            }
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              title: 'Pickup 7 Days by Bedroom count',
            }}
            height={500}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChartsDisplay);
