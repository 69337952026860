import * as React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  Theme,
  StyledComponentProps,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3,
      )}px`,
    },
  });

const CenteredPaperContainer: React.SFC<StyledComponentProps> = ({
  children,
  classes,
}) => {
  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>{children}</Paper>
    </main>
  );
};
export default withStyles(styles)(CenteredPaperContainer);
