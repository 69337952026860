import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      overflowX: 'scroll',
      marginTop: theme.spacing(2),
    },
    paper: {
      width: '2000px',
    },
    datesContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: `-${theme.spacing()}px`,
      marginBottom: `-${theme.spacing() + 1}px`,
    },
    datesInfoHeader: {
      flex: 'auto',
      textAlign: 'center',
      width: '60px',
      padding: `${theme.spacing()}px`,
    },
    datesInfo: {
      flex: 'auto',
      fontSize: '0.75rem',
      textAlign: 'center',
      width: '60px',
      padding: `${theme.spacing()}px`,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    reservationInfo: {
      height: '53px',
    },
    booked: { backgroundColor: '#BCD2EE	', color: '#444' },
    unavailable: { backgroundColor: 'rgba(224, 224, 224, 1)' },
    info: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '200px',
    },
    infoHeader: {
      maxWidth: '200px',
    },
    error: {
      marginTop: `${theme.spacing(3)}px`,
    },
  });
