import * as React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    valueContainer: {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      padding: '15px',
      width: '100%',
      textTransform: 'uppercase',
    },
    listItemContainer: {
      paddingTop: 0,
    },
    label: {
      textTransform: 'capitalize',
    },
  });

const EventListItem = ({ label, value, classes }) => (
  <ListItem className={classes.listItemContainer}>
    <ListItemText className={classes.label}>{label}</ListItemText>
    <ListItemText>
      <div className={classes.valueContainer}>
        <Typography>{value}</Typography>
      </div>
    </ListItemText>
  </ListItem>
);

export default withStyles(styles)(EventListItem);
