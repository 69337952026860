import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface InformationIconProps extends SvgIconProps {
  isActive?: boolean;
}

const InformationIcon = ({ isActive, ...props }: InformationIconProps) => {
  const color = isActive ? '#505D6E' : '#afc3cf';
  return (
    <SvgIcon viewBox="0 0 224 224" {...props}>
      <path
        d="M124.2 62c6.7 0 10 4.6 10 9.8 0 6.5-5.8 12.6-13.4 12.6-6.3 0-10-3.8-9.9-9.9 0-5.3 4.4-12.5 13.3-12.5zm-20.7 100c-5.3 0-9.2-3.3-5.5-17.6l6.1-25.5c1.1-4.1 1.2-5.7 0-5.7-1.6 0-8.5 2.8-12.5 5.6l-2.6-4.4c12.9-10.9 27.7-17.3 34-17.3 5.3 0 6.2 6.4 3.5 16.1l-6.9 26.8c-1.2 4.7-.7 6.4.5 6.4 1.6 0 6.8-2 11.9-6l3 4.1c-12.5 12.6-26.2 17.5-31.5 17.5z"
        fill={color}
      />
      <circle
        cx={112}
        cy={112}
        r={107}
        fill="none"
        stroke={color}
        strokeWidth={10}
        strokeMiterlimit={10}
      />
    </SvgIcon>
  );
};

export default InformationIcon;
