import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ListingLevelIcon: React.SFC<SvgIconProps> = props => (
  <SvgIcon
    id="listing_level_icon_Capa_1"
    x={0}
    y={0}
    viewBox="0 0 134.1 134"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.listing_level_icon_st0{fill:#888}'}</style>
    <path
      className="listing_level_icon_st0"
      d="M7.9 7.8h16.4V0H0v24h7.9V7.8zM109.8 0v7.9h16.4V24h7.9V0h-24.3zM7.9 110H0v24h24.3v-7.9H7.9V110zM126.2 126.1h-16.4v7.9h24.3v-24h-7.9v16.1z"
    />
    <g>
      <path
        className="listing_level_icon_st0"
        d="M102.4 95.5H49.9c-2.2 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9h52.5c2.2 0 3.9 1.8 3.9 3.9s-1.8 3.9-3.9 3.9zM102.4 70.9H49.9c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9h52.5c2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9zM102.4 46.3H49.9c-2.2 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9h52.5c2.2 0 3.9 1.8 3.9 3.9s-1.8 3.9-3.9 3.9z"
      />
      <circle className="listing_level_icon_st0" cx={33.1} cy={42.7} r={5.3} />
      <circle className="listing_level_icon_st0" cx={33.1} cy={67} r={5.3} />
      <circle className="listing_level_icon_st0" cx={33.1} cy={91.3} r={5.3} />
    </g>
  </SvgIcon>
);
