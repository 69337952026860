import React, { useState, useEffect } from 'react';
import { ReservationsFilters, PropertiesFilter } from '../lib/graphql';
import { IContextValue } from '../lib/types';
import { PendingChangesStatus } from '../lib/enum';

const store = React.createContext<IContextValue>(null);
const { Provider } = store;
const StateProvider: React.FunctionComponent = ({ children }) => {
  const [queryFilters, setQueryFilters] = useState<
    ReservationsFilters | PropertiesFilter
  >({});
  const [pendingChanges, setPendingChanges] = useState(
    PendingChangesStatus.IDLE,
  );

  useEffect(() => {
    if (pendingChanges === PendingChangesStatus.RUN)
      setPendingChanges(PendingChangesStatus.IDLE);
  }, [pendingChanges]);

  return (
    <Provider
      value={{
        queryFilters,
        setQueryFilters,
        pendingChanges,
        setPendingChanges,
      }}
    >
      {children}
    </Provider>
  );
};

export { store, StateProvider };
