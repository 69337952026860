import * as React from 'react';
import { FormattedMessage } from 'react-intl';

function Market({
  cleanUrl = 'http://market-url-company',
  markets = ['market'],
}) {
  return (
    <div className="box first-section">
      <p className="title is-4">
        <FormattedMessage id="onboarding.market.suscribed_to" />
        <span className="text-market"> {` [${markets.join(', ')}]`} </span>
      </p>
      <hr style={{ height: '1px', color: '#ff0' }} />
      <p className="title is-6" style={{ margin: '10px 0' }}>
        <FormattedMessage id="onboarding.market.company_url" />
      </p>
      <div className="control has-icons-right">
        <input
          className="input"
          type="text"
          defaultValue={cleanUrl}
          readOnly={true}
        />
        <span className="icon is-right">
          <i className="fas fa-copy" />
        </span>
      </div>
    </div>
  );
}

export default Market;
