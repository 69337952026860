import { schema } from 'normalizr';

const calendar = new schema.Entity('calendar', undefined, {
  idAttribute: value => value.date,
});

export const PriceVariationsSchema = {
  data: {
    days: [calendar],
  },
};
