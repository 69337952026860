export enum Status {
  IDLE = 0,
  RUNNING = 1,
  CREATED = 2,
  UPDATED = 3,
  DELETED = 4,
  LOADED = 5,
  IS_LOADING = 6,
  FAIL = 7,
}
