import * as React from 'react';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Chart } from 'components/Charts/AmCharts';
import { getConfig } from './config';
import { loadReviewsSample } from '../../store/actions';
import { getEntity } from 'store/entities/selectors';
import { Loader } from 'components/Layout';
import { ChartError } from '../';
import InformationBox from 'components/InformationBox';

type WordCloudProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    title: React.ReactNode;
    reviewsThreshold: [number, number];
    domain: string;
    infoBoxContent?: React.ReactNode;
  };

export const WordCloud = React.memo<WordCloudProps>(
  ({
    reviews,
    actions,
    loading,
    title,
    reviewsThreshold,
    appliedQuery,
    infoBoxContent = null,
    domain,
  }) => {
    const [from, to] = reviewsThreshold;
    React.useEffect(() => {
      async function load({ from, to }, domain) {
        await actions.loadReviewsSample({ from, to }, domain);
      }
      if (appliedQuery) {
        load({ from, to }, domain);
      }
    }, [appliedQuery, from, to, domain, actions]);

    if (loading || reviews === null) return <Loader />;
    if (Array.isArray(reviews) && !reviews.length)
      return (
        <ChartError>
          <h3 className="title is-5">
            There are no reviews matching your current selection.
          </h3>
        </ChartError>
      );
    return (
      <div className="box">
        <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
          <h3 className="is-5 title is flex is-justify-content-center">
            {title}
          </h3>
          {infoBoxContent && <InformationBox>{infoBoxContent}</InformationBox>}
        </div>
        <div className="px-4" style={{ height: 400 }}>
          <Chart
            chartType="WordCloud"
            exportMenu={true}
            config={getConfig({ data: reviews, domain })}
            chartId={domain}
          />
        </div>
      </div>
    );
  },
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loadReviewsSample }, dispatch),
});

const mapStateToProps = (state, { domain }) => {
  return {
    domain,
    reviews: getEntity(state, domain, 'reviews', { reviews: null }),
    error: get([domain, 'error'], state),
    loading: get([domain, 'isLoading'], state),
    appliedQuery: get(['filters', 'query'], state),
  };
};

export const WordCloudComposition = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WordCloud);
