import React from 'react';
import KPIButton from './components/KPIButton';
import InformationIcon from 'components/Icons/Information';
import './index.scss';
import '../styles/vars.scss';
import ExcelIcon from '../../../components/Icons/ExcelIcon/index';
import { Table, TableHead } from 'components/Tables';
import { CustomPagination } from 'routes/performance/Supply/Layout';
//import { getCurrencySymbol } from 'utils';

interface ISelfReportingKPIs {
  isActive: boolean;
}

const dateButtons = [
  {
    label: `Year to Date`,
    click: () => null,
  },
  {
    label: `This Week`,
    click: () => null,
  },
  {
    label: `This Month`,
    click: () => null,
  },
  {
    label: `This Year`,
    click: () => null,
  },
];

const tableHeadData = [
  `Property Title`,
  `Picture`,
  `Unit ID`,
  `YtD RevPAr ($)`,
  `YtD Occupancy`,
  `YtD ADR ($)`,
  `YtD GBV ($)`,
];

const tableData = [
  {
    propertyTitle: `The World Famous Seashell House ~ Casa Caracol`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Romantic Cabana with view`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Sunny, Modern room in East Village!`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Brownstone Studio`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `White shared space with 0min to bus stop`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `The World Famous Seashell House ~ Casa Caracol`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Romantic Cabana with view`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Sunny, Modern room in East Village!`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `Brownstone Studio`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
  {
    propertyTitle: `White shared space with 0min to bus stop`,
    picture: `https://fakeimg.pl/350x200/?text=World&font=lobster`,
    unitId: `00000`,
    ytdRevPar: `00000`,
    ytdOccupancy: `00000`,
    ytdAdr: `00000`,
    ytdGbv: `00000`,
  },
];

const TableRow: React.FunctionComponent<any> = ({
  propertyTitle,
  picture,
  unitId,
  ytdRevPar,
  ytdOccupancy,
  ytdAdr,
  ytdGbv,
  key,
}) => {
  return (
    <tr>
      <td>{propertyTitle}</td>
      <td>
        <img
          className="picture"
          alt="Property"
          src={picture}
          style={{ maxWidth: `200px` }}
        />
      </td>
      <td>{unitId}</td>
      <td>{ytdRevPar}</td>
      <td>{ytdOccupancy}</td>
      <td>{ytdAdr}</td>
      <td>{ytdGbv}</td>
    </tr>
  );
};

const SelfReportingKPIs: React.FunctionComponent<ISelfReportingKPIs> = ({
  isActive,
}) => {
  if (!isActive) return null;
  //const currencySymbol = getCurrencySymbol(intl, currency);

  return (
    <>
      <section id="self-reporting-kpis-selection">
        <h2 className="self-reporting-kpis-title">KPIs</h2>
        <div className="self-reporting-kpis-container">
          <h3 className="self-reporting-kpis-subtitle">Present Dates:</h3>
          <ul className="self-reporting-kpis-dates-selection">
            {dateButtons.map(config => (
              <KPIButton {...config} />
            ))}
          </ul>
          <div className="self-reporting-actions">
            <InformationIcon isActive={true} />
            <ExcelIcon />
          </div>
        </div>
      </section>
      <div className="sr-kpis-overview-container">
        <Table isBordered={false} isCentered={true}>
          <thead>
            <TableHead>ADR</TableHead>
            <TableHead>RevPar</TableHead>
            <TableHead>Total Revenue</TableHead>
            <TableHead>Occupied Nights</TableHead>
            <TableHead>Active Properties</TableHead>
            <TableHead>Paid Occupancy</TableHead>
          </thead>
          <tbody>
            <tr>
              <td>$285</td>
              <td>$80</td>
              <td>$5,000</td>
              <td>25</td>
              <td>34</td>
              <td>78%</td>
            </tr>
            <tr>
              <td>$276</td>
              <td>$72</td>
              <td>$4,895</td>
              <td>18</td>
              <td>24</td>
              <td>73%</td>
            </tr>
            <tr>
              <td className="positive">+3%</td>
              <td className="positive">+10%</td>
              <td className="positive">+2%</td>
              <td className="positive">+28%</td>
              <td className="positive">+29%</td>
              <td className="negative">-7%</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <header className="sr-top-performing-header">
        <h2 className="sr-top-performing-title">Top Performing Properties</h2>
        <div className="self-reporting-actions">
          <InformationIcon isActive={true} />
          <ExcelIcon />
        </div>
      </header>
      <div className="sr-top-performing-table-container">
        <Table isCentered={true} isBordered={true}>
          <thead>
            {tableHeadData.map(thd => (
              <TableHead>{thd}</TableHead>
            ))}
          </thead>
          <tbody>
            {/*cleanUrls.length === 0 ? <EmptyRow /> : null*/}
            {tableData.map((data, i) => (
              <TableRow {...data} />
            ))}
          </tbody>
        </Table>
        <CustomPagination currentPage={1} pages={3} onChange={() => null} />
      </div>
    </>
  );
};

export default SelfReportingKPIs;
