import * as React from 'react';
import { Table, TableHead, DateHead } from 'components/Tables';
import { ListingLevelLine } from './ListingLevelLine';
import { FormattedMessage } from 'react-intl';

export const TableLayout = ({
  isAsc,
  setSortBy,
  isSortedBy,
  elementsPerPage,
  currentPage,
  currency,
  listingsDetails,
  pricingDates,
  listingsPricing,
  isPricingLoading,
}) => (
  <Table isCentered={true}>
    <thead>
      <tr>
        <TableHead>#</TableHead>
        <TableHead
          width="450px"
          onClick={setSortBy('title')}
          isAsc={isAsc}
          isSortedBy={isSortedBy('title')}
        >
          <FormattedMessage id="tables.headers.property_title" />
        </TableHead>
        <TableHead
          width="75px"
          isAsc={isAsc}
          onClick={setSortBy('platform')}
          isSortedBy={isSortedBy('platform')}
        >
          <FormattedMessage id="filters.platform" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('zipcode')}
          isSortedBy={isSortedBy('zipcode')}
          width="85px"
        >
          <FormattedMessage id="filters.zipcode" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('bedrooms')}
          isSortedBy={isSortedBy('bedrooms')}
          width="85px"
        >
          <FormattedMessage id="filters.bedrooms" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('reviewScore')}
          isSortedBy={isSortedBy('reviewScore')}
          width="110px"
        >
          <FormattedMessage id="tables.headers.review_score" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('reviewCount')}
          isSortedBy={isSortedBy('reviewCount')}
          width="110px"
        >
          <FormattedMessage id="tables.headers.review_count" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('adr')}
          isSortedBy={isSortedBy('adr')}
          width="110px"
        >
          <FormattedMessage id="tables.headers.adr" />
        </TableHead>
        <TableHead
          isAsc={isAsc}
          onClick={setSortBy('avgOcc')}
          isSortedBy={isSortedBy('avgOcc')}
          width="110px"
        >
          <FormattedMessage id="tables.headers.average_occupancy" />
        </TableHead>
        {isPricingLoading
          ? null
          : pricingDates.map(date => (
              <DateHead width="85px" key={date} date={date} />
            ))}
      </tr>
    </thead>
    <tbody>
      {listingsDetails.map((stats, idx) => {
        return (
          <ListingLevelLine
            idx={elementsPerPage * (currentPage - 1) + (idx + 1)}
            key={idx}
            pricingDates={pricingDates}
            listingsPricing={listingsPricing}
            currency={currency}
            isLoading={isPricingLoading}
            {...stats}
          />
        );
      })}
    </tbody>
  </Table>
);
