export const IS_LOADING = 'IS_LOADING';
export const IS_READY = 'IS_READY';
export const OPEN_ASIDE = 'OPEN_ASIDE';
export const CLOSE_ASIDE = 'CLOSE_ASIDE';

export interface ILayoutState {
  isLoading: boolean;
  isOpen: boolean;
}

export interface IIsLoading {
  type: typeof IS_LOADING;
}

export interface IIsReady {
  type: typeof IS_READY;
}

export interface IOpenAside {
  type: typeof OPEN_ASIDE;
}

export interface ICloseAside {
  type: typeof CLOSE_ASIDE;
}

export type ILayoutActions = IIsLoading | IIsReady | ICloseAside | IOpenAside;
