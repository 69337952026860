import { createStyles } from '@material-ui/core';
import { drawerWidth } from '../styles';

export default theme =>
  createStyles({
    hidden: {
      display: 'none',
    },
    actionsContainer: {
      padding: '30px 10px 30px 10px',
      justifyContent: 'space-between',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: drawerWidth,
      background: 'white',
      borderTop: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
    },
    calendarContainer: {
      padding: 0,
      paddingBottom: 64,
    },
    applyButton: {
      color: 'white',
      boxShadow: 'none',
      fontWeight: 700,
    },
    clearButton: {
      color: '#888',
    },
    filterContainer: {
      height: '100%',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
    iconContainer: {
      height: 20,
    },
    listItemRoot: {
      minWidth: 0,
    },
  });
