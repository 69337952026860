import { uniq } from 'lodash';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ExtraArguments, ApplicationState } from 'store';
import { getToken, getCleanUrls } from 'store/auth/selectors';

import { RawGroup, Group, PM_GROUPS_DOMAIN } from '../types';

import { Status } from '../lib/enums';
import { createPaginator, createSelector } from 'store/pagination';
import {
  AddListingSelection,
  RemoveListingSelection,
  CreatePMGroupFailure,
  DeleteFromGroupFail,
  DeleteFromGroupSuccess,
  DeletePMGroupFailure,
  DeletePMGroupStart,
  DeletePMGroupSuccess,
  FetchPMGroupsFailure,
  FetchPMGroupsStart,
  FetchPMGroupsSuccess,
  HideCreatePMGroupsResult,
  RemoveAllListingSelection,
  ResetDeleteCount,
  UpdateGroupStatus,
  CreatePMGroupStartLS,
  CreatePMGroupSuccessLS,
  CreateUpdatePMGroupFailureLS,
  UpdatePMGroupSuccessLS,
  SetSavedFilters,
  SetCurrentListingsDomain,
} from './reducer';
import { EDIT_GROUP_DOMAIN, LISTING_SELECTION_DOMAIN } from './types';

const supplyEndpoint = 'listings_stats';

export const listingDetailsPaginator = createPaginator(supplyEndpoint);
export const listingSelectionPaginator = createPaginator(supplyEndpoint);
export const editGroupPaginator = createPaginator(supplyEndpoint);

export const listingSelectionSelector = createSelector(
  LISTING_SELECTION_DOMAIN,
);
export const editGroupSelector = createSelector(EDIT_GROUP_DOMAIN);

export const getCurrentPaginator = domain =>
  domain === LISTING_SELECTION_DOMAIN
    ? listingSelectionPaginator
    : editGroupPaginator;

export const addListingSelection: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = listing => dispatch => {
  dispatch(AddListingSelection({ listing }));
};

export const removeListingSelection: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = unified_id => dispatch => {
  dispatch(RemoveListingSelection({ unified_id }));
};

export const removeAllListingSelection: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => {
  dispatch(RemoveAllListingSelection());
};
interface ListingURLParseResult {
  url: string;
  unifiedIds: string[];
}

export const createListingsSelectionGroup: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (finalGroup: { groupName: string; unifiedIds: string[] }) => async (
  dispatch,
  getState,
) => {
  try {
    const state = getState();
    const cleanUrl = getCleanUrls(state)[0];
    const token = getToken(state);
    const { groups: currentGroups } = state.pmGroups;

    dispatch(CreatePMGroupStartLS());

    const {
      data: {
        added,
        groups: [group],
      },
    } = await apiGroupAction<{ added: number; groups: Group[] }>({
      data: { group: finalGroup, cleanUrl },
      method: 'POST',
      token,
      url: `${process.env.REACT_APP_SETTINGS_URI}/groups`,
    });

    const existingGroup =
      currentGroups.findIndex(g => g.groupName === finalGroup.groupName) !== -1;

    const action = existingGroup
      ? CreatePMGroupSuccessLS
      : UpdatePMGroupSuccessLS;
    dispatch(
      action({
        ...(added && { added }),
        ...(group && { group }),
      }),
    );
  } catch (error) {
    dispatch(CreateUpdatePMGroupFailureLS({ error: error.message ?? error }));
  }
};

export const createGroup: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (rawGroup: RawGroup) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { groups: currentGroups } = state.pmGroups;
    const cleanUrl = getCleanUrls(state)[0];
    const token = getToken(state);

    const urlsBatches = uniq(rawGroup.urls);

    const {
      data: { data: unifiedIds },
    } = await apiGroupAction<{ data: ListingURLParseResult[] }>({
      data: { urls: urlsBatches },
      method: 'POST',
      onStart: () =>
        dispatch(UpdateGroupStatus({ status: Status.RUNNING, added: 0 })),
      token,
      url: `${process.env.REACT_APP_API_URI}/listing_url_parser`,
    });

    const okUrls = unifiedIds
      .filter(info => info.unifiedIds.length > 0)
      .map(info => info.url);
    const unifiedIdsByUrl = okUrls.reduce<{ [url: string]: string[] }>(
      (acc, url) => {
        if (!acc[url]) acc[url] = [];
        acc[url].push(
          ...(unifiedIds.find(info => info.url === url)?.unifiedIds || []),
        );
        return acc;
      },
      {},
    );

    const matchedUrlsCount = Object.keys(unifiedIdsByUrl).length;
    const koUrls = urlsBatches.filter(
      url => !Object.keys(unifiedIdsByUrl).some(u => u === url),
    );
    const finalGroup: Group = {
      groupName: rawGroup.name,
      unifiedIds: okUrls.reduce(
        (acc, url) => [...acc, ...unifiedIdsByUrl[url]],
        [],
      ),
    };
    finalGroup.unifiedIds = uniq(finalGroup.unifiedIds);

    const {
      data: {
        added,
        groups: [group],
      },
    } = await apiGroupAction<{ added: number; groups: Group[] }>({
      data: { group: finalGroup, cleanUrl },
      method: 'POST',
      token,
      url: `${process.env.REACT_APP_SETTINGS_URI}/groups`,
    });

    const existingGroup =
      currentGroups.findIndex(g => g.groupName === finalGroup.groupName) !== -1;

    let status = Status.CREATED;
    if (existingGroup) status = Status.UPDATED;

    dispatch(
      UpdateGroupStatus({
        status,
        added,
        koUrls,
        group,
        matched: matchedUrlsCount,
        showResults: true,
      }),
    );
  } catch (error) {
    dispatch(CreatePMGroupFailure({ error: error.message ?? error }));
  }
};

export const hideCreationResults: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => dispatch(HideCreatePMGroupsResult());

export const fetchGroups: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const cleanUrl = getCleanUrls(state)[0];
    const token = getToken(state);

    const {
      data: { groups },
    }: { data: { groups: Group[] } } = await apiGroupAction({
      onStart: () => dispatch(FetchPMGroupsStart()),
      token,
      url: `${
        process.env.REACT_APP_SETTINGS_URI
      }/groups?cleanUrl=${encodeURIComponent(cleanUrl)}`,
    });

    const sortCallback = (a, b) => a.groupName.localeCompare(b.groupName);
    groups.sort(sortCallback);

    dispatch(FetchPMGroupsSuccess({ groups }));
  } catch (error) {
    dispatch(FetchPMGroupsFailure({ error: error.message ?? error }));
  }
};

export const deleteGroup: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (group: Group) => async (dispatch, getState) => {
  try {
    const state = getState();
    const cleanUrl = getCleanUrls(state)[0];
    const token = getToken(state);
    let { groups } = state[PM_GROUPS_DOMAIN];

    await apiGroupAction({
      method: 'DELETE',
      onStart: () => dispatch(DeletePMGroupStart()),
      token,
      url: `${
        process.env.REACT_APP_SETTINGS_URI
      }/groups?cleanUrl=${encodeURIComponent(
        cleanUrl,
      )}&groupName=${encodeURIComponent(group.groupName)}`,
    });
    groups = groups.filter(g => g.groupName !== group.groupName);
    dispatch(DeletePMGroupSuccess({ groups }));
  } catch (error) {
    dispatch(DeletePMGroupFailure({ error: error.message ?? error }));
  }
};

export const updateGroupsStatus: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (status: number) => dispatch => {
  dispatch(UpdateGroupStatus({ status }));
};

export const deleteFromGroup: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (
  group: Group,
  listingsSelected: any[],
  cleanUrl: any,
  successCallback: () => any,
) => async (dispatch, getState) => {
  const state = getState();
  const token = getToken(state);

  dispatch(ResetDeleteCount());

  try {
    group = {
      ...group,
      unifiedIds: group.unifiedIds.filter(
        uid => !listingsSelected.includes(uid),
      ),
    };

    const data = {
      cleanUrl,
      group,
    };
    const response = await apiGroupAction<any>({
      data,
      method: 'PUT',
      token,
      url: `${process.env.REACT_APP_SETTINGS_URI}/groups`,
    });

    dispatch(DeleteFromGroupSuccess({ deleteCount: response.data.deleted }));
    dispatch(RemoveAllListingSelection());
    successCallback();
  } catch (error) {
    dispatch(DeleteFromGroupFail({ error: error.message ?? error }));
  }
};

export const resetStatus: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => {
  dispatch(UpdateGroupStatus({ status: Status.IDLE }));
};

export const setSavedFilters: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (filters: any[]) => async dispatch => {
  dispatch(SetSavedFilters({ filters }));
};

export const setCurrentListingsDomain: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (listingsDomain: string) => async dispatch => {
  dispatch(SetCurrentListingsDomain({ listingsDomain }));
};

interface ApiGroupActionOptions extends AxiosRequestConfig {
  onFailure?: (reason: Error) => void;
  onStart?: () => void;
  onSuccess?: (response: AxiosResponse) => void;
  token: string;
}

function apiGroupAction<T = any>(
  options: ApiGroupActionOptions,
): Promise<AxiosResponse<T>> {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: false,
  });

  return new Promise((rs, rj) => {
    options.onStart && options.onStart();
    axiosInstance.request(options).then(
      response => {
        options.onSuccess && options.onSuccess(response);
        rs(response);
      },
      (reason: Error) => {
        options.onFailure && options.onFailure(reason);
        rj(reason);
      },
    );
  });
}
