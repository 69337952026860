import React from 'react';

const Downarrow = props => (
  <svg viewBox="0 0 31.479 31.479" {...props}>
    <path
      className="downarrow"
      d="M26.485 21.206a1.14 1.14 0 000-1.587c-.444-.429-1.159-.429-1.587 0l-8.047 8.047V1.111C16.851.492 16.359 0 15.74 0s-1.127.492-1.127 1.111v26.555l-8.031-8.047a1.14 1.14 0 00-1.587 0 1.14 1.14 0 000 1.587l9.952 9.952a1.14 1.14 0 001.587 0l9.951-9.952z"
      fill="#1e201d"
    />
  </svg>
);

export default Downarrow;
