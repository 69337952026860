import { StyleRules, createStyles } from '@material-ui/core/styles';
export const drawerWidth = 277;

const styles = (theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      overflowX: 'hidden',
    },
    asideLogo: {
      width: '100%',
      paddingLeft: 17,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      left: 0,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      left: -drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: 0,
      },
    },
    appBarSpacer: theme.mixins.toolbar,

    content: {
      height: '100%',
      marginLeft: drawerWidth,
      marginRight: 48,
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentHidden: {
      marginLeft: 48,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: 2,
    },
  });

export default styles;
