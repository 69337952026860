import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import markets from './markets.json';
export * from './cache';
export const messages = {
  en: { ...en, ...markets },
  es: { ...es, ...markets },
  fr: { ...fr, ...markets },
  it: { ...it, ...markets },
};
