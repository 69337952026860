import * as React from 'react';
import { withStyles, StyleRules, createStyles } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core';

const styles = (theme): StyleRules =>
  createStyles({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: 3,
      marginTop: 70,
      minHeight: '80vh',
      overflow: 'auto',
    },
  });

const Layout: React.SFC<StyledComponentProps> = function({
  children,
  classes,
}) {
  return <main className={classes.content}>{children}</main>;
};

export default withStyles(styles)(Layout);
