import * as React from 'react';
import { ReviewsComposition } from './layout';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';
export const ReviewsPage: React.FunctionComponent<any> = () => (
  <Layout>
    <Dashboard mainListItems={<Filters />}>
      <ReviewsComposition />
    </Dashboard>
  </Layout>
);
