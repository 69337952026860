import * as React from 'react';
import {
  Filters as FiltersDumb,
  FiltersActions,
  FiltersError,
} from 'components/Dashboard/Filters';
import { List } from '@material-ui/core';
import { store } from '../store/store';
import { useState, useContext } from 'react';
import {
  default as useFilters,
  emptyAvailableFilters,
  parseQuery,
} from '../hooks/use-filters';
import { Loader } from 'components/Layout';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { PendingChangesStatus } from '../lib/enum';

const Filters: React.FunctionComponent = () => {
  const { availableFilters } = useFilters();
  const [internalSelectedFilters, selectFilter] = useState<any>(
    emptyAvailableFilters,
  );

  const { queryFilters, setQueryFilters, setPendingChanges } = useContext(
    store,
  );

  const isAsideOpen = useSelector<ApplicationState, boolean>(
    state => state.layout.isOpen,
  );
  const [isOverButton, setIsOverButton] = useState<boolean>(false);

  const handleChange = (name: string) => (value: any) =>
    selectFilter({ ...internalSelectedFilters, [name]: value });

  const handleBlur = async () => {
    isOverButton
      ? await Promise.resolve()
          .then(() => setQueryFilters(parseQuery(internalSelectedFilters)))
          .then(() => handleSubmit())
          .then(() => setIsOverButton(false))
      : setQueryFilters(parseQuery(internalSelectedFilters));
  };
  const handleClear = () => {
    selectFilter(emptyAvailableFilters);
    availableFilters.setInitialState();
  };
  const handleSubmit = () => setPendingChanges(PendingChangesStatus.RUN);

  if (availableFilters.error)
    return (
      <FiltersError
        error={`Error`}
        retryLoad={() => availableFilters.fetchFilters(queryFilters)}
      />
    );
  if (availableFilters.loading) return <Loader />;

  const filtersProps = {
    availableFilters: availableFilters.filters,
    handleChange,
    selectedFilters: internalSelectedFilters,
    handleBlur,
  };

  return (
    <List>
      <FiltersDumb {...filtersProps} />
      <FiltersActions
        open={isAsideOpen}
        handleClear={handleClear}
        onClickSubmitButton={handleSubmit}
        onMouseEnter={() => setIsOverButton(true)}
        onMouseLeave={() => setIsOverButton(false)}
      />
    </List>
  );
};

export default Filters;
