import * as React from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const styles = {
  large: {
    fontSize: 30,
  },
};
function House({
  classes,
  fontSize,
  ...props
}: SvgIconProps & StyledComponentProps) {
  return (
    <SvgIcon
      {...props}
      classes={{
        fontSizeLarge: classes.large,
      }}
      fontSize={fontSize}
    >
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export default withStyles(styles)(House);
