import * as React from 'react';

import DownloadIcon from 'components/Icons/ExcelIcon';

export const ExportButton = ({ downloadCSV, downloading }) => {
  const onClick = () => downloadCSV();

  return (
    <button
      onClick={onClick}
      className={`button is-white${downloading ? ' is-loading' : ''}`}
      style={{
        display: 'flex',
        width: 40,
        height: 40,
        alignItems: 'center',
      }}
    >
      {!downloading && <DownloadIcon htmlColor="#afc3cf" />}
    </button>
  );
};
