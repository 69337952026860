import { Reducer } from 'redux';

import {
  IPriceVariationsState,
  PriceVariationsActions,
  SET_PRICE_VARIATIONS,
  START_LOADING_PRICE_VARIATIONS,
  LOAD_PRICE_VARIATIONS_ERROR,
  SET_PRICE_VARIATIONS_QUERY,
} from './types';

const defaultState: IPriceVariationsState = { isLoading: true, query: null };

const PriceVariations: Reducer<
  IPriceVariationsState,
  PriceVariationsActions
> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PRICE_VARIATIONS:
      return {
        ...state,
        calendar: action.payload.calendar,
        isLoading: false,
        error: null,
      };
    case START_LOADING_PRICE_VARIATIONS:
      return { ...state, isLoading: true };
    case LOAD_PRICE_VARIATIONS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case SET_PRICE_VARIATIONS_QUERY:
      return { ...state, query: action.payload.query };
    default:
      return state;
  }
};
export default PriceVariations;
