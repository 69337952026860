import { Mixpanel } from 'services/mixpanel/index';
import { XYChart, PieChart } from '@amcharts/amcharts4/charts';
import { IExportMenuItem, ExportMenu } from '@amcharts/amcharts4/core';
export const setExportMenu = (
  chartId: string,
  chart: XYChart | PieChart,
): void => {
  const menuItems: IExportMenuItem[] = [
    {
      label: '...',
      menu: [
        {
          label: 'Data',
          menu: [
            {
              type: 'custom',
              label: 'JSON',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'JSON',
                  });
                  chart.exporting.export('json');
                },
              },
            },
            {
              type: 'custom',
              label: 'CSV',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'CSV',
                  });
                  chart.exporting.export('csv');
                },
              },
            },
            {
              type: 'custom',
              label: 'XLSX',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'XLSX',
                  });
                  chart.exporting.export('xlsx');
                },
              },
            },
          ],
        },
        {
          label: 'Image',
          menu: [
            {
              type: 'custom',
              label: 'PNG',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'PNG',
                  });
                  chart.exporting.export('png');
                },
              },
            },
            {
              type: 'custom',
              label: 'JPG',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'JPG',
                  });
                  chart.exporting.export('jpg');
                },
              },
            },
            {
              type: 'custom',
              label: 'GIF',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'GIF',
                  });
                  chart.exporting.export('gif');
                },
              },
            },
            {
              type: 'custom',
              label: 'SVG',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'SVG',
                  });
                  chart.exporting.export('svg');
                },
              },
            },
            {
              type: 'custom',
              label: 'PDF',
              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'PDF',
                  });
                  chart.exporting.export('pdf');
                },
              },
            },
          ],
        },
        {
          label: 'Print',
          type: 'custom',

          options: {
            callback() {
              Mixpanel.track('Download chart data', {
                chartId,
                type: 'PRINT',
              });
              chart.exporting.export('print');
            },
          },
        },
      ],
    },
  ];
  chart.exporting.menu = new ExportMenu();
  chart.exporting.menu.items = menuItems;
  chart.exporting.filePrefix = chartId;
};
