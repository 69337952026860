export const ACTIONS = {
  STATUS: 'STATUS_LISTINGSDETAILS',
  START_LOADING: `START_LOADING_LISTINGSDETAILS`,
  LOAD_SUCCESS: `LOAD_LISTINGSDETAILS_SUCCESS`,
  LOAD_FAILED: `LOAD_LISTINGSDETAILS_FAILED`,
};

export const listingsDetailsDomain = 'listingsDetails';
export const listingsPricingDomain = 'listingsPricing';

export enum Status {
  IDLE = 0,
  RUNNING = 1,
  SUCCESS = 2,
  FAILED = 3,
}
