import * as React from 'react';
import { FaqItem } from './FaqItem';
import { IFaq } from './types';

function Faq({ title, faqItems }: IFaq) {
  return (
    <div className="column is-6">
      <div className="box first-section">
        <p className="title is-4">{title}</p>
        <hr style={{ height: '1px', color: '#ff0' }} />
        {faqItems.map((item, index) => (
          <FaqItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Faq;
