import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_wordCloud from '@amcharts/amcharts4/plugins/wordCloud';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import styled from 'styled-components';
import { setExportMenu } from './export';

type ChartTypesEnum = 'XYChart' | 'PieChart' | 'WordCloud';

interface ChartProps {
  config: {
    [index: string]: any;
  };
  chartId: string;
  chartType: ChartTypesEnum;
  exportMenu?: boolean;
  data?: any[];
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

function getChartType(chartType: ChartTypesEnum) {
  return {
    XYChart: am4charts.XYChart,
    PieChart: am4charts.PieChart,
    WordCloud: am4plugins_wordCloud.WordCloud,
  }[chartType];
}

export class Chart extends React.PureComponent<ChartProps> {
  private chart:
    | am4charts.XYChart
    | am4charts.PieChart
    | am4plugins_wordCloud.WordCloud;

  componentDidMount() {
    this.initChart();
  }

  componentWillUnmount() {
    this.dispose();
  }

  initChart() {
    const { chartId, config, data, chartType, exportMenu } = this.props;
    const ChartType = getChartType(chartType);
    am4core.useTheme(am4themesAnimated);
    this.chart = am4core.createFromConfig(config, chartId, ChartType) as
      | am4charts.XYChart
      | am4charts.PieChart;
    if (data) {
      this.chart.data = [...data];
    }
    if (!!this.chart && exportMenu) {
      setExportMenu(chartId, this.chart);
    }
  }

  dispose() {
    if (!!this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
  render() {
    const { chartId } = this.props;
    return <StyledDiv id={chartId} />;
  }
}
