import * as React from 'react';
import * as moment from 'moment';
import { Divider, Typography } from '@material-ui/core';
import EventToggle from '../../../components/Events/EventToggle';

import CalendarWeek from '../../../components/Calendar/CalendarWeek';
import CalendarContainer from '../../../components/Calendar/CalendarContainer';
import {
  ItemContainer,
  EmptyLine,
  ContentLine,
} from '../../../components/Calendar/Item';
import Semaphore from '../../../components/Calendar/Semaphore';
import {
  transformPriceVariationsCalendar,
  getSemaphoreColor,
  getPercent,
} from '../../../components/Calendar/utils';

const FIRST_WEEK_DAY = 0; // Sunday
const FIRST_YEAR_WEEK = 4; // Jan 4th

const labels = [
  'price_variations.my_price_variation',
  'price_variations.competitors',
  'price_variations.demand',
];

function PriceVariationsCalendar({
  calendar,
  currency,
  eventsCalendar,
  handleOpen,
}) {
  function ItemLayout({ item, locale, currency, event }) {
    const { marketPriceVariation, occupancy, priceVariation } = item;
    return (
      <ItemContainer inactive={item.inactive} date={item.date}>
        <>
          <ContentLine>
            <Semaphore
              value={priceVariation}
              color={getSemaphoreColor(priceVariation)}
              locale={locale}
            />
          </ContentLine>
          <ContentLine>
            <Semaphore
              value={marketPriceVariation}
              color={getSemaphoreColor(marketPriceVariation)}
              locale={locale}
            />
          </ContentLine>
          <Divider />
          <ContentLine>
            {occupancy ? (
              <Typography display="inline" variant="body2">
                {getPercent(occupancy, locale)}
              </Typography>
            ) : (
              <EmptyLine />
            )}
            {event && <EventToggle onClick={handleOpen(item.date)} />}
          </ContentLine>
        </>
      </ItemContainer>
    );
  }
  const weeks = transformPriceVariationsCalendar(calendar);
  const locale = 'en';

  moment.locale(locale, {
    week: {
      dow: FIRST_WEEK_DAY,
      doy: FIRST_YEAR_WEEK,
    },
  });
  return (
    <CalendarContainer>
      <>
        {weeks.map((week, index) => {
          return (
            <CalendarWeek
              key={index}
              dates={week}
              locale={locale}
              labels={labels}
              ItemLayout={ItemLayout}
              currency={currency}
              events={eventsCalendar}
            />
          );
        })}
      </>
    </CalendarContainer>
  );
}

export default PriceVariationsCalendar;
