import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Loader } from 'components/Layout';
import { ApplicationState } from 'store';
import { getEntities } from 'store/entities/selectors';
import { getFilterQuery } from 'store/filters/selectors';
import { serializeSortKey } from 'store/pagination';

import Table from './Layout';
import {
  loadSupplyFirstPage,
  loadSupplyPage,
  selector,
} from '../store/actions';
import { PERFORMANCE_DOMAIN } from '../types';

type PerformanceTableProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const PerformanceTable: React.SFC<PerformanceTableProps> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Loader /> : <Table {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadSupplyFirstPage, loadSupplyPage }, dispatch),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const { currency } = state[PERFORMANCE_DOMAIN]?.supply || {};
  const appliedQuery = getFilterQuery(state);
  const isLoading = !appliedQuery;
  const { page, sortKey } = selector.getCurrentPage(state);
  const [orderBy, order] = serializeSortKey(sortKey);
  const numberOfPages = selector.getTotalPages(state);
  const listings = getEntities(
    state,
    PERFORMANCE_DOMAIN,
    selector.getPageIds(state, { page, sortKey }),
  );

  return {
    appliedQuery,
    currency,
    currentPage: page,
    error: state[PERFORMANCE_DOMAIN]?.errorListings,
    fetching: state[PERFORMANCE_DOMAIN]?.fetchingSupply,
    isLoading,
    listings,
    numberOfPages,
    order,
    orderBy,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceTable);
