import * as React from 'react';
import Display from './layout/composition';
import Layout from '../../containers/Layout/Layout';
import Dashboard from '../../containers/Dashboard/Dashboard';
export const Supply: React.SFC<{}> = () => (
  <Layout shouldHideAside={true}>
    <Dashboard noAside={true}>
      <Display />
    </Dashboard>
  </Layout>
);
