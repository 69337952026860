import * as React from 'react';

const ImagePlaceholder = props => (
  <svg
    id="image_placeholder__Capa_1"
    x={0}
    y={0}
    viewBox="0 0 214 214"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.image_placeholder__st0{fill:#afbdcb}'}</style>
    <path
      className="image_placeholder__st0"
      d="M0 214h174V40H0v174zM44.7 72.7c7.7 0 13.9 6.2 13.9 13.9s-6.2 13.9-13.9 13.9-13.9-6.2-13.9-13.9S37 72.7 44.7 72.7zm12.7 52.7l19.9 25.8 31-44 51.2 72.8H15.2l42.2-54.6z"
    />
    <path className="image_placeholder__st0" d="M44 0v26h144v144h26V0z" />
  </svg>
);

export default ImagePlaceholder;
