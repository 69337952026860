import { get, omit, isEqual, intersection } from 'lodash/fp';
import { normalize } from 'normalizr';
import { ThunkAction } from 'redux-thunk';
import { stringify } from 'qs';
import { push } from 'connected-react-router';
import { ExtraArguments, ApplicationState } from '../';
import {
  IFiltersActions,
  FILTERS_LOADING,
  FILTERS_LOADING_ERROR,
  FILTERS_READY,
  SET_DATE_RANGE,
  SET_FILTER,
  SET_FILTERS,
  SET_QUERY,
  ADD_DISABLED,
  REMOVE_DISABLED,
} from './types';
import { FiltersSchema } from './schema';
import { formatFilters } from '../../services/filters';
import { apiAction } from 'store/actions';
import { AnyAction } from 'redux';

type ThunkResult<R> = ThunkAction<
  R,
  ApplicationState,
  ExtraArguments,
  IFiltersActions
>;

function onSuccess(response) {
  return dispatch => {
    const data = normalize(response, FiltersSchema);
    const [minDate, maxDate] = get(
      ['entities', 'availableFilters', 'dates'],
      data,
    );
    const [from, to] = get(['entities', 'activeFilters', 'dates'], data);

    const nonInconsistent = ['market', 'dates', 'groups'];

    // Detect filter inconsistencies
    const inconsistentFilters: Set<string> = new Set();
    Object.keys(get(['entities', 'activeFilters'], data))
      .filter(filterName => !nonInconsistent.some(x => x === filterName))
      .forEach(filterName => {
        const activeFilter = data.entities.activeFilters[filterName];
        const availableFilter = get(['entities', 'availableFilters'], data)[
          filterName
        ];
        if (!availableFilter) {
          inconsistentFilters.add(filterName);
          return;
        }
        const i = (intersection(
          availableFilter,
          activeFilter,
        ) as unknown) as any[];
        if (!isEqual(i.sort(), activeFilter.sort()))
          inconsistentFilters.add(filterName);
      });

    if (inconsistentFilters.size > 0) {
      for (const name in inconsistentFilters) {
        dispatch({ type: SET_FILTER, payload: { name, filter: [] } });
      }
    }

    dispatch({
      type: SET_FILTERS,
      payload: {
        availableFilters: omit(
          'dates',
          get(['entities', 'availableFilters'], data),
        ),
        selectedFilters: omit(
          ['dates', ...Array.from(inconsistentFilters.values())],
          get(['entities', 'activeFilters'], data),
        ),
        dates: {
          minDate,
          maxDate,
          selectedDates: {
            from,
            to,
          },
        },
      },
    });
  };
}

export function loadFilters(_query = null): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();
    const token = get(['auth', 'token'], state);
    const serverQuery = get(['filters', 'query'], state);
    const query = stringify(_query);
    if (query && isEqual(serverQuery, query)) return null;
    return dispatch(
      apiAction({
        url: '/filters',
        accessToken: token,
        method: 'GET',
        params: query,
        label: 'filters',
        onStart: () => dispatch => dispatch({ type: FILTERS_LOADING }),
        onFailure: e => dispatch => {
          if (e.message.includes('400')) {
            dispatch(loadFilters()).then(() => dispatch(applyFilters()));
          }
          dispatch({
            type: FILTERS_LOADING_ERROR,
            payload: { error: e.message },
          });
          throw new Error('BREAK');
        },
        onSuccess,
        onFinish: () => dispatch => dispatch({ type: FILTERS_READY }),
      }),
    );
  };
}

export const updateCalendar = (start: Date, end: Date): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: { from: start, to: end },
    });
  };
};

export const updateFilter = (name, filter): ThunkResult<void> => {
  return async dispatch => {
    dispatch({
      type: SET_FILTER,
      payload: {
        name,
        filter,
      },
    });
  };
};

export const applyFilters = (): ThunkResult<Promise<void>> => {
  return async (dispatch, getState) => {
    const { selectedFilters, dates, query } = get('filters', getState());
    const newQuery = stringify(formatFilters(selectedFilters, dates));
    if (!isEqual(newQuery, query)) {
      dispatch({ type: SET_QUERY, payload: newQuery });
      dispatch(push({ search: newQuery }) as AnyAction);
    }
  };
};

export const setSelectedFilters = (selectedFilters: any): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        selectedFilters,
      },
    });
  };
};

export const addDisabled = (fieldName: string): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: ADD_DISABLED,
      payload: fieldName,
    });
  };
};

export const removeDisabled = (fieldName: string): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: REMOVE_DISABLED,
      payload: fieldName,
    });
  };
};
