import { combineReducers, Reducer } from 'redux';

import {
  RECEIVE_PAGE,
  REQUEST_PAGE,
  RESET_PAGINATION,
  GO_TO_PAGE,
  IPagesState,
  IPageActions,
  ICurrentPageAction,
  IMetaState,
  ICurrentPageState,
} from './types';
export * from './selectors';
export const getSortKey = ({
  orderBy,
  order,
}: {
  orderBy: string;
  order: string;
}) => `${orderBy}/${order}`;
export const serializeSortKey = (sortKey: string = 'default/default') =>
  sortKey.split('/');
export const createPaginator = (endpoint: string) => {
  const requestPage = ({ orderBy = 'default', page, order = 'ASC' }) => ({
    type: REQUEST_PAGE,
    payload: {
      orderBy,
      page,
      order,
    },
    meta: {
      endpoint,
    },
  });

  const receivePage = (
    { orderBy = 'default', page, order = 'ASC', limit = 15, total, ...extra },
    results: string[],
  ) => ({
    type: RECEIVE_PAGE,
    payload: {
      page,
      orderBy,
      order,
      results,
      meta: {
        ...extra,
        limit,
        total,
      },
    },
    meta: {
      endpoint,
    },
  });
  const goToPage = ({ orderBy, page, order }) => ({
    type: GO_TO_PAGE,
    payload: {
      page,
      order,
      orderBy,
    },
    meta: {
      endpoint,
    },
  });

  const resetPagination = () => ({
    type: RESET_PAGINATION,
    meta: { endpoint },
  });
  const pages: Reducer<IPagesState, IPageActions> = (pages = {}, action) => {
    const HANDLER = {
      [REQUEST_PAGE]: (state, { payload }) => ({
        ...state,
        [getSortKey(payload)]: {
          ...state[getSortKey(payload)],
          [payload.page]: {
            ids: [],
            fetching: true,
          },
        },
      }),
      [RECEIVE_PAGE]: (state, { payload }) => ({
        ...state,
        [getSortKey(payload)]: {
          ...state[getSortKey(payload)],
          [payload.page]: {
            ids: payload.results,
            fetching: false,
          },
        },
      }),
      [RESET_PAGINATION]: () => ({}),
    };
    const handler = HANDLER[action.type];
    return handler ? handler(pages, action) : pages;
  };

  const currentPage: Reducer<ICurrentPageState, ICurrentPageAction> = (
    currentPage = { sortKey: 'default/ASC', page: 1 },
    { payload, type },
  ) => {
    if ([RECEIVE_PAGE, GO_TO_PAGE].some(t => t === type)) {
      return {
        ...currentPage,
        sortKey: getSortKey(payload),
        page: payload.page,
      };
    }

    return currentPage;
  };

  const meta: Reducer<IMetaState> = (
    state = { limit: null, total: null },
    { type, payload },
  ) => {
    if (type === RECEIVE_PAGE) {
      return {
        ...state,
        ...payload.meta,
      };
    }
    return state;
  };

  const onlyForEndpoint = reducer => (state = {}, action) => {
    if (action?.meta?.endpoint === endpoint) {
      return reducer(state, action);
    }
    return state;
  };

  const reducer = combineReducers({
    pages: onlyForEndpoint(pages),
    currentPage: onlyForEndpoint(currentPage),
    meta: onlyForEndpoint(meta),
  });

  return {
    requestPage,
    receivePage,
    goToPage,
    resetPagination,
    reducer,
  };
};
