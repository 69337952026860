import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store/index';
// import { RatesCalendar as Display } from '../../../components/RatesTable';
import Display from './Layout';
import { Loader } from '../../../components/Layout/index';
import { toggleOpen, selectCurrentEvent } from '../../../store/events/actions';
import { EventActions } from '../../../store/events/types';
import EventContainer from '../Events';

type RatesCalendarProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const RatesCalendar: React.SFC<RatesCalendarProps> = function({
  isLoading,
  actions,
  ...props
}) {
  const handleOpen = (date: string) => () => {
    actions.selectCurrentEvent(date);
    actions.toggleOpen();
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Display {...props} handleOpen={handleOpen} />
      <EventContainer />
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    currency: state.rates.currency || 'USD',
    calendar: state.rates.calendar,
    isLoading: state.rates.isLoading,
    eventsCalendar: state.events.calendar || [],
  };
};
function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, EventActions>,
) {
  return {
    actions: bindActionCreators({ toggleOpen, selectCurrentEvent }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RatesCalendar);
