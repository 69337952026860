import { schema } from 'normalizr';

import { DISCOUNTS_DOMAIN } from '../types';

const discountsEntity = new schema.Entity(DISCOUNTS_DOMAIN, undefined, {
  idAttribute: ({ cleanUrl }) => cleanUrl,
});

export const discountsSchema = {
  data: {
    cleanUrls: [discountsEntity],
  },
};
