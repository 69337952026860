import { get, find, mapKeys, filter } from 'lodash';
import fetch from 'isomorphic-fetch';
import * as qs from 'qs';
import decodeToken from '../decode-token';
import data from './data.json';
import events from './events-database.json';

const API_URL = process.env.REACT_APP_API_URI;
const SUCCESS = 'SUCCESS';
interface IHeaders {
  [key: string]: string;
  'Content-Type': 'application/json';
  Authorization: string;
}

interface RequestParams {
  token: string;
  query?: any;
}
interface FetchDataParams {
  headers: IHeaders;
  query: string;
  path: string;
}

export class Api {
  token: string;
  public async fetchData({ query, headers, path }: FetchDataParams) {
    const response = await fetch(`${API_URL}${path}?${query}`, { headers });
    return await response.json();
  }

  public formatStringQuery(query, queryModifiers = {}) {
    return qs.stringify(
      { ...query, ...queryModifiers },
      {
        arrayFormat: 'brackets',
        filter: (prefix, value) => {
          if (prefix === 'type') return;
          if (prefix === 'comparative') return;
          return value;
        },
      },
    );
  }

  public formatHeaders({ token }) {
    const headers: IHeaders = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    return headers;
  }

  private async getDataSeriesComparative({ QueryObject, token, path }) {
    const headers = this.formatHeaders({ token });
    const decoded = decodeToken(token);
    const cleanUrls: string[] = get(decoded, 'sub.clean_urls');
    const [queryResult, myValues] = await Promise.all([
      this.fetchData({
        headers,
        query: this.formatStringQuery(QueryObject),
        path,
      }),
      this.fetchData({
        headers,
        query: this.formatStringQuery(QueryObject, { cleanUrl: cleanUrls }),
        path,
      }),
    ]);

    if ([queryResult, myValues].some(response => response.status !== SUCCESS)) {
      throw new Error('GetDataSeriesError');
    }
    const currency = queryResult['currency'];
    const result = queryResult['data'].map(obj => {
      const { key } = obj;
      const myObject = mapKeys(find(myValues['data'], { key }), (_, key) =>
        key === 'key' ? key : 'myValue',
      );
      return { ...obj, ...myObject };
    });
    return { currency, result };
  }

  private async getDataSeriesSingle({ QueryObject, token, path }) {
    const headers = this.formatHeaders({ token });
    const response = await this.fetchData({
      headers,
      query: this.formatStringQuery(QueryObject),
      path,
    });
    if (response['status'] !== SUCCESS) {
      throw new Error('GetDataSeriesError');
    }
    const result = response['data'];
    const currency = response['currency'];
    return { result, currency };
  }

  public async getRates({ token, query }: RequestParams) {
    const serverQuery = qs.stringify(query, { arrayFormat: 'brackets' });
    const headers = this.formatHeaders({ token });
    return await this.fetchData({
      headers,
      path: '/rates',
      query: serverQuery || '',
    });
  }

  public async getDataSeries({ token, query }: RequestParams): Promise<any> {
    const getPath = () => {
      if (query['type'] === 'supply_stats') return '/supply-stats';
      if (query['type'] === 'pricing_stats') return '/supply-pricing-stats';
      if (query['type'] === 'pricing_series') return '/supply-pricing-series';
    };

    const response = query['comparative']
      ? await this.getDataSeriesComparative({
          QueryObject: query,
          token,
          path: getPath(),
        })
      : await this.getDataSeriesSingle({
          QueryObject: query,
          token,
          path: getPath(),
        });
    return response;
  }

  public async getCartoMap({ token, query }: RequestParams) {
    const serverQuery = qs.stringify(query, { arrayFormat: 'brackets' });
    const headers = this.formatHeaders({ token });
    const data = await this.fetchData({
      headers,
      path: '/supply',
      query: serverQuery || '',
    });

    if (data['status'] === SUCCESS) return data['data'];
    throw new Error('supply.error.generic');
  }

  public async getPriceVariations({ token, query }: RequestParams) {
    const serverQuery = qs.stringify(query, { arrayFormat: 'brackets' });
    const headers = this.formatHeaders({ token });
    return await this.fetchData({
      headers,
      path: '/price_variation',
      query: serverQuery || '',
    });
  }
  public async getEvents({ token, query }: RequestParams) {
    const serverQuery = qs.stringify(query, { arrayFormat: 'brackets' });
    const headers = this.formatHeaders({ token });
    return await this.fetchData({
      headers,
      path: '/events',
      query: serverQuery || '',
    });
  }
  /**
   * Get PMS Connection info.
   * * Guesty reservation info.
   */
  public async getPMSConnection({ token }: RequestParams) {
    const headers = this.formatHeaders({ token });
    const response = await this.fetchData({
      headers,
      path: '/pmsconnection',
      query: '',
    });

    if (response.status === SUCCESS) return response.data;
    throw new Error(response.error || 'pmsconnections.error.generic');
  }
}

export class MockApi extends Api {
  async fetchData({ query = '', headers, path }) {
    console.log(`GET ${path}?${query} \n Headers: ${headers}`);
    return data;
  }

  public async getEvents({ query }: RequestParams) {
    const { market } = query;
    return {
      data: { days: filter(events, { market }) },
      status: 'SUCCESS',
    };
  }
}
