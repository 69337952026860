import { default as React, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { PacingTrends } from './charts/PacingTrends';
import { PacingReservations } from './charts/PacingReservations';
import InfoContainer from 'components/InformationBox';
import { Loader } from 'components/Layout';

interface BookedADRProps {
  data?: any;
  error?: any;
  fetching: boolean;
  loadTrends: () => void;
}

const BookedADR: React.FunctionComponent<BookedADRProps> = ({
  data: { trends, reservations } = { trends: null, reservations: null },
  error,
  fetching,
  loadTrends,
}) => {
  useEffect(() => {
    if (!trends && !error) loadTrends();
  }, [trends, error, loadTrends]);

  return (
    <>
      <div className="columns">
        <div className="column is-full">
          <div className="box mx-4">
            <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
              <h3 className="is-5 title is flex is-justify-content-center">
                <FormattedMessage
                  id="pacing.trends.title"
                  defaultMessage="Pacing Trends"
                />
              </h3>
              <InfoContainer>
                <FormattedMessage
                  id="pacing.trends.info"
                  values={{ n: () => <p className="mt-2" /> }}
                />
              </InfoContainer>
            </div>
            <div className="px-4" style={{ height: 500 }}>
              {fetching ? <Loader /> : <PacingTrends data={trends} />}
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-full">
          <div className="box mx-4">
            <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
              <h3 className="is-5 title is flex is-justify-content-center">
                <FormattedMessage
                  id="pacing.reservations.title"
                  defaultMessage="Count of reservations"
                />
              </h3>
              <InfoContainer>
                <FormattedMessage
                  id="pacing.reservations.info"
                  values={{ n: () => <p className="mt-2" /> }}
                />
              </InfoContainer>
            </div>
            <div className="px-4" style={{ height: 500 }}>
              {fetching ? (
                <Loader />
              ) : (
                <PacingReservations data={reservations} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedADR;
