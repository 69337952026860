import React from 'react';

const ErrorIcon = props => (
  <svg viewBox="0 0 13 13" {...props}>
    <path
      d="M6.5 12.9957C10.0899 12.9957 13 10.0875 13 6.50001C13 2.91251 10.0899 0.00427246 6.5 0.00427246C2.91015 0.00427246 0 2.91251 0 6.50001C0 10.0875 2.91015 12.9957 6.5 12.9957Z"
      fill="#E04F5F"
    />
    <path
      d="M8.56212 9.56654L9.56738 8.56128L4.43851 3.43241L3.43325 4.43767L8.56212 9.56654Z"
      fill="white"
    />
    <path
      d="M4.43863 9.56778L9.5675 4.4389L8.56224 3.43364L3.43337 8.56251L4.43863 9.56778Z"
      fill="white"
    />
  </svg>
);

export default ErrorIcon;
