import * as React from 'react';
import { createIntl, RawIntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { messages, intlCache } from './messages';
import { getLocale } from './store/selectors';

export const I18nConnectedLayout = ({ children, locale }) => {
  const intl = createIntl(
    {
      locale,
      messages: messages[locale],
    },
    intlCache,
  );
  return <RawIntlProvider value={intl}> {children} </RawIntlProvider>;
};

const mapStateToProps = state => ({
  locale: getLocale(state),
});

export const I18nConnectedProvider = connect(mapStateToProps)(
  I18nConnectedLayout,
);
