import * as React from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const styles = {
  large: {
    fontSize: 100,
  },
  st0: {
    opacity: 0.2,
    fill: '#08D8CB',
  },
  st1: {
    fill: '#08D8CB',
  },
};
function CountMarketIcon({
  classes,
  fontSize,
}: SvgIconProps & StyledComponentProps) {
  return (
    <SvgIcon
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 426.7"
      fontSize={fontSize}
    >
      <path
        className={classes.st0}
        d="M341.3,42.7H320V0h-42.7v42.7H106.7V0H64v42.7H42.7c-23.6,0-42.5,19.1-42.5,42.7L0,384 c0,23.6,19.1,42.7,42.7,42.7h298.7c23.6,0,42.7-19.1,42.7-42.7V85.3C384,61.8,364.9,42.7,341.3,42.7z M341.3,384H42.7V149.3 h298.7V384z"
      />
      <polygon
        className={classes.st1}
        points="288.6,214.6 266,192 161.9,296.1 116.7,250.9 94.1,273.5 161.9,341.3"
      />
    </SvgIcon>
  );
}

export default withStyles(styles)(CountMarketIcon);
