import { schema } from 'normalizr';

const stats = new schema.Entity('stats', undefined, {
  idAttribute: value => value.pm_domain_name,
});

export const pmStatsSchema = {
  data: {
    stats: [stats],
  },
};

const pmDomainDetails = new schema.Entity('pmDomainDetails', undefined, {
  idAttribute: ({ pm_domain_name }) => pm_domain_name,
});

export const pmDomainDetailsSchema = {
  data: {
    pmDomainDetails: [pmDomainDetails],
  },
};
