import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import MyGroupsView from './layout';
import { deleteGroup, fetchGroups } from '../store/actions';
import { PM_GROUPS_DOMAIN } from '../types';

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ deleteGroup, fetchGroups }, dispatch),
});

const mapStateToProps = (state: ApplicationState) => ({
  status: state[PM_GROUPS_DOMAIN]?.status,
  groups: state[PM_GROUPS_DOMAIN]?.groups,
  selectedGroup: state[PM_GROUPS_DOMAIN]?.selectedGroup,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyGroupsView);
