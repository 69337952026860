import { Action as ReduxAction, Reducer } from 'redux';

import { SET_QUERY } from 'store/filters/types';

import { PacingState, ACTIONS } from '../types';

interface Action extends ReduxAction {
  type: string;
  payload?: any;
}

interface ActionHandlers {
  [action: string]: (state: PacingState, action?: Action) => PacingState;
}

const ACTION_HANDLERS: ActionHandlers = {
  [SET_QUERY]: state => initialState,
  [ACTIONS.FETCH_PACING_BOOKEDADR_START]: state => ({
    ...state,
    bookedADR: null,
    bookedADRCurrency: null,
    error: null,
    fetchingBookedADR: true,
  }),
  [ACTIONS.FETCH_PACING_BOOKEDADR_FAILURE]: (state, { payload }) => ({
    ...state,
    bookedADR: null,
    bookedADRCurrency: null,
    error: payload.error,
    fetchingBookedADR: false,
  }),
  [ACTIONS.FETCH_PACING_BOOKEDADR_SUCCESS]: (state, { payload }) => ({
    ...state,
    bookedADR: payload.bookedADR,
    bookedADRCurrency: payload.currency,
    error: null,
    fetchingBookedADR: false,
  }),
  [ACTIONS.FETCH_PACING_TRENDS_START]: state => ({
    ...state,
    trends: null,
    reservations: null,
    error: null,
    fetchingTrends: true,
  }),
  [ACTIONS.FETCH_PACING_TRENDS_FAILURE]: (state, { payload }) => ({
    ...state,
    trends: null,
    reservations: null,
    error: payload.error,
    fetchingTrends: false,
  }),
  [ACTIONS.FETCH_PACING_TRENDS_SUCCESS]: (state, { payload }) => ({
    ...state,
    error: null,
    fetchingTrends: false,
    reservations: payload.reservations,
    trends: payload.trends,
  }),
  [ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_START]: state => ({
    ...state,
    individualDate: null,
    error: null,
    fetchingIndividualDate: true,
  }),
  [ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_FAILURE]: (state, { payload }) => ({
    ...state,
    individualDate: null,
    error: payload.error,
    fetchingIndividualDate: false,
  }),
  [ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    error: null,
    fetchingIndividualDate: false,
    individualDate: payload.individualDate,
  }),
};

const initialState: PacingState = {
  bookedADR: null,
  bookedADRCurrency: null,
  error: null,
  fetchingBookedADR: false,
  fetchingIndividualDate: false,
  fetchingTrends: false,
  individualDate: null,
  trends: null,
  reservations: null,
};

export const reducer: Reducer<PacingState, Action> = (
  state = initialState,
  action?: Action,
) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
