import * as React from 'react';
import PerformanceLayout from './Layout';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';

export * from './types';
export * from './store';

const Performance: React.FunctionComponent<any> = props => (
  <Layout>
    <Dashboard mainListItems={<Filters />}>
      <PerformanceLayout {...props} />
    </Dashboard>
  </Layout>
);

export default Performance;
