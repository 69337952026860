export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const UPDATE_ALL_ENTITIES = 'UPDATE_ALL_ENTITIES';
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';

export interface UpdateEntitiesType {
  domain?: string;
  type: typeof UPDATE_ENTITIES;
  payload?: {
    entities: any;
  };
}
