import * as React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { MenuProps, Menu, MenuList, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Link, { LinkProps } from '@material-ui/core/Link';
import { KeyboardArrowDown } from '@material-ui/icons';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LanguageSelector } from 'i18n/layout';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import Avatar from '../Avatar';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledButton = withStyles({
  root: { whiteSpace: 'nowrap', height: 64 },
})(Button);

const WrappedLink = (props: LinkProps) => <Link {...props} />;

interface MenusProps extends WrappedComponentProps {
  handleLogout: (event: React.MouseEvent<HTMLElement>) => void;
  cleanUrl: string;
  avatar?: string;
}

function CustomizedMenus({
  intl,
  handleLogout,
  cleanUrl,
  avatar = null,
}: MenusProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const hideOnboarding = useSelector<ApplicationState, any>(
    ({
      auth: {
        user: { hide_onboarding },
      },
    }) => hide_onboarding,
  );

  return (
    <div>
      <StyledButton
        aria-owns={anchorEl ? 'main-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        color="inherit"
      >
        <Avatar avatar={avatar} />
        {cleanUrl}
        <KeyboardArrowDown style={{ marginLeft: 10 }} />
      </StyledButton>
      <StyledMenu
        id="main-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList style={{ outline: 'none' }}>
          <LanguageSelector />
          {!hideOnboarding && (
            <ListItem
              button={true}
              component={(props: RouterLinkProps) => <RouterLink {...props} />}
              to="/onboarding"
            >
              <ListItemText
                primary={intl.formatMessage({
                  id: 'navigation.tabs.onboarding',
                })}
              />
            </ListItem>
          )}
          <ListItem
            button={true}
            component={(props: RouterLinkProps) => <RouterLink {...props} />}
            to="/settings/pm-groups/import"
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'main_menu.settings.title' })}
            />
          </ListItem>
          <ListItem
            button={true}
            component={WrappedLink}
            href="https://www.seetransparent.com/pricing"
            target="_blank"
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'main_menu.upgrade' })}
            />
          </ListItem>
          <ListItem
            button={true}
            component={WrappedLink}
            href="https://help.seetransparent.com"
            target="_blank"
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'main_menu.help' })}
            />
          </ListItem>
          <ListItem
            button={true}
            component={WrappedLink}
            href="https://academy.seetransparent.com"
            target="_blank"
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'main_menu.academy' })}
            />
          </ListItem>
          <ListItem
            button={true}
            component={WrappedLink}
            href="https://seetransparent.com"
            target="_blank"
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'main_menu.landing' })}
            />
          </ListItem>
          <ListItem button={true} onClick={handleLogout}>
            <ListItemText
              primary={intl.formatMessage({ id: 'application.logout' })}
            />
          </ListItem>
        </MenuList>
      </StyledMenu>
    </div>
  );
}

export default injectIntl(CustomizedMenus);
