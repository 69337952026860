import { upperCase } from 'lodash/fp';
export default (_domain: string) => {
  const domain = upperCase(_domain);
  const START_LOADING = `START_LOADING_${domain}`;
  const LOAD_SUCCESS = `LOAD_${domain}_SUCCESS`;
  const LOAD_FAILED = `LOAD_${domain}_FAILED`;
  return {
    START_LOADING,
    LOAD_SUCCESS,
    LOAD_FAILED,
  };
};
