import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import ListingSelection from './layout';
import {
  deleteGroup,
  createGroup,
  fetchGroups,
  hideCreationResults,
} from '../store/actions';
import { PM_GROUPS_DOMAIN } from '../types';

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    { deleteGroup, createGroup, fetchGroups, hideCreationResults },
    dispatch,
  ),
});

const mapStateToProps = (state: ApplicationState) => ({
  groups: state[PM_GROUPS_DOMAIN]?.groups,
  added: state[PM_GROUPS_DOMAIN]?.added,
  koUrls: state[PM_GROUPS_DOMAIN]?.koUrls,
  matched: state[PM_GROUPS_DOMAIN]?.matched,
  showResults: state[PM_GROUPS_DOMAIN]?.showResults,
  status: state[PM_GROUPS_DOMAIN]?.status,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingSelection);
