import { get } from 'lodash/fp';
import { ApplicationState } from 'store';

export const getToken = (state: ApplicationState) =>
  get<ApplicationState, 'auth', 'token'>(['auth', 'token'], state);
export const getCleanUrls = (state: ApplicationState) =>
  get<ApplicationState, 'auth', 'user', '_cleanUrls'>(
    ['auth', 'user', '_cleanUrls'],
    state,
  );
