import * as React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Step from './Step';
import CredentialsIcon from '../../Icons/Onboarding/Credentials';
import CompetitorsIcon from '../../Icons/Onboarding/Competitors';
import OnboardingCall from '../../Icons/Onboarding/OnboardingCall';
import SupplyIcon from '../../Icons/Onboarding/OnboardingSupply';
import RateIcon from '../../Icons/Onboarding/OnboardingRates';
import { IOnboardingProps } from '../';
function StepsContainer({ user }: IOnboardingProps) {
  const {
    email,
    competitors_list,
    onboarding_call,
    onboarding_call_date,
    supply_mapping,
    rate_and_demand,
    rate_and_demand_date,
    payment_date,
  } = user;
  return (
    <div className="box first-section">
      <span style={{ textAlign: 'center' }}>
        <h1 className="title is-4">
          <FormattedMessage id="onboarding.main_title" values={{ email }} />
        </h1>
        <h2 className="subtitle is-6">
          <FormattedMessage
            id="onboarding.main_subtitle"
            values={{
              date: `${moment(payment_date).format('DD MMMM')}`,
            }}
          />
        </h2>
      </span>

      <div className="columns is-multiline" style={{ paddingTop: '40px' }}>
        <Step
          Icon={<CredentialsIcon width={70} active={true} />}
          step={
            <FormattedMessage id="onboarding.steps.creating_credentials.title" />
          }
          active={true}
        >
          ""
        </Step>
        <Step
          Icon={<CompetitorsIcon width={70} active={competitors_list} />}
          step={<FormattedMessage id="onboarding.steps.share_list.title" />}
          active={competitors_list}
        >
          <FormattedMessage id="onboarding.steps.share_list.content" />
          <a
            style={{ textDecoration: 'underline', color: '#000' }}
            href="https://pie2re.typeform.com/to/oIJU0r"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="here" />
          </a>
        </Step>
        <Step
          Icon={<OnboardingCall width={70} active={onboarding_call} />}
          active={onboarding_call}
          step={
            <FormattedMessage id="onboarding.steps.onboarding_call.title" />
          }
        >
          <FormattedMessage id="onboarding.steps.onboarding_call.description" />
          <br />
          {onboarding_call_date ? (
            moment(onboarding_call_date).format('YYYY-MM-DD')
          ) : (
            <FormattedMessage id="onboarding.steps.no_date_scheduled" />
          )}
        </Step>
        <Step
          Icon={<SupplyIcon width={70} active={supply_mapping} />}
          active={supply_mapping}
          step={<FormattedMessage id="onboarding.steps.supply_mapping.title" />}
        >
          <FormattedMessage id="onboarding.steps.supply_mapping.content" />
        </Step>
        <Step
          Icon={<RateIcon width={70} active={rate_and_demand} />}
          active={rate_and_demand}
          step={<FormattedMessage id="onboarding.steps.rate_demand.title" />}
        >
          <FormattedMessage id="onboarding.steps.rate_demand.content" />
          <br />
          {rate_and_demand_date ? (
            moment(rate_and_demand_date).format('YYYY-MM-DD')
          ) : (
            <FormattedMessage id="onboarding.steps.no_date_scheduled" />
          )}
        </Step>
      </div>
    </div>
  );
}

export default StepsContainer;
