import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, Route } from 'react-router-dom';

import PMGroups from '../PMGroups';

type SettingsProps = RouteComponentProps & WrappedComponentProps;

const SettingsContainer: React.SFC<SettingsProps> = function({ match }) {
  return <Route path={`${match.url}/pm-groups`} component={PMGroups} />;
};

export default injectIntl(SettingsContainer);
