import React, { useState } from 'react';
import SelfReportChart from '../reports/SelfReportChart';
import MetricsMenu from './MetricsMenu';
import SelfReportsSection from '../reports/SelfReportsSection';
import useReport from '../hooks/use-report';
import { useIntl } from 'react-intl';
import { IMetricsReportsProps, IReportListItem } from '../lib/types';

const MetricsReports: React.FunctionComponent<IMetricsReportsProps> = ({
  reportsList,
  title,
}) => {
  const intl = useIntl();
  const [reportSelected, setReportSelected] = useState<IReportListItem>(
    reportsList[0],
  );
  const selectReport: (r: IReportListItem) => void = (
    r: IReportListItem,
  ): void => setReportSelected(r);
  const { loading, error, data } = useReport(reportSelected.query);

  return (
    <SelfReportsSection title={title}>
      <MetricsMenu
        reportsList={reportsList}
        reportSelected={reportSelected}
        selectReport={selectReport}
      />
      <SelfReportChart
        id={reportSelected.id}
        loading={loading}
        error={error}
        config={reportSelected.getConfig(data, intl)}
      />
    </SelfReportsSection>
  );
};

export default MetricsReports;
