import * as React from 'react';

export interface FiltersIconProps {
  filterName: string;
  width?: number | string;
}
const Icon: React.SFC<FiltersIconProps> = function({ filterName, width }) {
  return (
    <img
      src={require(`./assets/${filterName}.svg`)}
      alt={`${filterName} icon`}
      width={width || 20}
    />
  );
};

export default Icon;
