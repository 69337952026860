import * as React from 'react';
import Display from './layout/composition';
import Layout from '../../containers/Layout/Layout';
export function Onboarding() {
  return (
    <Layout shouldHideAside={true}>
      <Display />
    </Layout>
  );
}
