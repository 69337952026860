import React from 'react';
import './styles.scss';

import PreTable from './PreTable';

interface TableProps {
  isCentered?: boolean;
  isBordered?: boolean;
}

export const Table: React.FunctionComponent<TableProps> = ({
  isCentered = false,
  isBordered = true,
  children,
}) => {
  const chs = React.Children.toArray(children);
  let preTable;
  const preTableIndex = chs.findIndex((ch: any) => ch.type === PreTable);
  if (preTableIndex !== -1) preTable = chs.splice(preTableIndex, 1);

  return (
    <React.Fragment>
      {preTable}
      <table
        className={`table-pms table is-hoverable${
          isCentered ? ' is-centered' : ''
        }${isBordered ? ' is-bordered' : ''}`}
      >
        {chs}
      </table>
    </React.Fragment>
  );
};
