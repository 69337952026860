import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { AxiosRequestConfig } from 'axios';
import { parse } from 'qs';
import { ExtraArguments, ApplicationState, IAllActions } from './';
import { loadFilters, applyFilters } from './filters/actions';
import { loadRates } from './rates/actions';
import { loadPriceVariations } from './priceVariations/actions';
import { loadSupplies } from '../routes/supply/store/actions';
import { clearCharts } from './charts';
import { loadEvents } from './events/actions';
import { loadPmStats } from 'routes/ranking/store';

type ThunkResult<R> = ThunkAction<
  R,
  ApplicationState,
  ExtraArguments,
  IAllActions
>;

export type GeneralDispatch = ThunkDispatch<
  ApplicationState,
  ExtraArguments,
  IAllActions
>;
export const initialize = (_query = null): ThunkResult<Promise<void>> => {
  const query = parse(_query);
  return async dispatch => {
    await dispatch(loadFilters(query))
      .then(() => dispatch(applyFilters()))
      .then(() => loadAll(dispatch))
      .catch(e => {
        const regex = /BREAK/g;
        if (!regex.test(e)) {
          console.error(`Unhandled error ${e}`);
        }
      });
  };
};

function loadAll(dispatch) {
  return Promise.all([
    dispatch(loadRates()),
    dispatch(loadSupplies()),
    dispatch(loadPriceVariations()),
    dispatch(loadEvents()),
    dispatch(loadPmStats()),
  ]);
}

export const apply = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch(applyFilters())
    .then(() => dispatch(clearCharts()))
    .then(() => loadAll(dispatch))
    .catch(e => {
      const regex = /BREAK/g;
      if (!regex.test(e)) {
        console.error(`Unhandled error ${e}`);
      }
    });
};

export const clear = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch(loadFilters())
    .then(() => dispatch(applyFilters()))
    .then(() => dispatch(clearCharts()))
    .then(() => loadAll(dispatch))
    .catch(e => {
      const regex = /BREAK/g;
      if (!regex.test(e)) {
        console.error(`Unhandled error ${e}`);
      }
    });
};

type ThunkAnyAction<R> = ThunkAction<
  R,
  ApplicationState,
  ExtraArguments,
  AnyAction
>;

interface ApiActionParams extends AxiosRequestConfig {
  accessToken: string;
  label: string;
  onSuccess?: (data: any) => ThunkAnyAction<void>;
  onFailure: (error: Error) => ThunkAnyAction<void>;
  onStart: () => ThunkAnyAction<void>;
  onFinish?: () => ThunkAnyAction<void>;
}
export const apiAction = ({
  url = '',
  method = 'GET',
  data = null,
  params = null,
  accessToken = null,
  onSuccess,
  onFailure,
  label = '',
  transformResponse,
  onStart,
  onFinish,
}: ApiActionParams) =>
  async function(dispatch: GeneralDispatch) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URI;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    if (label) {
      dispatch(onStart());
    }
    return axios
      .request({
        url,
        method,
        data,
        params: new URLSearchParams(params),
        transformResponse,
      })
      .then(({ data }) => {
        dispatch(onSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(onFailure(error));
      })
      .finally(() => {
        if (onFinish) {
          dispatch(onFinish());
        }
      });
  };
