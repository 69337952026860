import * as React from 'react';
import { Link } from 'react-router-dom';
import { RouterState } from 'connected-react-router';
import { useSelector } from 'react-redux';
import {
  Tabs as MaterialUiTabs,
  createStyles,
  Tab as MaterialUiTab,
  makeStyles,
} from '@material-ui/core';
import { TabsProps } from '@material-ui/core/Tabs';

import { ApplicationState } from 'store';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
    },
    flexContainer: {
      height: '100%',
      flex: '1 1 auto',
      paddingLeft: 24,
    },
  }),
);

type InterTabsProps = Omit<TabsProps, 'value'> & Pick<RouterState, 'location'>;
export const Tabs: React.FunctionComponent<InterTabsProps> = ({
  children,
  location,
  ...props
}) => {
  const classes = useStyles({});
  return (
    <MaterialUiTabs
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
      }}
      value={location.pathname}
      {...props}
    >
      {children}
    </MaterialUiTabs>
  );
};

export const ConnectedTab: React.SFC<any> = ({
  value,
  icon,
  label,
  to,
  validationKey,
  ...props
}) => {
  const location = useSelector<ApplicationState, any>(
    ({ router }) => router.location,
  );
  const authUser = useSelector<ApplicationState, any>(({ auth }) => auth?.user);
  const { search } = location;

  if (validationKey && (!authUser || !authUser[validationKey])) return null;

  return (
    <MaterialUiTab
      {...props}
      component={Link}
      to={{ pathname: to, search }}
      icon={icon || null}
      label={label}
      value={value}
      style={{ minWidth: 100 }}
    />
  );
};
