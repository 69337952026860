import * as React from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';

import styles from './sidebarContentStyle';

const SideBarContent: React.SFC<StyledComponentProps> = props => {
  return <div className={props.classes.content}>{props.children}</div>;
};

export default withStyles(styles)(SideBarContent);
