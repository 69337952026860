import { schema } from 'normalizr';
import { omitBy } from 'lodash';
const individualDateEntity = new schema.Entity('individualDate', undefined, {
  idAttribute: ({ x }) => x,
});

export const individualDateResponse = {
  data: [individualDateEntity],
};

export const trendsEntity = new schema.Entity('trends', undefined, {
  idAttribute: ({ pricing_date }) => pricing_date,
  processStrategy: ({ pricing_date: pricingDate, ...entity }) =>
    omitBy({ pricingDate, ...entity }, (_, key) => {
      return /num_recent/.test(key);
    }),
});

export const reservationsEntity = new schema.Entity('reservations', undefined, {
  idAttribute: ({ pricing_date }) => pricing_date,
  processStrategy: ({ pricing_date: pricingDate, ...entity }) =>
    omitBy({ pricingDate, ...entity }, (_, key) => {
      return /occupancy/.test(key);
    }),
});

export const bookedAdrEntity = new schema.Entity('bookedAdr', undefined, {
  idAttribute: ({ pricing_date }) => pricing_date,
  processStrategy: ({ pricing_date: pricingDate, ...entity }) => ({
    pricingDate,
    ...entity,
  }),
});
