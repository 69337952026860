import React from 'react';
import './index.scss';

export default ({ label, click }) => {
  return (
    <li className="kpi-button-container">
      <button className="kpi-button" onClick={click}>
        {label}
      </button>
    </li>
  );
};
