import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  form: { padding: '34px 34px 55px' },
  container: {
    display: 'flex',
    'flex-flow': 'row wrap',
    'align-items': 'flex-start',
  },
  col1: { minWidth: '252px' },
  col2: { paddingLeft: '34px', flex: 'auto' },
  inputLabel: { height: '60px', paddingBottom: '16px' },
  inputText: { padding: '18px', height: 'auto', border: '1px solid #CACACA' },
  inputTextArea: { height: '227px', border: '1px solid #CACACA' },
  inputLabelHint: { display: 'block', fontWeight: 'normal' },
  buttonContainer: { paddingTop: '45px', marginLeft: '14px' },
  button: {
    height: '52px',
    textTransform: 'uppercase',
    border: 'none',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  dialogContainer: {
    display: 'flex',
    'flex-flow': 'column wrap',
    'align-items': 'stretch',
    padding: '100px',
    textAlign: 'center',
    color: '#505D6E',
    fontSize: '14px',
    lineHeight: 'auto',
    position: 'relative',
  },
  dialogTitle: {
    paddingTop: '10px',
    fontSize: '36px',
    lineHeight: '36px',
    fontStyle: 'bold',
  },
  dialogSubtitle: {
    paddingTop: '10px',
    fontSize: '25px',
    lineHeight: '25px',
    fontStyle: 'bold',
  },
  urlsLabel: {
    textAlign: 'left',
    position: 'relative',
    padding: '15px',
    '&::before': {
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '5px',
      content: '" "',
    },
  },
  okUrlsLabel: {
    color: '#49AD79',
    marginTop: '50px',
    backgroundColor: '#EFFFF7',
    '&::before': {
      backgroundColor: '#7EE5AF',
    },
  },
  koUrlsLabel: {
    color: '#FF2C52',
    marginTop: '10px',
    backgroundColor: '#FFF3F3',
    '&::before': {
      backgroundColor: '#E04F5F',
    },
  },
  koUrls: {
    marginTop: '10px',
    overflow: 'hidden',
    overflowY: 'auto',
    borderRadius: '4px',
    border: '1px solid #EBEBEB',
    maxHeight: '150px',
  },
  koUrl: {
    display: 'flex',
    'flex-flow': 'row nowrap',
    'align-items': 'center',
    paddingLeft: '10px',
  },
  koUrlText: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '36px',
    borderBottom: '1px solid #EBEBEB',
    padding: '0 6px',
    maxWidth: '265px',
  },
  close: {
    position: 'absolute',
    top: '26px',
    right: '26px',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  },
});

export default styles;
