import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { parse } from 'qs';
import { IChartsActions } from './types';
import { ExtraArguments, ApplicationState } from '../';
import { DataSeriesParams } from '../../containers/Charts';
import { Api } from '../../services/api';

type ChartsThunkAction = ThunkAction<
  Promise<void>,
  ApplicationState,
  ExtraArguments,
  IChartsActions
>;

async function getChartData(
  chartId: string,
  params: DataSeriesParams,
  appState: ApplicationState,
  token: string,
  api: Api,
) {
  const currentParams = params || appState.charts[chartId].params;
  const { query: serverQuery } = appState.filters;
  return await api.getDataSeries({
    token,
    query: { ...currentParams, ...parse(serverQuery) },
  });
}

export const loadChart: ActionCreator<ChartsThunkAction> = (
  chartId: string,
  params: DataSeriesParams,
) => {
  return async (dispatch, getState, { api }) => {
    const appState = getState();
    const { token } = appState.auth;
    const { query: serverQuery } = appState.filters;

    const chartState = appState.charts[chartId];

    try {
      let { data } = chartState || { data: null };
      if (!data && !(chartState && chartState.isLoading)) {
        dispatch({
          type: 'START_LOAD_CHART',
          payload: { chartId, params: { ...params, ...parse(serverQuery) } },
        });
        const response = await getChartData(
          chartId,
          params,
          appState,
          token,
          api,
        );

        dispatch({
          type: 'LOAD_CHART_SUCCESS',
          payload: {
            chartId,
            data: response['result'],
            currency: response['currency'],
          },
        });
      }
    } catch (e) {
      dispatch({ type: 'LOAD_CHART_ERROR', payload: { chartId, error: e } });
    }
  };
};

export const clearCharts: ActionCreator<ChartsThunkAction> = () => {
  return async (dispatch, getState) => {
    const appState = getState();
    for (const chartId in appState.charts || {}) {
      if (chartId === 'listingsCount') continue; // TODO legacy delete this as soon as possible
      dispatch({ type: 'CLEAR_CHART', payload: { chartId } });
    }
  };
};
