import * as React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  StyledComponentProps,
} from '@material-ui/core';

interface IProps extends StyledComponentProps {
  onSubmit: (event: React.FormEvent) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      width: '100%', // Fix IE11 issue.
      marginTop: theme.spacing(1),
    },
  });
const Form: React.SFC<IProps> = ({ onSubmit, children, classes }) => {
  return (
    <form onSubmit={onSubmit} className={classes.form}>
      {children}
    </form>
  );
};

export default withStyles(styles)(Form);
