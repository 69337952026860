import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { normalize } from 'normalizr';

import { ApplicationState, ExtraArguments } from 'store';
import { getToken } from 'store/auth/selectors';
import { updateEntities } from 'store/entities/actions';
import { getFilterQuery } from 'store/filters/selectors';
import { createPaginator, createSelector, getSortKey } from 'store/pagination';

import { performanceSchema, aggregatedPerformanceSchema } from './schema';
import {
  PERFORMANCE_DOMAIN,
  ACTIONS,
  AGGREGATED_PERFORMANCE_DOMAIN,
} from '../types';

const PERFORMANCE_SUPPLY_ENDPOINT = 'performance-metrics/supply';
const PERFORMANCE_AGGREGATED_ENDPOINT = 'performance-metrics/aggregated';

export const paginator = createPaginator(PERFORMANCE_SUPPLY_ENDPOINT);
export const selector = createSelector(PERFORMANCE_DOMAIN);

export const loadSupplyFirstPage: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = ({ limit }) => async (dispatch, getState) => {
  const defaultPaginationValues = { limit, order: 'ASC', orderBy: 'title' };
  const state = getState();
  const statePage = 1;
  let pagParams = selector.getPaginationParams(
    statePage,
    defaultPaginationValues,
  );
  const filterQuery = getFilterQuery(state);
  const query = new URLSearchParams(filterQuery);
  Object.keys(pagParams)
    .filter(key => key !== 'dates') // discard dates
    .forEach(key => query.set(key, pagParams[key]));
  const token = getToken(state);

  dispatch(paginator.resetPagination());
  dispatch(
    paginator.requestPage({
      orderBy: pagParams.orderBy,
      order: pagParams.order,
      page: statePage,
    }),
  );

  try {
    const { data } = await apiAction({
      onStart: () => {
        dispatch({ type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_START });
      },
      params: query,
      token,
      url: PERFORMANCE_SUPPLY_ENDPOINT,
    });

    if (data.status !== 'SUCCESS') {
      dispatch({
        type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_FAILURE,
        payload: { error: data.error },
      });
      return;
    }

    const { entities, result } = normalize(data, performanceSchema);
    const { currency, _total: total } = result.data;
    dispatch(
      paginator.receivePage(
        {
          ...pagParams,
          page: statePage,
          total,
        },
        result.data.listings,
      ),
    );
    dispatch(updateEntities(PERFORMANCE_DOMAIN, entities[PERFORMANCE_DOMAIN]));
    dispatch({
      type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_SUCCESS,
      payload: { currency },
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_FAILURE,
      payload: { error: e },
    });
  }
};

export const loadSupplyPage: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (paginationParams: any = {}, page) => async (dispatch, getState) => {
  const state = getState();
  const totalPages = selector.getTotalPages(state);
  let pagParams = selector.getPaginationParams(page, paginationParams);
  const statePage = selector.getCurrentPage(state).page || 1;
  let currentPage = page || statePage;
  if (currentPage > totalPages || currentPage < 1) return;
  const isNewPage = !selector.isPageCached(
    state,
    currentPage,
    getSortKey({ order: pagParams.order, orderBy: pagParams.orderBy }),
  );
  const filterQuery = getFilterQuery(state);
  const query = new URLSearchParams(filterQuery);
  Object.keys(pagParams)
    .filter(key => key !== 'dates') // discard dates
    .forEach(key => query.set(key, pagParams[key]));
  const token = getToken(state);

  if (isNewPage) {
    try {
      dispatch(
        paginator.requestPage({
          orderBy: pagParams.orderBy,
          order: pagParams.order,
          page: currentPage,
        }),
      );
      const { data } = await apiAction({
        onStart: () => {
          dispatch({ type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_START });
        },
        params: query,
        token,
        url: PERFORMANCE_SUPPLY_ENDPOINT,
      });

      if (data.status !== 'SUCCESS') {
        dispatch({
          type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_FAILURE,
          payload: { error: data.error },
        });
        return;
      }

      const { entities, result } = normalize(data, performanceSchema);
      const { currency, _total: total } = result.data;
      dispatch(
        paginator.receivePage(
          {
            ...pagParams,
            page: currentPage,
            total,
          },
          result.data.listings,
        ),
      );
      dispatch(
        updateEntities(PERFORMANCE_DOMAIN, entities[PERFORMANCE_DOMAIN]),
      );
      dispatch({
        type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_SUCCESS,
        payload: { currency },
      });
    } catch (e) {
      dispatch({
        type: ACTIONS.FETCH_PERFORMANCE_SUPPLY_FAILURE,
        payload: { error: e },
      });
    }
  } else {
    dispatch(paginator.goToPage({ ...pagParams, page: currentPage }));
  }
};

interface StatsResponse {
  status: string;
  data?: {
    metrics: {
      listingsCount: number;
      avgRevenue: number;
      avgOccupancy: number;
    };
  };
  error?: any;
}

export const loadAggregated: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async (dispatch, getState) => {
  const state = getState();
  const filterQuery = getFilterQuery(state);
  const query = new URLSearchParams(filterQuery);
  const token = getToken(state);

  try {
    const { data } = await apiAction<StatsResponse>({
      onStart: () => {
        dispatch({ type: ACTIONS.FETCH_PERFORMANCE_AGGREGATED_START });
      },
      params: query,
      token,
      url: PERFORMANCE_AGGREGATED_ENDPOINT,
    });

    const { entities } = normalize(data, aggregatedPerformanceSchema);
    if (data.status !== 'SUCCESS') {
      dispatch({
        type: ACTIONS.FETCH_PERFORMANCE_AGGREGATED_FAILURE,
        payload: { error: data.error },
      });
      return;
    }

    dispatch(
      updateEntities(
        AGGREGATED_PERFORMANCE_DOMAIN,
        entities[AGGREGATED_PERFORMANCE_DOMAIN],
      ),
    );
    dispatch({
      type: ACTIONS.FETCH_PERFORMANCE_AGGREGATED_SUCCESS,
      payload: {
        currency: 'USD',
      },
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_PERFORMANCE_AGGREGATED_FAILURE,
      payload: { error: e },
    });
  }
};

interface ApiActionOptions extends AxiosRequestConfig {
  onFailure?: (reason: Error) => void;
  onStart?: () => void;
  onSuccess?: (response: AxiosResponse) => void;
  token: string;
}

function apiAction<T = any>(
  options: ApiActionOptions,
): Promise<AxiosResponse<T>> {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
  });

  return new Promise((rs, rj) => {
    options.onStart && options.onStart();
    axiosInstance.request(options).then(
      response => {
        options.onSuccess && options.onSuccess(response);
        rs(response);
      },
      (reason: Error) => {
        options.onFailure && options.onFailure(reason);
        rj(reason);
      },
    );
  });
}
