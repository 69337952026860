import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 150,
      paddingLeft: 50,
    },
    titleContainer: {
      width: '100%',
      padding: '0 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 20,
    },
    title: {
      margin: '10px 0',
      textAlign: 'center',
      textTransform: 'capitalize',
      fontSize: '15px',
    },
    value: {
      fontSize: 36,
      fontWeight: 'bold',
    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-around',
      alignContent: 'center',
      alignItems: 'center',
    },
  });

const EventRigthContent = ({ title, value, classes, icon }) => (
  <div className={classes.container}>
    <div className={classes.titleContainer}>
      <Divider />
      <div className={classes.titleBox}>
        {icon}
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <Divider />
    </div>
    <Typography className={classes.value}>{value}</Typography>
  </div>
);

export default withStyles(styles)(EventRigthContent);
