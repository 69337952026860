import * as React from 'react';
import { Moment } from 'moment';
import clsx from 'clsx';
import {
  withStyles,
  Grid,
  Paper,
  StyledComponentProps,
} from '@material-ui/core';
import EmptyLine from './EmptyLine';
import styles from '../styles';

const MONTH_NAME_FORMAT = 'MMM';
const DAY_NAME_FORMAT = 'ddd';
const DAY_NUMBER_FORMAT = 'DD';

interface EmptyItemProps extends StyledComponentProps {
  date: Moment;
  inactive: boolean;
}

const Empty = ({ classes, date, inactive }: EmptyItemProps) => (
  <Grid item={true} xs={true} className={classes.calendarItemContainer}>
    <Paper className={clsx(classes.calendarItem, inactive && classes.inactive)}>
      <Grid
        container={true}
        direction="column"
        spacing={8}
        justify="space-between"
        alignItems="stretch"
      >
        <Grid
          item={true}
          xs={12}
          container={true}
          spacing={0}
          justify="space-between"
        >
          <Grid item={true} className={classes.datesLabel}>
            {date.isValid() ? date.format(MONTH_NAME_FORMAT) : <EmptyLine />}
          </Grid>
          <Grid item={true} className={classes.datesLabel}>
            {date.isValid() ? date.format(DAY_NAME_FORMAT) : <EmptyLine />}
          </Grid>
          <Grid item={true} className={classes.datesLabel}>
            {date.isValid() ? date.format(DAY_NUMBER_FORMAT) : <EmptyLine />}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

export default withStyles(styles)(Empty);
