import moment from 'moment';
import * as React from 'react';
import { ListItem } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';

import './styles.scss';

interface DateChangeEvent {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

export interface DatePickerProps {
  endDate?: Date;
  maxDate: Date;
  minDate: Date;
  onDatesChanges: (startDate?: Date, endDate?: Date) => void;
  startDate?: Date;
}

const DatePicker: React.SFC<DatePickerProps> = ({
  endDate,
  maxDate,
  minDate,
  onDatesChanges,
  startDate,
}) => {
  const [focusedInput, setFocusedInput] = React.useState<string>();
  const [start, setStart] = React.useState<moment.Moment>(moment(startDate));
  const [end, setEnd] = React.useState<moment.Moment>(
    endDate ? moment(endDate) : moment(startDate).add(1, 'month'),
  );
  const onChange = ({ startDate, endDate }: DateChangeEvent) => {
    if (startDate) {
      if (end.isBefore(startDate)) setEnd(startDate.clone());
      setStart(startDate);
    }
    if (endDate) {
      if (start.isAfter(endDate)) setStart(endDate.clone());
      setEnd(endDate);
    }
  };

  const isOutsideRange = day =>
    !isInclusivelyAfterDay(day, moment(minDate)) ||
    isInclusivelyAfterDay(day, moment(maxDate));
  return (
    <ListItem>
      <div className="DatePickerContainer">
        <DateRangePicker
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          startDate={start}
          startDateId="DatePickerStartDate"
          endDate={end}
          endDateId="DatePickerEndDate"
          onDatesChange={onChange}
          focusedInput={focusedInput}
          onFocusChange={input => setFocusedInput(input)}
          openDirection={OPEN_UP}
          noBorder={true}
          displayFormat="L"
          appendToBody={true}
          enableOutsideDays={false}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel={true}
          onClose={({ startDate, endDate }) =>
            onDatesChanges(startDate.toDate(), endDate.toDate())
          }
        />
      </div>
    </ListItem>
  );
};
export default DatePicker;
