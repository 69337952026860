import React, { useState } from 'react';
import { Button, Fade, FormControl, Modal, TextField } from '@material-ui/core';
import GreenOkIcon from 'components/Icons/GreenOkIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import './Modal.scss';
import { FormattedMessage } from 'react-intl';
import { Status } from 'routes/settings/PMGroups/lib/enums';
import GarbageIcon from 'components/Icons/GarbageIcon';

const BaseModal: React.FC<any> = ({
  open,
  handleClose,
  children,
  groupName,
  added,
  status,
  count,
  action,
}: {
  open: boolean;
  handleClose: any;
  children: any;
  groupName: string;
  added: number;
  status: number;
  count: string;
  action: string;
}) => {
  const isFail = () => status === Status.FAIL;

  return (
    <Modal
      className="listings-selection-modal"
      open={open}
      onClose={handleClose}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={`group-modal ${[
            Status.CREATED,
            Status.UPDATED,
            Status.DELETED,
          ].includes(status) && 'created'} ${isFail() && 'failed'}`}
        >
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              width: 12,
              cursor: 'pointer',
              color: 'var(--gray-text)',
              zIndex: 100,
            }}
          />
          <div className="container confirm">{children}</div>
          {!isFail() ? (
            <div className="container success">
              <SuccessGroup
                handleClose={handleClose}
                groupName={groupName}
                added={added}
                count={count}
                action={action}
              />
            </div>
          ) : (
            <div className="container fail">
              <FailedGroup handleClose={handleClose} />
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export const CreateGroupModal: React.FC<any> = ({
  open = true,
  handleClose,
  createOrUpdateGroup,
  status,
  added,
}: {
  open: boolean;
  handleClose: any;
  createOrUpdateGroup: Function;
  status: number;
  added: number;
}) => {
  const [name, setName] = useState('');
  const handleCreateClick = () => createOrUpdateGroup(name);

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      groupName={name}
      count={added}
      status={status}
      action="added"
    >
      <h3 style={{ fontWeight: 'bold', fontSize: 16 }}>
        <FormattedMessage
          id="settings.pm_groups.listings_selection.modal.create_new_group"
          defaultMessage="Create New Group"
        />
      </h3>
      <FormControl>
        <TextField
          required={true}
          className="group-input"
          fullWidth={true}
          placeholder="Group Name"
          onChange={event => setName(event.target.value)}
        />
      </FormControl>
      <Button
        variant="contained"
        className={`action-button ${status === Status.RUNNING && 'creating'}`}
        fullWidth={true}
        onClick={handleCreateClick}
        disabled={status === Status.RUNNING}
      >
        <span className="animated-fill" />
        <span className="button-text">
          <FormattedMessage
            id="settings.pm_groups.listings_selection.modal.create_group"
            defaultMessage="CREATE GROUP"
          />
        </span>
      </Button>
    </BaseModal>
  );
};

export const AddToGroupModal: React.FC<any> = ({
  open,
  handleClose,
  listingsSelected,
  groupName,
  createOrUpdateGroup,
  status,
  added,
}: {
  open: boolean;
  handleClose: any;
  listingsSelected: string[];
  groupName: string;
  createOrUpdateGroup: Function;
  status: number;
  added: number;
}) => {
  const handleCreateClick = () => createOrUpdateGroup(groupName);

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      status={status}
      count={added}
      groupName={groupName}
      action="added"
    >
      <div style={{ textAlign: 'center', fontSize: 16 }}>
        <FormattedMessage
          id="settings.pm_groups.listings_selection.modal.total_listings_message"
          defaultMessage="Add {added} Listings to"
          values={{ added: listingsSelected.length }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          color: '#0177FF',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        {groupName}
      </div>
      <Button
        variant="contained"
        className={`action-button ${status === Status.RUNNING && 'creating'}`}
        fullWidth={true}
        onClick={handleCreateClick}
      >
        <span className="animated-fill" />
        <span className="button-text">
          <FormattedMessage
            id="settings.pm_groups.listings_selection.modal.add_selection"
            defaultMessage="Add Selection"
          />
        </span>
      </Button>
      <Button variant="text" onClick={handleClose}>
        <FormattedMessage
          id="settings.pm_groups.listings_selection.modal.cancel"
          defaultMessage="Cancel"
        />
      </Button>
    </BaseModal>
  );
};

const SuccessGroup: React.FC<any> = ({
  handleClose,
  groupName,
  count,
  action,
}: {
  handleClose: any;
  groupName: string;
  count: number;
  action: string;
}) => {
  const message =
    action === 'added'
      ? {
          id: 'settings.pm_groups.listings_selection.modal.success',
          defaultMessage: 'You just added {count} listings to your group',
        }
      : {
          id: 'settings.pm_groups.edit_group.modal.delete_success',
          defaultMessage: 'You just deleted {count} listings from your group',
        };
  return (
    <>
      <GreenOkIcon width={22} />
      <div style={{ textAlign: 'center', fontSize: 16 }}>
        <FormattedMessage
          id={message.id}
          defaultMessage={message.defaultMessage}
          values={{ count }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          color: '#0177FF',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        {groupName}
      </div>
      <Button
        variant="contained"
        className="ok-button"
        fullWidth={true}
        onClick={handleClose}
      >
        <FormattedMessage
          id="settings.pm_groups.listings_selection.modal.ok"
          defaultMessage="OK"
        />
      </Button>
    </>
  );
};

const FailedGroup: React.FC<any> = ({ handleClose }: { handleClose: any }) => (
  <>
    <CloseIcon width={22} fill="#FF889D" />
    <div style={{ textAlign: 'center', fontSize: 16 }}>
      <FormattedMessage
        id="settings.pm_groups.listings_selection.modal.fail"
        defaultMessage="Action Failed"
      />
    </div>

    <Button
      variant="contained"
      className="ok-button"
      fullWidth={true}
      onClick={handleClose}
    >
      <FormattedMessage
        id="settings.pm_groups.listings_selection.modal.close"
        defaultMessage="Close"
      />
    </Button>
  </>
);

export const DeleteFromGroupModal: React.FC<any> = ({
  open,
  handleClose,
  listingsSelected,
  groupName,
  deleteFromGroup,
  status,
  deleteCount,
}: {
  open: boolean;
  handleClose: any;
  listingsSelected: string[];
  groupName: string;
  deleteFromGroup: Function;
  status: number;
  deleteCount: number;
}) => {
  const handleClick = () => deleteFromGroup(groupName);

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      status={status}
      count={deleteCount}
      groupName={groupName}
      action="deleted"
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <GarbageIcon width={20} fill="#FF889D" />
      </div>
      <div style={{ textAlign: 'center', fontSize: 16 }}>
        <FormattedMessage
          id="settings.pm_groups.edit_group.modal.remove_listings_confirmation"
          defaultMessage="Do you want to remove {count} listings from"
          values={{ count: listingsSelected.length }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          color: '#0177FF',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        {groupName}
      </div>
      <Button
        variant="contained"
        className={`action-button ${status === Status.RUNNING && 'creating'}`}
        fullWidth={true}
        onClick={handleClick}
      >
        <span className="animated-fill" />
        <span className="button-text">
          <FormattedMessage
            id="settings.pm_groups.edit_group.modal.remove"
            defaultMessage="Remove"
          />
        </span>
      </Button>
      <Button variant="text" onClick={handleClose}>
        <FormattedMessage
          id="settings.pm_groups.edit_group.modal.cancel"
          defaultMessage="Cancel"
        />
      </Button>
    </BaseModal>
  );
};
