import * as actionTypes from './action-types';
import _ from 'lodash';

const ACTION_HANDLERS = {
  [actionTypes.UPDATE_ENTITY]: (state, { payload, domain }) => {
    const entity = payload.entity;
    // we're using strings as entity keys
    const id = String(payload.id);
    return _.update(_.cloneDeep(state), domain, (entities = {}) =>
      _.set(entities, id, entity),
    );
  },

  [actionTypes.UPDATE_ENTITIES]: (state, { payload, domain }) => {
    const { entities } = payload;
    return _.update(_.cloneDeep(state), domain, (current = {}) =>
      _.merge(current, entities),
    );
  },

  [actionTypes.UPDATE_ALL_ENTITIES]: (state, { payload }) => {
    const { entities } = payload;
    return _.merge(_.cloneDeep(state), entities);
  },

  [actionTypes.REMOVE_ENTITY]: (state, { payload, domain }) => {
    const id = String(payload.id);
    return _.update(_.cloneDeep(state), domain, (entities = {}) =>
      _.unset(entities, id),
    );
  },

  [actionTypes.CLEAR_ENTITIES]: (state, { domain }) =>
    _.set(_.cloneDeep(state), domain, {}),
};

const initialState = {};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
