import * as React from 'react';
import styled from 'styled-components';

interface PaginationButtonProps {
  isCurrent?: boolean;
}
const PaginationButton = styled.button<PaginationButtonProps>`
  border: 1px solid ${props => (props.isCurrent ? '#08d8cb' : '#dbdbdb')};
  background: ${props => (props.isCurrent ? '#08d8cb' : 'transparent')};
  ${props => props.isCurrent && 'color: #fff'}
`;

interface PaginationProps {
  currentPage: number;
  numberOfPages: number;
  goToPage: (page: number) => (e: React.MouseEvent) => void;
}
export function Pagination({
  currentPage,
  numberOfPages,
  goToPage,
}: PaginationProps) {
  const previusPage = currentPage - 1;
  const nextPage = currentPage + 1;
  return (
    <nav
      className="pagination is-small"
      role="navigation"
      aria-label="pagination"
    >
      <PaginationButton
        className="pagination-previous"
        disabled={previusPage < 0}
        onClick={goToPage(previusPage)}
      >
        Previous
      </PaginationButton>
      <PaginationButton
        className="pagination-next"
        onClick={goToPage(nextPage)}
        disabled={nextPage >= numberOfPages}
      >
        Next page
      </PaginationButton>
      <ul className="pagination-list">
        {[...Array(numberOfPages)].map((_, idx) => (
          <li key={idx}>
            <PaginationButton
              className="pagination-link"
              isCurrent={idx === currentPage}
              onClick={goToPage(idx)}
            >
              {idx + 1}
            </PaginationButton>
          </li>
        ))}
      </ul>
    </nav>
  );
}
