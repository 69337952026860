import { ThunkAction } from 'redux-thunk';
import { get } from 'lodash/fp';
import {
  ISuppliesActions,
  MarketUrl,
  START_LOAD_SUPPLIES,
  LOAD_SUPPLIES_ERROR,
  LOAD_SUPPLIES_SUCCESS,
} from '../types';
import { ExtraArguments, ApplicationState } from '../../../store/index';

type SuppliesThunkAction = ThunkAction<
  Promise<void>,
  ApplicationState,
  ExtraArguments,
  ISuppliesActions
>;

export const loadSupplies = (): SuppliesThunkAction => {
  return async (dispatch, getState, { api }) => {
    const appState = getState();
    const { token } = appState.auth;
    const market = get(
      ['filters', 'selectedFilters', 'market'],
      appState,
    ) as string;
    try {
      dispatch({ type: START_LOAD_SUPPLIES, payload: { markets: [market] } });
      const response = await api.getCartoMap({
        token,
        query: { markets: [market] },
      });
      dispatch({
        type: LOAD_SUPPLIES_SUCCESS,
        payload: { markets: response['urls'] as MarketUrl[] },
      });
    } catch (e) {
      dispatch({ type: LOAD_SUPPLIES_ERROR, payload: { error: e } });
    }
  };
};
