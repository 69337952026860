import React from 'react';

const OkIcon = props => (
  <svg viewBox="0 0 13 13" fill="" {...props}>
    <g>
      <path
        d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z"
        fill="#32BA7C"
      />
      <path
        d="M4.83911 9.43215L8.18138 12.7744C10.9495 12.0363 13 9.51417 13 6.49997C13 6.43846 13 6.37695 13 6.31543L10.3754 3.89587L4.83911 9.43215Z"
        fill="#0AA06E"
      />
      <path
        d="M6.664 7.95581C6.95107 8.24288 6.95107 8.73499 6.664 9.02206L6.06936 9.61669C5.7823 9.90376 5.29018 9.90376 5.00312 9.61669L2.39902 6.99209C2.11195 6.70502 2.11195 6.21291 2.39902 5.92584L2.99365 5.33121C3.28072 5.04414 3.77283 5.04414 4.0599 5.33121L6.664 7.95581Z"
        fill="white"
      />
      <path
        d="M8.94009 3.42428C9.22715 3.13722 9.71927 3.13722 10.0063 3.42428L10.601 4.01892C10.888 4.30599 10.888 4.7981 10.601 5.08517L6.08993 9.5757C5.80286 9.86277 5.31075 9.86277 5.02368 9.5757L4.42904 8.98107C4.14198 8.694 4.14198 8.20189 4.42904 7.91482L8.94009 3.42428Z"
        fill="white"
      />
    </g>
  </svg>
);

export default OkIcon;
