import { get } from 'lodash';
import { parse } from 'qs';
import { compareObjects } from '../../services/filters';

export function loadCountListings(chartId: string) {
  return async (dispatch, getState, { api }) => {
    const state = getState();
    const token = get(state, ['auth', 'token']);
    const { query } = get(state, ['filters']);
    if (!query) return;
    const cleanUrl = [get(state, ['auth', 'user', '_cleanUrls', 0])];
    const params = {
      metric: 'count',
      select: 'COUNT_ALL',
      type: 'pricing_stats',
    };
    const marketQuery = { ...params, ...parse(query) };
    const pmQuery = { ...marketQuery, cleanUrl };
    try {
      if (
        !compareObjects(marketQuery, get(state, ['charts', chartId, 'params']))
      ) {
        dispatch({
          type: 'START_LOAD_CHART',
          payload: { chartId, params: marketQuery },
        });
        const [marketResponse, pmResponse] = await Promise.all([
          api.getDataSeries({ token, query: marketQuery }),
          api.getDataSeries({ token, query: pmQuery }),
        ]);

        dispatch({
          type: 'LOAD_CHART_SUCCESS',
          payload: {
            chartId,
            data: { marketResponse, pmResponse },
            currency: marketResponse['currency'],
          },
        });
      }
    } catch (e) {
      dispatch({
        type: 'LOAD_CHART_ERROR',
        payload: { chartId, error: e, params: null },
      });
    }
  };
}
