import { push } from 'connected-react-router';
import fetch from 'isomorphic-fetch';
import { get } from 'lodash/fp';
import moment from 'moment';
import { ThunkAction } from 'redux-thunk';

import { ApplicationState, IAllActions } from '../';
import { ExtraArguments } from '../';
import decodeToken from '../../services/decode-token';
import { initialize } from '../actions';
import { Mixpanel } from '../../services/mixpanel';
import { setLocale } from 'i18n/store/actions';

import gtm from 'react-gtm-module';

const AUTH_PREFIX = process.env.CLIENT_AUTH_PREFIX || '/auth';

type ThunkResult<R> = ThunkAction<
  R,
  ApplicationState,
  ExtraArguments,
  IAllActions
>;

const setUserInGTM = ({ email, _cleanUrls, _id, free_trial }) => {
  const tagManagerArgs = {
    dataLayer: {
      userId: _id,
      cleanUrl: _cleanUrls[0] || '',
      userEmail: email,
      isFreeTrial: free_trial,
    },
  };
  gtm.dataLayer(tagManagerArgs);
};
export const getInitialAuthState = (): ThunkResult<void> => {
  return (dispatch, getState, { cookies }) => {
    const token = cookies.get('token');
    const locale = cookies.get('locale') || 'en';
    const decodedSession = decodeToken(token);
    dispatch({
      type: 'IS_LOADING',
    });
    dispatch(setLocale(locale));
    if (!token) {
      dispatch({
        type: '@INITIALIZE_AUTH_STATE',
        payload: {},
      });
      return dispatch({
        type: 'IS_READY',
      });
    }
    return fetch(`${AUTH_PREFIX}/user/${decodedSession.sub.id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: Response) => {
        if (response.ok) {
          return response.json().then(json => {
            setUserInGTM(json.user);
            dispatch({
              type: '@INITIALIZE_AUTH_STATE',
              payload: {
                token: token,
                user: json.user,
                hydrated: true,
              },
            });
          });
        }
        return response.json().then(json => {
          dispatch({
            type: '@INITIALIZE_AUTH_STATE',
            payload: {
              messages: Array.isArray(json) ? json : [json],
            },
          });
        });
      })
      .then(() => dispatch({ type: 'IS_READY' }));
  };
};
export const login = (email: string, password: string): ThunkResult<void> => {
  return (dispatch, getState, { cookies }) => {
    const query = get('router.location.search', getState());
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
    return fetch(`${AUTH_PREFIX}/login`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (response: Response) => {
      if (response.ok) {
        return await response
          .json()
          .then(json => {
            dispatch({
              type: 'LOGIN_SUCCESS',
              payload: {
                token: json.token,
                user: json.user,
              },
            });
            setUserInGTM(json.user);
            Mixpanel.identify(json.user._id);
            Mixpanel.track('Successful login');
            Mixpanel.register_once({
              'First Login Date': new Date().toISOString(),
            });
            Mixpanel.people.set({
              $email: json.user.email,
              $markets: json.user.markets,
            });
            dispatch({
              type: 'OPEN_ASIDE',
            });
            cookies.set('token', json.token, {
              expires: moment()
                .add(1, 'hour')
                .toDate(),
            });
            dispatch(push('/') as any);
          })
          .then(() => dispatch(initialize(query.substr(1))));
      }
      return await response.json().then(json => {
        dispatch({
          type: 'LOGIN_FAILURE',
          payload: {
            messages: Array.isArray(json) ? json : [json],
          },
        });
        Mixpanel.track('Unsuccessful login');
      });
    });
  };
};

export const logout = (): ThunkResult<void> => {
  return (dispatch, getState, { cookies }) => {
    cookies.remove('token');
    dispatch({ type: 'LOGOUT_SUCCESS' });
    dispatch({ type: 'CLOSE_ASIDE' });
    dispatch(push('/login') as any);
  };
};

export const forgotPassword = (email: string): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
    return fetch(`${AUTH_PREFIX}/forgot`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch({
            type: 'FORGOT_PASSWORD_SUCCESS',
            payload: {
              messages: [json],
            },
          });
        });
      }
      return response.json().then(json => {
        dispatch({
          type: 'FORGOT_PASSWORD_FAILURE',
          payload: {
            messages: Array.isArray(json) ? json : [json],
          },
        });
      });
    });
  };
};

export const resetPassword = (
  password: string,
  confirm: string,
  pathToken: string,
): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
    return fetch(`${AUTH_PREFIX}/reset/${pathToken}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password,
        confirm,
      }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch(push('/login') as any);
          dispatch({
            type: 'RESET_PASSWORD_SUCCESS',
            payload: {
              messages: [json],
            },
          });
        });
      }
      return response.json().then(json => {
        dispatch({
          type: 'RESET_PASSWORD_FAILURE',
          payload: {
            messages: Array.isArray(json) ? json : [json],
          },
        });
      });
    });
  };
};

export const updateProfile = (state: any, token: string): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
    return fetch(`${AUTH_PREFIX}/account`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: state.email,
        name: state.name,
        gender: state.gender,
        location: state.location,
        website: state.website,
      }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch({
            type: 'UPDATE_PROFILE_SUCCESS',
            payload: {
              messages: [json],
            },
          });
        });
      }
      return response.json().then(json => {
        dispatch({
          type: 'UPDATE_PROFILE_FAILURE',
          payload: {
            messages: Array.isArray(json) ? json : [json],
          },
        });
      });
    });
  };
};

export const changePassword = (
  password: string,
  confirm: string,
  token: string,
): ThunkResult<void> => {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
    return fetch(`${AUTH_PREFIX}/account`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
        confirm,
      }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch({
            type: 'CHANGE_PASSWORD_SUCCESS',
            payload: {
              messages: [json],
            },
          });
        });
      }
      return response.json().then(json => {
        dispatch({
          type: 'CHANGE_PASSWORD_FAILURE',
          payload: {
            messages: Array.isArray(json) ? json : [json],
          },
        });
      });
    });
  };
};
