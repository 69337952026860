import { Loader } from 'components/Layout';
import React from 'react';
import './SelfReportsLoader.scss';

const ReportsLoader = () => {
  return (
    <div className="loading-container">
      <Loader />
    </div>
  );
};

export default ReportsLoader;
