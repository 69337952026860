import axios from 'axios';
import moment from 'moment';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ExtraArguments, ApplicationState } from 'store';
import { apiAction } from 'store/actions';
import baseActionTypes from 'store/base-reducer/types';
import { getFilterQuery } from 'store/filters/selectors';
import { createPaginator, createSelector } from 'store/pagination';

import { listingsDetailsDomain } from './types';

const supplyEndpoint = 'listings_stats';

export const listingLevelPaginator = createPaginator(supplyEndpoint);

export const selector = createSelector(listingsDetailsDomain);

export const ACTIONS = {
  CSV_DOWNLOAD_START: `${listingsDetailsDomain}_CSV_DOWNLOAD_START`.toUpperCase(),
  CSV_DOWNLOAD_SUCESS: `${listingsDetailsDomain}_CSV_DOWNLOAD_SUCCESS`.toUpperCase(),
  CSV_DOWNLOAD_FAILURE: `${listingsDetailsDomain}_CSV_DOWNLOAD_FAILURE`.toUpperCase(),
  START_LOAD_LISTINGS: `${listingsDetailsDomain}_START_LOAD_LISTINGS`.toUpperCase(),
  SUCCESS_LOAD_LISTINGS: `${listingsDetailsDomain}_SUCCESS_LOAD_LISTINGS`.toUpperCase(),
  FAIL_LOAD_LISTINGS: `${listingsDetailsDomain}_FAIL_LOAD_LISTINGS`.toUpperCase(),
};

export const downloadCSV: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async (dispatch, getState) => {
  const state = getState();
  const filterQuery = getFilterQuery(state);
  const {
    auth: { token },
  } = state;
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  dispatch({ type: ACTIONS.CSV_DOWNLOAD_START });
  try {
    const response = await axiosInstance.request({
      url: 'listings_stats/csv',
      params: new URLSearchParams(filterQuery),
      responseType: 'blob',
    });

    dispatch({ type: ACTIONS.CSV_DOWNLOAD_SUCESS });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `listingLevelView_${moment().format('YYYYMMDD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    dispatch({ type: ACTIONS.CSV_DOWNLOAD_FAILURE });
  }
};

export const startLoadListings: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => {
  dispatch({ type: ACTIONS.START_LOAD_LISTINGS });
};

export const successLoadListings: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => {
  dispatch({ type: ACTIONS.SUCCESS_LOAD_LISTINGS });
};

export const failLoadListings: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async dispatch => {
  dispatch({ type: ACTIONS.FAIL_LOAD_LISTINGS });
};

export function requestActionHandler(
  url: string,
  domain: string,
  { params, token, filterQuery },
) {
  return apiAction({
    accessToken: token,
    url,
    method: 'GET',
    label: domain,
    params,
    onSuccess: data => dispatch => {
      dispatch({
        type: baseActionTypes(domain).LOAD_SUCCESS,
        payload: {
          query: filterQuery,
          extra: {
            currency: data?.data?.currency,
          },
        },
      });
    },
    onStart: () => dispatch =>
      dispatch({ type: baseActionTypes(domain).START_LOADING }),
    onFailure: e => dispatch =>
      dispatch({
        type: baseActionTypes(domain).LOAD_FAILED,
        payload: { error: e.message },
      }),
  });
}
