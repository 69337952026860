import moment from 'moment';

export function deserializeDates(dates: string) {
  return dates.split('_').map(d => moment(d).toDate());
}

export function serializeDates({ from, to }: any) {
  const getStringDate = date => moment(date).format('YYYY-MM-DD');
  return `${getStringDate(from)}_${getStringDate(to)}`;
}
