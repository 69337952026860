import React from 'react';

const ExternalIcon = props => (
  <svg viewBox="0 0 21 25" {...props}>
    <path d="M13.9483 9.0575C13.6249 9.0575 13.3628 9.31959 13.3628 9.64298V20.7085C13.3628 21.0317 13.6249 21.294 13.9483 21.294C14.2717 21.294 14.5338 21.0317 14.5338 20.7085V9.64298C14.5338 9.31959 14.2717 9.0575 13.9483 9.0575Z" />
    <path d="M7.03958 9.0575C6.7162 9.0575 6.4541 9.31959 6.4541 9.64298V20.7085C6.4541 21.0317 6.7162 21.294 7.03958 21.294C7.36297 21.294 7.62506 21.0317 7.62506 20.7085V9.64298C7.62506 9.31959 7.36297 9.0575 7.03958 9.0575Z" />
    <path d="M2.00465 7.44268V21.8676C2.00465 22.7202 2.31729 23.5209 2.86343 24.0954C3.40705 24.6715 4.1636 24.9986 4.95537 24.9999H16.0328C16.8248 24.9986 17.5813 24.6715 18.1247 24.0954C18.6709 23.5209 18.9835 22.7202 18.9835 21.8676V7.44268C20.0692 7.15451 20.7727 6.10568 20.6274 4.99167C20.482 3.87789 19.5331 3.04473 18.4097 3.0445H15.4121V2.31265C15.4155 1.69722 15.1722 1.10625 14.7365 0.671486C14.3008 0.236952 13.709 -0.00501556 13.0935 1.58919e-05H7.89465C7.27922 -0.00501556 6.68733 0.236952 6.25166 0.671486C5.81598 1.10625 5.57264 1.69722 5.57607 2.31265V3.0445H2.57847C1.45508 3.04473 0.506192 3.87789 0.360737 4.99167C0.215511 6.10568 0.919 7.15451 2.00465 7.44268ZM16.0328 23.829H4.95537C3.95434 23.829 3.17561 22.9691 3.17561 21.8676V7.49414H17.8126V21.8676C17.8126 22.9691 17.0338 23.829 16.0328 23.829ZM6.74703 2.31265C6.74314 2.00779 6.86298 1.71437 7.07933 1.49916C7.29545 1.28395 7.58956 1.16571 7.89465 1.17097H13.0935C13.3986 1.16571 13.6927 1.28395 13.9088 1.49916C14.1252 1.71414 14.245 2.00779 14.2411 2.31265V3.0445H6.74703V2.31265ZM2.57847 4.21546H18.4097C18.9918 4.21546 19.4636 4.68727 19.4636 5.26932C19.4636 5.85137 18.9918 6.32318 18.4097 6.32318H2.57847C1.99642 6.32318 1.5246 5.85137 1.5246 5.26932C1.5246 4.68727 1.99642 4.21546 2.57847 4.21546Z" />
    <path d="M10.4942 9.0575C10.1708 9.0575 9.90869 9.31959 9.90869 9.64298V20.7085C9.90869 21.0317 10.1708 21.294 10.4942 21.294C10.8176 21.294 11.0796 21.0317 11.0796 20.7085V9.64298C11.0796 9.31959 10.8176 9.0575 10.4942 9.0575Z" />
  </svg>
);

export default ExternalIcon;
