import * as React from 'react';
import { FormattedMessage } from 'react-intl';
function Resources() {
  return (
    <div className="box first-section">
      <p className="title is-6">
        <FormattedMessage id="onboarding.resources.title" />
      </p>
      <hr style={{ height: '1px', color: '#ff0' }} />
      <p>
        <i className="fas fa-life-ring" /> &nbsp; Transparent&nbsp;
        <a
          href="https://publications.seetransparent.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#888',
            textDecoration: 'underline',
            fontWeight: 700,
          }}
        >
          <FormattedMessage id="main_menu.help" />
        </a>
      </p>
      <p>
        <i className="fas fa-newspaper" /> &nbsp; Transparent’s&nbsp;
        <a
          href="https://publications.seetransparent.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#888',
            textDecoration: 'underline',
            fontWeight: 700,
          }}
        >
          <FormattedMessage id="publications" />
        </a>
      </p>
      <p>
        <i className="fas fa-graduation-cap" /> &nbsp; Transparent’s&nbsp;
        <a
          href="https://academy.seetransparent.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#888',
            textDecoration: 'underline',
            fontWeight: 700,
          }}
        >
          <FormattedMessage id="onboarding.resources.revenue_course" />
        </a>
      </p>
    </div>
  );
}

export default Resources;
