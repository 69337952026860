import * as pmStatsTypes from '../types';

const ACTION_HANDLERS = {
  [pmStatsTypes.START_LOAD_PM_STATS]: state => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  },
  [pmStatsTypes.LOAD_PM_STATS_SUCCESS]: (state, { payload }) => {
    const { query, pm_stats, currency } = payload;
    return {
      ...state,
      query,
      pm_stats,
      currency,
      isLoading: false,
      error: null,
    };
  },
  [pmStatsTypes.LOAD_PM_STATS_ERROR]: (state, { payload }) => {
    return { ...state, isLoading: false, error: payload.error };
  },
};

const initialState: pmStatsTypes.IPmStatsState = {
  error: null,
  isLoading: true,
  pm_stats: null,
  query: null,
  currency: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
