import * as React from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Paper, StyledComponentProps } from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import Display from './Display';
import MarketIcon from '../../components/Icons/CountListingsMarket';
import PmCountIcon from '../../components/Icons/CountListingsPm';
import * as actions from './actions';

const chartId = 'listingsCount';

export type ListingsCountProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  GridProps &
  StyledComponentProps;

const ListingsCount: React.SFC<ListingsCountProps> = React.memo(
  ({
    isLoading,
    count,
    classes,
    cleanUrl,
    actions,
    appliedQuery,
    ...props
  }) => {
    React.useEffect(() => {
      if (appliedQuery) {
        actions.loadChart();
      }
    }, [actions, appliedQuery]);

    return (
      <React.Fragment>
        <Grid item={true} {...props}>
          <Paper className={classes.paper}>
            <Display
              isLoading={isLoading}
              count={count.market}
              icon={<MarketIcon fontSize="large" />}
              label={<FormattedMessage id="listings_count.label" />}
              title={<FormattedMessage id="listings_count.your_selection" />}
            />
          </Paper>
        </Grid>
        <Grid item={true} {...props}>
          <Paper className={classes.paper}>
            <Display
              isLoading={isLoading}
              count={count.pm}
              icon={<PmCountIcon fontSize="large" />}
              label={<FormattedMessage id="listings_count.label" />}
              title={cleanUrl}
            />
          </Paper>
        </Grid>
      </React.Fragment>
    );
  },
);

function formatCount(response) {
  return {
    market: get(response, ['marketResponse', 'result', 0, 'key'], '-'),
    pm: get(response, ['pmResponse', 'result', 0, 'key'], '-'),
  };
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    isLoading: get(state, `charts.${chartId}.isLoading`, true),
    count: formatCount(get(state, `charts.${chartId}.data`)),
    cleanUrl: get(state, 'auth.user._cleanUrls.0'),
    appliedQuery: get(state, 'filters.query'),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        loadChart: async () => await actions.loadCountListings(chartId),
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingsCount);
