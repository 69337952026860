export const getConfig = data => ({
  innerRadius: 40,
  series: [
    {
      type: 'PieSeries',
      alignLabels: false,
      ticks: {
        disabled: true,
      },
      labels: {
        text: `{value.percent.formatNumber('#.0')}%`,
        bent: true,
        adapter: {
          text: function(text, target) {
            if (target.dataItem && target.dataItem.values.value.percent < 5) {
              return '';
            }
            return text;
          },
        },
      },
      slices: {
        template: {
          configField: 'config',
        },
      },
      name: 'Review Score',
      dataFields: {
        value: 'count',
        category: 'score',
      },
    },
  ],
  data,
  legend: {
    marginLeft: 0,
    marginRigth: 0,
    contentWidthmaxWidth: 100,
    horizontalCenter: 'left',
    position: 'right',
    markers: {
      template: {
        width: 10,
        height: 10,
      },
    },
    labels: {
      template: {
        text: '{category} Rating',
      },
    },
    valueLabels: {
      template: {
        text: '',
      },
    },
  },
});
