import * as React from 'react';
import { PricingCell } from './PricingLine';
import { injectIntl } from 'react-intl';
import { Link } from '@material-ui/core';
export const ListingLevelLine = injectIntl<'intl', any>(
  ({
    idx,
    title,
    zipcode,
    bedrooms,
    review_count,
    intl,
    review_score,
    pricingDates,
    unified_id,
    url,
    adr,
    avg_occ,
    platform,
    currency = 'USD',
    isLoading,
  }) => {
    return (
      <tr>
        <td style={{ width: 77 }}>{idx}</td>
        <td style={{ width: 400 }}>
          <Link href={url} target="_blank">
            {title || 'No title available'}
          </Link>
        </td>
        <td>
          <img
            src={`/img/${platform.toLowerCase()}-icon.png`}
            width={30}
            alt={platform}
          />
        </td>
        <td style={{ width: 450 }}>{zipcode}</td>
        <td>{bedrooms}</td>
        <td>{review_score}</td>
        <td>{review_count}</td>
        <td>
          {intl.formatNumber(adr, {
            style: 'currency',
            currency,
          })}
        </td>
        <td>
          {intl.formatNumber(avg_occ, {
            style: 'percent',
          })}
        </td>
        {pricingDates.map((date, idx) => (
          <PricingCell
            key={idx}
            date={date}
            unifiedId={unified_id}
            currency={currency}
            isLoading={isLoading}
          />
        ))}
      </tr>
    );
  },
);
