import React from 'react';

const ChevronLeft = props => (
  <svg {...props} viewBox="0 0 34 51" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.3457 6.56625L12.7357 25.5L33.3457 44.4338L27.0007 50.25L0.000704637 25.5L27.0007 0.75L33.3457 6.56625Z"
      fill={props.fill}
    />
  </svg>
);

export default ChevronLeft;
