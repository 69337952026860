export const SET_EVENTS_QUERY = 'SET_EVENTS_QUERY';
export const START_LOADING_EVENTS = 'START_LOADING_EVENTS';
export const SET_EVENTS = 'SET_EVENTS';
export const LOAD_EVENTS_ERROR = 'LOAD_EVENTS_ERROR';
export const SELECT_EVENT = 'SELECT_EVENT';
export const TOGGLE_OPEN = 'TOGGLE_OPEN';
export interface EventType {
  [key: string]: any;
  name: string;
  date: string;
  category: string;
  source: string;
  diff: number;
  occupancy: number;
}

export interface IEventItem {
  [date: string]: EventType;
}

export interface IEventsState {
  calendar?: {
    [date: string]: IEventItem;
  };
  selected?: EventType;
  error?: any;
  isOpen?: boolean;
  isLoading?: boolean;
  query?: string;
}

interface ISet {
  type: typeof SET_EVENTS;
  payload: IEventsState;
}

interface IStartLoad {
  type: typeof START_LOADING_EVENTS;
}

interface ILoadError {
  type: typeof LOAD_EVENTS_ERROR;
  payload: IEventsState;
}

interface ISetQuery {
  type: typeof SET_EVENTS_QUERY;
  payload: object;
}

interface ISelectEvent {
  type: typeof SELECT_EVENT;
  payload: IEventItem;
}

interface IToggleOpen {
  type: typeof TOGGLE_OPEN;
}
export type EventActions =
  | ISet
  | IStartLoad
  | ILoadError
  | ISetQuery
  | ISelectEvent
  | IToggleOpen;
