import { createStyles, Theme } from '@material-ui/core';

export const GOOD = 'rgb(88,203,125)'; // Color for positive values of semaphore
export const BAD = 'rgb(255,88,118)'; // Color for negative values of semaphore
export const ZERO = 'rgb(220,220,220)'; // Color for zero values of semaphore

const baseFont = {
  fontSize: '16px',
  fontWeight: 400,
  padding: '5px 0 !important',
};

const customBoxShadow = '0 0 20px 0 rgba(31,45,61,.05)';

export function getSemaphoreStyle(color: string) {
  return {
    ...baseFont,
    backgroundColor: color,
    position: 'relative' as 'relative',
    borderRadius: 4,
    padding: 4,
  };
}

export default (theme: Theme) =>
  createStyles({
    calendarContainer: {
      paddingLeft: 15,
    },
    calendar: {},
    datesLabel: {
      fontSize: 10,
      color: '#888',
      marginBottom: '8px',
    },
    calendarWeek: {
      width: 'auto',
    },
    calendarItemContainer: {
      flexGrow: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    calendarItem: {
      boxShadow: customBoxShadow,
      padding: '9px 16px',
      textAlign: 'center',
      height: 174,
    },
    labelsItem: {
      boxShadow: customBoxShadow,
      padding: '9px 16px',
      textAlign: 'center',
      height: 174,
      paddingTop: 30.5,
      width: 166,
    },
    calendarLabelsContainer: {
      whiteSpace: 'nowrap',
    },
    calendarLabelLine: {
      padding: '10px 0',
      textAlign: 'right',
    },
    calendarLabelLastLine: {
      border: '0',
    },
    inactive: {
      backgroundColor: '#ebe9e9',
      boxShadow: customBoxShadow,
      flex: 1,
      width: '138px',
    },
    textLine: {
      ...baseFont,
      marginTop: '2px',
      marginBottom: '2px',
      minWidth: '106px',
      height: 32,
      position: 'relative',
    },
  });
