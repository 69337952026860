import * as React from 'react';

interface MediaObjectProps extends React.HTMLProps<HTMLDivElement> {
  image: () => React.ReactNode;
}

export const MediaObject: React.FunctionComponent<MediaObjectProps> = ({
  image,
  children,
}) => (
  <div className="media">
    <div className="media-left">
      <figure>{image()}</figure>
    </div>
    <div className="media-content">
      <div className="content">{children}</div>
    </div>
  </div>
);
