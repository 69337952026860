import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const MarketOverview: React.SFC<SvgIconProps> = function(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 134 134"
      {...props}
    >
      <path className="st0" d="M7.9,7.9h16.4V0H0v24h7.9V7.9z" />
      <path className="st0" d="M109.8,0v7.9h16.4V24h7.9V0H109.8z" />
      <path className="st0" d="M7.9,110.1H0v24h24.3v-7.9H7.9V110.1z" />
      <path className="st0" d="M126.2,126.2h-16.4v7.9H134v-24h-7.9V126.2z" />
      <path
        className="st0"
        d="M105.5,47.4c-7.2-14-21.7-23.5-38.4-23.5c-16.7,0-31.3,9.6-38.4,23.6c0,0.1-0.1,0.1-0.1,0.2	c-3,5.8-4.6,12.4-4.6,19.4c0,7,1.7,13.6,4.6,19.4c0,0.1,0.1,0.1,0.1,0.2c7.2,14,21.7,23.6,38.4,23.6c16.7,0,31.3-9.6,38.4-23.5	c0-0.1,0.1-0.1,0.1-0.2c3-5.8,4.6-12.4,4.6-19.4c0-7-1.7-13.6-4.6-19.4C105.5,47.5,105.5,47.5,105.5,47.4z M102.6,83.9H86.1	c0.9-4.6,1.5-9.7,1.6-15h18.7C106.1,74.3,104.8,79.3,102.6,83.9z M67,106.4c-5.9,0-11.3-7.6-14.3-18.8h28.6	C78.3,98.8,73,106.4,67,106.4z M51.8,83.9c-1-4.6-1.6-9.7-1.7-15h33.7c-0.1,5.3-0.7,10.4-1.7,15H51.8z M27.7,68.9h18.7	c0.1,5.3,0.6,10.4,1.6,15H31.5C29.3,79.3,28,74.3,27.7,68.9z M31.5,50.1H48c-0.9,4.6-1.5,9.7-1.6,15H27.7	C28,59.8,29.3,54.7,31.5,50.1z M67,27.6c5.9,0,11.3,7.6,14.3,18.8H52.7C55.8,35.2,61.1,27.6,67,27.6z M82.2,50.1	c1,4.6,1.6,9.7,1.7,15H50.2c0.1-5.3,0.7-10.4,1.7-15H82.2z M87.6,65.1c-0.1-5.3-0.6-10.4-1.6-15h16.5c2.2,4.6,3.5,9.7,3.8,15H87.6z M100.5,46.4H85.2c-1.9-7.4-4.8-13.5-8.4-17.5C86.8,31.5,95.2,37.8,100.5,46.4z M57.2,28.9c-3.6,4-6.5,10-8.4,17.5H33.5	C38.8,37.8,47.2,31.5,57.2,28.9z M33.5,87.7h15.3c1.9,7.4,4.8,13.5,8.4,17.5C47.2,102.6,38.8,96.2,33.5,87.7z M76.9,105.1	c3.6-4,6.5-10,8.4-17.5h15.3C95.2,96.2,86.8,102.6,76.9,105.1z"
      />
    </SvgIcon>
  );
};

export default MarketOverview;
