import { truncate } from 'lodash';
import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';

import { Dialog } from '@material-ui/core';

import { Loader } from 'components/Layout';
import CloseIcon from 'components/Icons/CloseIcon';
import ErrorIcon from 'components/Icons/ErrorIcon';
import OkIcon from 'components/Icons/OkIcon';
import WarningIcon from 'components/Icons/WarningIcon';

import { RawGroup } from '../types';
import styles from './styles';
import { Status } from '../lib/enums';

interface ImportPMGroupsProps {
  added: number;
  createGroup: (group: RawGroup) => void;
  fetchGroups: () => void;
  hideCreationResults: () => void;
  matched: number;
  koUrls: string[];
  showResults: boolean;
  status: Status;
}

const GroupName: React.FunctionComponent<any> = function({
  intl,
  groupName,
  pristine,
  setGroupName,
  setPristine,
}) {
  const classes = styles({});
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (pristine) setPristine(false);
    setGroupName(e.target.value);
  };

  return (
    <div className="field">
      <label className={`label ${classes.inputLabel}`}>
        <FormattedMessage id="settings.pm_groups.import.group_name.label" />
      </label>
      <div className="control">
        <input
          className={`input ${classes.inputText} ${
            groupName ? '' : pristine ? '' : 'is-danger'
          }`}
          type="text"
          placeholder={intl.formatMessage({
            id: 'settings.pm_groups.import.group_name.placeholder',
          })}
          onChange={onChange}
          value={groupName}
        />
      </div>
      {groupName ? null : pristine ? null : (
        <p className="help is-danger">
          <FormattedMessage id="settings.pm_groups.import.group_name.error" />
        </p>
      )}
    </div>
  );
};

const ListingURLs: React.FunctionComponent<any> = function({
  intl,
  pristine,
  setPristine,
  setUrls,
  setValue,
  value,
}) {
  const classes = styles({});
  const getUrls = (value: string) =>
    value
      .replace(/,/gi, '\n')
      .replace(/ /gi, '\n')
      .split('\n')
      .map(s => s.trim())
      .filter(x => x);
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (pristine) setPristine(false);
    setValue(e.target.value);
  };
  const onBlur = () => {
    setUrls(getUrls(value));
  };

  return (
    <div className="field">
      <label className={`label ${classes.inputLabel}`}>
        <FormattedMessage id="settings.pm_groups.import.listings_urls.label" />
        <span className={classes.inputLabelHint}>
          <FormattedMessage id="settings.pm_groups.import.listings_urls.label_hint" />
        </span>
      </label>
      <div className="control">
        <textarea
          className={`textarea ${classes.inputTextArea} ${
            value ? '' : pristine ? '' : 'is-danger'
          }`}
          placeholder={intl.formatMessage({
            id: 'settings.pm_groups.import.listings_urls.placeholder',
          })}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      </div>
      {value ? null : pristine ? null : (
        <p className="help is-danger">
          <FormattedMessage id="settings.pm_groups.import.listings_urls.error" />
        </p>
      )}
    </div>
  );
};

const Buttons: React.FunctionComponent<any> = function({
  createGroup,
  groupName,
  setGroupName,
  setGroupNamePristine,
  setUrls,
  setUrlsPristine,
  setUrlsValue,
  urls,
}) {
  const valid = groupName && urls.join('\n');
  const classes = styles({});
  const clearAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setGroupName('');
    setUrls([]);
    setGroupNamePristine(true);
    setUrlsPristine(true);
    setUrlsValue('');
  };
  const save = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setGroupNamePristine(false);
    setUrlsPristine(false);
    if (valid) {
      createGroup({ name: groupName, urls });
      setUrlsValue(urls.join('\n'));
    }
  };

  return (
    <div className="field is-grouped is-grouped-right">
      <div className={`control ${classes.buttonContainer}`}>
        <button
          className={`button ${classes.button}`}
          style={{ background: '#E4E4E4' }}
          onClick={clearAll}
        >
          <FormattedMessage id="settings.pm_groups.import.buttons.clear_all" />
        </button>
      </div>
      <div className={`control ${classes.buttonContainer}`}>
        <button
          className={`button is-success ${classes.button}`}
          style={{ background: '#08D8CB' }}
          onClick={save}
        >
          <FormattedMessage id="settings.pm_groups.import.buttons.save_group" />
        </button>
      </div>
    </div>
  );
};

const OkUrlsResult: React.FunctionComponent<any> = ({ added, matched }) => {
  const classes = styles({});

  return added === 0 ? null : (
    <div className={`${classes.okUrlsLabel} ${classes.urlsLabel}`}>
      <span style={{ padding: '0 10px' }}>
        <OkIcon height={12} />
      </span>
      &nbsp;&nbsp;
      <FormattedMessage id="seetings.pm_groups.import.results.success" />
      &nbsp;
      <FormattedMessage
        id="seetings.pm_groups.import.results.success.message"
        values={{ added, matched }}
      />
    </div>
  );
};

const KoUrl: React.FunctionComponent<any> = ({ url }) => {
  const classes = styles({});

  return (
    <div className={classes.koUrl}>
      <span style={{ paddingRight: '10px' }}>
        <ErrorIcon height={12} />
      </span>
      <span className={classes.koUrlText} title={url}>
        {url}
      </span>
    </div>
  );
};

const KoUrlsResult: React.FunctionComponent<any> = ({ koUrls }) => {
  const classes = styles({});

  return koUrls.length === 0 ? null : (
    <div>
      <div className={`${classes.koUrlsLabel} ${classes.urlsLabel}`}>
        <span style={{ padding: '0 10px' }}>
          <WarningIcon height={12} />
        </span>
        &nbsp;&nbsp;
        <FormattedMessage id="seetings.pm_groups.import.results.error" />
        &nbsp;
        <FormattedMessage
          id="seetings.pm_groups.import.results.error.message"
          values={{ matched: koUrls.length }}
        />
      </div>
      <div className={classes.koUrls}>
        {koUrls.map(url => (
          <KoUrl key={url} url={url} />
        ))}
      </div>
    </div>
  );
};

const Results: React.FunctionComponent<any> = ({
  added,
  group,
  hideCreationResults,
  matched,
  showResults,
  koUrls,
  status,
}) => {
  const GROUP_NAME_LENGTH = 20;
  const classes = styles({});
  const noAddedNoMissing = added === 0 && koUrls.length === 0;

  const OkMessage = () => (
    <React.Fragment>
      <FormattedMessage id="seetings.pm_groups.import.results.title">
        {txt => <span className={classes.dialogTitle}>{txt}</span>}
      </FormattedMessage>
      {status === Status.UPDATED ? (
        <FormattedMessage
          id="seetings.pm_groups.import.results.updated"
          values={{
            groupName: truncate(group?.groupName, {
              length: GROUP_NAME_LENGTH,
            }),
          }}
        >
          {txt => <span className={classes.dialogSubtitle}>{txt}</span>}
        </FormattedMessage>
      ) : (
        <FormattedMessage
          id="seetings.pm_groups.import.results.created"
          values={{
            groupName: truncate(group?.groupName, {
              length: GROUP_NAME_LENGTH,
            }),
          }}
        >
          {txt => <span className={classes.dialogSubtitle}>{txt}</span>}
        </FormattedMessage>
      )}
    </React.Fragment>
  );
  const KoMessage = () =>
    status === Status.UPDATED ? (
      <FormattedMessage
        id="seetings.pm_groups.import.results.notUpdated"
        values={{
          groupName: truncate(group?.groupName, { length: GROUP_NAME_LENGTH }),
        }}
      >
        {txt => <span className={classes.dialogSubtitle}>{txt}</span>}
      </FormattedMessage>
    ) : (
      <FormattedMessage
        id="seetings.pm_groups.import.results.notCreated"
        values={{
          groupName: truncate(group?.groupName, {
            length: GROUP_NAME_LENGTH,
          }),
        }}
      >
        {txt => <span className={classes.dialogSubtitle}>{txt}</span>}
      </FormattedMessage>
    );

  return (
    <Dialog onClose={() => hideCreationResults()} open={showResults}>
      <div className={classes.dialogContainer}>
        <div className={classes.close} onClick={() => hideCreationResults()}>
          <CloseIcon height={26} fill={'#B3C1CE'} />
        </div>
        {added ? <OkMessage /> : <KoMessage />}
        <OkUrlsResult added={added} matched={matched} />
        <KoUrlsResult koUrls={koUrls} />
        {noAddedNoMissing ? (
          <FormattedMessage id="seetings.pm_groups.import.results.noAddedNoMissing">
            {txt => <span className={classes.dialogTitle}>{txt}</span>}
          </FormattedMessage>
        ) : null}
      </div>
    </Dialog>
  );
};

const ImportPMGroups: React.FunctionComponent<ImportPMGroupsProps &
  WrappedComponentProps> = function({
  intl,
  added,
  createGroup,
  fetchGroups,
  hideCreationResults,
  matched,
  koUrls,
  showResults,
  status,
}) {
  const classes = styles({});
  const [groupNamePristine, setGroupNamePristine] = React.useState<boolean>(
    true,
  );
  const [groupName, setGroupName] = React.useState<string>('');
  const [urls, setUrls] = React.useState<string[]>([]);
  const [urlsPristine, setUrlsPristine] = React.useState<boolean>(true);
  const [urlsValue, setUrlsValue] = React.useState<string>('');

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return status === Status.RUNNING ? (
    <Loader />
  ) : (
    <React.Fragment>
      <form className={classes.form}>
        <div className={classes.container}>
          <div className={classes.col1}>
            <GroupName
              intl={intl}
              groupName={groupName}
              pristine={groupNamePristine}
              setGroupName={setGroupName}
              setPristine={setGroupNamePristine}
            />
          </div>
          <div className={classes.col2}>
            <ListingURLs
              intl={intl}
              pristine={urlsPristine}
              setPristine={setUrlsPristine}
              setValue={setUrlsValue}
              setUrls={setUrls}
              value={urlsValue}
            />
          </div>
        </div>
        <Buttons
          createGroup={createGroup}
          groupName={groupName}
          setGroupName={setGroupName}
          setGroupNamePristine={setGroupNamePristine}
          setUrls={setUrls}
          setUrlsPristine={setUrlsPristine}
          setUrlsValue={setUrlsValue}
          urls={urls}
        />
      </form>
      <Results
        {...{
          added,
          group: { groupName },
          hideCreationResults,
          koUrls,
          matched,
          showResults,
          status,
        }}
      />
    </React.Fragment>
  );
};

export default injectIntl(ImportPMGroups);
