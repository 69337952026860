import { IAuthState, AuthActions } from './types';
import { AnyAction, Reducer } from 'redux';

const initialState: IAuthState = {
  token: null,
  user: null,
  hydrated: false,
};

const auth: Reducer<IAuthState> = (
  state: IAuthState = initialState,
  action: AnyAction,
) => {
  switch ((action as AuthActions).type) {
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        token: action.payload.token,
        user: action.payload.user,
      });
    case 'LOGOUT_SUCCESS':
      return initialState;
    case '@INITIALIZE_AUTH_STATE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default auth;
