import Pagination from 'bulma-pagination-react';
import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
  WrappedComponentProps,
} from 'react-intl';

import { Link } from '@material-ui/core';

import { Loader } from 'components/Layout';
import { Table, TableHead } from 'components/Tables';
import { Box } from 'components/Transparent';

import styles from './supply.module.scss';

const ELEMENTS_PER_PAGE = 10;

interface TableProps {
  appliedQuery: string;
  currency: string;
  currentPage: number;
  error: any;
  fetching: boolean;
  loadSupplyFirstPage: (params: any) => void;
  loadSupplyPage: (params: any, page: number) => void;
  listings: any[];
  numberOfPages: number;
  order: string;
  orderBy: string;
}

interface FormatterProps {
  value: number | null;
}

interface FormatRevenueProps extends FormatterProps {
  currency?: string;
}

const FormatRevenue: React.FC<FormatRevenueProps> = ({
  value,
  currency = 'USD',
}) => (
  <>
    {value ? ( // eslint-disable-next-line
      <FormattedNumber value={value} style="currency" currency={currency} />
    ) : (
      '-'
    )}
  </>
);

const FormatOccupancy: React.FC<FormatterProps> = ({ value }) => (
  <>
    {value ? (
      <FormattedNumber
        value={value}
        // eslint-disable-next-line
        style="percent"
        maximumFractionDigits={2}
      />
    ) : (
      '-'
    )}
  </>
);

interface TableRowProps {
  listing: any;
  idx: number;
  currency: string;
}

const TableRow: React.FC<TableRowProps> = ({ listing, idx, currency }) => (
  <tr>
    <td>{idx + 1}</td>
    <td className={styles.title}>
      <Link href={listing.url} target="_blank">
        {listing.title || 'No title available'}
      </Link>
    </td>
    <td>
      <FormatOccupancy value={listing.occupancy_2020} />
    </td>
    <td>
      <FormatOccupancy value={listing.occupancy_2021} />
    </td>
    <td>
      <FormatOccupancy value={listing.occupancy_2022} />
    </td>
    <td>
      <FormatRevenue value={listing.revenue_2020} currency={currency} />
    </td>
    <td>
      <FormatRevenue value={listing.revenue_2021} currency={currency} />
    </td>
    <td>
      <FormatRevenue value={listing.revenue_2022} currency={currency} />
    </td>
  </tr>
);

const EmptyRow: React.FC = () => (
  <tr>
    <td colSpan={8}>
      <FormattedMessage id="performance.table.no_listings" />
    </td>
  </tr>
);

interface CustomPaginationProps {
  currentPage: number;
  pages: number;
  onChange: (i: number) => void;
}

export const CustomPagination = React.memo<CustomPaginationProps>(
  ({ currentPage, pages, onChange }) => (
    <Pagination
      currentPage={currentPage}
      pages={pages}
      onChange={onChange}
      className="is-small"
    />
  ),
);

const ListingsError: React.FC<any> = () => (
  <div className={styles.error}>
    <FormattedMessage id="performance.table.error" />
  </div>
);

const Performance: React.FC<TableProps & WrappedComponentProps> = ({
  appliedQuery,
  currency,
  currentPage,
  error,
  fetching,
  loadSupplyFirstPage,
  loadSupplyPage,
  listings,
  numberOfPages,
  order,
  orderBy,
}) => {
  const sortBy = (key: string) => {
    let sortOrder = order;
    if (key === orderBy) {
      sortOrder = order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOrder = 'ASC';
    }
    loadSupplyPage(
      { order: sortOrder, orderBy: key, limit: ELEMENTS_PER_PAGE },
      1,
    );
  };
  const loadPage = (i: number) => {
    loadSupplyPage({ order, orderBy, limit: ELEMENTS_PER_PAGE }, i);
  };

  React.useEffect(() => {
    loadSupplyFirstPage({ limit: ELEMENTS_PER_PAGE });
  }, [appliedQuery, loadSupplyFirstPage]);

  if (fetching) return <Loader />;

  return (
    <Box className={styles.container}>
      {error ? (
        <ListingsError />
      ) : (
        <React.Fragment>
          <Table isCentered={true} isBordered={true}>
            <col />
            <col />
            <colgroup span={3} />
            <colgroup span={3} />
            <thead>
              <tr>
                <th rowSpan={1} />
                <th rowSpan={1} />
                <TableHead colSpan={3} scope="colgroup">
                  {' '}
                  Occupancy{' '}
                </TableHead>
                <TableHead colSpan={3} scope="colgroup">
                  Revenue
                </TableHead>
              </tr>
              <tr>
                <TableHead>#</TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isCentered={false}
                  isSortedBy={orderBy === 'title'}
                  onClick={() => sortBy('title')}
                >
                  <FormattedMessage id="performance.table.header.property_title" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'occupancy_2020'}
                  onClick={() => sortBy('occupancy_2020')}
                >
                  <FormattedMessage
                    id="performance.table.header.2020"
                    defaultMessage="2020"
                  />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'occupancy_2021'}
                  onClick={() => sortBy('occupancy_2021')}
                >
                  <FormattedMessage
                    id="performance.table.header.2021"
                    defaultMessage="2021"
                  />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'occupancy_2022'}
                  onClick={() => sortBy('occupancy_2022')}
                >
                  <FormattedMessage
                    id="performance.table.header.2022_occ"
                    defaultMessage="2022"
                  />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'revenue_2020'}
                  onClick={() => sortBy('revenue_2020')}
                >
                  <FormattedMessage
                    id="performance.table.header.2020_revenue"
                    defaultMessage="2020"
                  />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'revenue_2021'}
                  onClick={() => sortBy('revenue_2021')}
                >
                  <FormattedMessage
                    id="performance.table.header.2020"
                    defaultMessage="2021"
                  />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'revenue_2022'}
                  onClick={() => sortBy('revenue_2022')}
                >
                  <FormattedMessage
                    id="performance.table.header.2022"
                    defaultMessage="2022"
                  />
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {listings.length === 0 ? <EmptyRow /> : null}
              {listings.map((l, i) => (
                <TableRow key={i} idx={i} listing={l} currency={currency} />
              ))}
            </tbody>
          </Table>
          <CustomPagination
            currentPage={currentPage}
            pages={numberOfPages}
            onChange={loadPage}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default injectIntl(Performance);
