import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  font-size: 28px;
  color: #505d6e;
  font-weight: 700;
  margin: 0.67em 0;
  text-transform: capitalize;
`;
interface TitleProps {
  Icon: React.ElementType;
}
export const Title: React.SFC<TitleProps> = ({ children, Icon }) => {
  const StyledIcon = styled(Icon)`
    margin-right: 8px;
  `;
  return (
    <StyledH1>
      <StyledIcon width={25} fill="" />
      {children}
    </StyledH1>
  );
};
