import { schema } from 'normalizr';
import { deserializeDates } from '../../services/filters';

interface IDatabaseFilter {
  key: string;
  value: string | string[];
}

const formatDates = dates => deserializeDates(dates as string);

const processFilters = ({ key, value }: IDatabaseFilter) => {
  if (key === 'dates') return formatDates(value);
  return value;
};

const activeFilters = new schema.Entity('activeFilters', undefined, {
  idAttribute: value => value.key,
  processStrategy: processFilters,
});
const availableFilters = new schema.Entity('availableFilters', undefined, {
  idAttribute: value => value.key,
  processStrategy: processFilters,
});

export const FiltersSchema = {
  data: {
    activeFilters: [activeFilters],
    availableFilters: [availableFilters],
  },
};
