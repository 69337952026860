import mixpanel, { Dict } from 'mixpanel-browser';
import { History } from 'history';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const env_check = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props?: Dict) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  register_once: (props: Dict) => {
    if (env_check) mixpanel.register_once(props);
  },
};

export function useMixpanelTrackingWithOrigin() {
  const origin = useSelector<ApplicationState, string>(
    state => state.router.location.pathname,
  );
  const trackMixpanelAction = (name: string, props?: Dict) => {
    props = { ...props, origin };
    actions.track(name, props);
  };
  return trackMixpanelAction;
}

export function addHistoryListener(history: History) {
  history.listen((location, action) => {
    if (action === 'PUSH') {
      actions.track('user changing view', {
        name: location.pathname,
      });
    }
  });
}

export const Mixpanel = actions;
