import { schema } from 'normalizr';

const listingsDetails = new schema.Entity('listingsDetails', undefined, {
  idAttribute: ({ unified_id }) => unified_id,
  processStrategy: (value, parent, key) => ({
    ...value,
    currency: parent.currency,
  }),
});

export const listingsDetailsSchema = {
  data: {
    listings: [listingsDetails],
  },
};

const listingsPricing = new schema.Entity('listingsPricing', undefined, {
  idAttribute: ({ unifiedId }) => unifiedId,
  processStrategy: ({ unifiedId, ...entity }) => ({
    [entity.date]: {
      price: entity.price,
      available: entity.available,
    },
  }),
});
// TODO simplify this method
const exportPricingSchema = new schema.Entity('listingsPricing', undefined, {
  idAttribute: ({ unifiedId }) => unifiedId,
  processStrategy: ({ unifiedId, ...entity }) => ({
    [entity.date]: entity.price,
  }),
});

export const listingsPricingSchema = {
  data: {
    pricing: [listingsPricing],
  },
};

export const listingsPricingExportSchema = {
  data: {
    pricing: [exportPricingSchema],
  },
};
