import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { normalize } from 'normalizr';
import { get, isEqual } from 'lodash/fp';
import { parse } from 'qs';

import { RatesSchema } from './schema';
import { ExtraArguments, ApplicationState } from '../';
import * as actionTypes from './types';
export const loadRates: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  actionTypes.RatesActions
>> = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const { query } = state.filters;
      const appliedQuery = state.rates.query;
      if (appliedQuery && isEqual(query, appliedQuery)) return null;
      dispatch({ type: actionTypes.START_LOADING_RATES });
      const response = await api.getRates({ token, query: parse(query) });
      const { entities, result } = normalize(response, RatesSchema);
      if (get('status', result) !== 'SUCCESS') throw new Error('GetRatesError');
      dispatch({ type: actionTypes.SET_RATES_QUERY, payload: { query } });
      dispatch({
        type: actionTypes.SET_RATES,
        payload: {
          calendar: entities.calendar,
          currency: get('currency', result),
        },
      });
    } catch (e) {
      dispatch({ type: actionTypes.LOAD_RATES_ERROR, payload: { error: e } });
    }
  };
};
