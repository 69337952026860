import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store';
import { toggleOpen } from '../../../store/events/actions';
import { EventActions } from '../../../store/events/types';
import Layout from './Layout';

type EventProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const EventsContainer = ({ event, open, actions }: EventProps) => {
  const handleClose = () => {
    actions.toggleOpen();
  };

  return <Layout event={event} isOpen={open} handleClose={handleClose} />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    event: state.events.selected,
    open: state.events.isOpen,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, EventActions>,
) {
  return {
    actions: bindActionCreators({ toggleOpen }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsContainer);
