import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader } from 'components/Layout';
import { ApplicationState } from 'store';
import { ListingLevelView } from './layout/composition';
import { goToPage, loadFirstPage } from 'store/listings/actions';
import { downloadCSV } from './store/actions';
import { getFilterQuery } from 'store/filters/selectors';

type ListingsLevelProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const ListingLevelLayout: React.SFC<ListingsLevelProps> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Loader /> : <ListingLevelView {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        goToPage,
        loadFirstPage,
        downloadCSV,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const isLoading = !getFilterQuery(state);
  return {
    isLoading,
  };
};

export const ListingLevel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingLevelLayout);
