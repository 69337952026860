import { isEmpty, get } from 'lodash';
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableLayout } from './layout';
import { goToPage, loadFirstPage } from 'store/listings/actions';
import {
  addListingSelection,
  removeListingSelection,
  removeAllListingSelection,
  getCurrentPaginator,
} from 'routes/settings/PMGroups/store/actions';
import { getSelector } from 'store/listings';
import { Status as ListingsStatus } from 'store/listings/types';
import { getEntities } from 'store/entities/selectors';
import { serializeSortKey } from 'store/pagination';
import { getFilterQuery } from 'store/filters/selectors';
import { Loader } from 'components/Layout';

const elementsPerPage = 15;

const ListingsTable = ({
  listingsDetails,
  currentPage,
  goToPage,
  order,
  orderBy,
  addListingSelection,
  removeListingSelection,
  //elementsPerPage,
  listingsSelected,
  numberOfPages,
  startLoadListings,
  successLoadListings,
  failLoadListings,
  currentListingsDomain,
  paginator,
}: any) => {
  const isSortedBy = (key: string) => sortedBy === key;

  const isAsc = order === 'ASC';
  const sortedBy = orderBy;
  const setSortBy = (key: string) => () => {
    goToPage(
      {
        orderBy: key,
        order: isAsc ? 'DESC' : 'ASC',
        limit: elementsPerPage,
      },
      1,
      startLoadListings,
      successLoadListings,
      failLoadListings,
      paginator,
      currentListingsDomain,
    );
  };
  const goTo = (options, page) => {
    goToPage(
      options,
      page,
      startLoadListings,
      successLoadListings,
      failLoadListings,
      paginator,
      currentListingsDomain,
    );
  };

  const listingsUids = listingsDetails.map(listing => listing.unified_id);
  const handleDeselectAllPage = () => {
    for (let listing of listingsDetails) {
      removeListingSelection(listing.unified_id);
    }
  };

  const isSelectAllChecked = () =>
    listingsUids.every(uid => listingsSelected.includes(uid));

  return (
    <>
      <TableLayout
        isSortedBy={isSortedBy}
        isAsc={isAsc}
        setSortBy={setSortBy}
        elementsPerPage={elementsPerPage}
        currentPage={currentPage}
        listingsDetails={listingsDetails}
        addListingSelection={addListingSelection}
        removeListingSelection={removeListingSelection}
        listingsSelected={listingsSelected}
        handleDeselectAll={handleDeselectAllPage}
        isSelectAllChecked={isSelectAllChecked}
        order={order}
        orderBy={orderBy}
        numberOfPages={numberOfPages}
        goToPage={goTo}
      />
    </>
  );
};

const mapStateToProps = state => {
  const domain = state?.pmGroups?.currentListingsDomain;
  const selector = getSelector(domain);
  const paginator = getCurrentPaginator(domain);
  const { page, sortKey } = selector.getCurrentPage(state);
  const numberOfPages = selector.getTotalPages(state);
  const [orderBy, order] = serializeSortKey(sortKey);
  const listingsDetails = getEntities(
    state,
    state?.pmGroups?.currentListingsDomain,
    selector.getPageIds(state, { page, sortKey }),
  );

  return {
    listingsDetails,
    status: get('listingsDetails.isLoading', state),
    error: get('listingsDetails.error', state),
    appliedQuery: getFilterQuery(state),
    numberOfPages,
    currentPage: page,
    orderBy,
    order,
    listingsStatus: state?.listings?.status,
    listingsSelected: state?.pmGroups?.listingsSelected || [],
    paginator,
    ...state.pmGroups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        goToPage,
        loadFirstPage,
        addListingSelection,
        removeListingSelection,
        removeAllListingSelection,
      },
      dispatch,
    ),
  };
};

const ListingsTableLayout: React.SFC<any> = ({
  error,
  listingsStatus,
  actions,
  startLoadListings,
  successLoadListings,
  failLoadListings,
  ...props
}) => {
  const { loadFirstPage } = actions;
  const { appliedQuery, paginator, currentListingsDomain } = props;
  const promises = React.useRef(null);

  const loadFirst = useCallback(() => {
    return loadFirstPage(
      startLoadListings,
      successLoadListings,
      failLoadListings,
      paginator,
      currentListingsDomain,
    );
  }, [
    startLoadListings,
    successLoadListings,
    failLoadListings,
    loadFirstPage,
    paginator,
    currentListingsDomain,
  ]);

  useEffect(() => {
    if (!promises.current) promises.current = loadFirst();
    else promises.current.then(loadFirst);
  }, [appliedQuery, loadFirst]);

  props = {
    ...actions,
    ...props,
    startLoadListings,
    successLoadListings,
    failLoadListings,
  };

  return listingsStatus === ListingsStatus.RUNNING ? (
    <Loader />
  ) : (
    !isEmpty(props.listingsDetails) && <ListingsTable {...props} />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingsTableLayout) as any;
