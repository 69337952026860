import * as React from 'react';
import { head } from 'lodash';
import { useIntl } from 'react-intl';
import { Chart, messages, config } from 'components/Charts/AmCharts';

export const PacingReservations = ({ data }) => {
  const intl = useIntl();
  const getConfig = data => {
    if (!data || !head(data)) return null;
    const { pricingDate, ...dataHead } = head(data);
    const series = Object.keys(dataHead).map(key => ({
      type: 'LineSeries',
      name: intl.formatMessage(messages[key]),
      tooltipText: `[bold]{dateX.formatDate('MMM dd')}[/]
                        {valueY}`,
      dataFields: {
        valueY: key,
        dateX: 'pricingDate',
      },
      strokeWidth: 2,
    }));
    return {
      ...config,
      series,
      dateFormatter: {
        inputDateFormat: 'yyyy-MM-dd',
      },
      xAxes: [
        {
          type: 'DateAxis',
          parseDates: true,
          title: {
            text: intl.formatMessage(messages['date']),
          },
          renderer: {
            labels: {
              location: 0.5,
            },
          },

          cursorTooltipEnabled: false,
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          title: {
            text: intl.formatMessage(messages['reservations']),
          },
          numberFormatter: {
            type: 'NumberFormatter',
            forceCreate: true,
            numberFormat: `#`,
          },
          cursorTooltipEnabled: false,
        },
      ],
      legend: {},
      data,
      cursor: {},
    };
  };

  return (
    <>
      {data && (
        <Chart
          chartType="XYChart"
          exportMenu={true}
          config={getConfig(data)}
          chartId="PacingReservations"
        />
      )}
    </>
  );
};
