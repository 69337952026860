import * as actionTypes from './action-types';
import { AnyAction } from 'redux';

export const updateEntity = (domain, entityId, newEntity) => ({
  domain,
  type: actionTypes.UPDATE_ENTITY,
  payload: { id: entityId, entity: newEntity },
});

export const updateEntities = (
  domain: string,
  newEntityList: any = {},
): AnyAction => ({
  domain,
  type: actionTypes.UPDATE_ENTITIES,
  payload: { entities: newEntityList },
});

export const updateAllEntities = (entities = {}) => ({
  type: actionTypes.UPDATE_ALL_ENTITIES,
  payload: { entities },
});

export const removeEntity = (domain, entityId) => ({
  domain,
  type: actionTypes.REMOVE_ENTITY,
  payload: { id: entityId },
});

export const clearEntities = domain => ({
  domain,
  type: actionTypes.CLEAR_ENTITIES,
});
