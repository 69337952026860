import { ThunkAction } from 'redux-thunk';
import { ILayoutActions, ILayoutState, OPEN_ASIDE, CLOSE_ASIDE } from './types';
import { Mixpanel } from '../../services/mixpanel/index';

export const openAside = (): ThunkAction<
  void,
  ILayoutState,
  void,
  ILayoutActions
> => {
  return dispatch => {
    Mixpanel.track('Click hide/show drawer', { action: 'open' });
    dispatch({ type: OPEN_ASIDE });
  };
};
export const closeAside = (): ThunkAction<
  void,
  ILayoutState,
  void,
  ILayoutActions
> => {
  return dispatch => {
    Mixpanel.track('Click hide/show drawer', { action: 'close' });
    dispatch({ type: CLOSE_ASIDE });
  };
};
