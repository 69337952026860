import * as React from 'react';
import { mapValues } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { parse } from 'qs';

import { ApplicationState } from '../../store/index';

import {
  Chart as Display,
  ChartProps as DisplayProps,
} from '../../components/Charts';
import { IChartState, IChartsActions, loadChart } from '../../store/charts';
import { loadFilters, applyFilters } from 'store/filters/actions';
import { IFilterDictionary } from '../../store/filters/types';

export type DataSeriesType = 'pricing_stats' | 'pricing_series';
export type TimeGranularity = 'day' | 'month' | 'year';
export type MetricType = 'count' | 'avg' | 'sum';
export type VisualizationType = 'line' | 'bar' | 'pie' | 'bar_line';

export interface DataSeriesParams {
  filters?: IFilterDictionary;
  time_granularity?: TimeGranularity;
  group?: string;
  metric: MetricType;
  select: string;
  type: DataSeriesType;
  comparative: boolean;
}

export interface IProps extends DisplayProps {
  dataType: DataSeriesType;
  granularity?: TimeGranularity;
  group?: string;
  metric: MetricType;
  select: string;
  comparative: boolean;
  isPercentage: boolean;
}

type ChartProps = IProps & StateProps & DispatchProps;

function transformSeries(props: IProps, state: IChartState) {
  if (!state) return [];

  const { data = [] } = state;

  if (!data) return null;
  if (props.isPercentage) {
    return data.map(d =>
      mapValues(d, (value, key) => (key === 'key' ? value : value * 100)),
    );
  }
  return data;
}

class Chart extends React.Component<ChartProps> {
  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate(newProps: ChartProps) {
    const dataChanged =
      JSON.stringify(newProps.data) !== JSON.stringify(this.props.data)
        ? this.props.data === null
          ? true
          : false
        : false;
    if (dataChanged) {
      this.loadChart();
    }
  }

  shouldComponentUpdate(newProps: ChartProps) {
    if (JSON.stringify(newProps.data) !== JSON.stringify(this.props.data))
      return true;
    if (newProps.loading !== this.props.loading) return true;
    if (newProps.error !== this.props.error) return true;
    return false;
  }

  loadChart() {
    const {
      actions,
      chartId,
      dataType,
      metric,
      select,
      group,
      comparative,
      granularity = 'day',
    } = this.props;
    const { loadChart, loadQuery } = actions;
    const params: DataSeriesParams = {
      metric,
      select,
      group,
      comparative,
      type: dataType,
      time_granularity: granularity,
    };
    const query = parse(this.props.routerQuery.substr(1));
    return loadQuery(query).then(() => loadChart(chartId, params));
  }

  render() {
    const {
      chartId,
      data,
      error,
      loading,
      options,
      vizType,
      currency,
      ...props
    } = this.props;

    return (
      <Display
        chartId={chartId}
        data={data}
        error={error}
        loading={loading}
        options={options}
        vizType={vizType}
        currency={currency}
        {...props}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const { charts = {} } = state;
  const { chartId } = ownProps;

  return {
    data: transformSeries(ownProps, charts[chartId]),
    loading: (charts[chartId] || { isLoading: true }).isLoading,
    error: (charts[chartId] || { error: null }).error,
    currency: (charts[chartId] || { currency: '' }).currency,
    routerQuery: state.router.location.search,
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ApplicationState, null, IChartsActions>,
) => {
  return {
    actions: bindActionCreators(
      {
        loadChart,
        loadQuery: query =>
          dispatch(loadFilters(query))
            .catch(() => loadFilters())
            .then(() => applyFilters()),
      },
      dispatch,
    ),
  };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
