import * as React from 'react';
import { isEmpty, get } from 'lodash/fp';
import { List, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../../store';
import {
  loadFilters,
  updateCalendar,
  updateFilter,
} from '../../store/filters/actions';
import { apply, clear } from '../../store/actions';
import { IFiltersActions } from '../../store/filters/types';
import {
  Filters as FiltersDumb,
  FiltersActions,
  FiltersError,
} from '../../components/Dashboard/Filters';
import { Form } from '../../components/Forms';
import DatePicker from '../../components/DatePicker';
import { Loader } from '../../components/Layout';
import { ActionMeta } from 'react-select';
import { formatFilters } from '../../services/filters';
import {
  Mixpanel,
  useMixpanelTrackingWithOrigin,
} from '../../services/mixpanel';

type IFiltersProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Filters(props: IFiltersProps) {
  const [isOverButton, setState] = React.useState(false);
  const trackEvent = useMixpanelTrackingWithOrigin();

  function filterClickEvent({ action, option }: any, filter) {
    if (action === 'select-option') {
      trackEvent('Filter selected', {
        filter,
        value: option,
      });
    }
  }
  async function apply() {
    setState(false);
    await props.actions.apply();
  }

  const handleChange = (name: string) => (
    value: any,
    metaAction: ActionMeta<any>,
  ) => {
    filterClickEvent(metaAction, name);
    props.actions.updateFilter(name, value || []);
  };

  const handleClickSubmit = () => {
    Mixpanel.track('Click apply filters');
  };

  const handleBlur = async () => {
    const query = formatFilters(props.selectedFilters, props.dates);
    isOverButton
      ? await Promise.resolve()
          .then(() => props.actions.loadFilters(query))
          .then(() => apply())
      : await Promise.resolve(props.actions.loadFilters(query));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    Mixpanel.track('Filters Submitted');
    return apply();
  };

  const handleClear = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    props.actions.clear();
  };

  const handleDateSelect = (startDate: Date, endDate: Date) => {
    Mixpanel.track('Dates Changed');
    props.actions.updateCalendar(startDate, endDate);
  };

  if (props.error) {
    return (
      <FiltersError error={props.error} retryLoad={props.actions.loadFilters} />
    );
  }
  if (props.isLoading || isEmpty(props.dates)) {
    return <Loader />;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <List>
        <FiltersDumb
          availableFilters={props.availableFilters}
          handleChange={handleChange}
          selectedFilters={props.selectedFilters}
          handleBlur={handleBlur}
        />
        <Divider style={{ marginTop: 25 }} />
        <DatePicker
          minDate={props.dates.minDate}
          maxDate={props.dates.maxDate}
          startDate={props.dates.selectedDates.from}
          endDate={props.dates.selectedDates.to}
          onDatesChanges={handleDateSelect}
        />
        <FiltersActions
          open={props.isAsideOpen}
          handleClear={handleClear}
          onClickSubmitButton={handleClickSubmit}
          onMouseEnter={e => setState(true)}
          onMouseLeave={e => setState(false)}
        />
      </List>
    </Form>
  );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    availableFilters: state.filters.availableFilters,
    selectedFilters: state.filters.selectedFilters,
    isLoading: state.filters.isLoading,
    query: state.filters.query || null,
    dates: state.filters.dates,
    error: state.filters.error,
    isAsideOpen: get('layout.isOpen', state) || null,
    locale: state['i18n'].locale,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, IFiltersActions>,
) {
  return {
    actions: bindActionCreators(
      {
        loadFilters,
        updateCalendar,
        updateFilter,
        apply,
        clear,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
