import * as React from 'react';

const ClockIcon = () => (
  <>
    <style>{'.prefix__st8{fill:#02315e;fill-opacity:.9}'}</style>
    <circle cx={154.2} cy={153.9} r={20.3} fill="#fff" />
    <g id="prefix__schedule">
      <path
        className="prefix__st8"
        d="M154.2 133.6c-11.3 0-20.3 9.2-20.3 20.3s9.2 20.4 20.3 20.4 20.4-9.2 20.4-20.4c0-11.1-9.1-20.3-20.4-20.3zm0 36.7c-8.9 0-16.4-7.3-16.4-16.4s7.3-16.4 16.4-16.4 16.4 7.3 16.4 16.4-7.4 16.4-16.4 16.4z"
      />
      <path
        className="prefix__st8"
        d="M155.3 143.8h-3.1V156l10.6 6.5 1.6-2.6-9.1-5.5z"
      />
    </g>
  </>
);

const DoneTick = () => (
  <>
    <path
      d="M114.4 118.4c-1.8.3-3.6.4-4.7.4-4.5 0-8.3-1.1-11.5-3.2-5.5-3.7-8.8-9.7-8.8-16.1 0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3 0 7.9 4 15.3 10.7 19.9 3.9 2.6 8.6 4 14 4 .6 0 2.9 0 5.5-.5 1.2-.2 2.1-1.4 1.8-2.6s-1.2-2.1-2.4-1.9z"
      fill="url(#credentials__e)"
    />
    <path
      d="M153.5 132.7c-11.6 0-21 9.4-21 21s9.4 21 21 21 21-9.4 21-21-9.4-21-21-21zm-3.9 31.8l-9.2-9.2 3.1-3.1 6.2 6.2 13.9-13.9 3.1 3.1-17.1 16.9z"
      fill="#7ee5af"
    />
  </>
);

export function StatusPattern({ done = false }) {
  return done ? <DoneTick /> : <ClockIcon />;
}

export function getOnboardingIconColors(isActive: boolean = false) {
  return isActive ? ['#08d8cb', '#7ee5af'] : ['#02315e', '#0268c7'];
}
