import * as React from 'react';
import { IFaqItem } from './types';
import { FormattedMessage } from 'react-intl';
export function FaqItem({ question, answer }: IFaqItem) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ float: 'left', marginRight: '20px', height: '75px' }}>
        <i className="far fa-question-circle" />
      </div>
      <div>
        <p>
          <span className="title is-6">
            <FormattedMessage id={`${question}`} />
          </span>
          <br />
          <FormattedMessage id={`${answer}`} />
        </p>
        <br />
      </div>
    </div>
  );
}
