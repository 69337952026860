import * as React from 'react';
import * as moment from 'moment';
import { Divider, Typography } from '@material-ui/core';
import EventToggle from '../../../components/Events/EventToggle';

import CalendarWeek from '../../../components/Calendar/CalendarWeek';
import CalendarContainer from '../../../components/Calendar/CalendarContainer';
import {
  ItemContainer,
  EmptyLine,
  ContentLine,
} from '../../../components/Calendar/Item';
import Semaphore from '../../../components/Calendar/Semaphore';

import {
  transformRatesCalendar,
  getSemaphoreColor,
  getCurrency,
  getPercent,
} from '../../../components/Calendar/utils';

const FIRST_WEEK_DAY = 0; // Sunday
const FIRST_YEAR_WEEK = 4; // Jan 4th
function calculateDiff(price: number, competitorsPrice: number) {
  return (price - competitorsPrice) / competitorsPrice;
}

const labels = [
  'rates.my_price',
  'rates.difference',
  'rates.competitors',
  'rates.demand',
];

function RatesCalendar({ calendar, currency, eventsCalendar, handleOpen }) {
  function ItemLayout({ item, locale, currency, event }) {
    const { price, compSetPrice, occupancy } = item;
    return (
      <ItemContainer inactive={item.inactive} date={item.date}>
        <>
          <ContentLine>
            {price ? (
              <Typography variant="h6">
                {getCurrency(price, locale, currency)}
              </Typography>
            ) : (
              <EmptyLine />
            )}
          </ContentLine>
          <ContentLine>
            {price ? (
              <Semaphore
                value={calculateDiff(price, compSetPrice)}
                color={getSemaphoreColor(price - compSetPrice)}
                locale={locale}
              />
            ) : (
              <EmptyLine />
            )}
          </ContentLine>
          <ContentLine>
            {compSetPrice ? (
              <Typography variant="body2">
                {getCurrency(compSetPrice, locale, currency)}
              </Typography>
            ) : (
              <EmptyLine />
            )}
          </ContentLine>
          <Divider />
          <ContentLine>
            {occupancy ? (
              <Typography display="inline" variant="body2">
                {getPercent(occupancy, locale)}
              </Typography>
            ) : (
              <EmptyLine />
            )}
            {event && <EventToggle onClick={handleOpen(item.date)} />}
          </ContentLine>
        </>
      </ItemContainer>
    );
  }
  const weeks = transformRatesCalendar(calendar);
  const locale = 'en';

  moment.locale(locale, {
    week: {
      dow: FIRST_WEEK_DAY,
      doy: FIRST_YEAR_WEEK,
    },
  });
  return (
    <CalendarContainer>
      <>
        {weeks.map((week, index) => {
          return (
            <CalendarWeek
              key={index}
              dates={week}
              locale={locale}
              labels={labels}
              ItemLayout={ItemLayout}
              currency={currency}
              events={eventsCalendar}
            />
          );
        })}
      </>
    </CalendarContainer>
  );
}

export default RatesCalendar;
