import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    chartsContainer: {
      flexGrow: 1,
      width: '100%',
      height: '100%',
    },

    root: {
      flexGrow: 1,
      marginLeft: 38,
    },

    paper: {
      boxShadow: '0 0 20px 0 rgba(31,45,61,.05)',
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      margin: '0 16px',
    },
  });
export default styles;
