import React, { useEffect, useState } from 'react';
import SettingsLayout from './Layout';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeAside } from 'store/layout/actions';
import { addDisabled, removeDisabled } from '../../store/filters/actions';

const Settings: React.FC<any> = ({ actions, isAsideOpen, ...props }) => {
  const [noAside, setNoAside] = useState<boolean>(true);
  const { location } = props;
  const { closeAside } = actions;

  useEffect(() => {
    const pathArray = location.pathname.split('/');
    const isEdit = pathArray.length === 5 && pathArray[3] === 'my-groups';
    setNoAside(
      location.pathname !== '/settings/pm-groups/listing-selection' && !isEdit,
    );
    if (isEdit) actions.addDisabled('groups');
    else actions.removeDisabled('groups');
  }, [location, location.pathname, actions]);

  useEffect(() => {
    if (noAside && isAsideOpen) closeAside();
  }, [noAside, isAsideOpen, closeAside]);

  return (
    <Layout shouldHideAside={noAside}>
      <Dashboard noAside={noAside} mainListItems={<Filters />}>
        <SettingsLayout {...props} />
      </Dashboard>
    </Layout>
  );
};

const mapActionsToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { closeAside, addDisabled, removeDisabled },
      dispatch,
    ),
  };
};

const mapStateToProps = state => {
  return {
    isAsideOpen: state?.layout?.isOpen || null,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Settings);
