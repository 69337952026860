export const config = {
  colors: {
    list: [
      '#02315E',
      '#83eec5',
      '#723ac3',
      '#5b97ff',
      '#9aa5b8',
      '#fa378f',
      '#e04a3b',
      '#f5d915',
    ],
  },
};
