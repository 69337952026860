import { IReportListItem } from './types';
import moment from 'moment';
import { getConfig, getKeyData } from './helpers';

const LINE_SERIES = 'LineSeries';

export const FILTERS_QUERY = `query Filters($filter: ReservationsFilter ) {
  reservation {
    filters(filter: $filter) {
      propertyType,
      propertyName,
      bedroom,
      group,
      capacity,
      bathroom,
    }
  }
}`;

/**
 * Queries By Year
 */

export const AVERAGE_DAILY_RATES_YEAR_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesbyYear(filter: $filter) {
      avgDailyRate,
      month,
      year,
    }
  }
}`;

export const AVERAGE_LENGTH_STAY_YEAR_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesbyYear(filter: $filter) {
      averageLengthOfStay,
      month,
      year,
    }
  }
}`;

export const BOOKING_WINDOW_YEAR_QUERY = `query($filter: ReservationsFilter ) {
  reservation {
    checkinSeriesbyYear(filter: $filter) {
      bookingWindow,
      month,
      year,
    }
  }
}`;

export const CANCELLED_PER_CONFIRMED_BOOKING_RATIO_YEAR_QUERY = `query($filter: ReservationsFilter ) {
  reservation {
    checkinSeriesbyYear(filter: $filter) {
      cancelForBookRatio,
      month,
      year,
    }
  }
}`;

export const BOOKED_NIGHTS_YEAR_QUERY = `query($filter: PropertiesFilter ) {
  listingNights {
    pricingDateSeries(filter: $filter) {
      bookedNights,
      year,
      month
    	}
	}
}`;

export const GROSS_BOOKING_VALUE_YEAR_QUERY = `query($filter: PropertiesFilter ) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: {_between: ["2019-01-01", "now()"]}) {
      grossBookingValue,
      year,
      month
    }
  }
}`;

export const INVENTORY_YEAR_QUERY = `query($filter: PropertiesFilter ) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _between: ["2019-01-01", "now()"] }) {
      inventory
      year
      month
    }
  }
}`;

export const OCCUPANCY_ACHIEVED_YEAR_QUERY = `query($filter: PropertiesFilter ) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _between: ["2019-01-01", "now()"] }) {
      occupancy
      year
      month
    }
  }
}`;

export const OCCUPANCY_COMPARISSONS_YEAR_QUERY = `query($filter: PropertiesFilter ) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }) {
      occupancy
      year
      month
    }
  }
}`;

/**
 * Queries By Group
 */

export const AVERAGE_DAILY_RATES_GROUP_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesByYearAndType(filter: $filter) {
      avgDailyRate,
      propertyType,
      month,
      year,
    }
  }
}`;

export const AVERAGE_LENGTH_STAY_GROUP_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesByYearAndType(filter: $filter) {
      averageLengthOfStay,
      propertyType,
      month,
      year,
    }
  }
}`;

export const BOOKING_WINDOW_GROUP_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesByYearAndType(filter: $filter) {
      bookingWindow,
      month,
      year,
      propertyType,
    }
  }
}`;

export const CANCELLED_PER_CONFIRMED_BOOKING_GROUP_QUERY = `query($filter: ReservationsFilter) {
  reservation {
    checkinSeriesByYearAndType(filter: $filter) {
      cancelForBookRatio,
      propertyType
      month,
      year,
    }
  }
}`;

export const BOOKED_NIGHTS_GROUP_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    pricingDateSeries(filter: $filter) {
      bookedNights,
      year,
      month,
      propertyType,
    	}
	}
}`;

export const GROSS_BOOKING_VALUE_GROUP_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _between: ["2019-01-01", "now()"] }) {
      grossBookingValue
      year
      month
      propertyType
    }
  }
}`;

export const INVENTORY_GROUP_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _between: ["2019-01-01", "now()"] }) {
      inventory
      year
      month
      propertyType
    }
  }
}`;

export const OCCUPANCY_ACHIEVED_GROUP_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _between: ["2019-01-01", "now()"] }) {
       occupancy
      propertyType
      year
      month
      day
    }
  }
}`;

export const OCCUPANCY_COMPARISSONS_GROUP_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }) {
      occupancy
      propertyType
      year
      month
      day
    }
  }
}`;

export const metricsByGroupList: IReportListItem[] = [
  {
    id: 'adr',
    index: 0,
    text: `Average Daily Rates`,
    valueY: `avgDailyRate`,
    name: `propertyType`,
    query: AVERAGE_DAILY_RATES_GROUP_QUERY,
    filterType: `ReservationsFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesByYearAndType || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `avgLengthStay`,
    index: 1,
    text: `Average Length of Stay`,
    valueY: `averageLengthOfStay`,
    name: `propertyType`,
    query: AVERAGE_LENGTH_STAY_GROUP_QUERY,
    filterType: `ReservationsFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesByYearAndType || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `bookingWindow`,
    index: 2,
    text: `Booking Window / Booking Lead Time`,
    valueY: `bookingWindow`,
    name: `propertyType`,
    query: BOOKING_WINDOW_GROUP_QUERY,
    filterType: `ReservationsFilter`,
    tooltipXLabel: `MMM YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesByYearAndType || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `cancelledPerConfirmed`,
    index: 3,
    text: `Cancelled Per Confirmed Booking`,
    valueY: `cancelForBookRatio`,
    name: `propertyType`,
    query: CANCELLED_PER_CONFIRMED_BOOKING_GROUP_QUERY,
    filterType: `ReservationsFilter`,
    tooltipXLabel: `MMM YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesByYearAndType || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `bookedNights`,
    index: 4,
    text: `Booked Nights`,
    valueY: `bookedNights`,
    name: `propertyType`,
    query: BOOKED_NIGHTS_GROUP_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `grossBookingValue`,
    index: 5,
    text: `Gross Booking Value`,
    valueY: `grossBookingValue`,
    name: `propertyType`,
    query: GROSS_BOOKING_VALUE_GROUP_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `inventory`,
    index: 6,
    text: `Inventory`,
    valueY: `inventory`,
    name: `propertyType`,
    query: INVENTORY_GROUP_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `occupancy`,
    index: 7,
    text: `Achieved Occupancy`,
    valueY: `occupancy`,
    name: `propertyType`,
    query: OCCUPANCY_ACHIEVED_GROUP_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00%`,
    numberFormatter: { type: 'NumberFormatter', numberFormat: '#%' },
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
  {
    id: `occupanyForecast`,
    index: 8,
    text: `Occupancy Forecast Comparisons`,
    valueY: `occupancy`,
    name: `propertyType`,
    query: OCCUPANCY_COMPARISSONS_GROUP_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(item.year, item.month - 1, item.day || 1),
  },
];

export const metricsByYearList: IReportListItem[] = [
  {
    id: 'adrYear',
    index: 0,
    text: `Average Daily Rates`,
    valueY: `avgDailyRate`,
    name: `year`,
    query: AVERAGE_DAILY_RATES_YEAR_QUERY,
    filterType: `ReservationsFilter`,
    stacked: true,
    tooltipXLabel: `MMM `,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesbyYear || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `avgLengthStayYear`,
    index: 1,
    text: `Average Length of Stay`,
    valueY: `averageLengthOfStay`,
    name: `year`,
    query: AVERAGE_LENGTH_STAY_YEAR_QUERY,
    filterType: `ReservationsFilter`,
    stacked: true,
    tooltipXLabel: `MMM `,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesbyYear || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `bookingWindowYear`,
    index: 2,
    text: `Booking Window / Booking Lead Time`,
    valueY: `bookingWindow`,
    name: `year`,
    query: BOOKING_WINDOW_YEAR_QUERY,
    filterType: `ReservationsFilter`,
    stacked: true,
    tooltipXLabel: `MMM `,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesbyYear || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `cancelledPerConfirmedYear`,
    index: 3,
    text: `Cancelled Per Confirmed Booking Ratio`,
    valueY: `cancelForBookRatio`,
    name: `year`,
    query: CANCELLED_PER_CONFIRMED_BOOKING_RATIO_YEAR_QUERY,
    filterType: `ReservationsFilter`,
    stacked: true,
    tooltipXLabel: `MMMM`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.reservation?.checkinSeriesbyYear || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `bookedNightsYear`,
    index: 4,
    text: `Booked Nights`,
    valueY: `bookedNights`,
    name: `year`,
    query: BOOKED_NIGHTS_YEAR_QUERY,
    filterType: `PropertiesFilter`,
    stacked: true,
    tooltipXLabel: `MMMM`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `grossBookingValueYear`,
    index: 5,
    text: `Gross Booking Value`,
    valueY: `grossBookingValue`,
    name: `year`,
    query: GROSS_BOOKING_VALUE_YEAR_QUERY,
    filterType: `PropertiesFilter`,
    stacked: true,
    tooltipXLabel: `MMMM`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `inventoryYear`,
    index: 6,
    text: `Inventory`,
    valueY: `inventory`,
    name: `year`,
    query: INVENTORY_YEAR_QUERY,
    filterType: `PropertiesFilter`,
    stacked: true,
    tooltipXLabel: `MMM `,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `occupancyYear`,
    index: 7,
    text: `Achieved Occupancy`,
    valueY: `occupancy`,
    name: `year`,
    query: OCCUPANCY_ACHIEVED_YEAR_QUERY,
    filterType: `PropertiesFilter`,
    stacked: true,
    tooltipXLabel: `MMMM`,
    tooltipYLabel: `#.00%`,
    numberFormatter: { type: 'NumberFormatter', numberFormat: '#%' },
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
  {
    id: `occupanyForecastYear`,
    index: 8,
    text: `Occupancy Forecast Comparisons`,
    valueY: `occupancy`,
    name: `year`,
    query: OCCUPANCY_COMPARISSONS_YEAR_QUERY,
    filterType: `PropertiesFilter`,
    stacked: true,
    tooltipXLabel: `MMMM`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => data?.listingNights?.pricingDateSeries || [],
    getDate: item => new Date(1999, item.month - 1, item.day || 1),
  },
];

// PACING

const PACING_OCCUPANCY_QUERY = `query($filter: PropertiesFilter) {
  listingNights {
    today: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate: { _lte: "now()" }) {
      occupancy
      date
    },
    occupancy_7: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate:{_lte: "${moment()
      .subtract(7, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      occupancy,
      date
    },
    occupancy_14: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate:{_lte: "${moment()
      .subtract(14, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      occupancy,
      date
    },
    occupancy_30: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate:{_lte: "${moment()
      .subtract(30, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      occupancy,
      date
    },
    occupancy_60: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate:{_lte: "${moment()
      .subtract(60, 'days')
      .format('YYYY-MM-DD')}" }) {
      occupancy,
      date
    }
  }
}`;

const PACING_BOOKED_NIGHTS_QUERY = `query($filter: PropertiesFilter) {  
  listingNights {
    booked_nights_7: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate: { _gte: "${moment()
      .subtract(7, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      bookedNights,
      date
    },
    booked_nights_14: pricingDateSeries(filter: $filter,  pricingDate: { _gte: "now()" }, reservationCreationDate: { _gte: "${moment()
      .subtract(14, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      bookedNights,
      date
    },
    booked_nights_30: pricingDateSeries(filter: $filter, pricingDate: { _gte: "now()" }, reservationCreationDate: { _gte: "${moment()
      .subtract(30, 'days')
      .startOf('day')
      .format('YYYY-MM-DD')}" }) {
      bookedNights,
      date
    }
  }
}`;

export const PacingReports: IReportListItem[] = [
  {
    id: `pacingTrendOccupancy`,
    index: 0,
    text: `Pacing Trend Occupancy`,
    valueY: `occupancy`,
    name: `name`,
    query: PACING_OCCUPANCY_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00%`,
    numberFormatter: { type: 'NumberFormatter', numberFormat: '#%' },
    dateFormats: 'MMMM dd yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => {
      const _data = data?.listingNights || [];
      return getKeyData(_data);
    },
    getDate: item => new Date(item.date),
  },
  {
    id: `bookedNights`,
    index: 1,
    text: `Booked Nights`,
    valueY: `bookedNights`,
    name: `name`,
    query: PACING_BOOKED_NIGHTS_QUERY,
    filterType: `PropertiesFilter`,
    tooltipXLabel: `MMM dd YYYY`,
    tooltipYLabel: `#.00`,
    dateFormats: 'MMMM dd yyyy',
    getConfig: function(data, intl) {
      return getConfig(this, data, intl, LINE_SERIES);
    },
    getData: data => {
      let _data = data?.listingNights || [];
      return getKeyData(_data);
    },
    getDate: item => new Date(item.date),
  },
];
