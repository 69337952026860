import React from 'react';
import './MetricsMenu.scss';
import { useIntl } from 'react-intl';
import { IMetricsMenuProps } from '../lib/types';

const MetricsMenu: React.FunctionComponent<IMetricsMenuProps> = ({
  reportsList,
  reportSelected,
  selectReport,
}) => {
  const intl = useIntl();

  return (
    <nav className="reports-menu">
      <ul className="reports-menu-list">
        {reportsList.map((r, index) => (
          <li key={index} className="reports-menu-item">
            <button
              className={`reports-menu-button ${
                reportSelected.index === index ? 'active' : ''
              }`}
              onClick={() => selectReport(r)}
            >
              <span className="button-text">
                {intl.formatMessage({
                  id: `selfreporting.menu.${r.id}`,
                  defaultMessage: r.text,
                })}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MetricsMenu;
