import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import Layout from './layout';
import { loadTrends } from '../store/actions';

type TrendsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Trends: React.FunctionComponent<TrendsProps> = props => (
  <Layout {...props} />
);

const mapStateToProps = (state: ApplicationState) => {
  const { error, fetchingTrends: fetching, trends, reservations } =
    state.pacing || {};

  return {
    data: { trends, reservations },
    error,
    fetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadTrends }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trends);
