import { Action as ReduxAction, Reducer } from 'redux';

import { PerformanceState, ACTIONS } from '../types';

interface Action extends ReduxAction {
  type: string;
  payload?: any;
}

interface ActionHandlers {
  [action: string]: (
    state: PerformanceState,
    action?: Action,
  ) => PerformanceState;
}

const ACTION_HANDLERS: ActionHandlers = {
  [ACTIONS.FETCH_PERFORMANCE_SUPPLY_START]: state => ({
    ...state,
    fetchingSupply: true,
  }),
  [ACTIONS.FETCH_PERFORMANCE_SUPPLY_SUCCESS]: (
    state,
    { payload: { currency } },
  ) => ({
    ...state,
    errorListings: null,
    fetchingSupply: false,
    supply: { currency },
  }),
  [ACTIONS.FETCH_PERFORMANCE_SUPPLY_FAILURE]: (
    state,
    { payload: { error } },
  ) => ({
    ...state,
    errorListings: error,
    fetchingSupply: false,
    supply: null,
  }),
  [ACTIONS.FETCH_PERFORMANCE_AGGREGATED_START]: state => ({
    ...state,
    fetchingAggregated: true,
  }),
  [ACTIONS.FETCH_PERFORMANCE_AGGREGATED_SUCCESS]: (
    state,
    { payload: { currency } },
  ) => ({
    ...state,
    currency,
    errorAggregated: null,
    fetchingAggregated: false,
  }),
  [ACTIONS.FETCH_PERFORMANCE_AGGREGATED_FAILURE]: (
    state,
    { payload: { error } },
  ) => ({
    ...state,
    errorAggregated: error,
    fetchingAggregated: false,
  }),
};

const initialState: PerformanceState = {
  currency: 'USD',
  errorListings: null,
  errorAggregated: null,
  fetchingSupply: false,
  fetchingAggregated: false,
  supply: null,
};

export const reducer: Reducer<PerformanceState, Action> = (
  state = initialState,
  action?: Action,
) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
