import * as React from 'react';

const NotFound = () => {
  return (
    <div className="container text-center">
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
};

export default NotFound;
