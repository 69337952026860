import { AnyAction, Reducer } from 'redux';
import { CLEAR_MESSAGES } from './types';
/* eslint-disable  no-fallthrough */
const messages: Reducer<{}> = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case 'LOGIN_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
    case 'CHANGE_PASSWORD_FAILURE':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'CONTACT_FORM_FAILURE':
      return {
        error: action.payload.messages,
      };
    case 'UPDATE_PROFILE_SUCCESS':
    case 'CHANGE_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
    case 'CONTACT_FORM_SUCCESS':
      return {
        success: action.payload.messages,
      };
    case 'FORGOT_PASSWORD_SUCCESS':
      return {
        info: action.payload.messages,
      };
    case CLEAR_MESSAGES:
      return {};
    default:
      return state;
  }
};
export default messages;
