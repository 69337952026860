import * as React from 'react';
import { Tabs, ConnectedTab as Tab } from 'components/Transparent/Navigation';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { RouterLocation } from 'connected-react-router';
import { MenuItem, Popover } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const getTabValueFactory = location => value => {
  const locationArray = location.pathname
    .split('/')
    .filter(path => path !== '');
  const valueArray = value.split('/').filter(path => path !== '');
  return locationArray[0] === valueArray[0] ? location.pathname : value;
};

const styles = {
  popover: { marginTop: '5px', padding: '20px 10px' },
  menuItem: {
    padding: `15px 40px`,
    justifyContent: `center`,
    alignItems: `center`,
    display: `flex`,
    color: 'white',
  },
  link: {
    color: 'black',
    fontWeight: 'bold' as 'bold',
  },
  border: {
    borderBottom: '1px solid #E7E7E7',
  },
};

export const Navigation = injectIntl(({ intl }) => {
  const location = useSelector<ApplicationState, RouterLocation<unknown>>(
    ({ router }) => router.location,
  );
  const [performancesPopover, setPerformancesPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>(null);

  const getSelfServiceLabel = (id: string, defaultMessage: string): string => {
    return intl.formatMessage({
      id,
      defaultMessage,
    });
  };

  const [selfServiceLabel, setSelfServiceLabel] = useState<string>(
    location.pathname.indexOf('pms') > -1
      ? location.pathname.indexOf('pacing') > -1
        ? getSelfServiceLabel('navigation.tabs.yourpacing', 'Your Pacing')
        : getSelfServiceLabel('navigation.tabs.yourmetrics', 'Your Metrics')
      : getSelfServiceLabel('navigation.tabs.Pms', 'Pms'),
  );

  const handlePmsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(e.currentTarget);
    setPerformancesPopover(!performancesPopover);
  };

  const getValue = getTabValueFactory(location);
  const handlePopoverClose = () => setPerformancesPopover(false);
  const handlePMSClick = (id: string, defaultMessage: string) => {
    setSelfServiceLabel(getSelfServiceLabel(id, defaultMessage));
    handlePopoverClose();
  };

  return (
    <>
      <Tabs location={location} variant="scrollable">
        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.rates' })}
          value={getValue('/rates')}
          to="/rates"
        />

        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.calendar' })}
          value={getValue('/calendar')}
          to="/calendar"
        />

        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.pacing' })}
          value={getValue('/pacing')}
          to="/pacing"
        />

        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.supply' })}
          value={getValue('/supply')}
          to="/supply"
        />

        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.ranking' })}
          value={getValue('/ranking')}
          to="/ranking"
        />

        <Tab
          label={intl.formatMessage({ id: 'navigation.tabs.reviews' })}
          value={getValue('/reviews')}
          to="/reviews"
        />
        <Tab
          label={intl.formatMessage({
            id: 'navigation.tabs.performance',
            defaultMessage: 'Performance',
          })}
          value={getValue('/performance')}
          to="/performance"
        />
        <Tab
          label={selfServiceLabel}
          value={getValue('/pms')}
          validationKey="has_pms_connection"
          onClick={handlePmsClick}
        />
      </Tabs>
      <Popover
        open={performancesPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={styles.popover}
      >
        <MenuItem style={{ ...styles.menuItem, ...styles.border }}>
          <Link
            to="/pms"
            onClick={() =>
              handlePMSClick(`navigation.tabs.yourmetrics`, 'Your Metrics')
            }
            style={styles.link}
          >
            {getSelfServiceLabel('navigation.tabs.yourmetrics', 'Your Metrics')}
          </Link>
        </MenuItem>
        <MenuItem
          style={styles.menuItem}
          onClick={() =>
            handlePMSClick(`navigation.tabs.yourpacing`, 'Your Pacing')
          }
        >
          <Link to="/pms/pacing" style={styles.link}>
            {getSelfServiceLabel('navigation.tabs.yourpacing', 'Your Pacing')}
          </Link>
        </MenuItem>
      </Popover>
    </>
  );
});
