import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import ImportPMGroupsView from './layout';
import {
  createGroup,
  fetchGroups,
  hideCreationResults,
} from '../store/actions';
import { PM_GROUPS_DOMAIN } from '../types';

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { createGroup, fetchGroups, hideCreationResults },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    added: state[PM_GROUPS_DOMAIN]?.added,
    koUrls: state[PM_GROUPS_DOMAIN]?.koUrls,
    matched: state[PM_GROUPS_DOMAIN]?.matched,
    showResults: state[PM_GROUPS_DOMAIN]?.showResults,
    status: state[PM_GROUPS_DOMAIN]?.status,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportPMGroupsView);
