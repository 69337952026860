import * as React from 'react';
import moment from 'moment';
import {
  Typography,
  withStyles,
  createStyles,
  StyledComponentProps,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
function timeUntilNextDayOfWeek(today: moment.Moment, dayOfWeek: number) {
  const actual = today.isoWeekday();

  const getNextTargetWeekDay = (actualDay: number, targetDay: number) =>
    actualDay <= targetDay
      ? moment().isoWeekday(targetDay)
      : moment()
          .add(1, 'weeks')
          .isoWeekday(targetDay);
  const targetDay = getNextTargetWeekDay(actual, dayOfWeek);
  return targetDay.diff(today, 'days');
}

const styles = createStyles({
  container: {
    position: 'absolute',
    right: 45,
    bottom: 0,
    height: '72px',
    borderLeft: '2px dotted rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15,
  },
});
function CountDown({ classes }: StyledComponentProps) {
  const today = moment();
  const tuesday = 2;
  const intl = useIntl();
  return (
    <div className={classes.container}>
      <Typography variant="overline">
        {intl.formatDate(today.toDate(), {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        })}
      </Typography>
      <Typography variant="overline">{`${intl.formatMessage(
        {
          id: 'countdown.message',
          defaultMessage: 'Next update {date}',
        },
        {
          date: intl.formatRelativeTime(
            timeUntilNextDayOfWeek(today, tuesday),
            'days',
            { numeric: 'auto' },
          ),
        },
      )}`}</Typography>
    </div>
  );
}
export default withStyles(styles)(CountDown);
