import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { login } from '../../store/auth/actions';
import Messages from '../Layout/Messages';
import { ApplicationState } from '../../store';
import { AuthActions } from '../../store/auth/types';
import SignInForm from '../../components/Account/SignInForm';
import Layout from '../Layout/Layout';
type LoginContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface IComponentState {
  [key: string]: string;
  email: string;
  password: string;
}

class Login extends React.Component<LoginContainerProps, IComponentState> {
  state = { email: '', password: '' };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = { ...this.state };
    formData[event.target.name] = event.target.value;
    this.setState(formData);
  };

  handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.actions.login(this.state.email, this.state.password);
  };

  render() {
    return (
      <Layout>
        <Messages messages={this.props.messages} />
        <SignInForm
          handleSubmit={this.handleLogin}
          email={this.state.email}
          password={this.state.password}
          handleChange={this.handleChange}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    messages: state.messages,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, AuthActions>,
) {
  return {
    actions: bindActionCreators({ login }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
