import { Action as ReduxAction, Reducer } from 'redux';

import { DiscountsState, ACTIONS } from '../types';

interface Action extends ReduxAction {
  type: string;
  payload?: any;
}

interface ActionHandlers {
  [action: string]: (state: DiscountsState, action?: Action) => DiscountsState;
}

const ACTION_HANDLERS: ActionHandlers = {
  [ACTIONS.FETCH_DISCOUNTS_SUPPLY_START]: state => ({
    ...state,
    fetchingSupply: true,
  }),
  [ACTIONS.FETCH_DISCOUNTS_SUPPLY_SUCCESS]: (
    state,
    { payload: { currency } },
  ) => ({
    ...state,
    errorSupply: null,
    fetchingSupply: false,
    supply: { currency },
  }),
  [ACTIONS.FETCH_DISCOUNTS_SUPPLY_FAILURE]: (
    state,
    { payload: { error } },
  ) => ({
    ...state,
    errorSupply: error,
    fetchingSupply: false,
    supply: null,
  }),
  [ACTIONS.FETCH_DISCOUNTS_AGGREGATED_START]: state => ({
    ...state,
    fetchingAggregated: true,
  }),
  [ACTIONS.FETCH_DISCOUNTS_AGGREGATED_SUCCESS]: (
    state,
    {
      payload: {
        metrics: {
          avgCleaningFee,
          avgMonthlyDiscount,
          avgServiceFee,
          listingCount,
        },
        currency,
      },
    },
  ) => ({
    ...state,
    aggregated: {
      avgCleaningFee,
      avgMonthlyDiscount,
      avgServiceFee,
      currency,
      listingCount,
    },
    errorAggregated: null,
    fetchingAggregated: false,
  }),
  [ACTIONS.FETCH_DISCOUNTS_AGGREGATED_FAILURE]: (
    state,
    { payload: { error } },
  ) => ({
    ...state,
    errorAggregated: error,
    fetchingAggregated: false,
  }),
  [ACTIONS.DISCOUNTS_CSV_DOWNLOAD_START]: state => ({
    ...state,
    downloadingCSV: true,
  }),
  [ACTIONS.DISCOUNTS_CSV_DOWNLOAD_SUCESS]: state => ({
    ...state,
    downloadingCSV: false,
  }),
  [ACTIONS.DISCOUNTS_CSV_DOWNLOAD_FAILURE]: state => ({
    ...state,
    downloadingCSV: false,
  }),
};

const initialState: DiscountsState = {
  aggregated: {
    avgCleaningFee: 0,
    avgMonthlyDiscount: 0,
    avgServiceFee: 0,
    currency: 'USD',
    listingCount: 0,
  },
  downloadingCSV: false,
  errorSupply: null,
  errorAggregated: null,
  fetchingSupply: false,
  fetchingAggregated: false,
  supply: { currency: null },
};

export const reducer: Reducer<DiscountsState, Action> = (
  state = initialState,
  action?: Action,
) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
