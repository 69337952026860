import { schema } from 'normalizr';
import * as uuid from 'uuid';

const reviewGrowth = new schema.Entity('reviewGrowth', undefined, {
  idAttribute: ({ series }) => series,
});

const reviewScore = new schema.Entity('reviewScore', undefined, {
  idAttribute: ({ score }) => score,
});

const recentReviews = new schema.Entity('recentReviews', undefined, {
  idAttribute: () => uuid.v4(),
});

export const reviewGrowthSchema = {
  data: {
    reviewGrowth: [reviewGrowth],
  },
};

export const reviewScoreSchema = {
  data: {
    review_score: [reviewScore],
  },
};

export const recentReviewsSchema = {
  data: {
    items: [recentReviews],
  },
};
