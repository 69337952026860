import { createStyles } from '@material-ui/core';
export const styles = createStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.5em',
    marginLeft: '15px',
    color: '#505d6e',
  },
  marginTop: {
    marginTop: 25,
  },
});
