import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  CssBaseline,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Input,
  withStyles,
  StyledComponentProps,
  Button,
  Avatar,
  Link,
} from '@material-ui/core';

import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import styles from './styles';

interface IComponentProps extends StyledComponentProps {
  handleSubmit: any;
  handleChange: any;
  email: string;
  password: string;
}

const SignInForm: React.StatelessComponent<IComponentProps> = props => {
  const { classes, handleSubmit, email, password, handleChange } = props;
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              name="email"
              type="email"
              autoComplete="email"
              autoFocus={true}
              value={email}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={handleChange}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign in
          </Button>
        </form>
        <Link component={RouterLink} to="/forgot">
          Forgot your password?
        </Link>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(SignInForm);
