import * as React from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Grid, DialogContent, List } from '@material-ui/core';

const style = {
  container: {
    minWidth: 600,
    width: '100%',
  },
  list: {
    paddingTop: 0,
  },
};
interface EventContainerProps extends StyledComponentProps {
  rightContent: React.ReactElement;
}

const EventContainer: React.SFC<EventContainerProps> = ({
  children,
  rightContent,
  classes,
}) => {
  return (
    <Grid
      container={true}
      component={DialogContent}
      className={classes.container}
    >
      <Grid item={true} xs={8}>
        <List className={classes.list}>{children}</List>
      </Grid>
      <Grid item={true} xs={4}>
        {rightContent}
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(EventContainer);
