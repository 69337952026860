import { serializeDates } from './datesSerializr';
import {
  CalendarStateDates,
  IFilterDictionary,
} from '../../store/filters/types';

export function formatFilters(
  activeFilters: IFilterDictionary,
  dates: CalendarStateDates,
) {
  if (!activeFilters) return null;
  const query = {
    ...activeFilters,
    dates: dates ? serializeDates(dates.selectedDates) : undefined,
  };
  return query;
}
export function compareObjects(query1: object, query2: object): boolean {
  return JSON.stringify(query1) === JSON.stringify(query2);
}
