import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { normalize } from 'normalizr';
import { values } from 'lodash';
import { ApplicationState, ExtraArguments } from 'store';
import { getToken } from 'store/auth/selectors';

import { getFilterQuery } from 'store/filters/selectors';

import { ACTIONS } from '../types';
import { trendsEntity, reservationsEntity, bookedAdrEntity } from './schema';
const PACING_BOOKEDADR_ENDPOINT = 'pacing/bookedadr';
const PACING_TRENDS_ENDPOINT = 'pacing/pacingtrends';
const PACING_INDIVIDUAL_DATE_ENDPOINT = 'pacing/individualdate';

export const loadBookedADR: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async (dispatch, getState) => {
  const state = getState();
  const filterQuery = getFilterQuery(state);
  const token = getToken(state);
  const query = new URLSearchParams(filterQuery);

  try {
    const {
      data: {
        data: { bookedADR, currency },
        error,
        status,
      },
    } = await apiAction({
      onStart: () => {
        dispatch({ type: ACTIONS.FETCH_PACING_BOOKEDADR_START });
      },
      params: query,
      token,
      url: PACING_BOOKEDADR_ENDPOINT,
    });

    if (status !== 'SUCCESS') {
      dispatch({
        type: ACTIONS.FETCH_PACING_BOOKEDADR_FAILURE,
        payload: { error },
      });
      return;
    }
    const {
      entities: { bookedAdr },
    } = normalize(bookedADR, [bookedAdrEntity]);
    dispatch({
      type: ACTIONS.FETCH_PACING_BOOKEDADR_SUCCESS,
      payload: {
        bookedADR: values(bookedAdr),
        currency,
      },
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_PACING_BOOKEDADR_FAILURE,
      payload: { error: e },
    });
  }
};

export const loadTrends: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => async (dispatch, getState) => {
  const state = getState();
  const filterQuery = getFilterQuery(state);
  const token = getToken(state);
  const query = new URLSearchParams(filterQuery);

  try {
    const {
      data: { data, error, status },
    } = await apiAction({
      onStart: () => {
        dispatch({ type: ACTIONS.FETCH_PACING_TRENDS_START });
      },
      params: query,
      token,
      url: PACING_TRENDS_ENDPOINT,
    });

    if (status !== 'SUCCESS') {
      dispatch({
        type: ACTIONS.FETCH_PACING_TRENDS_FAILURE,
        payload: { error },
      });
      return;
    }

    const {
      entities: { trends },
    } = normalize(data, [trendsEntity]);
    const {
      entities: { reservations },
    } = normalize(data, [reservationsEntity]);
    dispatch({
      type: ACTIONS.FETCH_PACING_TRENDS_SUCCESS,
      payload: {
        trends: values(trends),
        reservations: values(reservations),
      },
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_PACING_TRENDS_FAILURE,
      payload: { error: e },
    });
  }
};

export const loadIndividualDate: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (date = moment.utc().format('YYYY-MM-DD')) => async (
  dispatch,
  getState,
) => {
  console.debug(`loadIndividualDate with date = ${date}`);

  const state = getState();
  const filterQuery = getFilterQuery(state);
  const token = getToken(state);
  const query = new URLSearchParams(filterQuery);
  query.append('date', date);

  try {
    const {
      data: { data, error, status },
    } = await apiAction({
      onStart: () => {
        dispatch({ type: ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_START });
      },
      params: query,
      token,
      url: PACING_INDIVIDUAL_DATE_ENDPOINT,
    });

    if (status !== 'SUCCESS') {
      dispatch({
        type: ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_FAILURE,
        payload: { error },
      });
      return;
    }

    dispatch({
      type: ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_SUCCESS,
      payload: { individualDate: data },
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_PACING_INDIVIDUAL_DATE_FAILURE,
      payload: { error: e },
    });
  }
};

interface ApiActionOptions extends AxiosRequestConfig {
  onFailure?: (reason: Error) => void;
  onStart?: () => void;
  onSuccess?: (response: AxiosResponse) => void;
  token: string;
}

function apiAction<T = any>(
  options: ApiActionOptions,
): Promise<AxiosResponse<T>> {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    },
  });

  return new Promise((rs, rj) => {
    options.onStart && options.onStart();
    axiosInstance.request(options).then(
      response => {
        options.onSuccess && options.onSuccess(response);
        rs(response);
      },
      (reason: Error) => {
        options.onFailure && options.onFailure(reason);
        rj(reason);
      },
    );
  });
}
