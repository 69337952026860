import * as React from 'react';
import { Table, TableHead } from 'components/Tables';
import { ListingLevelLine } from './ListingLevelLine';
import { CustomPagination } from './Pagination';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@material-ui/core';
import './table-styles.scss';
import {
  StartLoadListings,
  SuccessLoadListings,
  FailLoadListings,
} from 'routes/settings/PMGroups/store';

export const TableLayout = ({
  isAsc,
  setSortBy,
  isSortedBy,
  elementsPerPage,
  currentPage,
  listingsDetails,
  addListingSelection,
  removeListingSelection,
  listingsSelected,
  handleDeselectAll,
  isSelectAllChecked,
  numberOfPages,
  goToPage,
  order,
  orderBy,
}) => {
  const ELEMENTS_PER_PAGE = 15;

  const handleListingSelection = event => {
    if (event.target.checked) {
      addListingSelection(event.target.value);
    } else removeListingSelection(event.target.value);
  };
  const handleAllCheckbox = () => {
    if (isSelectAllChecked()) return handleDeselectAll();
    return handleSelectAll();
  };
  const handleSelectAll = () => {
    for (let listing of listingsDetails) {
      if (!listingsSelected.includes(listing.unified_id)) {
        addListingSelection(listing.unified_id);
      }
    }
  };

  const goTo = (i: number) => {
    goToPage(
      {
        order,
        orderBy,
        limit: ELEMENTS_PER_PAGE,
      },
      i,
      StartLoadListings,
      SuccessLoadListings,
      FailLoadListings,
    );
  };

  return (
    <>
      <Table isCentered={true}>
        <thead>
          <tr>
            <TableHead>
              <Checkbox
                checked={isSelectAllChecked()}
                onClick={handleAllCheckbox}
              />
            </TableHead>
            <TableHead
              width="450px"
              onClick={setSortBy('title')}
              isAsc={isAsc}
              isSortedBy={isSortedBy('title')}
            >
              <FormattedMessage id="tables.headers.property_title" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('clean_url')}
              isSortedBy={isSortedBy('clean_url')}
              width="135px"
            >
              <FormattedMessage
                id="tables.headers.pm_domain_name"
                defaultMessage="Domain Name"
              />
            </TableHead>
            <TableHead
              width="75px"
              isAsc={isAsc}
              onClick={setSortBy('platform')}
              isSortedBy={isSortedBy('platform')}
            >
              <FormattedMessage id="filters.platform" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('zipcode')}
              isSortedBy={isSortedBy('zipcode')}
              width="85px"
            >
              <FormattedMessage id="filters.zipcode" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('bedrooms')}
              isSortedBy={isSortedBy('bedrooms')}
              width="85px"
            >
              <FormattedMessage id="filters.bedrooms" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('reviewScore')}
              isSortedBy={isSortedBy('reviewScore')}
              width="110px"
            >
              <FormattedMessage id="tables.headers.review_score" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('reviewCount')}
              isSortedBy={isSortedBy('reviewCount')}
              width="110px"
            >
              <FormattedMessage id="tables.headers.review_count" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('adr')}
              isSortedBy={isSortedBy('adr')}
              width="110px"
            >
              <FormattedMessage id="tables.headers.adr" />
            </TableHead>
            <TableHead
              isAsc={isAsc}
              onClick={setSortBy('avgOcc')}
              isSortedBy={isSortedBy('avgOcc')}
              width="110px"
            >
              <FormattedMessage id="tables.headers.average_occupancy" />
            </TableHead>
          </tr>
        </thead>
        <tbody>
          {listingsDetails.map((stats, idx) => {
            return (
              <ListingLevelLine
                idx={elementsPerPage * (currentPage - 1) + (idx + 1)}
                key={idx}
                handleListingSelection={handleListingSelection}
                checked={listingsSelected.includes(stats.unified_id)}
                {...stats}
              />
            );
          })}
        </tbody>
      </Table>
      <CustomPagination
        currentPage={currentPage}
        pages={numberOfPages}
        onChange={goTo}
      />
    </>
  );
};
