import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PriceVariations: React.SFC<SvgIconProps> = function(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278.3 278.3"
      {...props}
    >
      <path
        className="st0"
        d="M252.8,70.8l-48.2-40.1c-2.6-2.2-6.4-2.2-9,0l-48.2,40.1c-1.6,1.3-2.5,3.3-2.5,5.4v195.1c0,3.9,3.1,7,7,7h96.3 c3.9,0,7-3.1,7-7V76.2C255.3,74.1,254.4,72.2,252.8,70.8z M241.3,264.3H159V79.5l41.2-34.3l41.2,34.3V264.3z"
      />
      <path
        className="st0"
        d="M152,54.1c1.6,0,3.2-0.5,4.5-1.6l43.7-36.4l43.7,36.4c3,2.5,7.4,2.1,9.9-0.9c2.5-3,2.1-7.4-0.9-9.9L204.6,1.6 c-2.6-2.2-6.4-2.2-9,0l-48.2,40.1c-3,2.5-3.4,6.9-0.9,9.9C148,53.3,150,54.1,152,54.1L152,54.1z"
      />
      <path
        className="st0"
        d="M130.9,151.3l-48.2-40.1c-2.6-2.2-6.4-2.2-9,0l-48.2,40.1c-1.6,1.3-2.5,3.3-2.5,5.4v114.6c0,3.9,3.1,7,7,7 h96.3c3.9,0,7-3.1,7-7V156.7C133.4,154.6,132.5,152.7,130.9,151.3L130.9,151.3z M119.4,264.3H37V160l41.2-34.3l41.2,34.3V264.3z"
      />
      <path
        className="st0"
        d="M34.5,133l43.7-36.4l43.7,36.4c1.3,1.1,2.9,1.6,4.5,1.6c2,0,4-0.9,5.4-2.5c2.5-3,2.1-7.4-0.9-9.9L82.7,82.1 c-2.6-2.2-6.4-2.2-9,0l-48.2,40.1c-3,2.5-3.4,6.9-0.9,9.9C27.1,135.1,31.5,135.5,34.5,133z"
      />
      <path
        className="st0"
        d="M71.2,166.4v51.9l-3.2-3.2c-2.7-2.7-7.2-2.7-9.9,0c-2.7,2.7-2.7,7.2,0,9.9l13.8,13.8c1.7,1.7,4,2.6,6.3,2.6 s4.6-0.9,6.3-2.6L98.3,225c2.7-2.7,2.7-7.2,0-9.9c-2.7-2.7-7.2-2.7-9.9,0l-3.2,3.2v-51.9c0-3.9-3.1-7-7-7S71.2,162.5,71.2,166.4 L71.2,166.4z"
      />
      <path
        className="st0"
        d="M193.8,149.1L180,162.9c-2.7,2.7-2.7,7.2,0,9.9c2.7,2.7,7.2,2.7,9.9,0l3.2-3.2v51.9c0,3.9,3.1,7,7,7s7-3.1,7-7 v-51.9l3.2,3.2c1.4,1.4,3.2,2.1,4.9,2.1s3.6-0.7,4.9-2.1c2.7-2.7,2.7-7.2,0-9.9l-13.8-13.8C203,145.7,197.3,145.7,193.8,149.1 L193.8,149.1z"
      />
    </SvgIcon>
  );
};

export default PriceVariations;
