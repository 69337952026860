export const PACING_DOMAIN = 'pacing';

interface BookedADR {
  pricingDate: string;
  bookedADR: number;
}
interface Trend {
  pricingDate: string;
  [occupancy: string]: number | string;
}
interface Reservation {
  pricingDate: string;
  [reservationDate: string]: number | string;
}
export interface PacingState {
  bookedADR: BookedADR[] | null;
  bookedADRCurrency: string | null;
  error: any | null;
  fetchingBookedADR: boolean;
  fetchingIndividualDate: boolean;
  fetchingTrends: boolean;
  individualDate: any;
  reservations: Reservation[] | null;
  trends: Trend[] | null;
}

export const ACTIONS = {
  FETCH_PACING_BOOKEDADR_START: 'FETCH_PACING_BOOKEDADR_START',
  FETCH_PACING_BOOKEDADR_SUCCESS: 'FETCH_PACING_BOOKEDADR_SUCCESS',
  FETCH_PACING_BOOKEDADR_FAILURE: 'FETCH_PACING_BOOKEDADR_FAILURE',
  FETCH_PACING_TRENDS_START: 'FETCH_PACING_TRENDS_START',
  FETCH_PACING_TRENDS_SUCCESS: 'FETCH_PACING_TRENDS_SUCCESS',
  FETCH_PACING_TRENDS_FAILURE: 'FETCH_PACING_TRENDS_FAILURE',
  FETCH_PACING_INDIVIDUAL_DATE_START: 'FETCH_PACING_INDIVIDUAL_DATE_START',
  FETCH_PACING_INDIVIDUAL_DATE_SUCCESS: 'FETCH_PACING_INDIVIDUAL_DATE_SUCCESS',
  FETCH_PACING_INDIVIDUAL_DATE_FAILURE: 'FETCH_PACING_INDIVIDUAL_DATE_FAILURE',
};
