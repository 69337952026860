import * as React from 'react';

import { CartoMap, CartoMapParams } from '.';

export interface SupplyProps {
  error?: null;
  loading?: boolean;
  marketDoc: CartoMapParams;
}

export default function Supply({ marketDoc }: SupplyProps) {
  const { market, url } = marketDoc;
  return (
    <CartoMap
      key={market}
      market={market}
      url={url}
      height="calc(100vh - 270px)"
    />
  );
}
