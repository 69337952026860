import * as React from 'react';

const BellIcon = props => (
  <svg viewBox="0 0 70 70" {...props}>
    <g clipPath="url(#clipBellIcon)">
      <path
        d="M34.9991 70.0001C39.3418 70.0001 42.8623 66.4796 42.8623 62.1369C42.8623 57.7942 39.3418 54.2738 34.9991 54.2738C30.6564 54.2738 27.136 57.7942 27.136 62.1369C27.136 66.4796 30.6564 70.0001 34.9991 70.0001Z"
        fill="url(#paint0BellIconlinear)"
      />
      <path
        d="M34.9992 54.2742C30.9035 54.2742 27.5407 57.406 27.1714 61.4052L35.7312 69.9652C39.7304 69.5958 42.8622 66.233 42.8622 62.1373C42.8622 57.7946 39.3419 54.2742 34.9992 54.2742Z"
        fill="url(#paint1BellIconlinear)"
      />
      <path
        d="M60.766 48.4249H9.23297C5.71341 48.4249 2.86035 51.278 2.86035 54.7977C2.86035 58.3172 5.71341 61.1704 9.23297 61.1704H60.766C64.2856 61.1704 67.1388 58.3172 67.1388 54.7977C67.1387 51.278 64.2856 48.4249 60.766 48.4249Z"
        fill="url(#paint2BellIconlinear)"
      />
      <path
        d="M57.077 43.6169V30.8422C57.077 20.5109 49.981 11.8406 40.3978 9.43172C40.9444 8.50162 41.2609 7.41798 41.2609 6.26161C41.2608 2.80384 38.4569 0 34.9992 0C31.5414 0 28.7376 2.80384 28.7376 6.26161C28.7376 7.41812 29.0541 8.50162 29.6007 9.43172C20.0172 11.8406 12.9231 20.511 12.9231 30.8422V43.6169C12.9231 46.3823 10.6812 48.6244 7.91577 48.6244H62.0826C59.317 48.6244 57.077 46.3823 57.077 43.6169ZM34.9992 8.76453C33.6164 8.76453 32.4945 7.64452 32.4945 6.26174C32.4945 4.87705 33.6164 3.75705 34.9992 3.75705C36.382 3.75705 37.5039 4.87705 37.5039 6.26174C37.5039 7.64452 36.382 8.76453 34.9992 8.76453Z"
        fill="url(#paint3BellIconlinear)"
      />
      <path
        d="M2.93945 56.0641C3.42057 59.107 6.05474 61.434 9.23291 61.1462H60.766C63.9443 61.434 66.5783 59.107 67.0594 56.0641H2.93945Z"
        fill="url(#paint4BellIconlinear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0BellIconlinear"
        x1="33.7532"
        y1="60.0584"
        x2="47.2841"
        y2="82.6094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint1BellIconlinear"
        x1="47.1781"
        y1="72.3026"
        x2="29.1904"
        y2="54.3149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" stopOpacity="0" />
        <stop offset="0.203" stopColor="#FFBB00" stopOpacity="0.203" />
        <stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
        <stop offset="0.852" stopColor="#FF8800" stopOpacity="0.852" />
        <stop offset="1" stopColor="#FF7800" />
      </linearGradient>
      <linearGradient
        id="paint2BellIconlinear"
        x1="27.1151"
        y1="41.6549"
        x2="60.8884"
        y2="97.9427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint3BellIconlinear"
        x1="27.0094"
        y1="28.3694"
        x2="57.8221"
        y2="57.3927"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" />
        <stop offset="0.268" stopColor="#FFBB00" />
        <stop offset="0.659" stopColor="#FFA801" />
        <stop offset="1" stopColor="#FF9102" />
      </linearGradient>
      <linearGradient
        id="paint4BellIconlinear"
        x1="63.7112"
        y1="55.3717"
        x2="0.0176676"
        y2="61.9447"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC200" stopOpacity="0" />
        <stop offset="0.203" stopColor="#FFBB00" stopOpacity="0.203" />
        <stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
        <stop offset="0.852" stopColor="#FF8800" stopOpacity="0.852" />
        <stop offset="1" stopColor="#FF7800" />
      </linearGradient>
      <clipPath id="clipBellIcon">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BellIcon;
