import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store/index';
import Display from './Layout';
import { Loader } from '../../../components/Layout/index';
import { toggleOpen, selectCurrentEvent } from '../../../store/events/actions';
import { EventActions } from '../../../store/events/types';
import EventContainer from '../Events';

type PriceVariationsCalendarProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const PriceVariationsCalendar: React.SFC<PriceVariationsCalendarProps> = function({
  isLoading,
  actions,
  ...props
}) {
  const handleOpen = (date: string) => () => {
    actions.selectCurrentEvent(date);
    actions.toggleOpen();
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Display {...props} handleOpen={handleOpen} />
      <EventContainer />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    currency: state.rates.currency || 'USD',
    calendar: state.priceVariations.calendar,
    isLoading: state.priceVariations.isLoading,
    eventsCalendar: state.events.calendar || [],
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, EventActions>,
) {
  return {
    actions: bindActionCreators({ toggleOpen, selectCurrentEvent }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PriceVariationsCalendar);
