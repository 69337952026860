import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';

import { SET_LOCALE } from './types';

const initialState = {
  locale: 'en',
};

export const i18nReducer = (state = initialState, action) => {
  if (action.type === SET_LOCALE) {
    const {
      payload: { locale },
    } = action;

    moment.locale(locale);
    return {
      ...state,
      locale,
    };
  }

  return state;
};
