import React from 'react';

const CloseIcon = props => (
  <svg viewBox="0 0 26 26" {...props}>
    <g>
      <path d="M25.9997 24.3898C25.9993 24.1683 25.9535 23.9492 25.8651 23.746C25.7766 23.5429 25.6475 23.36 25.4856 23.2088L15.2871 13.0103L25.4856 2.81175C25.6452 2.66352 25.7733 2.48468 25.8623 2.28589C25.9513 2.0871 25.9994 1.87243 26.0036 1.65468C26.0079 1.43692 25.9683 1.22053 25.8872 1.0184C25.806 0.816276 25.685 0.632553 25.5314 0.478182C25.3777 0.32381 25.1946 0.201949 24.9929 0.119862C24.7911 0.0377744 24.5749 -0.00285943 24.3571 0.000381958C24.1394 0.00362334 23.9245 0.0506736 23.7253 0.138729C23.5261 0.226784 23.3466 0.354041 23.1976 0.512917L12.9991 10.7114L2.80063 0.512917C2.6523 0.354357 2.4736 0.227256 2.27514 0.139177C2.07669 0.0510993 1.86255 0.00384439 1.64546 0.000224954C1.42836 -0.00339448 1.21276 0.0366956 1.01149 0.118109C0.810206 0.199523 0.627362 0.320596 0.473835 0.474124C0.320307 0.627652 0.199234 0.810496 0.11782 1.01177C0.0364063 1.21305 -0.00368372 1.42865 -6.42873e-05 1.64575C0.00355515 1.86284 0.0508101 2.07698 0.138888 2.27543C0.226966 2.47389 0.354068 2.65259 0.512628 2.80092L10.7111 12.9994L0.512628 23.1979C0.353768 23.3462 0.226381 23.5249 0.138057 23.7234C0.049734 23.9219 0.00228298 24.1362 -0.00146778 24.3535C-0.00521853 24.5707 0.0348078 24.7865 0.116226 24.988C0.197643 25.1895 0.318786 25.3725 0.472434 25.5261C0.626082 25.6798 0.809089 25.8009 1.01055 25.8823C1.21201 25.9637 1.4278 26.0038 1.64506 26C1.86232 25.9963 2.0766 25.9488 2.27513 25.8605C2.47366 25.7722 2.65238 25.6448 2.80063 25.4859L12.9991 15.2874L23.1976 25.4859C23.4188 25.7229 23.7061 25.888 24.0222 25.9598C24.3383 26.0316 24.6687 26.0069 24.9706 25.8888C25.2725 25.7707 25.532 25.5647 25.7155 25.2974C25.899 25.0302 25.998 24.714 25.9997 24.3898Z" />
    </g>
  </svg>
);

export default CloseIcon;
