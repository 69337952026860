import * as React from 'react';
import { get } from 'lodash';
import { withStyles, Grid } from '@material-ui/core';
import LabelLayout from './Labels';
import styles from './styles';

function CalendarWeek({
  locale,
  classes,
  dates,
  ItemLayout,
  labels,
  currency = 'USD',
  events,
}) {
  return (
    <Grid
      container={true}
      item={true}
      xs={12}
      justify="flex-start"
      wrap="nowrap"
      className={classes.calendarWeek}
      spacing={2}
    >
      <LabelLayout labels={labels} />
      {dates.map((item, index) => (
        <ItemLayout
          key={index}
          item={item}
          locale={locale}
          currency={currency}
          event={get(events, item.date, null)}
        />
      ))}
    </Grid>
  );
}

export default withStyles(styles)(CalendarWeek);
