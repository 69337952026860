import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';

function CalendarContainer({ children, classes }) {
  return (
    <div className={classes.calendarContainer}>
      <Grid
        container={true}
        direction="column"
        justify="center"
        className={classes.calendar}
        spacing={2}
      >
        {children}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(CalendarContainer);
