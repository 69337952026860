import { useManualQuery } from 'graphql-hooks';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { IContextValue, IUseReport } from '../lib/types';
import { store } from '../store/store';
import { PendingChangesStatus } from '../lib/enum';

const useReport = (query: string): IUseReport => {
  const ignore = useRef(false);
  const prevData = useRef([]);
  const { queryFilters, pendingChanges } = useContext<IContextValue>(store);
  const [fetchQuery, { loading, error, data: _data }] = useManualQuery(query, {
    variables: { filter: queryFilters },
  });

  const data: { [key: string]: any }[] = useMemo(() => {
    if (typeof _data !== `undefined`) {
      prevData.current = _data;
      return _data;
    }
    return prevData.current;
  }, [_data]);

  const fetchQueryParams = useMemo(
    () => ({
      variables: { filter: queryFilters },
      skipCache: true,
    }),
    [queryFilters],
  );

  useEffect(() => {
    ignore.current = false;
  }, [query]);

  useEffect(() => {
    if (pendingChanges === PendingChangesStatus.RUN || !ignore.current)
      fetchQuery(fetchQueryParams);
    return () => {
      ignore.current = true;
    };
  }, [pendingChanges, fetchQueryParams, fetchQuery]);

  return {
    fetch: () => fetchQuery(fetchQueryParams),
    loading,
    error,
    data,
  };
};

export default useReport;
