export const START_LOAD_PM_STATS = 'START_LOAD_PM_STATS';
export const LOAD_PM_STATS_SUCCESS = 'LOAD_PM_STATS_SUCCESS';
export const LOAD_PM_STATS_ERROR = 'LOAD_PM_STATS_ERROR';

export interface IPmStatsLine {
  pm_domain_name: string;
  count: number;
  avg_rates: number;
  avg_occ: number;
  review_score: number;
}

export interface IPmStatsState {
  error: Error;
  isLoading: boolean;
  pm_stats: IPmStatsLine[];
  query?: string;
  currency?: string;
}

interface IStartLoadPmStats {
  type: typeof START_LOAD_PM_STATS;
}

interface ILoadPmStatsSucess {
  type: typeof LOAD_PM_STATS_SUCCESS;
  payload: {
    pm_stats: IPmStatsLine[];
    query: string;
    currency: string;
  };
}

interface ILoadPmStatsError {
  type: typeof LOAD_PM_STATS_ERROR;
  payload: { error: Error };
}

export type IPmStatsActions =
  | IStartLoadPmStats
  | ILoadPmStatsSucess
  | ILoadPmStatsError;
