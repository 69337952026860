import { useManualQuery } from 'graphql-hooks';
import { useMemo, useContext, useRef, useState, useEffect } from 'react';
import { IAvailableFilters, IContextValue } from '../lib/types';
import { FILTERS_QUERY } from '../lib/consts';
import { IFilterDictionary } from '../../../store/filters/types';
import { store } from '../store/store';
import _ from 'lodash';
import { ReservationsFilters, PropertiesFilter } from '../lib/graphql';
import { PendingChangesStatus } from '../lib/enum';

export const emptyAvailableFilters: IFilterDictionary = {
  propertyType: [],
  propertyName: [],
  bedroom: [],
  bathroom: [],
  capacity: [],
  group: [],
};

/**
 * Get Graphql response and parse for filters component
 * @param _availableFilters
 * @returns availableFilters
 */
const parseAvailFilters = (_availableFilters): IFilterDictionary => {
  let filters = _availableFilters?.reservation?.filters || {};
  // Remove null values - FiltersDumb fails on null fields
  for (let key in filters) filters[key] = filters[key].filter(data => !!data);
  return { ...emptyAvailableFilters, ...filters };
};

/**
 * Parse selected filters to graphql query format
 * @param filters
 * @returns
 */
export const parseQuery = (
  filters: IAvailableFilters,
): ReservationsFilters | PropertiesFilter => {
  const ret: ReservationsFilters | PropertiesFilter = {};
  for (let key in filters) {
    if (filters[key] && filters[key].length > 0) {
      ret[key] = { _in: filters[key] };
    }
  }
  return ret;
};

/**
 * useFilters hook
 * @returns availableFilters state - error, loading, current filters, and fetch function
 */
const useFilters = () => {
  let _initialState = useRef<IFilterDictionary>(null);
  const [availableFilters, setAvailableFilters] = useState<IFilterDictionary>(
    emptyAvailableFilters,
  );
  const { queryFilters, setQueryFilters, setPendingChanges } = useContext<
    IContextValue
  >(store);
  const [fetchFilters, { loading, error, data }] = useManualQuery(
    FILTERS_QUERY,
    {
      variables: { filter: queryFilters },
    },
  );

  const setInitialState = () => {
    setAvailableFilters(_initialState.current);
    setQueryFilters({});
    setPendingChanges(PendingChangesStatus.RUN);
  };

  const _availableFilters = useMemo<IFilterDictionary>(() => {
    setPendingChanges(PendingChangesStatus.PENDING);
    const parsed = parseAvailFilters(data);
    if (!_initialState.current && !_.isEqual(parsed, emptyAvailableFilters)) {
      _initialState.current = parsed;
    }
    return parsed;
  }, [data, setPendingChanges]);

  useEffect(() => {
    setAvailableFilters(_availableFilters);
  }, [_availableFilters]);

  useEffect(() => {
    fetchFilters({
      variables: { filter: queryFilters },
    });
  }, [queryFilters, fetchFilters]);

  return {
    availableFilters: {
      error,
      loading,
      filters: availableFilters,
      fetchFilters: queryFilters => {
        fetchFilters({ variables: { filter: queryFilters } });
      },
      setInitialState,
    },
  };
};

export default useFilters;
