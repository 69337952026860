import { normalize } from 'normalizr';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { get, isEqual } from 'lodash/fp';
import * as pmStatsTypes from '../types';
import { pmDomainDetailsSchema } from './schema';
import { ExtraArguments, ApplicationState } from 'store';
import { updateEntities } from 'store/entities/actions';
import baseActionTypes from 'store/base-reducer/types';
import { apiAction } from 'store/actions';

export const loadPmStats: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  pmStatsTypes.IPmStatsActions
>> = () => {
  return async (dispatch, getState, { api }) => {
    const { fetchData, formatHeaders } = api;
    try {
      const state = getState();
      const { token } = state.auth;
      const { query } = state.filters;
      const appliedQuery = state.pmStats.query;
      if (isEqual(query, appliedQuery)) return null;
      dispatch({ type: pmStatsTypes.START_LOAD_PM_STATS });
      const response = await fetchData({
        path: '/pm_stats',
        query: query,
        headers: formatHeaders({ token }),
      });
      if (get('status', response) !== 'SUCCESS')
        throw new Error(response.error || 'ranking.error.generic');
      dispatch({
        type: pmStatsTypes.LOAD_PM_STATS_SUCCESS,
        payload: {
          query,
          pm_stats: get(['data', 'stats'], response),
          currency: get('currency', response),
        },
      });
    } catch (e) {
      dispatch({
        type: pmStatsTypes.LOAD_PM_STATS_ERROR,
        payload: { error: e },
      });
    }
  };
};

const pmDetailsActionTypes = baseActionTypes('pmDetails');

export const loadPmDomainDetails: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (query?: string) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const savedQuery: string | null = get('pmDetails.query', state);
    if (savedQuery && isEqual(query, savedQuery)) return null;
    return dispatch(
      apiAction({
        accessToken: token,
        url: '/pm_domain_details',
        method: 'GET',
        label: 'chartspmDomainDetails',
        params: query,
        onSuccess: data => dispatch => {
          const { entities } = normalize(data, pmDomainDetailsSchema);
          dispatch(
            updateEntities('pmDomainDetails', get('pmDomainDetails', entities)),
          );
          dispatch({
            type: pmDetailsActionTypes.LOAD_SUCCESS,
            payload: { query },
          });
        },
        onStart: () => dispatch =>
          dispatch({ type: pmDetailsActionTypes.START_LOADING }),
        onFailure: e => dispatch =>
          dispatch({
            type: pmDetailsActionTypes.LOAD_FAILED,
            payload: { error: e.message },
          }),
      }),
    );
  };
};
