import * as React from 'react';
import { get } from 'lodash/fp';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AuthActions } from '../../store/auth/types';
import { closeAside } from '../../store/layout/actions';
import {
  default as DumbDashboard,
  IDashboardProps,
} from '../../components/Dashboard/Dashboard';
import { Loader } from '../../components/Layout';
import { initialize } from '../../store/actions';

type DashboardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Pick<IDashboardProps, 'mainListItems'> &
  IDashboardContainerProps;

interface IDashboardContainerProps {
  noAside?: boolean;
  children?: React.ReactNode;
}
class Dashboard extends React.PureComponent<DashboardProps> {
  componentDidMount() {
    this.props.isHydrated &&
      this.props.actions.initialize(this.props.query.substr(1));
    if (this.props.noAside) {
      this.props.actions.closeAside();
    }
  }
  render() {
    const {
      isLoading,
      actions,
      isAsideOpen,
      children,
      isLogedIn,
      mainListItems,
      noAside = false,
    } = this.props;

    if (isLoading) return <Loader />;
    const handleDrawerClose = () => {
      actions.closeAside();
    };

    return (
      <DumbDashboard
        open={isAsideOpen}
        hasAside={isLogedIn && !noAside}
        handleDrawerClose={handleDrawerClose}
        mainListItems={mainListItems}
      >
        {children}
      </DumbDashboard>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    isLoading: get('layout.isLoading', state),
    isLogedIn: get('auth.token', state) ? true : false,
    isAsideOpen: get('layout.isOpen', state) || null,
    isHydrated: get('auth.hydrated', state) || null,
    query: get('router.location.search', state),
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, AuthActions>,
) {
  return {
    actions: bindActionCreators({ closeAside, initialize }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
