export const START_LOADING_RATES = 'START_LOADING_RATES';
export const SET_RATES = 'SET_RATES';
export const LOAD_RATES_ERROR = 'LOAD_RATES_ERROR';
export const SET_RATES_QUERY = 'SET_RATES_QUERY';

export interface IRatesItem {
  [key: string]: any;
  compSetPrice: number;
  date: string;
  occupancy: number;
  price: number;
}

export interface IRatesCalendar {
  [date: string]: IRatesItem;
}

export interface IRatesState {
  calendar?: {
    [date: string]: IRatesItem;
  };
  currency?: string;
  error?: any;
  isLoading?: boolean;
  query?: string;
}

interface ISet {
  type: typeof SET_RATES;
  payload: IRatesState;
}

interface IStartLoad {
  type: typeof START_LOADING_RATES;
}

interface ILoadError {
  type: typeof LOAD_RATES_ERROR;
  payload: IRatesState;
}

interface ISetRatesQuery {
  type: typeof SET_RATES_QUERY;
  payload: { query: string };
}

export type RatesActions = ISet | IStartLoad | ILoadError | ISetRatesQuery;
