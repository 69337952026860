import { Reducer } from 'redux';

import {
  IRatesState,
  RatesActions,
  SET_RATES,
  START_LOADING_RATES,
  LOAD_RATES_ERROR,
  SET_RATES_QUERY,
} from './types';

const defaultState: IRatesState = { isLoading: true, query: null };

const Rates: Reducer<IRatesState, RatesActions> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case SET_RATES:
      return {
        ...state,
        currency: action.payload.currency,
        calendar: action.payload.calendar,
        isLoading: false,
        error: null,
      };
    case START_LOADING_RATES:
      return { ...state, isLoading: true };
    case LOAD_RATES_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case SET_RATES_QUERY:
      return { ...state, query: action.payload.query };
    default:
      return state;
  }
};
export default Rates;
