import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';
import { RouteComponentProps, Route } from 'react-router-dom';
import { makeStyles, Paper, Tabs, Tooltip } from '@material-ui/core';

import { ConnectedTab as Tab, Title } from 'components/Transparent';
import AddFolderIcon from 'components/Icons/AddFolderIcon';

import ListingSelection from './ListingSelection';
import MyGroups from './MyGroups';
import ImportPMGroups from './ImportPMGroups';
import EditGroup from './EditGroup';

import './index.scss';
import { Group } from './types';
import ChevronLeft from 'components/Icons/ChevronLeft';

export * from './types';
export * from './store/reducer';

type PMGroupsProps = RouteComponentProps &
  WrappedComponentProps & { groupSelected: Group | null };

const styles = makeStyles({
  container: { padding: '16px 0 20px' },
  innerContainer: { padding: '0 16px 0 34px' },
  titleContainer: { padding: '8px' },
  tabs: { borderBottom: '1px solid rgba(0, 0, 0, 0.12)' },
  tab: {
    padding: '23px',
    fontSize: '18px',
    lineHeight: '21px',
    minWidth: '210px',
    textTransform: 'none',
  },
  editGroupTab: {
    padding: '23px',
    fontSize: '18px',
    lineHeight: '21px',
    minWidth: '210px',
    textTransform: 'capitalize',
  },
  backButton: {
    padding: '0',
    fontWeight: 600,
    lineHeight: '21px',
    minWidth: '20px!important',
  },
  hidden: {
    display: 'none!important',
  },
});

const PMGroups: React.SFC<PMGroupsProps & any> = function({
  intl,
  history,
  match,
  location,
}) {
  const [groupSelected, setGroupSelected] = React.useState(null);
  const classes = styles({});

  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const path = location.pathname.split('/');
      const group = path.pop();
      const address = path.pop();
      if (address === 'my-groups') setGroupSelected(decodeURIComponent(group));
      else setGroupSelected(null);
    });

    return () => {
      unlisten();
    };
  }, [history, setGroupSelected]);

  return (
    <div className={`${classes.container} container`}>
      <div className={classes.titleContainer}>
        <Title Icon={AddFolderIcon}>
          <FormattedMessage id="settings.pm_groups.title" />
        </Title>
      </div>
      <div>
        <Paper className={classes.innerContainer}>
          <Tabs
            className={classes.tabs}
            value={history.location.pathname}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab
              className={classes.tab}
              label={intl.formatMessage({
                id: 'settings.pm_groups.import.title',
              })}
              to={`${match.url}/import`}
              value={`${match.url}/import`}
            />
            <Tab
              className={classes.tab}
              label={intl.formatMessage({
                id: 'settings.pm_groups.listing.selection.title',
                defaultMessage: 'Listing Selection',
              })}
              to={`${match.url}/listing-selection`}
              value={`${match.url}/listing-selection`}
            />
            <Tab
              className={`${classes.tab} ${
                groupSelected ? classes.hidden : ''
              }`}
              label={intl.formatMessage({
                id: 'settings.pm_groups.my_groups.title',
              })}
              to={`${match.url}/my-groups`}
              value={`${match.url}/my-groups`}
            />
            <Tooltip
              className={`${groupSelected ? '' : classes.hidden}`}
              title={intl.formatMessage({
                id: 'settings.pm_groups.tooltips.gobacktomygroups',
                defaultMessage: 'Go back to My Groups',
              })}
            >
              <Tab
                className={classes.backButton}
                icon={<ChevronLeft fill="#757575" height="20" width="15" />}
                to={`${match.url}/my-groups`}
              />
            </Tooltip>
            <Tab
              className={`${groupSelected ? '' : classes.hidden} ${
                classes.editGroupTab
              } Mui-selected MuiTab-textColorSecondary`}
              label={groupSelected || ''}
            />
          </Tabs>
          <Route
            path={`${match.url}/import`}
            render={() => <ImportPMGroups />}
          />
          <Route
            path={`${match.url}/listing-selection`}
            component={ListingSelection}
          />
          <Route
            exact={true}
            path={`${match.url}/my-groups`}
            render={() => <MyGroups />}
          />
          <Route
            path={`${match.url}/my-groups/:groupName`}
            render={() => <EditGroup />}
          />
        </Paper>
      </div>
    </div>
  );
};

export default injectIntl(PMGroups);
