import { get } from 'lodash';
export function selectEvent(date: string, state) {
  const ratesObject = get(state, ['rates', 'calendar', date]);
  const event = get(state, ['events', 'calendar', date]);
  if (!ratesObject || !event) return null;
  return {
    ...event,
    occupancy: ratesObject.occupancy,
  };
}
