import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import House from '../../components/Icons/House';

interface AvatarProps {
  avatar?: string | null;
  size?: number | string;
  color?: string;
}
export default function({
  avatar = null,
  size = 40,
  color = '#fefefe',
}: AvatarProps) {
  const commonStyle = {
    width: size,
    height: size,
    backgroundColor: color,
    marginLeft: 15,
    marginRight: 15,
    padding: 6,
  };
  return avatar ? (
    <Avatar src={avatar} style={commonStyle} />
  ) : (
    <Avatar style={{ ...commonStyle, backgroundColor: '#bdbdbd' }}>
      <House fontSize="large" />
    </Avatar>
  );
}
