import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Display from '../../components/Account/Forgot';
import { forgotPassword } from '../../store/auth/actions';
import Messages from '../../containers/Layout/Messages';
import { ApplicationState } from '../../store/index';
import { AuthActions } from '../../store/auth/types';
import Layout from '../Layout/Layout';
interface IComponentState {
  email: string;
}

type ForgotContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Forgot extends React.Component<ForgotContainerProps, IComponentState> {
  state = { email: '' };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = { ...this.state };
    formData[event.target.name] = event.target.value;
    this.setState(formData);
  };

  handleForgot = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.actions.forgotPassword(this.state.email);
  };

  render() {
    const { messages } = this.props;
    const { email } = this.state;

    return (
      <Layout shouldHideAside={true}>
        <Messages messages={messages} />
        <Display
          email={email}
          handleChange={this.handleChange}
          handleForgot={this.handleForgot}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    messages: state.messages,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, AuthActions>,
) {
  return {
    actions: bindActionCreators({ forgotPassword }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
