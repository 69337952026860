import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const ExcelIcon: React.SFC<SvgIconProps> = function(props) {
  return (
    <SvgIcon
      id="ExcelIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        className="st0"
        d="M371.5,192h-67.6L256,262.5L208.1,192h-67.6l81.1,121.2L130.1,448h131.1v-45.8h-30.3l25.1-37.5l55.7,83.3h70.2 l-91.5-134.8L371.5,192z M451.7,99.7l-71.4-71.4C364.7,12.7,334,0,312,0H72C50,0,32,18,32,40v432c0,22,18,40,40,40h368 c22,0,40-18,40-40V168C480,146,467.3,115.3,451.7,99.7z M429.1,122.3c1.6,1.6,3.1,3.5,4.6,5.7H352V46.3c2.2,1.5,4.1,3.1,5.7,4.6 L429.1,122.3z M448,472c0,4.3-3.7,8-8,8H72c-4.3,0-8-3.7-8-8V40c0-4.3,3.7-8,8-8h240c2.4,0,5.1,0.3,8,0.9V160h127.1 c0.5,2.9,0.9,5.6,0.9,8V472z"
      />
    </SvgIcon>
  );
};

export default ExcelIcon;
