import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';

import PacingLayout from './layout';

export * from './types';
export * from './store';

const Pacing: React.FunctionComponent<RouteComponentProps> = props => (
  <Layout>
    <Dashboard mainListItems={<Filters />}>
      <PacingLayout {...props} />
    </Dashboard>
  </Layout>
);

export default Pacing;
