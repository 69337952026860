import * as React from 'react';
import ListingCount, {
  ListingsCountProps,
} from '../../components/Dashboard/ListingCount';
import { Loader } from '../../components/Layout';

interface ListingsCountDisplay extends ListingsCountProps {
  isLoading: boolean;
}
function Display({ isLoading, ...props }: ListingsCountDisplay) {
  return isLoading ? <Loader /> : <ListingCount {...props} />;
}

export default Display;
