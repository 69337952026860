import { Action } from 'redux';

export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const REQUEST_PAGE = 'REQUEST_PAGE';
export const SET_META = 'SET_META';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const GO_TO_PAGE = 'GO_TO_PAGE';
export interface IPagesState {
  [sortKey: string]: {
    [page: number]: {
      ids: [];
      fetching: boolean;
    };
  };
}
export interface IMetaState {
  total: number;
  limit: number;
}

export interface IPagination {}

export interface ICurrentPageState {
  page: number;
  sortKey: string;
}

export type SortDirection = 'ASC' | 'DESC';
export interface IPageActions extends Action<string> {
  payload: {
    page: number;
    orderBy: string;
    oder: SortDirection;
  };
  meta: {
    endpoint: string;
    resultKey: string;
  };
}
export interface ICurrentPageAction extends Action<string> {
  payload: {
    page: number;
    orderBy: string;
    order: SortDirection;
  };
}
