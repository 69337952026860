import * as React from 'react';
import StepsContainer from './Steps/Container';
import Notification from './Notification';
import Faq from './Faq/Faq';
import Market from './Market';
import Resources from './Resources';
import Layout from './Layout';
import { IUser } from '../../store/auth/types';
import { FormattedMessage } from 'react-intl';

const questionList = [
  {
    question: 'onboarding.faq.how_long.question',
    answer: 'onboarding.faq.how_long.answer',
  },
  {
    question: 'onboarding.faq.why_schedule.question',
    answer: 'onboarding.faq.why_schedule.answer',
  },
  {
    question: 'onboarding.faq.what_to_do.question',
    answer: 'onboarding.faq.what_to_do.answer',
  },
];
export interface IOnboardingProps {
  user: IUser;
}
function OnboardingPage({ user }: IOnboardingProps) {
  const { _markets, _cleanUrls } = user;
  return (
    <Layout>
      <Notification />

      <StepsContainer user={user} />
      <div className="columns is-multiline">
        <Faq
          title={<FormattedMessage id="faq" defaultMessage="FAQ" />}
          faqItems={questionList}
        />
        <div className="column is-6">
          <Market markets={_markets} cleanUrl={_cleanUrls[0]} />
          <Resources />
        </div>
      </div>
    </Layout>
  );
}

export default OnboardingPage;
