import {
  ReviewsDomains,
  reviewsNegativeDomain,
  reviewsPositiveDomain,
} from '../../store/types';
import { cloneDeep } from 'lodash';
function getSettings(domain: ReviewsDomains) {
  return {
    [reviewsPositiveDomain]: {
      colors: {
        min: '#66fa87',
        max: '#1ec759',
      },
    },
    [reviewsNegativeDomain]: {
      colors: {
        min: '#ffaaaa',
        max: '#ff5555',
      },
    },
  }[domain];
}

export const getConfig = ({
  data,
  domain,
}: {
  data: any[];
  domain: ReviewsDomains;
}) => {
  const { colors } = getSettings(domain);

  return cloneDeep({
    series: [
      {
        data,
        type: 'WordCloudSeries',
        accuracy: 4,
        step: 15,
        angles: 0,
        dataFields: {
          word: 'word',
          value: 'count',
        },
        heatRules: [
          {
            target: 'labels.template',
            property: 'fill',
            ...colors,
          },
        ],
      },
    ],
  });
};
