import { Action } from 'redux';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export interface IMessagesState {
  success?: MessageContent[];
  error?: MessageContent[];
  info?: MessageContent[];
}

interface MessageContent {
  msg: string;
}
export interface IClearMessages extends Action {
  type: typeof CLEAR_MESSAGES;
}
