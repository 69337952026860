import * as React from 'react';
import { StatusPattern, getOnboardingIconColors } from './Utils';
import { IOnboardingIconProps } from './types';

const OnboardingSupplyIcon = ({ active, ...props }: IOnboardingIconProps) => {
  const [primary, secondary] = getOnboardingIconColors(active);
  return (
    <svg id="rates__1" viewBox="0 0 174.9 174.9" {...props}>
      <circle cx={87.4} cy={87.4} r={87.4} fill="#aad0f5" opacity={0.1} />
      <StatusPattern done={active} />
      <g opacity={0.5}>
        <linearGradient
          id="rates__SVG_1"
          x1={87.4}
          x2={87.4}
          y1={77.767}
          y2={99.13}
          gradientTransform="matrix(1 0 0 -1 0 175.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#rates__SVG_1)"
          d="M87.4 76.8c-5.9 0-10.7 4.8-10.7 10.7s4.8 10.7 10.7 10.7 10.7-4.8 10.7-10.7-4.8-10.7-10.7-10.7zm0 15.2c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5z"
        />
        <linearGradient
          id="rates__SVG_2"
          x1={87.5}
          x2={87.5}
          y1={62.59}
          y2={116.89}
          gradientTransform="matrix(1 0 0 -1 0 175.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#rates__SVG_2)"
          d="M87.4 61.5c-1.3 0-2.7.1-4 .3-1.4-1.7-3.6-2.8-6.1-2.8-4.5 0-8.1 3.7-8.1 8.1 0 .6.1 1.2.2 1.8-4.8 4.7-7.8 11.3-7.8 18.5 0 14.3 11.6 25.9 25.9 25.9s25.9-11.6 25.9-25.9-11.7-25.9-26-25.9zm-10.1 3.7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm10.1 42c-10.9 0-19.7-8.9-19.7-19.7 0-5.2 2-9.9 5.3-13.4 1.3.8 2.7 1.3 4.3 1.3 4.3 0 7.8-3.3 8.1-7.5.7-.1 1.3-.1 2-.1 10.9 0 19.7 8.9 19.7 19.7.1 10.8-8.8 19.7-19.7 19.7z"
        />
        <linearGradient
          id="rates__SVG_3"
          x1={87.5}
          x2={87.5}
          y1={44.79}
          y2={132.084}
          gradientTransform="matrix(1 0 0 -1 0 175.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#rates__SVG_3)"
          d="M131.1 67.2c0-4.5-3.7-8.1-8.1-8.1-.8 0-1.5.1-2.2.3-8-9.5-20-15.5-33.3-15.5-24.1 0-43.6 19.6-43.6 43.6 0 13.4 6 25.3 15.5 33.3-.2.7-.3 1.4-.3 2.2 0 4.5 3.7 8.1 8.1 8.1 2.3 0 4.3-.9 5.8-2.5 4.5 1.6 9.4 2.5 14.5 2.5 24.1 0 43.6-19.6 43.6-43.6 0-5.1-.9-9.9-2.5-14.5 1.5-1.5 2.5-3.6 2.5-5.8zm-6.2 0c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-57.7 57.7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm20.2 0c-4.2 0-8.3-.7-12.1-2 0-4.5-3.7-8.1-8.1-8.1-1.5 0-2.9.4-4.1 1.1C55.1 109 50 98.8 50 87.4c0-20.7 16.8-37.5 37.5-37.5 11.4 0 21.6 5.1 28.5 13.1-.7 1.2-1.1 2.6-1.1 4.1 0 4.5 3.6 8.1 8.1 8.1 1.3 3.8 2 7.9 2 12.1-.1 20.8-16.9 37.6-37.6 37.6z"
        />
      </g>
    </svg>
  );
};

export default OnboardingSupplyIcon;
