import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - 368px)`,
      },
      overflow: 'auto',
      height: '100%',
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });
