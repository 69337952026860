import { ThunkAction } from 'redux-thunk';
import { ExtraArguments } from '../';
import { IMessagesState, IClearMessages, CLEAR_MESSAGES } from './types';

export const clearMessages = (): ThunkAction<
  void,
  IMessagesState,
  ExtraArguments,
  IClearMessages
> => {
  return dispatch => {
    dispatch({
      type: CLEAR_MESSAGES,
    });
  };
};
