import { Action, Reducer } from 'redux';

import baseReducer, { IBaseReducerState } from 'store/base-reducer';

import { listingsDetailsDomain } from './types';
import { ACTIONS } from './actions';
import { Status as ListingsStatus } from 'store/listings';

interface State extends IBaseReducerState {
  downloading: boolean;
  listingsStatus: ListingsStatus;
  listingsError: any;
}

const bReducer = baseReducer(listingsDetailsDomain).reducer;
const initialState: State = {
  downloading: false,
  error: null,
  isLoading: true,
  query: null,
  listingsStatus: ListingsStatus.IDLE,
  listingsError: null,
};

const ACTION_HANDLERS = {
  [ACTIONS.CSV_DOWNLOAD_START]: state => ({ ...state, downloading: true }),
  [ACTIONS.CSV_DOWNLOAD_SUCESS]: state => ({ ...state, downloading: false }),
  [ACTIONS.CSV_DOWNLOAD_FAILURE]: state => ({ ...state, downloading: false }),
  [ACTIONS.START_LOAD_LISTINGS]: state => ({
    ...state,
    listingsStatus: ListingsStatus.RUNNING,
  }),
  [ACTIONS.SUCCESS_LOAD_LISTINGS]: state => ({
    ...state,
    listingsStatus: ListingsStatus.SUCCESS,
  }),
  [ACTIONS.FAIL_LOAD_LISTINGS]: state => ({
    ...state,
    listingsStatus: ListingsStatus.FAILED,
  }),
};

export const reducer = (
  state = initialState,
  action: Action,
): Reducer<IBaseReducerState, Action> => {
  if (Object.values(ACTIONS).some(a => a === action.type)) {
    return ACTION_HANDLERS[action.type](state);
  }
  return bReducer(state, action);
};
