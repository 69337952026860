import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ChartError } from './';
import { Loader } from '../Layout';
import { Mixpanel } from '../../services/mixpanel/index';
import ChartInfo from './Information/InfoBox';
am4core.useTheme(am4themes_animated);

export interface BaseChartProps {
  chartId: string;
  data: any[];
  error?: any;
  height?: string;
  loading?: boolean;
  width?: string;
  infoMessage?: React.ReactElement;
}

const Container = (props: any) => {
  const { chartId } = props;
  return (
    <div
      style={{
        flexGrow: 1,
        padding: 20,
      }}
    >
      <div
        id={chartId}
        style={{
          height: props.height,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default abstract class BaseChart<
  T extends BaseChartProps
> extends React.PureComponent<T> {
  protected chart: am4charts.Chart;

  abstract buildChart(): am4charts.Chart;
  abstract dispose();
  abstract updateSeries(chart: am4charts.Chart): void;

  show() {
    const { data, error, loading, chartId } = this.props;

    if (data && !loading && !error) {
      if (!this.chart) this.chart = this.buildChart();
      this.updateSeries(this.chart);
      this.chart.data = data;
      const chart = this.chart;
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = 'right';
      chart.exporting.menu.verticalAlign = 'top';
      chart.exporting.filePrefix = chartId;

      chart.exporting.menu.items = [
        {
          label: '...',
          menu: [
            {
              label: 'Data',
              menu: [
                {
                  type: 'custom',
                  label: 'JSON',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'JSON',
                      });
                      chart.exporting.export('json');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'CSV',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'CSV',
                      });
                      chart.exporting.export('csv');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'XLSX',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'XLSX',
                      });
                      chart.exporting.export('xlsx');
                    },
                  },
                },
              ],
            },
            {
              label: 'Image',
              menu: [
                {
                  type: 'custom',
                  label: 'PNG',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'PNG',
                      });
                      chart.exporting.export('png');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'JPG',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'JPG',
                      });
                      chart.exporting.export('jpg');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'GIF',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'GIF',
                      });
                      chart.exporting.export('gif');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'SVG',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'SVG',
                      });
                      chart.exporting.export('svg');
                    },
                  },
                },
                {
                  type: 'custom',
                  label: 'PDF',
                  options: {
                    callback() {
                      Mixpanel.track('Download chart data', {
                        chartId,
                        type: 'PDF',
                      });
                      chart.exporting.export('pdf');
                    },
                  },
                },
              ],
            },
            {
              label: 'Print',
              type: 'custom',

              options: {
                callback() {
                  Mixpanel.track('Download chart data', {
                    chartId,
                    type: 'PRINT',
                  });
                  chart.exporting.export('print');
                },
              },
            },
          ],
        },
      ];
    } else {
      this.dispose();
    }
  }

  componentDidMount() {
    this.show();
  }

  componentDidUpdate() {
    this.show();
  }

  componentWillUnmount() {
    this.dispose();
  }

  render() {
    const { error, loading, infoMessage } = this.props;

    if (loading)
      return (
        <Container {...this.props}>
          <Loader />
        </Container>
      );
    if (error)
      return (
        <Container {...this.props}>
          <ChartError error={error} />
        </Container>
      );

    return (
      <div style={{ position: 'relative' }}>
        {infoMessage && <ChartInfo>{infoMessage}</ChartInfo>}
        <Container {...this.props} />
      </div>
    );
  }
}
