import * as React from 'react';
import ImagePlaceholder from 'components/Icons/ImagePlaceholder';

export const ImgFallback: React.SFC<React.ImgHTMLAttributes<
  HTMLImageElement
>> = props => {
  const { alt, src, width } = props;
  const [isErrored, setErrored] = React.useState<boolean>(false);
  React.useEffect(() => {}, [isErrored]);
  const onError = () => {
    setErrored(true);
  };
  return isErrored || !src ? (
    <ImagePlaceholder width={width} />
  ) : (
    <img onError={onError} alt={alt} {...props} />
  );
};
