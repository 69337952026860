import * as React from 'react';
import { capitalize } from 'lodash';
import { makeStyles } from '@material-ui/core';
import Icon from '../FiltersIcons';
import { Title } from 'components/Transparent';
import { styles } from './styles';

export interface CartoMapParams {
  market: string;
  url: string;
}

export interface CartoMapProps extends CartoMapParams {
  height?: string;
  width?: string;
}

const useStyles = makeStyles(styles);
const IconComponent = props => (
  <Icon filterName="market" width={25} {...props} />
);
function CartoMap({
  height = '500px',
  market,
  url,
  width = '100%',
}: CartoMapProps) {
  const classes = useStyles({});
  return (
    <div>
      <div className={classes.titleContainer}>
        <Title Icon={IconComponent}>{capitalize(market)}</Title>
      </div>
      <iframe
        title="Carto Map"
        style={{ height, width, border: 'none', minHeight: 500 }}
        src={url}
      />
    </div>
  );
}

export default CartoMap;
