import * as React from 'react';
import RatesLayout from './Layout';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';

const Rates: React.FunctionComponent<any> = props => (
  <Layout>
    <Dashboard mainListItems={<Filters />}>
      <RatesLayout {...props} />
    </Dashboard>
  </Layout>
);

export default Rates;
