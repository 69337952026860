import * as React from 'react';
import { Route, Switch } from 'react-router';
import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  RenderHome,
  ValidatedRoute,
} from '../components/Auth';
import NotFound from '../components/NotFound';

import Rates from './RatesAndDemand';
import Calendar from '../containers/Calendar';
import { Supply } from './supply';
import { Onboarding } from './onboarding';
import Settings from './settings';
import { PropertyManagers } from './ranking';
import Forgot from '../containers/Account/Forgot';
import Login from '../containers/Account/Login';
import Reset from '../containers/Account/Reset';
import Inactive from '../components/Account/Inactive';
import { ReviewsPage } from './reviews';
import Performance from './performance';
import Pacing from './pacing';
import SelfReporting from './self-reporting';

import 'normalize.css';

export const App: React.FunctionComponent<{}> = () => {
  return (
    <Switch>
      <Route path="/" exact={true} render={() => <RenderHome />} />
      <UnauthenticatedRoute path="/forgot" exact={true} component={Forgot} />

      <UnauthenticatedRoute path="/login" exact={true} component={Login} />
      <UnauthenticatedRoute
        path="/reset/:token"
        exact={true}
        component={Reset}
      />

      <UnauthenticatedRoute
        path="/inactive"
        exact={true}
        component={Inactive}
      />
      <AuthenticatedRoute path="/onboarding" component={Onboarding} />
      <AuthenticatedRoute path="/rates" component={Rates} />
      <AuthenticatedRoute path="/calendar" component={Calendar} />
      <AuthenticatedRoute path="/supply" component={Supply} />
      <AuthenticatedRoute path="/ranking" component={PropertyManagers} />
      <ValidatedRoute
        validationKey="has_pms_connection"
        path="/pms"
        component={SelfReporting}
      />
      <AuthenticatedRoute path="/reviews" component={ReviewsPage} />
      <AuthenticatedRoute path="/performance" component={Performance} />
      <AuthenticatedRoute path="/pacing" component={Pacing} />
      <AuthenticatedRoute path="/settings" component={Settings} />
      <Route component={NotFound} status={404} />
    </Switch>
  );
};
