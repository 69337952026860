export const DISCOUNTS_DOMAIN = 'discounts';

export interface AggregatedDiscountsInfo {
  avgCleaningFee: number;
  avgMonthlyDiscount: number;
  avgServiceFee: number;
  currency: string;
  listingCount: number;
}

export interface DiscountsState {
  aggregated: AggregatedDiscountsInfo;
  downloadingCSV: boolean;
  errorSupply: Error;
  errorAggregated: Error;
  fetchingSupply: boolean;
  fetchingAggregated: boolean;
  supply: { currency: string };
}

export const ACTIONS = {
  FETCH_DISCOUNTS_AGGREGATED_START: 'FETCH_DISCOUNTS_AGGREGATED_START',
  FETCH_DISCOUNTS_AGGREGATED_SUCCESS: 'FETCH_DISCOUNTS_AGGREGATED_SUCCESS',
  FETCH_DISCOUNTS_AGGREGATED_FAILURE: 'FETCH_DISCOUNTS_AGGREGATED_FAILURE',
  FETCH_DISCOUNTS_SUPPLY_START: 'FETCH_DISCOUNTS_SUPPLY_START',
  FETCH_DISCOUNTS_SUPPLY_SUCCESS: 'FETCH_DISCOUNTS_SUPPLY_SUCCESS',
  FETCH_DISCOUNTS_SUPPLY_FAILURE: 'FETCH_DISCOUNTS_SUPPLY_FAILURE',
  DISCOUNTS_CSV_DOWNLOAD_START: 'DISCOUNTS_CSV_DOWNLOAD_START',
  DISCOUNTS_CSV_DOWNLOAD_SUCESS: 'DISCOUNTS_CSV_DOWNLOAD_SUCCESS',
  DISCOUNTS_CSV_DOWNLOAD_FAILURE: 'DISCOUNTS_CSV_DOWNLOAD_FAILURE',
};
