import { IReportListItem, GetDateType } from './types';
import { IntlShape } from 'react-intl';
import { config as defaultConfig } from '../../../components/Charts/AmCharts';

/**
 *
 * @param data
 * @returns Parsed data for queries with multiple keys
 */
export const getKeyData = (
  data: { [key: string]: any }[],
): { [key: string]: any }[] => {
  let retData = [];
  for (let idx in data) {
    for (let i = 0; i < data[idx].length; i++) {
      retData.push({ ...data[idx][i], name: idx });
    }
  }
  return retData;
};

/**
 *
 * @param data
 * @param getDate
 * @param xKey
 * @returns Add date value and remove empty items
 */
const prepareData = (
  data: { [key: string]: any }[] | null,
  getDate: GetDateType,
  xKey: string,
): { [key: string]: any }[] => {
  return data.reduce((acc: any, reportData) => {
    if (!reportData[xKey]) return acc;
    const date = getDate(reportData);

    const ret = {
      ...reportData,
      date,
    };

    acc.push(ret);
    return acc;
  }, []);
};

const getSeries = (
  seriesRaw: { [key: string]: any }[],
  name: string,
  valueY: any,
  seriesType: string,
  stacked: boolean,
  intl: IntlShape,
  id: string,
  tooltipXLabel: string,
  tooltipYLabel: string,
): [{ [key: string]: any }[], { [key: string]: any }[]] => {
  const series = [];
  const seriesData = seriesRaw.reduce(
    (acc: { [key: string]: any }[], reportDataItem: { [key: string]: any }) => {
      const { date } = reportDataItem;
      const currentName = reportDataItem[name];
      const currentValueY = reportDataItem[valueY];
      let index = acc.findIndex(
        parsedSeries => date.getTime() === parsedSeries.date.getTime(),
      );
      if (index === -1) {
        acc.push({ date });
        index = acc.length - 1;
      }
      const nameLabel = intl.formatMessage({
        id: `selfreporting.reports.${id}.${currentName}`,
        defaultMessage: currentName,
      });
      if (
        series.findIndex(
          currentSeries => currentName === currentSeries._name,
        ) === -1
      ) {
        const newSeries = {
          type: seriesType,
          dataFields: {
            valueY: `data_${currentName}`,
            dateX: 'date',
          },
          strokeWidth: 2,
          name: nameLabel,
          _name: currentName,
          tooltipText: `[bold]{dateX.formatDate('${tooltipXLabel}')}[/]
                       {valueY.formatNumber("${tooltipYLabel}")}`,
          fillOpacity: stacked ? 0.3 : null,
          bullets: stacked ? [{ type: 'CircleBullet', radius: 4 }] : null,
        };
        series.push(newSeries);
      }
      acc[index][`data_${currentName}`] = currentValueY;
      return acc;
    },
    [],
  );
  return [series, seriesData as { [key: string]: any }[]];
};

/**
 * Calculate events to zoom automatically to the first 1000 entries
 * if total entries are more than 3000
 */
const getEvents = (
  seriesData: { [key: string]: any }[],
  seriesRaw: { [key: string]: any }[],
) => {
  let events;
  if (seriesData.length === 0) return {};
  if (seriesData.length * (Object.keys(seriesData[0]).length - 1 || 0) > 500) {
    events = {
      ready: function(ev) {
        ev.target.xAxes
          .getIndex(0)
          .zoomToDates(
            seriesRaw[0].date,
            seriesRaw[
              Math.floor(
                seriesData.length /
                  ((seriesData.length *
                    (Object.keys(seriesData[0]).length - 1 || 0)) /
                    500),
              )
            ].date,
          );
      },
    };
  }
  return events;
};

export const getConfig = (
  {
    id,
    valueY,
    name,
    text,
    stacked,
    tooltipXLabel,
    tooltipYLabel,
    numberFormatter,
    dateFormats,
    getData,
    getDate,
  }: IReportListItem,
  rawGraphqlData: { [key: string]: any }[] | null,
  intl: IntlShape,
  seriesType: string,
):
  | {
      colors: { list: any };
      series: any[];
      xAxes: any[];
      yAxes: any[];
      legend: {};
      data: any[];
      cursor: {};
    }
  | {} => {
  if (!rawGraphqlData || rawGraphqlData.length === 0) return [];

  rawGraphqlData = getData(rawGraphqlData);
  const seriesRaw = prepareData(rawGraphqlData, getDate, name);
  const [series, data] = getSeries(
    seriesRaw,
    name,
    valueY,
    seriesType,
    stacked,
    intl,
    id,
    tooltipXLabel,
    tooltipYLabel,
  );

  const events = getEvents(data, seriesRaw);
  const ret = {
    ...defaultConfig,
    series,
    xAxes: [
      {
        type: 'DateAxis',
        parseDates: true,
        dateFormats,
        periodChangeDateFormats: dateFormats,
        renderer: {
          labels: {
            location: 0.5,
          },
        },
        cursorTooltipEnabled: false,
      },
    ],
    yAxes: [
      {
        type: 'ValueAxis',
        title: {
          text: intl.formatMessage({
            id: `selfreporting.reports.${id}`,
            defaultMessage: text,
          }),
        },
        numberFormatter,
        cursorTooltipEnabled: false,
      },
    ],
    legend: { type: `Legend` },
    data,
    cursor: { type: `XYCursor` },
    scrollbarX: {},
    events,
  };
  return ret;
};
