import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ListingsTable from 'routes/settings/Components/ListingsTable';
import { bindActionCreators } from 'redux';
import {
  deleteFromGroup,
  resetStatus,
  fetchGroups,
  setCurrentListingsDomain,
} from '../store/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Tooltip } from '@material-ui/core';
import GarbageIcon from 'components/Icons/GarbageIcon';
import { DeleteFromGroupModal } from 'routes/settings/Components/Modal/Modal';
import { withRouter } from 'react-router';
import {
  removeAllListingSelection,
  editGroupPaginator,
} from '../store/actions';
import { loadFirstPage } from 'store/listings/actions';
import { updateFilter } from 'store/filters/actions';
import { apply, clear } from 'store/actions';
import {
  StartLoadListings,
  SuccessLoadListings,
  FailLoadListings,
} from 'routes/settings/PMGroups/store';
import { EDIT_GROUP_DOMAIN } from '../store/types';
import { removeDisabled } from 'store/filters/actions';
import { Alert } from '@material-ui/lab';

const EditGroup = ({
  listingsSelected,
  deleteCount,
  status,
  actions,
  cleanUrl,
  location,
  groups,
  currentListingsDomain,
  selectedFilters,
  query,
  intl,
}) => {
  const {
    removeAllListingSelection,
    loadFirstPage,
    fetchGroups,
    updateFilter,
    apply,
    setCurrentListingsDomain,
    removeDisabled,
  } = actions;

  useEffect(() => {
    if (currentListingsDomain !== EDIT_GROUP_DOMAIN)
      setCurrentListingsDomain(EDIT_GROUP_DOMAIN);
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentMarket, setCurrentMarket] = useState(selectedFilters?.market);

  const groupName = useRef(
    decodeURIComponent(location.pathname.split('/').pop()),
  );

  useEffect(() => {
    setCurrentMarket(
      decodeURI(query)
        .split('&')
        .find(item => item.indexOf('market') > -1)
        ?.replace('market=', ''),
    );
    // eslint-disable-next-line
  }, [query]);

  const onExit = useCallback(() => {
    // TODO: Test batch rendering with redux (npm libraries) to avoid market reset
    actions.clear();
    removeAllListingSelection();
    removeDisabled('groups');

    // eslint-disable-next-line
  }, [removeAllListingSelection, removeDisabled]);

  const fetchGroupsOnInit = useCallback(() => {
    if (!groups || groups.length === 0) {
      fetchGroups();
    }
  }, [groups, fetchGroups]);

  const setGroupOnInit = useCallback(() => {
    const g = groups.find(g => g.groupName === groupName.current);
    setSelectedGroup(g);
    updateFilter('groups', [groupName.current]).then(apply);
    // eslint-disable-next-line
  }, [groups, groupName]);

  useEffect(() => {
    const promises = [];
    Object.keys(selectedFilters).forEach(filter => {
      if (!['market', 'groups'].includes(filter))
        promises.push(updateFilter(filter, null));
    });
    Promise.all(promises).then(apply);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => onExit, []);
  useEffect(fetchGroupsOnInit, [fetchGroupsOnInit]);
  useEffect(setGroupOnInit, [setGroupOnInit, groups]);

  const deleteFromGroup = async () => {
    actions.deleteFromGroup(selectedGroup, listingsSelected, cleanUrl, () =>
      loadFirstPage(
        StartLoadListings,
        SuccessLoadListings,
        FailLoadListings,
        editGroupPaginator,
        currentListingsDomain,
      ),
    );
  };

  const handleClick = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    actions.resetStatus();
  };

  return (
    <div
      style={{
        margin: '16px',
        paddingBottom: '20px',
        maxWidth: '1920px!important',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <DeleteFromGroupModal
        open={showModal}
        handleClose={handleClose}
        listingsSelected={listingsSelected}
        groupName={selectedGroup?.groupName ?? ''}
        deleteFromGroup={deleteFromGroup}
        status={status}
        deleteCount={deleteCount}
      />
      <section style={{ marginBottom: 50, marginTop: 50 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          <div>
            <span
              style={{
                display: 'inline-block',
                paddingRight: 23,
                fontSize: 16,
                color: '#505d6e',
              }}
            >
              <FormattedMessage
                id="settings.pm_groups.listings_selection.listings_selected"
                defaultMessage="{listingsCount} Listings Selected"
                values={{ listingsCount: listingsSelected.length }}
              />
            </span>
            <Button
              variant="outlined"
              className="create-modal-button"
              onClick={handleClick}
              disabled={listingsSelected.length < 1}
            >
              <GarbageIcon width={20} fill="#FF889D" />
            </Button>
          </div>
          <Tooltip
            title={intl.formatMessage({
              id: 'settings.pm_groups.tooltips.change_market_filters',
              defaultMessage: 'Change the market using the filters',
            })}
          >
            <Alert severity="info" style={{ display: 'inline-flex' }}>
              <FormattedMessage
                id="settings.pm_groups.edit.market_info"
                defaultMessage="Current Market"
              />
              : {currentMarket}
            </Alert>
          </Tooltip>
        </div>
        {selectedGroup ? (
          <ListingsTable
            startLoadListings={StartLoadListings}
            successLoadListings={SuccessLoadListings}
            failLoadListings={FailLoadListings}
          />
        ) : null}
      </section>
    </div>
  );
};

const mapActionsToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        deleteFromGroup,
        resetStatus,
        removeAllListingSelection,
        loadFirstPage,
        fetchGroups,
        updateFilter,
        apply,
        setCurrentListingsDomain,
        removeDisabled,
        clear,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = state => {
  return {
    listingsSelected: state?.pmGroups?.listingsSelected,
    deleteCount: state?.pmGroups?.deleteCount,
    selectedGroup: state?.pmGroups?.selectedGroup,
    status: state?.pmGroups?.status,
    cleanUrl: state?.auth?.user?._cleanUrls[0] || '',
    groups: state?.pmGroups?.groups || [],
    currentListingsDomain: state?.pmGroups?.currentListingsDomain,
    selectedFilters: state?.filters?.selectedFilters || {},
    query: state?.filters?.query || '',
  };
};

export default withRouter(
  injectIntl(connect(mapStateToProps, mapActionsToProps)(EditGroup)),
);
