import * as moment from 'moment';

import { IPriceVariationsCalendar } from '../../store/priceVariations/types';

// import { IPriceVariationsCalendarItem } from './Item/PriceVariationsCalendarItem';
import { BAD, GOOD, ZERO } from './styles';
import { IRatesCalendar, IRatesItem } from '../../store/rates/types';
import { IPriceVariationsItem } from '../../store/priceVariations/types';
export interface IRatesCalendarItem extends IRatesItem {
  inactive?: boolean;
}
export interface IPriceVariationsCalendarItem extends IPriceVariationsItem {
  inactive?: boolean;
}
const DATE_FORMAT = 'YYYY-MM-DD';
const WEEK_SIZE = 7;

function fillDays<T>(_date: string, atEnd: boolean = false, calendarKeys) {
  const startOfWeek = moment
    .utc(_date)
    .startOf('week')
    .subtract(1, 'second')
    .endOf('day');
  const endOfWeek = moment.utc(_date).endOf('week');
  const date = moment.utc(_date);
  const add =
    (atEnd ? endOfWeek : startOfWeek).diff(date, 'day') * (atEnd ? 1 : -1);
  const fill = Array.from(Array(add).keys()).map(index => {
    const calendarItem: T = {
      date: (atEnd ? date : startOfWeek)
        .clone()
        .add(index + 1, 'day')
        .format(DATE_FORMAT),
      ...calendarKeys,
    };
    return calendarItem;
  });

  return fill;
}

export function transformPriceVariationsCalendar(
  calendar: IPriceVariationsCalendar,
): IPriceVariationsCalendarItem[][] {
  if (!calendar) return [[]];

  return Object.keys(calendar)
    .sort()
    .reduce((acc: IPriceVariationsCalendarItem[], date: string) => {
      const rawItem = calendar[date];
      acc.push({
        marketPriceVariation: Number(rawItem.marketPriceVariation),
        priceVariation: Number(rawItem.priceVariation),
        date: rawItem.date,
        occupancy: Number(rawItem.occupancy),
      });
      return acc;
    }, [])
    .reduce(
      (
        dates: IPriceVariationsCalendarItem[],
        calendarItem: IPriceVariationsCalendarItem,
        index: number,
        flatCalendar: IPriceVariationsCalendarItem[],
      ) => {
        if (index === 0)
          dates.push(
            ...fillDays<IPriceVariationsCalendarItem>(
              calendarItem.date,
              false,
              {
                marketPriceVariation: null,
                occupancy: null,
                priceVariation: null,
                inactive: true,
              },
            ),
          );
        dates.push(calendarItem);
        if (index === flatCalendar.length - 1)
          dates.push(
            ...fillDays<IPriceVariationsCalendarItem>(calendarItem.date, true, {
              marketPriceVariation: null,
              occupancy: null,
              priceVariation: null,
              inactive: true,
            }),
          );
        return dates;
      },
      [],
    )
    .reduce(
      (
        groups: IPriceVariationsCalendarItem[][],
        calendarItem: IPriceVariationsCalendarItem,
        index: number,
      ) => {
        let week = groups[Math.floor(index / WEEK_SIZE)];
        if (!week) {
          groups.push([]);
          week = groups[Math.floor(index / WEEK_SIZE)];
        }
        week.push(calendarItem);
        return groups;
      },
      [],
    );
}

export function transformRatesCalendar(
  calendar: IRatesCalendar,
): IRatesCalendarItem[][] {
  if (!calendar) return [[]];

  return Object.keys(calendar)
    .sort()
    .reduce((acc: IRatesCalendarItem[], date: string) => {
      const rawItem = calendar[date];
      acc.push({
        compSetPrice: Number(rawItem.compSetPrice),
        date: rawItem.date,
        occupancy: Number(rawItem.occupancy),
        price: Number(rawItem.price),
      });
      return acc;
    }, [])
    .reduce(
      (
        dates: IRatesCalendarItem[],
        calendarItem: IRatesCalendarItem,
        index: number,
        flatCalendar: IRatesCalendarItem[],
      ) => {
        if (index === 0)
          dates.push(
            ...fillDays<IRatesCalendarItem>(calendarItem.date, false, {
              compSetPrice: null,
              occupancy: null,
              price: null,
              inactive: true,
            }),
          );
        dates.push(calendarItem);
        if (index === flatCalendar.length - 1)
          dates.push(
            ...fillDays<IRatesCalendarItem>(calendarItem.date, true, {
              compSetPrice: null,
              occupancy: null,
              price: null,
              inactive: true,
            }),
          );
        return dates;
      },
      [],
    )
    .reduce(
      (
        groups: IRatesCalendarItem[][],
        calendarItem: IRatesCalendarItem,
        index: number,
      ) => {
        let week = groups[Math.floor(index / WEEK_SIZE)];
        if (!week) {
          groups.push([]);
          week = groups[Math.floor(index / WEEK_SIZE)];
        }
        week.push(calendarItem);
        return groups;
      },
      [],
    );
}

export const MONTH_NAME_FORMAT = 'MMM';
export const DAY_NAME_FORMAT = 'ddd';
export const DAY_NUMBER_FORMAT = 'DD';

export function getPercent(value: number, locale: string) {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
  };
  return value.toLocaleString(locale, options);
}

export function getSemaphoreColor(value: number, inverted: boolean = false) {
  const getColors = (inverted: boolean) =>
    inverted ? [BAD, GOOD] : [GOOD, BAD];

  if (value === 0) return ZERO;
  const [positiveColor, negativeColor] = getColors(inverted);
  return value > 0 ? negativeColor : positiveColor;
}

export function getCurrency(value: number, locale: string, currency: string) {
  const options: Intl.NumberFormatOptions = {
    currency,
    style: 'currency',
  };
  return value.toLocaleString(locale, options);
}
