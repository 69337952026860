import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
  WrappedComponentProps,
} from 'react-intl';

import { Loader } from 'components/Layout';
import {
  Box,
  Columns,
  Column,
  ColumnSizes,
  MediaObject,
  GapSizes,
} from 'components/Transparent';

import AvgOccupancyIcon from 'assets/img/occupancy.png';
import AvgRevenueIcon from 'assets/img/revenue.png';
import ListingsCountIcon from 'assets/img/listings.png';

import BellIcon from '../BellIcon';
import { abbreviateNumber, getCurrencySymbol } from 'utils';

import styles from './aggregated.module.scss';

interface AggregatedProps extends WrappedComponentProps {
  metrics: any[];
  currency: string;
  appliedQuery: string;
  fetching: boolean;
  isMenuOpen: boolean;
  error: Error;
  loadAggregated: () => void;
}

const Aggregate: React.FC<any> = ({ year, children }) => (
  <div>
    <p className={styles.valueTitle}>
      {year} <span className={styles.value}> {children}</span>
    </p>
  </div>
);

const AggregatedError: React.FC<any> = () => {
  return (
    <Box>
      <Columns className={styles.noStats} isVCentered={true}>
        <Column columnSize={ColumnSizes.Full}>
          <FormattedMessage id="performance.stats.no_stats" />
        </Column>
      </Columns>
    </Box>
  );
};

const InfoBox: React.FC<any> = () => (
  <Box className={styles.infoBox}>
    <Columns isVCentered={true} isMultiline={true}>
      <Column isNarrow={true}>
        <BellIcon height={30} />
      </Column>
      <Column className={styles.infoBoxTitle}>
        <FormattedMessage id="performance.stats.info_box.title" />
      </Column>
    </Columns>
    <div className={styles.infoBoxMessage}>
      <FormattedMessage id="performance.stats.info_box.message" />
    </div>
  </Box>
);

const AggregatedInfo: React.FC<any> = ({ metrics, currencySymbol }) => (
  <Box className={styles.boxContainer}>
    <Columns
      className={styles.aggregatedInfoContainer}
      isCentered={true}
      isVCentered={true}
      isMultiline={true}
      gapSize={GapSizes.Two}
    >
      <Column className={`${styles.borderRight} ${styles.card}`}>
        <MediaObject
          image={() => (
            <img alt="listings count icon" src={ListingsCountIcon} />
          )}
        >
          <div className={styles.info}>
            <div className={styles.title}>
              <FormattedMessage id="performance.stats.listings_count" />
            </div>

            {metrics.map(({ year, listingsCount }) => (
              <Aggregate year={year}>
                {abbreviateNumber(listingsCount)}
              </Aggregate>
            ))}
          </div>
        </MediaObject>
      </Column>
      <Column className={`${styles.borderRight} ${styles.card}`}>
        <MediaObject
          image={() => <img alt="average revenue icon" src={AvgRevenueIcon} />}
        >
          <div className={styles.info}>
            <div className={styles.title}>
              <FormattedMessage id="performance.stats.avg_revenue" />
            </div>
            {metrics.map(({ year, avgRevenue }) => (
              <Aggregate year={year}>
                {avgRevenue ? (
                  `${currencySymbol}${abbreviateNumber(avgRevenue)}`
                ) : (
                  <span>&nbsp;</span>
                )}
              </Aggregate>
            ))}
          </div>
        </MediaObject>
      </Column>
      <Column className={styles.card}>
        <MediaObject
          image={() => (
            <img alt="average revenue icon" src={AvgOccupancyIcon} />
          )}
        >
          <div className={styles.info}>
            <div className={styles.title}>
              <FormattedMessage id="performance.stats.avg_occupancy" />
            </div>

            {metrics.map(({ year, marketOccupancy }) => (
              <Aggregate year={year}>
                {marketOccupancy === null ? (
                  <span>&nbsp;</span>
                ) : (
                  <FormattedNumber
                    value={marketOccupancy}
                    // eslint-disable-next-line
                    style="percent"
                    maximumFractionDigits={1}
                  />
                )}
              </Aggregate>
            ))}
          </div>
        </MediaObject>
      </Column>
    </Columns>
  </Box>
);

const Aggregated: React.FC<AggregatedProps> = ({
  metrics,
  currency,
  appliedQuery,
  error,
  fetching,
  intl,
  isMenuOpen,
  loadAggregated,
}) => {
  React.useEffect(() => {
    loadAggregated();
  }, [appliedQuery, loadAggregated]);
  const currencySymbol = getCurrencySymbol(intl, currency);
  return fetching ? (
    <Loader />
  ) : (
    <Columns
      className={`${styles.container}${
        isMenuOpen ? ` ${styles.menuOpened}` : ''
      }`}
      isCentered={true}
      isMultiline={true}
      isVCentered={true}
    >
      <Column
        className={styles.infoBoxContainer}
        columnSize={ColumnSizes.OneQuarter}
      >
        <InfoBox />
      </Column>
      <Column>
        {error ? (
          <AggregatedError />
        ) : (
          <AggregatedInfo currencySymbol={currencySymbol} metrics={metrics} />
        )}
      </Column>
    </Columns>
  );
};

export default injectIntl(Aggregated);
