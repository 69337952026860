import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';

import { resetPassword } from '../../store/auth/actions';
import Messages from '../Layout/Messages';
import Display from '../../components/Account/Reset';
import { ApplicationState } from '../../store/index';
import { AuthActions } from '../../store/auth/types';
import Layout from '../Layout/Layout';
interface IParams {
  token: string;
}

interface IComponentState {
  confirm: string;
  password: string;
}

type ResetContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IParams>;

class Reset extends React.Component<ResetContainerProps, IComponentState> {
  state = { password: '', confirm: '' };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(({
      [event.target.name]: event.target.value,
    } as any) as IComponentState);
  };

  handleChangePassword = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.actions.resetPassword(
      this.state.password,
      this.state.confirm,
      this.props.match.params.token,
    );
  };

  render() {
    return (
      <Layout>
        <Messages messages={this.props.messages} />
        <Display
          {...this.state}
          handleChange={this.handleChange}
          handleChangePassword={this.handleChangePassword}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => state;

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, AuthActions>,
) {
  return {
    actions: bindActionCreators({ resetPassword }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
