import * as React from 'react';

const UpArrow = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path
      d="M81.2 167.1c-7 7.2-7 18.6 0 25.8 7.2 7 18.9 7 25.8 0L237.9 62v431.9c0 10.1 8 18.1 18.1 18.1 10.1 0 18.3-8 18.3-18.1V62L405 192.9c7.2 7 18.6 7 25.8 0 7-7.2 7-18.6 0-25.8L268.9 5.2c-7.2-7-18.6-7-25.8 0L81.2 167.1z"
      className="uparrow"
      fill="#1e201d"
    />
  </svg>
);

export default UpArrow;
