import * as React from 'react';
import { connect } from 'react-redux';
import { get, isNumber } from 'lodash/fp';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import Pagination from 'bulma-pagination-react';
import { FormattedDate } from 'react-intl';
import { recentReviewsDomain } from '../../store/types';
import { loadRecentReviews } from '../../store/actions';
import { getAllEntities } from 'store/entities/selectors';
import { clearEntities } from 'store/entities/actions';
import { Loader } from 'components/Layout';
import { ChartError } from '../../charts';
import { ReviewContainer } from 'components/Reviews';
import InfoContainer from 'components/InformationBox';

type RecentReviewsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { itemsPerPage: number };

function getNumberOfPages({ total, itemsPerPage }) {
  return Math.floor(total / itemsPerPage);
}

export const RecentReviewsLayout: React.SFC<RecentReviewsProps> = React.memo(
  ({
    loading,
    actions,
    appliedQuery,
    recentReviews,
    totalPages,
    itemsPerPage,
  }) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    React.useEffect(() => {
      if (appliedQuery) {
        actions.loadRecentReviews({
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        });
      }
      return () => {
        actions.clearEntities(recentReviewsDomain);
      };
    }, [actions, currentPage, appliedQuery, itemsPerPage]);

    const handlePageChange = page => {
      if (page > 0 && page <= totalPages) {
        setCurrentPage(page);
      }
    };

    if (loading) {
      return (
        <div className="box" style={{ paddingTop: 40, height: 700 }}>
          <Loader />;
        </div>
      );
    }
    if (Array.isArray(recentReviews) && !recentReviews.length)
      return (
        <ChartError>
          <h3 className="title is-5">
            <FormattedMessage id="reviews.recent_reviews.error" />
          </h3>
        </ChartError>
      );

    return (
      <div className="box px-5">
        <div className="columns is-centered">
          <div className="column is-full">
            <div className="is-flex is-justify-content-space-between px-4 my-3">
              <h5 className="title is-5">
                <FormattedMessage id="reviews.recent_reviews.title" />
              </h5>
              <InfoContainer>
                <FormattedMessage id="reviews.recent_reviews.info" />
              </InfoContainer>
            </div>
            {recentReviews.map(
              (
                { description, review_month, title, url, review_score },
                idx,
                array,
              ) => (
                <div key={idx}>
                  <ReviewContainer
                    title={title}
                    url={url}
                    reviewScore={
                      isNumber(review_score) ? review_score.toFixed(1) : '-'
                    }
                    date={
                      <FormattedDate
                        value={new Date(review_month)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    }
                    description={description}
                    maxLength={280}
                  />
                  {array.length !== idx + 1 && <div className="is-divider" />}
                </div>
              ),
            )}
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              className="mt-5 is-small"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  },
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loadRecentReviews, clearEntities }, dispatch),
});

const mapStateToProps = (state, { itemsPerPage }) => {
  const totalPages = getNumberOfPages({
    total: get([recentReviewsDomain, 'total'], state),
    itemsPerPage,
  });
  return {
    totalPages,
    recentReviews: getAllEntities(state, recentReviewsDomain),
    error: get([recentReviewsDomain, 'error'], state),
    loading: get([recentReviewsDomain, 'isLoading'], state),
    appliedQuery: get(['filters', 'query'], state),
  };
};

export const RecentReviews = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecentReviewsLayout);
