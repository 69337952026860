import * as React from 'react';

export enum GapSizes {
  Zero = 'is-0',
  One = 'is-1',
  Two = 'is-2',
  Three = 'is-3',
  Four = 'is-4',
  Five = 'is-5',
  Six = 'is-6',
  Seven = 'is-7',
  Eight = 'is-8',
  Nine = 'is-9',
}

export enum ColumnSizes {
  ThreeQuarters = 'is-three-quarters',
  TwoThirds = 'is-two-thirds',
  Half = 'is-half',
  OneThird = 'is-one-third',
  OneQuarter = 'is-one-quarter',
  Full = 'is-full',
  FourFifths = 'is-four-fifths',
  ThreeFifths = 'is-three-fifths',
  TwoFifths = 'is-two-fifths',
  OneFifth = 'is-one-fifth',
}

interface ColumnsProps extends React.HTMLProps<HTMLDivElement> {
  isGapless?: boolean;
  isMultiline?: boolean;
  isCentered?: boolean;
  isVCentered?: boolean;
  gapSize?: GapSizes;
}
export const Columns: React.SFC<ColumnsProps> = ({
  className,
  isGapless,
  isMultiline,
  isCentered,
  isVCentered,
  gapSize,
  ...props
}) => (
  <div
    className={`columns${className ? ` ${className}` : ''}${
      isGapless ? ' is-gapless' : ''
    }${isMultiline ? ' is-multiline' : ''}${
      gapSize ? ` is-variable ${gapSize}` : ''
    }${isVCentered ? ' is-vcentered' : ''}${isCentered ? ' is-centered' : ''}`}
    {...props}
  />
);

interface ColumnProps extends React.HTMLProps<HTMLDivElement> {
  isNarrow?: boolean;
  columnSize?: ColumnSizes;
}
export const Column: React.SFC<ColumnProps> = ({
  className,
  isNarrow,
  columnSize,
  ...props
}) => (
  <div
    className={`column${columnSize ? ` ${columnSize}` : ''}${
      className ? ` ${className}` : ''
    }${isNarrow ? ' is-narrow' : ''}`}
    {...props}
  />
);
