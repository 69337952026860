import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Loader } from 'components/Layout';
import { ApplicationState } from 'store';
import { getEntities } from 'store/entities/selectors';
import { getFilterQuery } from 'store/filters/selectors';
import { serializeSortKey } from 'store/pagination';

import Table from './Layout';
import {
  downloadCSV,
  loadSupplyFirstPage,
  loadSupplyPage,
  selector,
} from '../store/actions';
import { DISCOUNTS_DOMAIN } from '../types';

type DiscountsTableProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const DiscountsTable: React.FunctionComponent<DiscountsTableProps> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Loader /> : <Table {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        loadSupplyFirstPage,
        loadSupplyPage,
        downloadCSV,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const {
    supply: { currency },
    downloadingCSV,
  } = state[DISCOUNTS_DOMAIN];
  const appliedQuery = getFilterQuery(state);
  const isLoading = !appliedQuery;
  const { page, sortKey } = selector.getCurrentPage(state);
  const [orderBy, order] = serializeSortKey(sortKey);
  const numberOfPages = selector.getTotalPages(state);
  const cleanUrls = getEntities(
    state,
    DISCOUNTS_DOMAIN,
    selector.getPageIds(state, { page, sortKey }),
  );
  return {
    appliedQuery,
    currency,
    currentPage: page,
    downloadingCSV,
    error: state[DISCOUNTS_DOMAIN]?.errorSupply,
    fetching: state[DISCOUNTS_DOMAIN]?.fetchingSupply,
    isLoading,
    cleanUrls,
    numberOfPages,
    order,
    orderBy,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsTable);
