import React from 'react';
import { Chart } from '../../../components/Charts/AmCharts';
import ReportsLoader from './SelfReportsLoader';
import ErrorMessage from '../components/ErrorMessage';
import { useIntl } from 'react-intl';
import { ISelfReportChartProps } from '../lib/types';

const SelfReportChart: React.FunctionComponent<ISelfReportChartProps> = ({
  id,
  loading,
  error,
  config,
}) => {
  const intl = useIntl();

  if (loading) return <ReportsLoader />;
  if (error) return <>ERRORS</>;
  if (!config || !config.data || config.data.length === 0)
    return (
      <ErrorMessage
        errorMessage={intl.formatMessage({
          id: `No Data Available`,
          defaultMessage: `No Data Available`,
        })}
        icon="warning"
      />
    );
  return (
    <article className="report">
      <Chart
        chartType="XYChart"
        exportMenu={true}
        config={config}
        chartId={id}
      />
    </article>
  );
};

export default SelfReportChart;
