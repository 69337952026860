import * as React from 'react';

import {
  Grid,
  Paper,
  withStyles,
  StyledComponentProps,
  Typography,
} from '@material-ui/core';

import styles from './styles';
import { Chart } from 'containers/Charts';
import { Loader } from 'components/Layout';
import ListingsCount from 'containers/ListingsCount';
import { FormattedMessage } from 'react-intl';
export interface ChartsDisplayProps {
  delay?: boolean;
}

const ChartsDisplay: React.SFC<ChartsDisplayProps &
  StyledComponentProps> = function({ delay, classes }) {
  if (delay)
    return (
      <Grid container={true} spacing={2}>
        <Loader />
      </Grid>
    );

  return (
    <Grid container={true} spacing={2}>
      <ListingsCount xs={12} sm={6} lg={2} classes={classes} />

      <Grid item={true} xs={12} sm={6} lg={4}>
        <Paper className={classes.paper}>
          <Chart
            chartId="listingsByBedrooms"
            dataType="supply_stats"
            metric="count"
            select="COUNT_ALL"
            group="bedrooms"
            vizType="bar"
            options={{
              categoryField: 'bedrooms',
              categoryLabel: 'Bedrooms',
              title: 'Bedrooms',
            }}
            height={250}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12} sm={6} lg={4}>
        <Paper className={classes.paper}>
          <Chart
            chartId="listingsByMinStay"
            dataType="supply_stats"
            metric="count"
            select="COUNT_ALL"
            group="min_stay"
            vizType="pie"
            options={{
              categoryField: 'min_stay',
              categoryLabel: 'Min Stay',
              title: 'Min Stay',
            }}
            height={250}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            comparative={true}
            chartId="averageOccupancyPerNight"
            dataType="pricing_series"
            metric="avg"
            select="occupancy"
            vizType="bar_line"
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.avg_nightly_occupancy" />
              </Typography>
            }
            height={500}
            isPercentage={true}
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              valueLabel: 'Occupancy',
              title: 'Average Nightly Occupancy',
              barValueField: 'occupancy',
              lineValueField: 'myValue',
              barSeriesName: 'Occupancy',
              lineSeriesName: 'My Occupancy',
            }}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            comparative={true}
            chartId="averagePricePerNight"
            dataType="pricing_series"
            metric="avg"
            select="price"
            vizType="bar_line"
            formatCurrency={true}
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.avg_nightly_rate" />
              </Typography>
            }
            height={500}
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              valueLabel: 'Nightly Price',
              title: 'Average Nightly Rate',
              barValueField: 'price',
              lineValueField: 'myValue',
              barSeriesName: 'Nightly Price',
              lineSeriesName: 'My Nightly Price',
            }}
          />
        </Paper>
      </Grid>
      <Grid item={true} xs={12}>
        <Paper className={classes.paper}>
          <Chart
            chartId="listingsByPickup"
            dataType="pricing_series"
            metric="sum"
            select="pickup"
            vizType="bar"
            infoMessage={
              <Typography>
                <FormattedMessage id="charts.information.pickup_7_days" />
              </Typography>
            }
            options={{
              categoryField: 'key',
              categoryLabel: 'Date',
              title: 'Pickup 7 Days',
            }}
            height={500}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChartsDisplay);
