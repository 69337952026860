import * as React from 'react';
import { get, isEmpty } from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '../Layout';
import { ApplicationState } from '../../store';

export default ({ component: C, ...rest }) => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => !isEmpty(state.auth.token),
  );
  const isActive = useSelector(state =>
    get(state, ['auth', 'user', 'active'], false),
  );
  const isLoading = useSelector(state => get(state, ['layout', 'isLoading']));
  const internalRender = props => {
    if (isLoading) return <Loader />;
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }
    if (!isActive) {
      return <Redirect to="/inactive" />;
    }
    return <C {...props} />;
  };
  return <Route {...rest} render={internalRender} />;
};
