import * as React from 'react';
import { Tabs, makeStyles } from '@material-ui/core';
import { ConnectedTab as Tab } from 'components/Transparent';
import { RouteComponentProps, Route } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Rates from '../Rates';
import RatesByBedroom from '../RatesByBedroom';
import OverviewIcon from 'components/Icons/MarketOverview';
import ByBedroomIcon from 'components/Icons/ByBedroom';
import CountDown from 'components/CountDown';
import { ListingLevelIcon } from 'components/Icons/ListingLevel';
import { ListingLevel } from '../ListingLevel/';

type RatesProps = RouteComponentProps & WrappedComponentProps;

const useStyles = makeStyles({
  tabs: {
    marginBottom: '20px',
    marginLeft: '20px',
  },
  tab: {
    flexDirection: 'row',
    marginLeft: '20px',
    justifyContent: 'flex-start',
  },
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  icon: {
    marginRight: 20,
  },
});
const RatesAndDemandContainer: React.SFC<RatesProps> = function({
  history,
  intl,
  match,
}) {
  const classes = useStyles({});
  return (
    <div>
      <Tabs
        value={history.location.pathname}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        classes={{ root: classes.root }}
      >
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}`}
          label={intl.formatMessage({ id: 'navigation.rates.overview' })}
          value={`${match.url}`}
          icon={<OverviewIcon className={classes.icon} />}
        />
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}/by_bedrooms`}
          label={intl.formatMessage({ id: 'navigation.rates.by_bedrooms' })}
          value={`${match.url}/by_bedrooms`}
          icon={<ByBedroomIcon className={classes.icon} />}
        />
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}/listing_level`}
          label={intl.formatMessage({ id: 'navigation.rates.listing_level' })}
          value={`${match.url}/listing_level`}
          icon={<ListingLevelIcon className={classes.icon} />}
        />
        <CountDown />
      </Tabs>
      <Route exact={true} path={`${match.url}`} render={() => <Rates />} />
      <Route
        path={`${match.url}/by_bedrooms`}
        render={() => <RatesByBedroom />}
      />
      <Route
        path={`${match.url}/listing_level`}
        render={() => <ListingLevel />}
      />
    </div>
  );
};

export default injectIntl(RatesAndDemandContainer);
