import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  occupancy_0: {
    id: 'chart.messages.0DaysAgo',
    defaultMessage: '0 days ago',
  },
  occupancy_7: {
    id: 'chart.messages.7DaysAgo',
    defaultMessage: '7 days ago',
  },
  occupancy_14: {
    id: 'chart.messages.14DaysAgo',
    defaultMessage: '14 days ago',
  },
  occupancy_30: {
    id: 'chart.messages.30DaysAgo',
    defaultMessage: '30 days ago',
  },
  occupancy_60: {
    id: 'chart.messages.60DaysAgo',
    defaultMessage: '60 days ago',
  },
  num_recent_reserved_listing_nights_7: {
    id: 'chart.messages.7Since',
    defaultMessage: 'Since 7 days',
  },
  num_recent_reserved_listing_nights_14: {
    id: 'chart.messages.14Since',
    defaultMessage: 'Since 14 days',
  },
  num_recent_reserved_listing_nights_30: {
    id: 'chart.messages.30Since',
    defaultMessage: ' Since 30 days',
  },
  occupancy: {
    id: 'chart.messages.occupancy',
    defaultMessage: 'Occupancy',
  },
  date: {
    id: 'chart.messages.date',
    defaultMessage: 'Date',
  },
  reservations: {
    id: 'charts.messages.reservations',
    defaultMessage: 'Reservations',
  },
});
