import * as React from 'react';
import { get, isEmpty } from 'lodash';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '../Layout';
export default ({ component: C, ...rest }: RouteProps) => {
  const isAuthenticated = useSelector(
    state => !isEmpty(get(state, 'auth.token')),
  );
  const isActive = useSelector(state =>
    get(state, ['auth', 'user', 'active'], false),
  );
  const isLoading = useSelector(state => get(state, ['layout', 'isLoading']));
  const internalRender = props => {
    if (isLoading) return <Loader />;
    if (isAuthenticated && isActive) {
      return <Redirect to="/calendar" />;
    }
    return <C {...props} />;
  };
  return <Route {...rest} render={internalRender} />;
};
