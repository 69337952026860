import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StyledComponentProps,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface PMSConnectionErrorProps {
  error: Error;
}

const PMSConnectionError: React.SFC<PMSConnectionErrorProps &
  StyledComponentProps> = ({ classes, error }) => {
  return (
    <Typography variant="h4" className={classes.error}>
      <FormattedMessage id={error.message} />
    </Typography>
  );
};

export default withStyles(styles)(PMSConnectionError);
