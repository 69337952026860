import * as React from 'react';

import { Button } from '@material-ui/core';

const FiltersError: React.SFC<{ error: any; retryLoad: () => any }> = ({
  error,
  retryLoad,
}) => (
  <React.Fragment>
    <h1>{error.message}</h1>
    <Button color="secondary" variant="contained" onClick={() => retryLoad()}>
      Retry
    </Button>
  </React.Fragment>
);

export default FiltersError;
