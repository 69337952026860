import React from 'react';
import Popper from '@material-ui/core/Popper';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import InformationIcon from '../Icons/Information';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    textContainer: {
      minWidth: 200,
      maxWidth: 400,
      padding: 15,
      minHeight: 50,
      backgroundColor: 'rgb(217, 217, 217)',
      textAlign: 'justify',
    },
    container: {
      position: 'absolute',
      right: 60,
      top: -5,
      zIndex: 2,
    },
  }),
);

export default function InfoContainer({ children, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHover, setHover] = React.useState<boolean>(false);
  function handleEventChange(event: React.MouseEvent<HTMLElement>) {
    setHover(!isHover);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const classes = useStyles({});
  return (
    <div {...props}>
      <Fab
        aria-describedby={id}
        color="inherit"
        size="small"
        onMouseEnter={handleEventChange}
        onMouseLeave={handleEventChange}
        disableRipple={true}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          cursor: 'auto',
        }}
      >
        <InformationIcon isActive={isHover} />
      </Fab>
      <Popper
        id={id}
        placement={props.placement || 'left'}
        open={open}
        anchorEl={anchorEl}
        transition={true}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.textContainer}>{children}</Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
