import * as React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import EventContainer from './EventContainer';

interface EventProps extends DialogProps {
  handleClose: () => void;
  rigthContent: React.ReactElement;
  titleComponent: React.ReactElement;
}

const Event: React.SFC<EventProps> = ({
  handleClose,
  open,
  children,
  rigthContent,
  titleComponent,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {titleComponent}
      <EventContainer rightContent={rigthContent}>{children}</EventContainer>
    </Dialog>
  );
};
export default Event;
