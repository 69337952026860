import { applyMiddleware, createStore, Store, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import Cookies, { Cookie } from 'universal-cookie';
import promise from 'redux-promise';
import { createLogger } from 'redux-logger';
import getReducer, { ApplicationState } from '.';
import { routerMiddleware } from 'connected-react-router';
import { Api } from '../services/api';
import { addHistoryListener } from 'services/mixpanel';
export type ApplicationStore = Store<ApplicationState, AnyAction>;

const isProduction = process.env.NODE_ENV === 'production';

export const history =
  typeof window !== 'undefined'
    ? createBrowserHistory()
    : createMemoryHistory();

addHistoryListener(history);

export default function configureStore(
  initialState?: ApplicationState,
  cookies?: Cookie,
) {
  const logger = createLogger();
  const thunkWithArguments = thunk.withExtraArgument({
    api: new Api(),
    cookies: cookies || new Cookies(),
  });
  const composeEnhancers = composeWithDevTools({});

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      state = undefined;
    }
    const store = getReducer(history);
    return store(state, action);
  };

  const store = createStore(
    rootReducer,
    initialState,
    isProduction
      ? applyMiddleware(routerMiddleware(history), thunkWithArguments, promise)
      : composeEnhancers(
          applyMiddleware(
            routerMiddleware(history),
            thunkWithArguments,
            promise,
            logger,
          ),
        ),
  );
  return store;
}
