import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { styles } from './styles';
export interface SuppliesErrorProps {
  error: any;
}

const useStyles = makeStyles(styles);
export default (props: SuppliesErrorProps) => {
  const classes = useStyles({});
  return (
    <Typography variant="h4" className={classes.marginTop}>
      <FormattedMessage id={props.error.message} />
    </Typography>
  );
};
