import { default as React, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { BookedAdrChart } from './BookedAdrChart';
import { Loader } from 'components/Layout';
import InfoContainer from 'components/InformationBox';

interface BookedADRProps {
  data?: any;
  error?: any;
  fetching: boolean;
  loadBookedADR: () => void;
}

const BookedADR: React.FunctionComponent<BookedADRProps> = ({
  data,
  error,
  fetching,
  loadBookedADR,
}) => {
  useEffect(() => {
    if (!data && !error) loadBookedADR();
  }, [data, error, loadBookedADR]);

  if (fetching) return <Loader />;

  return (
    <div className="columns">
      <div className="column">
        <div className="box mx-4">
          <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
            <h3 className="is-5 title is flex is-justify-content-center">
              <FormattedMessage
                id="pacing.bookedAdr.title"
                defaultMessage="Booked ADR"
              />
            </h3>
            <InfoContainer>
              <FormattedMessage id="pacing.bookedAdr.info" />
            </InfoContainer>
          </div>
          <div className="px-4" style={{ height: 500 }}>
            <BookedAdrChart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookedADR;
