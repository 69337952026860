import * as React from 'react';

import { IFilterProps } from './types';
import { Select } from '../../Forms/';
import { connect } from 'react-redux';

const Filter: React.SFC<IFilterProps> = ({
  filterName,
  disabledFilters,
  ...props
}) => (
  <Select
    placeholder={filterName}
    disabled={disabledFilters.includes(filterName)}
    {...props}
  />
);

const mapStateToProps = state => ({
  disabledFilters: state?.filters?.disabled || [],
});

export default connect(mapStateToProps)(Filter);
