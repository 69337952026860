import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
  StyledComponentProps,
  Drawer,
  Divider,
  IconButton,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import Logo from '../Icons/Logo';
import styles from './styles';

export interface IDashboardProps extends StyledComponentProps {
  open: boolean;
  mainListItems?: React.ReactNode;
  handleDrawerClose: (event: React.FormEvent) => void;
  hasAside: boolean;
}

const Dashboard: React.FunctionComponent<IDashboardProps> = function({
  classes,
  open,
  children,
  mainListItems,
  handleDrawerClose,
  hasAside,
}) {
  return (
    <>
      {hasAside && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <div className={classes.asideLogo}>
              <Logo width={140} color={open ? 'blue' : 'white'} />
            </div>
            <IconButton onClick={handleDrawerClose}>
              <ArrowBack />
            </IconButton>
          </div>
          <Divider />
          {mainListItems}
        </Drawer>
      )}
      <div className={clsx(classes.content, !open && classes.contentHidden)}>
        {children}
      </div>
    </>
  );
};

export default withStyles(styles)(Dashboard);
