import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import messages from './messages/reducer';
import auth from './auth/reducer';
import rates from './rates/reducer';
import layout from './layout/reducer';
import priceVariations from './priceVariations/reducer';
import events from './events/reducer';
import { IAuthState, AuthActions } from './auth/types';
import { ContactActions } from './contact/types';
import { IRatesState, RatesActions } from './rates/types';
import { ILayoutState, ILayoutActions } from './layout/types';
import filters from './filters/reducer';
import { IFiltersState } from './filters/types';
import { Cookie } from 'universal-cookie';
import { Api } from '../services/api';
import { IClearMessages } from './messages/types';
import { charts, IChartsState, IChartsActions } from './charts';
import { ISuppliesState, ISuppliesActions } from 'routes/supply/types';
import { IPmStatsState, IPmStatsActions } from 'routes/ranking/types';
import { supplies } from '../routes/supply/store';
import {
  IPriceVariationsState,
  PriceVariationsActions,
} from './priceVariations/types';
import { IEventsState, EventActions } from './events/types';
import { pmStats } from '../routes/ranking/store';
import entities from './entities/reducer';
import baseReducer from './base-reducer';
import {
  listingsPricingDomain,
  listingsDetailsDomain,
  listingLevelPaginator,
  reducer as listingsDetailsReducer,
} from 'routes/RatesAndDemand/ListingLevel/store';
import {
  reviewScoreDomain,
  recentReviewsDomain,
  reviewsNegativeDomain,
  reviewsPositiveDomain,
} from 'routes/reviews/store/types';
import {
  IPagesState,
  ICurrentPageState,
  IMetaState,
} from 'store/pagination/types';
import { i18nReducer as i18n } from 'i18n/store/reducer';
import { PmGroupsState, PM_GROUPS_DOMAIN } from 'routes/settings/PMGroups';
import { pmGroupsSlice } from 'routes/settings/PMGroups/store/reducer';
import {
  PERFORMANCE_DOMAIN,
  paginator as performancePaginator,
  reducer as performanceReducer,
  PerformanceState,
} from 'routes/performance/';
import {
  DISCOUNTS_DOMAIN,
  paginator as discountsPaginator,
  reducer as discountsReducer,
  DiscountsState,
} from 'routes/discounts/';
import {
  PACING_DOMAIN,
  PacingState,
  reducer as pacingReducer,
} from 'routes/pacing';
import {
  EDIT_GROUP_DOMAIN,
  LISTING_SELECTION_DOMAIN,
} from 'routes/settings/PMGroups/store/types';
import {
  editGroupPaginator,
  listingSelectionPaginator,
} from 'routes/settings/PMGroups/store';
export interface ApplicationState {
  messages?: {};
  auth?: IAuthState;
  rates?: IRatesState;
  layout: ILayoutState;
  filters?: IFiltersState;
  charts?: IChartsState;
  supplies?: ISuppliesState;
  priceVariations?: IPriceVariationsState;
  events?: IEventsState;
  router: RouterState;
  pmStats?: IPmStatsState;
  pagination?: {
    [domain: string]: {
      pages: IPagesState;
      currentPage: ICurrentPageState;
      meta: IMetaState;
    };
  };
  [PM_GROUPS_DOMAIN]?: PmGroupsState;
  [PERFORMANCE_DOMAIN]?: PerformanceState;
  [DISCOUNTS_DOMAIN]?: DiscountsState;
  [PACING_DOMAIN]?: PacingState;
}
export interface ExtraArguments {
  cookies?: Cookie;
  api?: Api;
}
export type IAllActions =
  | AuthActions
  | ContactActions
  | RatesActions
  | ILayoutActions
  | IChartsActions
  | ISuppliesActions
  | IClearMessages
  | PriceVariationsActions
  | EventActions
  | IPmStatsActions;

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    filters,
    messages,
    auth,
    rates,
    layout,
    charts,
    supplies,
    priceVariations,
    events,
    pmStats,
    entities,
    i18n,
    pmDetails: baseReducer('pmDetails').reducer,
    [listingsDetailsDomain]: listingsDetailsReducer,
    [listingsPricingDomain]: baseReducer(listingsPricingDomain).reducer,
    [reviewScoreDomain]: baseReducer(reviewScoreDomain).reducer,
    [recentReviewsDomain]: baseReducer(recentReviewsDomain).reducer,
    [reviewsPositiveDomain]: baseReducer(reviewsPositiveDomain).reducer,
    [reviewsNegativeDomain]: baseReducer(reviewsNegativeDomain).reducer,
    pagination: combineReducers({
      [listingsDetailsDomain]: listingLevelPaginator.reducer,
      [PERFORMANCE_DOMAIN]: performancePaginator.reducer,
      [DISCOUNTS_DOMAIN]: discountsPaginator.reducer,
      [EDIT_GROUP_DOMAIN]: listingSelectionPaginator.reducer,
      [LISTING_SELECTION_DOMAIN]: editGroupPaginator.reducer,
    }),
    [PM_GROUPS_DOMAIN]: pmGroupsSlice.reducer,
    [PERFORMANCE_DOMAIN]: performanceReducer,
    [DISCOUNTS_DOMAIN]: discountsReducer,
    [PACING_DOMAIN]: pacingReducer,
  });
export default reducers;
