import * as React from 'react';

import {
  withStyles,
  Grid,
  Paper,
  StyledComponentProps,
  Typography,
  Divider,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ContentLine from './Item/ContentLine';
import styles from './styles';

interface LabelProps extends StyledComponentProps {
  labels: string[];
}

const Labels = withStyles(styles)((props: LabelProps) => {
  const { classes, labels } = props;
  return (
    <Grid item={true} xs={true} className={classes.calendarItemContainer}>
      <Paper className={classes.labelsItem}>
        <Grid
          container={true}
          direction="column"
          spacing={1}
          justify="space-between"
          alignItems="stretch"
          className={classes.calendarLabelsContainer}
        >
          {labels.map((tag, index) => (
            <React.Fragment key={tag}>
              <ContentLine>
                <Typography variant="body2">
                  <FormattedMessage id={tag} />
                </Typography>
              </ContentLine>
              {index < labels.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
});

export default Labels;
