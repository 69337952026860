import * as React from 'react';
import { useIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';

import { makeStyles, Tabs } from '@material-ui/core';

import CountDown from 'components/CountDown';
import { ConnectedTab as Tab } from 'components/Transparent';

import BookedADR from './BookedADR';
import Trends from './Trends';
import IndividualDate from './IndividualDate';

const useStyles = makeStyles({
  tabs: {
    marginBottom: '20px',
    marginLeft: '20px',
  },
  tab: {
    flexDirection: 'row',
    marginLeft: '20px',
    justifyContent: 'flex-start',
    minHeight: '64px',
  },
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  icon: {
    marginRight: 20,
  },
});

const Pacing: React.FunctionComponent<RouteComponentProps> = ({
  history,
  match,
}) => {
  const intl = useIntl();
  const classes = useStyles({});

  return (
    <div>
      <Tabs
        value={history.location.pathname}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        classes={{ root: classes.root }}
      >
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}`}
          label={intl.formatMessage({ id: 'navigation.pacing.trends' })}
          value={`${match.url}`}
        />
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}/individualdate`}
          label={intl.formatMessage({ id: 'navigation.pacing.datePicker' })}
          value={`${match.url}/individualdate`}
        />
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}/bookedadr`}
          label={intl.formatMessage({ id: 'navigation.pacing.bookedadr' })}
          value={`${match.url}/bookedadr`}
        />
        <CountDown />
      </Tabs>

      <Route exact={true} path={`${match.url}`} render={() => <Trends />} />
      <Route
        path={`${match.url}/individualdate`}
        render={() => <IndividualDate />}
      />
      <Route path={`${match.url}/bookedadr`} render={() => <BookedADR />} />
    </div>
  );
};

export default Pacing;
