import * as React from 'react';
import {
  withStyles,
  StyledComponentProps,
  createStyles,
} from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const styles = createStyles({
  large: {
    fontSize: 100,
  },
  st0: { fill: '#E4FAD3' },
  st1: { fill: '#F6F7FA' },
  st2: { fill: '#B3C1CE' },
  st3: { fill: '#E1E7ED' },
});
function CountPMIcon({
  fontSize,
  classes,
}: SvgIconProps & StyledComponentProps) {
  return (
    <SvgIcon
      id="count_by_pm"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 254.5 136.6"
      classes={{
        fontSizeLarge: classes.large,
      }}
      fontSize={fontSize}
    >
      <circle className="st0" cx="87.1" cy="56.1" r="17.4" />
      <path
        className={classes.st1}
        d="M237.4,95.1l-23.7-21.6l-23.7,21.6v30.7c0,2.4,1.9,4.3,4.3,4.3H233c2.4,0,4.3-1.9,4.3-4.3V95.1z"
      />
      <path
        className={classes.st1}
        d="M61.8,95.1L38.2,73.5L14.5,95.1v30.7c0,2.4,1.9,4.3,4.3,4.3h38.6c2.4,0,4.3-1.9,4.3-4.3V95.1z"
      />
      <rect x="119.5" y="99.7" className="st2" width="15.7" height="15.7" />
      <path
        className={classes.st2}
        d="M87.3,34c-12.3,0-22.3,10-22.3,22.3c0,12.3,10,22.3,22.3,22.3s22.3-10,22.3-22.3C109.6,43.9,99.6,34,87.3,34z M87.3,68c-6.5,0-11.8-5.3-11.8-11.8c0-6.5,5.3-11.8,11.8-11.8c6.5,0,11.8,5.3,11.8,11.8C99.1,62.7,93.8,68,87.3,68z"
      />
      <path
        className={classes.st2}
        d="M127.3,63.3l-40.8,37.2l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.2-6.4,14.2-14.2v-16.8	l2.9,2.7l7.1-7.7L127.3,63.3z M147.6,122.4c0,2.1-1.7,3.7-3.7,3.7h-33.2c-2.1,0-3.7-1.7-3.7-3.7V96l20.3-18.5L147.6,96V122.4z"
      />
      <path
        className={classes.st3}
        d="M213.7,63.3l-40.8,37.2l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.2-6.4,14.2-14.2v-16.8	l2.9,2.7l7.1-7.7L213.7,63.3z M234,122.4c0,2.1-1.7,3.7-3.7,3.7h-33.2c-2.1,0-3.7-1.7-3.7-3.7V96l20.3-18.5L234,96V122.4z"
      />
      <path
        className={classes.st3}
        d="M61.1,111.7v10.7c0,2.1-1.7,3.7-3.7,3.7H24.2c-2.1,0-3.7-1.7-3.7-3.7V96l18.3-16.7c-1.7-3.7-2.9-7.3-3.8-10.7	L0,100.5l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.1-6.3,14.2-14C68.3,119.5,64.7,115.8,61.1,111.7z"
      />
      <path
        className={classes.st2}
        d="M84.5,10.5c22.4,0,40.8,17.1,43,38.9L138,59c0.1-1.3,0.2-2.6,0.2-3.8v-1.5C138.1,24.1,114.1,0,84.5,0	S30.8,24.1,30.8,53.7v1.5c0,15,8.6,32.4,25.5,51.8c12.3,14.1,24.4,23.9,24.9,24.3l3.3,2.7v-13.6c-10.6-9.3-43.2-39.9-43.2-65.2v-1.5	C41.3,29.9,60.6,10.5,84.5,10.5z"
      />
    </SvgIcon>
  );
}

export default withStyles(styles)(CountPMIcon);
