import { keys, omit } from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import SeriesChart, {
  SeriesChartProps,
  SeriesChartOptions,
} from './SeriesChart';

export interface LineChartOptions extends SeriesChartOptions {
  bulletRadius: number;
  bulletStrokeWidth: number;
}
export interface LineChartProps extends SeriesChartProps {
  options: LineChartOptions;
}

export default class LineChart extends SeriesChart<LineChartProps> {
  updateSeries(chart: am4charts.XYChart) {
    const {
      data,
      options,
      currency,
      isPercentage,
      formatCurrency,
    } = this.props;
    const {
      categoryField,
      horizontal,
      bulletRadius = 4,
      bulletStrokeWidth = 2,
    } = options;

    chart.series.clear();

    const series = keys(omit(data[0], categoryField));
    if (series.length > 1) chart.legend = new am4charts.Legend();
    for (const valueField of series) {
      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = valueField;
      if (horizontal) {
        lineSeries.dataFields.categoryY = categoryField;
        lineSeries.dataFields.valueX = valueField;
      } else {
        lineSeries.dataFields.categoryX = categoryField;
        lineSeries.dataFields.valueY = valueField;
      }
      lineSeries.tensionY = 0.95;
      lineSeries.tensionX = 0.95;
      lineSeries.strokeWidth = 2;

      const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      const yAxis = lineSeries.yAxis;
      bullet.circle.tooltipText = `[bold]{categoryX}[/]
                                    {valueY}`;
      if (isPercentage) {
        yAxis.numberFormatter.numberFormat = `#.#'%'`;
        const numberFormatter = (lineSeries.xAxis.numberFormatter = new am4core.NumberFormatter());
        numberFormatter.numberFormat = `#.#'%'`;
      }
      if (formatCurrency) {
        yAxis.numberFormatter.numberFormat = `#. ' ${currency}'`;
      }
      bullet.circle.strokeWidth = bulletStrokeWidth;
      bullet.circle.radius = bulletRadius;
      bullet.circle.fill = am4core.color('#fff');

      const bullethover = bullet.states.create('hover');
      bullethover.properties.scale = 1.3;
    }
  }
}
