import { get } from 'lodash/fp';
import { ApplicationState } from 'store';
import { ICurrentPageState } from './types';

const isNotEmpty = array => Array.isArray(array) && !!array.length;

export const createSelector = (domain: string) => {
  const getCurrentPage = (state: ApplicationState): ICurrentPageState =>
    get(['pagination', domain, 'currentPage'], state);

  const getPageIds = (
    state: ApplicationState,
    { sortKey, page },
  ): string[] | [] =>
    get(['pagination', domain, 'pages', sortKey, page, 'ids'], state);

  const isPageCached = (state, page, sortKey) =>
    isNotEmpty(getPageIds(state, { page, sortKey }));

  const getCurrentPaginationParams = state =>
    get(['pagination', domain, 'meta'], state);

  const getTotalPages = state => {
    const { total, limit } = getCurrentPaginationParams(state);
    return Math.ceil(total / limit);
  };

  const getPaginationParams = (page: number, paginationParams) => {
    const { limit } = paginationParams;
    const offset = (page - 1) * limit;
    const { order, orderBy } = paginationParams;
    return {
      offset,
      limit,
      order: order,
      orderBy: orderBy,
    };
  };

  return {
    getCurrentPage,
    getPageIds,
    getTotalPages,
    getPaginationParams,
    isPageCached,
    getCurrentPaginationParams,
  };
};
