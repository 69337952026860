import * as React from 'react';
import NotificationIcon from '../Icons/Notification';
import { FormattedMessage } from 'react-intl';
function Notification() {
  return (
    <article className="message is-warning">
      <div className="message-body">
        <NotificationIcon className="icon-notification" fontSize="large" />
        <p>
          <strong>
            <FormattedMessage id="onboarding.notification.title" />
          </strong>
        </p>
        <p style={{ fontSize: '15px' }}>
          <FormattedMessage id="onboarding.notification.content" />
        </p>
      </div>
    </article>
  );
}

export default Notification;
