import * as React from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  Typography,
  Button,
  withStyles,
  StyledComponentProps,
  Paper,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form } from '../Forms';
import styles from './styles';

interface ForgotProps {
  email: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleForgot: (event: React.FormEvent) => void;
}

const Forgot: React.SFC<ForgotProps & StyledComponentProps> = ({
  email,
  handleChange,
  handleForgot,
  classes,
}) => {
  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          <FormattedMessage id="account.reset_password_message" />
        </Typography>

        <Form onSubmit={handleForgot}>
          <FormControl margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="email">
              <FormattedMessage id="account.email_address" />
            </InputLabel>
            <Input
              name="email"
              type="email"
              autoComplete="email"
              autoFocus={true}
              value={email}
              onChange={handleChange}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            <FormattedMessage id="account.reset_password" />
          </Button>
        </Form>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(Forgot);
