import React from 'react';
import SelfReportingPacing from '../pacing';
import './PacingLayout.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { PacingReports } from '../lib/consts';

const PacingLayout: React.FunctionComponent = () => {
  const isAsideOpen = useSelector<ApplicationState, boolean>(
    state => state.layout.isOpen,
  );
  return (
    <div className={`layout ${isAsideOpen ? 'layout-margin' : ''}`}>
      {PacingReports.map(report => (
        <SelfReportingPacing key={report.id} report={report} />
      ))}
    </div>
  );
};

export default PacingLayout;
