import React from 'react';
import './SelfReportsSection.scss';
import { ISelfReportsSectionProps } from '../lib/types';

const SelfReportsSection: React.FunctionComponent<ISelfReportsSectionProps> = ({
  title,
  fullwidthReport,
  children,
}) => {
  return (
    <div className="reports-container">
      <div>
        <header>
          <h2 className="reports-title">{title}</h2>
        </header>
        <section
          className={`reports-section ${fullwidthReport ? 'fullwidth' : ''}`}
        >
          {children}
        </section>
      </div>
    </div>
  );
};

export default SelfReportsSection;
