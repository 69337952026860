import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import Layout from './layout';
import { loadIndividualDate } from '../store/actions';

type IndividualDateProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const IndividualDate: React.FunctionComponent<IndividualDateProps> = props => (
  <Layout {...props} />
);

const mapStateToProps = (state: ApplicationState) => {
  const { individualDate: data, error, fetchingIndividualDate: fetching } =
    state.pacing || {};

  return {
    data,
    error,
    fetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadIndividualDate }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualDate);
