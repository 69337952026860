import * as React from 'react';
import { StatusPattern, getOnboardingIconColors } from './Utils';
import { IOnboardingIconProps } from './types';

const OnboardingCallIcon = ({ active, ...props }: IOnboardingIconProps) => {
  const [primary, secondary] = getOnboardingIconColors(active);
  return (
    <svg id="call__1" viewBox="0 0 174.9 174.9" {...props}>
      <circle cx={87.4} cy={87.1} r={87.4} fill="#aad0f5" opacity={0.1} />
      <g opacity={0.5}>
        <linearGradient
          id="call__SVG_1"
          x1={87.5}
          x2={87.5}
          y1={44.89}
          y2={132.69}
          gradientTransform="matrix(1 0 0 -1 0 175.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#call__SVG_1)"
          d="M80.4 91.5c0 2.5-2.1 4.6-4.6 4.6s-4.6-2.1-4.6-4.6 2.1-4.6 4.6-4.6c2.6 0 4.6 2.1 4.6 4.6zm18.7-4.6c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6c-.1-2.5-2.1-4.6-4.6-4.6zm33.1 2.1v8.7c0 4.4-3.6 7.9-7.9 7.9h-5.8c-5.7 15.8-20.3 25.4-31 25.4s-25.3-9.6-31-25.4h-5.8c-4.4 0-7.9-3.6-7.9-7.9V89c0-3 1.7-5.6 4.2-7 .1-12.4 3.7-22.1 10.7-28.8 7-6.6 17-10 29.8-10s22.8 3.4 29.8 10C124.4 59.9 128 69.6 128 82c2.5 1.3 4.2 4 4.2 7zm-16.8 2.6c0-2-.1-4-.3-5.8-3.5-3.2-9.9-5.7-18-6.6 1.1 1.2 2 2.8 2.5 4.8-4.9-3.9-14.6-3-22-11.7-.4-.5-.8-1-1.1-1.4-1.3-1.8-1.8-2.8-1.8-1.7-.2 10-7.3 17.8-15.2 18.9 0 1.1-.1 2.2-.1 3.4 0 4.2.7 8.1 1.9 11.6 4.4 5.3 11.7 6.8 18.2 7.2 1.2-1.9 3.9-3.2 7-3.2 4.3 0 7.7 2.4 7.7 5.5 0 3-3.4 5.5-7.7 5.5-3.3 0-6.1-1.5-7.2-3.5-4.5-.3-9.5-1-13.9-3.1 6.2 9.2 15.9 14 21.9 14 9.6 0 28.1-12 28.1-33.9zm7.8-10.6c-.5-21.6-12.8-33-35.8-33S52.2 59.4 51.7 81h3.1c1.1-7.3 3.5-13.3 7.1-17.9C67.6 55.8 76.2 52 87.6 52s20 3.7 25.7 11.1c3.6 4.6 5.9 10.6 7.1 17.9h2.8z"
        />
      </g>
      <StatusPattern done={active} />
    </svg>
  );
};

export default OnboardingCallIcon;
