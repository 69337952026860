import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { bindActionCreators } from 'redux';
import {
  addListingSelection,
  removeListingSelection,
  fetchGroups,
  createListingsSelectionGroup,
  removeAllListingSelection,
} from '../store';
import ListingsTable from 'routes/settings/Components/ListingsTable';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import './layout.scss';
import {
  AddToGroupModal,
  CreateGroupModal,
} from 'routes/settings/Components/Modal/Modal';
import { FormattedMessage } from 'react-intl';
import { Status } from '../lib/enums';
import { apply } from 'store/actions';
import { setCurrentListingsDomain, resetStatus } from '../store/actions';
import {
  StartLoadListings,
  SuccessLoadListings,
  FailLoadListings,
} from 'routes/settings/PMGroups/store';
import { LISTING_SELECTION_DOMAIN } from '../store/types';

function ListingSelectionTable({
  removeAllListingSelection,
  listingsSelected,
  createGroup,
  groups,
  added,
  status,
  fetchGroups,
  resetStatus,
}: any) {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openAddToGroupModal, setOpenAddToGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleDeselectAll = () => removeAllListingSelection();

  const handleCreateGroup = async (group: {
    groupName: string;
    unifiedIds: string[];
  }) => {
    await createGroup(group);
  };

  const handleCloseAddToGroupModal = () => {
    setOpenAddToGroupModal(false);
    handleDeselectAll();
    status === Status.UPDATED && handleDeselectAll();
    resetStatus();
  };

  const handleCloseCreateGroupModal = async () => {
    setOpenCreateModal(false);
    status === Status.UPDATED && handleDeselectAll();
    fetchGroups();
    resetStatus();
  };

  const createOrUpdateGroup = (name: string) => {
    if (name.length === 0 || listingsSelected.length === 0) return null;
    const group = {
      groupName: name,
      unifiedIds: listingsSelected,
    };
    handleCreateGroup(group);
  };

  return (
    <div className="table-container">
      <CreateGroupModal
        open={openCreateModal}
        handleClose={handleCloseCreateGroupModal}
        createOrUpdateGroup={createOrUpdateGroup}
        status={status}
        added={added}
      />
      <AddToGroupModal
        createOrUpdateGroup={createOrUpdateGroup}
        open={openAddToGroupModal}
        handleClose={handleCloseAddToGroupModal}
        listingsSelected={listingsSelected}
        groupName={selectedGroup}
        status={status}
        added={added}
      />
      <section style={{ marginBottom: 50, marginTop: 50 }}>
        <span
          style={{
            display: 'inline-block',
            paddingRight: 23,
            fontSize: 16,
            color: '#505d6e',
          }}
        >
          <FormattedMessage
            id="settings.pm_groups.listings_selection.listings_selected"
            defaultMessage="{listingsCount} Listings Selected"
            values={{ listingsCount: listingsSelected.length }}
          />
        </span>
        <Button
          variant="outlined"
          className="create-modal-button"
          onClick={() => setOpenCreateModal(true)}
          disabled={listingsSelected.length === 0}
        >
          <AddCircleOutline style={{ paddingRight: 10 }} />
          <span style={{ textTransform: 'capitalize' }}>
            <FormattedMessage
              id="settings.pm_groups.listings_selection.create_new_group"
              defaultMessage="Create New Group"
            />
          </span>
        </Button>

        {status !== Status.IS_LOADING ? (
          <FormControl
            style={{ display: 'inline-block', marginLeft: 20, width: 190 }}
          >
            <InputLabel className="select-group-label">
              <FormattedMessage
                id="settings.pm_groups.listings_selection.add_listings_to"
                defaultMessage="Add Listings To"
              />
            </InputLabel>
            <Select
              className="select-group"
              variant="outlined"
              value={null}
              disabled={listingsSelected.length === 0}
            >
              {groups.map(group => (
                <MenuItem
                  key={group.groupName}
                  onClick={() => {
                    setSelectedGroup(group.groupName);
                    setOpenAddToGroupModal(true);
                  }}
                >
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </section>

      <ListingsTable
        startLoadListings={StartLoadListings}
        successLoadListings={SuccessLoadListings}
        failLoadListings={FailLoadListings}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    status: get('listingsDetails.isLoading', state),
    error: get('listingsDetails.error', state),
    listingsSelected: state?.pmGroups?.listingsSelected || [],
    ...state.pmGroups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        addListingSelection,
        removeListingSelection,
        createGroup: createListingsSelectionGroup,
        fetchGroups,
        removeAllListingSelection,
        apply,
        setCurrentListingsDomain,
        resetStatus,
      },
      dispatch,
    ),
  };
};

const ListingSelectionLayout: React.SFC<any> = ({
  error,
  actions,
  history,
  ...props
}) => {
  const { fetchGroups, setCurrentListingsDomain } = actions;

  useEffect(() => {
    if (props.currentListingsDomain !== LISTING_SELECTION_DOMAIN)
      setCurrentListingsDomain(LISTING_SELECTION_DOMAIN);
  });

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  props = {
    ...props,
    ...actions,
  };

  return <ListingSelectionTable {...props} />;
};

const ListingSelection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingSelectionLayout);
export default ListingSelection;
