import 'assets/scss/app.scss';

import * as React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { I18nConnectedProvider } from 'i18n';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';
import { App } from './routes';
import configureStore, { history } from './store/configureStore';
import { getInitialAuthState } from './store/auth/actions';
import * as serviceWorker from './serviceWorker';
import * as am4core from '@amcharts/amcharts4/core';

am4core.addLicense('CH308974366');

const tagManagerArgs = {
  gtmId: 'GTM-TWGVTW3',
  dataLayer: {
    js: new Date(),
    config: 'UA-137799199-1',
  },
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();
store.dispatch(getInitialAuthState() as any);
const Main: React.FC = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nConnectedProvider>
        <App />
      </I18nConnectedProvider>
    </ConnectedRouter>
  </Provider>
);

const theme = () => {
  const palette = createPalette({
    primary: { main: '#02315e' },
    secondary: { main: '#08d8cb' },
  });
  const typography = createTypography(palette, {
    fontFamily: '"Lato"',
  });
  return createMuiTheme({
    typography,
    palette,
  });
};

// Create a new class name generator.
render(
  <MuiThemeProvider theme={theme()}>
    <Main />
  </MuiThemeProvider>,
  document.getElementById('app'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
