import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from 'store';

import Layout from './layout';
import { loadBookedADR } from '../store/actions';

type BookedADRProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BookedADR: React.FunctionComponent<BookedADRProps> = props => (
  <Layout {...props} />
);

const mapStateToProps = (state: ApplicationState) => {
  const {
    bookedADR: data,
    bookedADRCurrency: currency,
    error,
    fetchingBookedADR: fetching,
  } = state.pacing || {};

  return {
    data,
    currency,
    error,
    fetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadBookedADR }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookedADR);
