import * as React from 'react';
import { sortBy, reverse, identity, toFinite } from 'lodash/fp';

export function usePagination<T>(
  elements: T[],
  elementsPerPage: number,
): [number, React.Dispatch<number>, T[], number] {
  const [currentPage, setCurrentPage] = React.useState(1);
  const currentElements = elements.slice(
    (currentPage - 1) * elementsPerPage,
    currentPage * elementsPerPage,
  );
  return [
    currentPage,
    setCurrentPage,
    currentElements,
    Math.ceil(elements.length / elementsPerPage),
  ];
}

export function useSortArrayByKey<T>(
  array: T[],
): [T[], (key: string, isAsc?: boolean) => void] {
  const shouldAsc = (isAsc: boolean) => (isAsc ? identity : reverse);
  const stringOrFinite = (val: string | number) =>
    typeof val === 'string' ? val : toFinite(val);
  const [sortedArray, setSortedArray] = React.useState(array);
  const sortByKey = (key: string, isAsc?: boolean) => {
    setSortedArray(
      shouldAsc(isAsc)(
        sortBy<T>(o => stringOrFinite(o[key]))(array),
      ),
    );
  };
  return [sortedArray, sortByKey];
}
