import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { normalize } from 'normalizr';
import { get, isEqual } from 'lodash/fp';
import { parse } from 'qs';

import { PriceVariationsSchema } from './schema';
import { ExtraArguments, ApplicationState } from '../';
import {
  PriceVariationsActions,
  START_LOADING_PRICE_VARIATIONS,
  SET_PRICE_VARIATIONS,
  LOAD_PRICE_VARIATIONS_ERROR,
  SET_PRICE_VARIATIONS_QUERY,
} from './types';
export const loadPriceVariations: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  PriceVariationsActions
>> = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const state = getState();
      const { token } = state.auth;
      const { query } = state.filters;
      const appliedQuery = state.priceVariations.query;
      if (isEqual(query, appliedQuery)) return null;
      dispatch({ type: START_LOADING_PRICE_VARIATIONS });
      const response = await api.getPriceVariations({
        token,
        query: parse(query),
      });
      const { entities, result } = normalize(response, PriceVariationsSchema);
      if (get('status', result) !== 'SUCCESS')
        throw new Error('GetPriceVariationsError');
      dispatch({ type: SET_PRICE_VARIATIONS_QUERY, payload: { query } });
      dispatch({
        type: SET_PRICE_VARIATIONS,
        payload: {
          calendar: entities.calendar,
        },
      });
    } catch (e) {
      dispatch({ type: LOAD_PRICE_VARIATIONS_ERROR, payload: { error: e } });
    }
  };
};
