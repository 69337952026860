import React from 'react';
import { withStyles, Fab, createStyles } from '@material-ui/core';
import CalendarIcon from '../Icons/CalendarWithCheck';

const styles = createStyles({
  eventButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: '24px',
    borderRadius: 0,
    position: 'absolute',
    transform: 'translateY(-8px)',
  },
});

function Toggle({ onClick, classes }) {
  return (
    <>
      <Fab onClick={onClick} size="small" className={classes.eventButton}>
        <CalendarIcon onClick={onClick} />
      </Fab>
    </>
  );
}

export default withStyles(styles)(Toggle);
