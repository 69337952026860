import * as React from 'react';

import {
  Typography,
  withStyles,
  StyledComponentProps,
  createStyles,
  Divider,
} from '@material-ui/core';

interface IProps {
  count: number;
  label: React.ReactNode | string;
  title: React.ReactNode | string;
  icon: React.ReactNode;
}

export type ListingsCountProps = IProps & StyledComponentProps;

const styles = createStyles({
  container: {
    height: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#505d6f',
  },
  iconContainer: {
    fontSize: 100,
    margin: '0 auto',
    height: 120,
  },
  title: {
    fontSize: '1.7em',
    fontWeight: 700,
    marginTop: 25,
    overflow: 'hidden',
  },
  subtitle: {
    padding: '8px 0',
    fontSize: 16,
    overflow: 'hidden',
  },
  divider: {
    margin: '5px 25px',
  },
});
const ListingsCount: React.SFC<ListingsCountProps> = ({
  count,
  label,
  classes,
  icon,
  title,
}) => (
  <div className={classes.container}>
    <div className={classes.iconContainer}>{icon}</div>
    <Divider className={classes.divider} />
    <Typography component="h5" variant="h4" className={classes.subtitle}>
      {title}
    </Typography>
    <Divider className={classes.divider} />
    <Typography component="h2" variant="h1" className={classes.title}>
      {count}
    </Typography>
    <Typography component="h5" variant="h4" className={classes.subtitle}>
      {label}
    </Typography>
  </div>
);

export default withStyles(styles)(ListingsCount);
