import * as React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  source: string;
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 30,
      padding: 20,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    closeButton: {
      position: 'absolute',
      right: 20,
      top: 13,
      color: theme.palette.grey[500],
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontSize: 26,
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, source } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classes.root}>
      <Link href={source} className={classes.title} target="_blank">
        {children}
      </Link>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
