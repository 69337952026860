import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash/fp';

import InfoContainer from 'components/InformationBox';
import { Loader } from 'components/Layout';
import { getEntities } from 'store/entities/selectors';
import { getFilterQuery, getAppliedDates } from 'store/filters/selectors';
import { serializeSortKey } from 'store/pagination';

import { ExportButton } from '../layout/DownloadButton';
import { listingsDetailsDomain } from '../store/types';
import { CustomPagination } from './Pagination';
import { TableLayout } from './Table';
import { Status as ListingsStatus } from 'store/listings/types';
import { loadFirstPage, goToPage } from 'store/listings/actions';
import { bindActionCreators } from 'redux';
import {
  selector,
  failLoadListings,
  startLoadListings,
  successLoadListings,
  listingLevelPaginator,
} from '../store/actions';

const moment = MomentRange.extendMoment(Moment);

const InfoBox = () => (
  <FormattedMessage
    id="listing_level.info_box"
    values={{ n: () => <div style={{ marginTop: '10px' }} /> }}
  />
);

function ListingLevelTable({
  listingsDetails,
  isPricingLoading,
  pricingDates,
  listingsPricing,
  currency,
  numberOfPages,
  currentPage,
  goToPage,
  order,
  orderBy,
  downloadCSV,
  downloading,
}: any) {
  const ELEMENTS_PER_PAGE = 15;

  const goTo = (i: number) => {
    goToPage(
      {
        order,
        orderBy,
        limit: ELEMENTS_PER_PAGE,
      },
      i,
      startLoadListings,
      successLoadListings,
      failLoadListings,
      listingLevelPaginator,
      listingsDetailsDomain,
    );
  };
  const isAsc = order === 'ASC';
  const sortedBy = orderBy;
  const setSortBy = (key: string) => () => {
    goToPage(
      {
        orderBy: key,
        order: isAsc ? 'DESC' : 'ASC',
        limit: ELEMENTS_PER_PAGE,
      },
      1,
      startLoadListings,
      successLoadListings,
      failLoadListings,
      listingLevelPaginator,
      listingsDetailsDomain,
    );
  };

  const isSortedBy = (key: string) => sortedBy === key;

  return (
    <div style={{ margin: 16 }}>
      <div className="table-card space-top">
        <div className="icons-right">
          <ul className="aligned-list">
            <li>
              <InfoContainer placement="left-start">
                <InfoBox />
              </InfoContainer>
            </li>
            <li>
              <ExportButton
                downloadCSV={downloadCSV}
                downloading={downloading}
              />
            </li>
          </ul>
        </div>
        <div className="table-space">
          <div className="table-container">
            <TableLayout
              isPricingLoading={isPricingLoading}
              isSortedBy={isSortedBy}
              isAsc={isAsc}
              setSortBy={setSortBy}
              elementsPerPage={ELEMENTS_PER_PAGE}
              currentPage={currentPage}
              currency={currency}
              pricingDates={pricingDates}
              listingsDetails={listingsDetails}
              listingsPricing={listingsPricing}
            />
          </div>
          <CustomPagination
            currentPage={currentPage}
            pages={numberOfPages}
            onChange={goTo}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { actions }) => {
  const { page, sortKey } = selector.getCurrentPage(state);
  const numberOfPages = selector.getTotalPages(state);
  const [orderBy, order] = serializeSortKey(sortKey);
  const listingsPricing = getEntities(
    state,
    'listingsPricing',
    selector.getPageIds(state, { page, sortKey }),
  );
  const [star, end] = getAppliedDates(state).map<Moment.Moment>(d => moment(d));
  const range = moment.range(star, end);
  return {
    listingsPricing,
    listingsDetails: getEntities(
      state,
      'listingsDetails',
      selector.getPageIds(state, { page, sortKey }),
    ),
    pricingDates: Array.from(range.by('day'), d => d.format('YYYY-MM-DD')),
    currency: get('listingsPricing.currency', state),
    isPricingLoading: get('listingsPricing.isLoading', state),
    error: get('listingDetails.error', state),
    appliedQuery: getFilterQuery(state),
    numberOfPages,
    currentPage: page,
    orderBy,
    order,
    downloadCSV: actions.downloadCSV,
    downloading: state[listingsDetailsDomain]?.downloading,
    listingsStatus: state?.listingsDetails?.listingsStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        goToPage,
        loadFirstPage,
      },
      dispatch,
    ),
  };
};

const ListingLevelLayout: React.SFC<any> = ({
  error,
  listingsStatus,
  actions,
  ...props
}) => {
  const { loadFirstPage } = actions;

  React.useEffect(() => {
    loadFirstPage(
      startLoadListings,
      successLoadListings,
      failLoadListings,
      listingLevelPaginator,
      listingsDetailsDomain,
    );
  }, [props.appliedQuery, loadFirstPage]);
  return listingsStatus === ListingsStatus.RUNNING ? (
    <Loader />
  ) : (
    !isEmpty(props.listingsDetails) && (
      <ListingLevelTable {...{ ...props, ...actions }} />
    )
  );
};

export const ListingLevelView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingLevelLayout);
