import React from 'react';

const ExternalIcon = props => (
  <svg id="externalIcon__Layer_1" viewBox="0 0 512 512" {...props}>
    <style>{'.externalIcon__st0{fill:#afbdcb}'}</style>
    <path
      className="externalIcon__st0"
      d="M412.9 261.5c-11.4 0-20.7 9.3-20.7 20.7V439c0 17.4-14.2 31.6-31.6 31.6H73c-17.4 0-31.6-14.2-31.6-31.6V151.4c0-17.4 14.2-31.6 31.6-31.6h156.9c11.4 0 20.7-9.3 20.7-20.7s-9.3-20.7-20.7-20.7H73c-40.2 0-73 32.7-73 73V439c0 40.2 32.7 73 73 73h287.6c40.2 0 73-32.7 73-73V282.1c0-11.4-9.3-20.6-20.7-20.6zM491.3 0H334.4c-11.4 0-20.7 9.3-20.7 20.7s9.3 20.7 20.7 20.7h136.2v136.2c0 11.4 9.3 20.7 20.7 20.7S512 189 512 177.6V20.7C512 9.3 502.7 0 491.3 0z"
    />
    <path
      className="externalIcon__st0"
      d="M505.9 6.1c-8.1-8.1-21.2-8.1-29.2 0L189.1 293.7c-8.1 8.1-8.1 21.2 0 29.2 4 4 9.3 6.1 14.6 6.1 5.3 0 10.6-2 14.6-6.1L505.9 35.3c8.1-8.1 8.1-21.2 0-29.2z"
    />
  </svg>
);

export default ExternalIcon;
