import React from 'react';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import SelfReportingKPIs from '../kpis';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import '../styles/vars.scss';
import './MetricsLayout.scss';
import MetricsReports from '../metrics/MetricsReports';
import { /* metricsByGroupList */ metricsByYearList } from '../lib/consts';

const useStyles = makeStyles({
  tabs: {
    marginBottom: '20px',
  },
  tab: {
    flexDirection: 'row',
    marginLeft: '20px',
    justifyContent: 'flex-start',
    minHeight: '64px',
  },
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  icon: {
    marginRight: 20,
  },
});

const MetricsLayout: React.FunctionComponent = () => {
  const [tabIdx, setTabIdx] = useState<number>(0);
  const isAsideOpen = useSelector<ApplicationState, boolean>(
    state => state.layout.isOpen,
  );
  const [isOverview] = useState<boolean>(false);

  const intl = useIntl();

  const classes = useStyles({});

  const handleTabChange: (e: React.ChangeEvent, idx: number) => void = (
    e: React.ChangeEvent,
    idx: number,
  ) => setTabIdx(idx);

  const isActive = (idx: number) => tabIdx === idx;

  const metricsByYearProps = {
    title: intl.formatMessage({
      id: 'selfreporting.title.metricsYear',
      defaultMessage: 'Year on Year Comparison',
    }),
    reportsList: metricsByYearList,
  };

  return (
    <div className={`layout ${isAsideOpen ? 'layout-margin' : ''}`}>
      {isOverview ? (
        <>
          <Tabs
            value={tabIdx}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            classes={{ root: classes.root }}
            onChange={handleTabChange}
          >
            <Tab
              classes={{ wrapper: classes.tab }}
              label={intl.formatMessage({
                id: 'navigation.self-reporting.performance',
              })}
              value={0}
            />
          </Tabs>
          <SelfReportingKPIs isActive={isActive(0)} />
        </>
      ) : (
        <>
          <MetricsReports {...metricsByYearProps} />
        </>
      )}
    </div>
  );
};

export default MetricsLayout;
