import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Loader } from 'components/Layout';
import { ApplicationState } from 'store';
import { getFilterQuery } from 'store/filters/selectors';
import { getAllEntities } from 'store/entities/selectors';

import { loadAggregated } from '../store/actions';
import { AGGREGATED_PERFORMANCE_DOMAIN, PERFORMANCE_DOMAIN } from '../types';
import AggregatedLayout from './Layout';

type AggregatedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const Aggregated: React.SFC<AggregatedProps> = ({ isLoading, ...props }) => {
  return isLoading ? <Loader /> : <AggregatedLayout {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadAggregated }, dispatch),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const { isOpen: isMenuOpen } = state.layout;
  const appliedQuery = getFilterQuery(state);
  const isLoading = !appliedQuery;
  const metrics = getAllEntities(state, AGGREGATED_PERFORMANCE_DOMAIN);
  const { currency, errorAggregated, fetchingAggregated } = state[
    PERFORMANCE_DOMAIN
  ];

  return {
    currency,
    metrics,
    appliedQuery,
    error: errorAggregated,
    fetching: fetchingAggregated,
    isMenuOpen,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Aggregated);
