export const PERFORMANCE_DOMAIN = 'performance';
export const AGGREGATED_PERFORMANCE_DOMAIN = 'aggregated_performance';
export interface PerformanceState {
  currency: string;
  errorListings: Error;
  errorAggregated: Error;
  fetchingSupply: boolean;
  fetchingAggregated: boolean;
  supply: { currency: string };
}

export const ACTIONS = {
  FETCH_PERFORMANCE_AGGREGATED_START: 'FETCH_PERFORMANCE_AGGREGATED_START',
  FETCH_PERFORMANCE_AGGREGATED_SUCCESS: 'FETCH_PERFORMANCE_AGGREGATED_SUCCESS',
  FETCH_PERFORMANCE_AGGREGATED_FAILURE: 'FETCH_PERFORMANCE_AGGREGATED_FAILURE',
  FETCH_PERFORMANCE_SUPPLY_START: 'FETCH_PERFORMANCE_SUPPLY_START',
  FETCH_PERFORMANCE_SUPPLY_SUCCESS: 'FETCH_PERFORMANCE_SUPPLY_SUCCESS',
  FETCH_PERFORMANCE_SUPPLY_FAILURE: 'FETCH_PERFORMANCE_SUPPLY_FAILURE',
};
