import { get } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo from 'components/Icons/Logo';
import { Loader } from 'components/Layout';
import { default as Display } from 'components/Layout/Layout';
import Menu from 'components/Menu';
import { ApplicationState } from 'store';
import { logout } from 'store/auth/actions';
import { AuthActions } from 'store/auth/types';
import { closeAside, openAside } from 'store/layout/actions';

import { Navigation } from '../Navigation';

interface LayoutProps {
  shouldHideAside?: boolean;
  children: React.ReactNode;
}
type DashboardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Pick<RouteProps, 'location'> &
  LayoutProps;
const Dashboard: React.SFC<DashboardProps> = function({
  shouldHideAside = false,
  ...props
}) {
  if (props.isLoading) return <Loader />;
  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    props.actions.logout();
  };

  const handleDrawerOpen = () => {
    props.actions.openAside();
  };

  const BrandComponent = (
    <Link to="/">
      <Logo width={150} color="white" />
    </Link>
  );

  return (
    <Display
      shouldHideAside={shouldHideAside}
      open={shouldHideAside ? false : props.isAsideOpen}
      isLoggedIn={props.isLoggedIn}
      brandComponent={BrandComponent}
      handleDrawerOpen={handleDrawerOpen}
      leftLinks={props.isLoggedIn && <Navigation />}
      rightLinks={
        props.isLoggedIn && (
          <Menu
            handleLogout={handleLogout}
            cleanUrl={props.cleanUrl}
            avatar={props.avatar}
          />
        )
      }
    >
      {props.children}
    </Display>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    isLoading: get('layout.isLoading', state),
    isLoggedIn: get('auth.token', state) ? true : false,
    isAsideOpen: get('layout.isOpen', state) || null,
    cleanUrl: get('auth.user._cleanUrls.0', state) || 'Property Manager',
    avatar: get('auth.user.favicon', state) || null,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, AuthActions>,
) {
  return {
    actions: bindActionCreators({ logout, closeAside, openAside }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
