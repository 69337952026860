import { PmGroupsState } from '../types';
import { Status } from '../lib/enums';
import { createSlice } from '@reduxjs/toolkit';
import { Status as ListingsStatus } from 'store/listings/types';
import { LISTING_SELECTION_DOMAIN } from './types';

const initialState: PmGroupsState = {
  listingsError: null,
  listingsStatus: ListingsStatus.IDLE,
  downloading: false,
  error: null,
  query: null,
  savedFilters: [],
  added: 0,
  groups: [],
  listingsSelected: [],
  koUrls: [],
  matched: 0,
  showResults: false,
  status: Status.IDLE,
  selectedGroup: null,
  deleteCount: 0,
  group: null,
  currentListingsDomain: LISTING_SELECTION_DOMAIN,
};

export const pmGroupsSlice = createSlice({
  name: 'pmGroups',
  initialState,
  reducers: {
    StartLoadListings: state => {
      state.listingsStatus = ListingsStatus.RUNNING;
      state.error = null;
    },
    SuccessLoadListings: state => {
      state.listingsStatus = ListingsStatus.SUCCESS;
      state.error = null;
    },
    FailLoadListings: (state, { payload }) => {
      state.listingsStatus = ListingsStatus.FAILED;
      state.error = payload.error;
    },
    StatusListings: (state, { payload }) => {
      state.listingsStatus = payload;
    },
    UpdateGroupStatus: (state, { payload }) => {
      state.status = payload.status;
      state.added = payload.added ?? state.added;
      /*(payload.group && {
          group: [...state.groups, payload.group].filter(g => g),
        }),*/
      state.group = payload.group
        ? [...state.groups, payload.group].filter(g => g)
        : state.group;
      state.koUrls = payload.koUrls ?? state.koUrls;
      state.matched = payload.matched ?? state.matched;
      state.showResults = payload.showResults ?? state.showResults;
    },
    AddListingSelection: (state, { payload: { listing } }) => {
      state.listingsSelected = [...state.listingsSelected, listing];
    },
    RemoveListingSelection: (state, { payload: { unified_id } }) => {
      state.listingsSelected = state.listingsSelected.filter(
        uid => uid !== unified_id,
      );
    },
    RemoveAllListingSelection: state => {
      state.listingsSelected = [];
    },
    CreatePMGroupStartLS: state => {
      state.added = 0;
      state.matched = 0;
      state.status = Status.RUNNING;
    },
    CreatePMGroupSuccessLS: (state, { payload: { added, group } }) => {
      state.added = added;
      state.error = null;
      state.groups = [...state.groups, group];
      state.status = Status.CREATED;
    },
    UpdatePMGroupSuccessLS: (state, { payload: { added, group } }) => {
      state.groups = state.groups.map(g =>
        g.groupName === group.groupName ? group : g,
      );
      state.added = added;
      state.error = null;
      state.status = Status.UPDATED;
    },
    CreateUpdatePMGroupFailureLS: (state, { payload: { error } }) => {
      state.status = Status.FAIL;
      state.error = error;
    },
    HideCreatePMGroupsResult: state => {
      state.showResults = false;
    },
    CreatePMGroupFailure: (state, { payload: { error } }) => {
      state.added = 0;
      state.error = error;
      state.koUrls = [];
      state.matched = 0;
      //state.okUrls = [];
      state.status = Status.IDLE;
    },
    FetchPMGroupsStart: state => {
      state.status = Status.IS_LOADING;
    },
    FetchPMGroupsSuccess: (state, { payload: { groups } }) => {
      state.error = null;
      state.status = Status.LOADED;
      state.groups = groups;
    },
    FetchPMGroupsFailure: (state, { payload: { error } }) => {
      state.error = error;
      state.status = Status.IDLE;
      state.groups = [];
    },
    DeletePMGroupStart: state => {
      state.status = Status.RUNNING;
    },
    DeletePMGroupSuccess: (state, { payload: { groups } }) => {
      state.status = Status.DELETED;
      state.error = null;
      state.groups = groups;
    },
    DeletePMGroupFailure: (state, { payload: { error } }) => {
      state.status = Status.IDLE;
      state.error = error;
    },
    DeleteFromGroupStart: state => {
      state.status = Status.RUNNING;
    },
    DeleteFromGroupSuccess: (state, { payload: { deleteCount } }) => {
      state.status = Status.DELETED;
      state.deleteCount = deleteCount;
    },
    DeleteFromGroupFail: (state, { payload: { error } }) => {
      state.status = Status.FAIL;
      state.error = error;
    },
    ResetDeleteCount: state => {
      state.deleteCount = 0;
    },
    SetSavedFilters: (state, { payload: { filters } }) => {
      state.savedFilters = filters;
    },
    SetCurrentListingsDomain: (state, { payload: { listingsDomain } }) => {
      state.currentListingsDomain = listingsDomain;
    },
  },
});

export const {
  StartLoadListings,
  SuccessLoadListings,
  FailLoadListings,
  StatusListings,
  UpdateGroupStatus,
  AddListingSelection,
  RemoveListingSelection,
  RemoveAllListingSelection,
  CreatePMGroupStartLS,
  CreatePMGroupSuccessLS,
  UpdatePMGroupSuccessLS,
  CreateUpdatePMGroupFailureLS,
  HideCreatePMGroupsResult,
  CreatePMGroupFailure,
  FetchPMGroupsStart,
  FetchPMGroupsSuccess,
  FetchPMGroupsFailure,
  DeletePMGroupStart,
  DeletePMGroupSuccess,
  DeletePMGroupFailure,
  DeleteFromGroupStart,
  DeleteFromGroupSuccess,
  DeleteFromGroupFail,
  ResetDeleteCount,
  SetSavedFilters,
  SetCurrentListingsDomain,
} = pmGroupsSlice.actions;

export default pmGroupsSlice.reducer;
