import { capitalize } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MarketUrl } from '../../routes/supply/types';
import ExcelIcon from '../../components/Icons/ExcelIcon';
import {
  Button,
  Typography,
  withStyles,
  StyledComponentProps,
} from '@material-ui/core';

interface IProps extends StyledComponentProps {
  marketUrls: MarketUrl;
  onClickButton?: (event: React.MouseEvent<HTMLElement>) => void;
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
  },
  text: {
    marginRight: '20px',
  },
  button: {
    color: 'white',
    boxShadow: 'none',
    fontWeight: 700,
  },
  leftIcon: {
    marginRight: 8,
  },
};

const MarketDownload = ({ marketUrls, classes, onClickButton }: IProps) => (
  <div className={classes.container}>
    <Typography className={classes.text}>
      <FormattedMessage
        id="supply.download.message"
        defaultMessage="Download Supply for market {market}"
        values={{
          market: capitalize(marketUrls.market),
        }}
      />
    </Typography>
    <Button
      className={classes.button}
      href={marketUrls.signedUrl}
      download={true}
      variant="contained"
      color="secondary"
      size="large"
      onClick={onClickButton}
    >
      <ExcelIcon className={classes.leftIcon} />
      <FormattedMessage
        id="supply.download.button"
        defaultMessage="Export Csv"
      />
    </Button>
  </div>
);

export default withStyles(styles)(MarketDownload);
