import React from 'react';

const AddFolderIcon = props => (
  <svg
    id="prefix__Capa_1"
    x={0}
    y={0}
    viewBox="0 0 36 30"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.prefix__st1{fill:#b0becb}'}</style>
    <defs>
      <path id="prefix__SVGID_1_" d="M0 0h36v30H0z" />
    </defs>
    <clipPath id="prefix__SVGID_2_">
      <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
    </clipPath>
    <g clipPath="url(#prefix__SVGID_2_)">
      <path
        className="prefix__st1"
        d="M18.2 27.5H3.4V8c0-1.5 1.3-2.7 2.8-2.7h24c1.6 0 2.8 1.2 2.8 2.8v5.4h-.8V8.1c0-1.1-.9-2-2-2h-24c-1.1 0-2 .9-2 2v18.7h14v.7z"
      />
      <path
        className="prefix__st1"
        d="M4.2 27.5H2.8c-1.6 0-2.8-1.2-2.8-2.7V2.6C0 1.1 1.2 0 2.6 0h6.8c.7 0 1.4.3 1.9.8L13 2.3c.4.4.9.6 1.4.6h13.5c1.6 0 2.8 1.2 2.8 2.8v.1h-.8v-.2c0-1.1-.9-2-2-2H14.4c-.7 0-1.4-.3-1.9-.8l-1.6-1.5c-.5-.3-1-.5-1.5-.5H2.6C1.6.8.8 1.6.8 2.6v22.2c0 1.1.9 2 2 2h.6V8h.8v19.5z"
      />
      <path
        className="prefix__st1"
        d="M13.8 12.5H7.6c-.6 0-1.1-.5-1.1-1.1V9.2c0-.6.5-1.1 1.1-1.1h6.3c.6 0 1.1.5 1.1 1.1v2.2c-.1.6-.5 1.1-1.2 1.1zM7.6 8.8c-.2 0-.4.2-.4.4v2.2c0 .2.2.3.3.3h6.3c.2 0 .3-.2.3-.3V9.2c0-.2-.2-.3-.3-.3H7.6zM27.7 30c-4.6 0-8.3-3.6-8.3-8.1s3.7-8.1 8.3-8.1c4.6 0 8.3 3.6 8.3 8.1S32.3 30 27.7 30zm0-15.2c-4 0-7.2 3.2-7.2 7.1s3.2 7.1 7.2 7.1 7.2-3.2 7.2-7.1-3.2-7.1-7.2-7.1z"
      />
      <path
        className="prefix__st1"
        d="M27.7 26.3c-.3 0-.5-.2-.5-.5V18c0-.3.2-.5.5-.5s.5.2.5.5v7.8c0 .3-.2.5-.5.5z"
      />
      <path
        className="prefix__st1"
        d="M31.7 22.4h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c.3 0 .5.2.5.5s-.2.5-.5.5z"
      />
    </g>
  </svg>
);

export default AddFolderIcon;
