import * as React from 'react';

import LineChart, { LineChartOptions } from './LineChart';
import BarChart, { BarChartOptions } from './BarChart';
import BarLineChart, { BarLineChartOptions } from './BarLineChart';
import PieChart, { PieChartOptions } from './PieChart';
import { VisualizationType } from '../../containers/Charts';

export interface ChartsOptions {
  [key: string]: any;
}

export interface ChartProps {
  chartId: string;
  data: any;
  error?: any;
  height?: string;
  loading?: boolean;
  options: ChartsOptions;
  vizType: VisualizationType;
  width?: string;
  isPercentage?: boolean;
  formatCurrency?: boolean;
  currency?: string;
}

export default function Chart({
  chartId,
  data,
  error,
  height,
  loading,
  options,
  vizType = 'line',
  width,
  currency,
  ...props
}: ChartProps) {
  if (vizType === 'line') {
    return (
      <LineChart
        chartId={chartId}
        data={data}
        error={error}
        height={height}
        loading={loading}
        options={options as LineChartOptions}
        width={width}
        currency={currency}
        {...props}
      />
    );
  }
  if (vizType === 'bar') {
    return (
      <BarChart
        chartId={chartId}
        data={data}
        error={error}
        height={height}
        loading={loading}
        options={options as BarChartOptions}
        width={width}
        currency={currency}
        {...props}
      />
    );
  }
  if (vizType === 'bar_line') {
    return (
      <BarLineChart
        chartId={chartId}
        data={data}
        error={error}
        height={height}
        loading={loading}
        options={options as BarLineChartOptions}
        width={width}
        currency={currency}
        {...props}
      />
    );
  }
  if (vizType === 'pie') {
    return (
      <PieChart
        chartId={chartId}
        data={data}
        error={error}
        height={height}
        loading={loading}
        options={options as PieChartOptions}
        width={width}
        currency={currency}
      />
    );
  }
  throw new Error(`Unsupported chart type ${vizType}`);
}
