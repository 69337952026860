import React from 'react';

const WarningIcon = props => (
  <svg viewBox="0 0 13 13" {...props}>
    <g>
      <path
        d="M12.8441 10.5723L7.56439 1.19979C7.11419 0.381239 5.88634 0.381239 5.43611 1.19979L0.156396 10.5723C-0.293801 11.3909 0.27919 12.4141 1.22054 12.4141H11.7391C12.7213 12.3732 13.2943 11.3909 12.8441 10.5723Z"
        fill="#E24C4B"
      />
      <path
        d="M6.5003 1.81366L11.78 11.1862H1.22058L6.5003 1.81366Z"
        fill="white"
      />
      <path
        d="M5.6408 6.11117L5.96823 8.28036C6.00915 8.52592 6.21379 8.73056 6.5003 8.73056C6.74586 8.73056 6.99145 8.52592 7.03237 8.28036L7.3598 6.11117C7.44164 5.5791 7.03237 5.12891 6.5003 5.12891C5.9682 5.12888 5.59985 5.5791 5.6408 6.11117Z"
        fill="#3F4448"
      />
      <path
        d="M6.5003 10.1631C6.77156 10.1631 6.99145 9.94319 6.99145 9.67193C6.99145 9.40068 6.77156 9.18079 6.5003 9.18079C6.22905 9.18079 6.00916 9.40068 6.00916 9.67193C6.00916 9.94319 6.22905 10.1631 6.5003 10.1631Z"
        fill="#3F4448"
      />
    </g>
  </svg>
);

export default WarningIcon;
