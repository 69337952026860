import * as React from 'react';

export default function inactive() {
  return (
    <div className="container">
      <p>
        You account has been suspended. Please reach our sales team for more
        information.
      </p>
    </div>
  );
}
