import * as React from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import Aggregated from '../Aggregated';
import Supply from '../Supply';

type PerformanceProps = RouteComponentProps;

const styles = makeStyles({
  container: {
    padding: 48,
    marginBottom: 48,
  },
});

const Performance: React.SFC<any> = () => (
  <React.Fragment>
    <Aggregated />
    <Supply />
  </React.Fragment>
);

const PerformanceContainer: React.SFC<PerformanceProps> = ({ match }) => {
  const classes = styles({});
  return (
    <div className={classes.container}>
      <Route path={`${match.url}`} component={Performance} />
    </div>
  );
};

export default PerformanceContainer;
