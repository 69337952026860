import { IntlShape } from 'react-intl';

var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export function abbreviateNumber(number?: number, fixed = 2) {
  var tier = (Math.log10(number) / 3) | 0;
  if (tier === 0) return number?.toFixed(fixed);
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  var scaled = number / scale;
  return scaled.toFixed(fixed) + suffix;
}

export function getCurrencySymbol(intl: IntlShape, currency?: string) {
  return currency
    ? intl
        .formatNumber(1, { currency, style: 'currency' })
        .replace(/[,.]/g, '')
        .replace(/\d/g, '')
    : '';
}
