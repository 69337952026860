import React from 'react';

const GreenOkIcon = ({ width }) => {
  return (
    <svg
      width={width}
      height="50"
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6336 49.242C25.086 49.8783 24.1391 49.9269 23.5292 49.3497L0.475364 27.5301C-0.134488 26.9529 -0.161003 25.9818 0.415791 25.372L7.76087 17.6106C8.33801 17.0007 9.30909 16.9742 9.91894 17.551L22.5123 29.4695C23.1222 30.0466 24.0692 29.998 24.6167 29.3617L49.2198 0.775082C49.7673 0.138715 50.7363 0.0660563 51.3723 0.613924L59.4709 7.58435C60.1072 8.13187 60.1799 9.10089 59.632 9.73725L25.6336 49.242Z"
        fill="#08D8CB"
      />
    </svg>
  );
};

export default GreenOkIcon;
