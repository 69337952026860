import { Reducer } from 'redux';

import { IChartsState, IChartsActions, IChartState } from './types';

const defaultChartState: IChartState = {
  data: null,
  params: {},
  isLoading: true,
  error: null,
  currency: 'USD',
};

const Charts: Reducer<IChartsState, IChartsActions> = (state = {}, action) => {
  if (!action.payload) return state;

  const { chartId } = action.payload;
  const chartState = state[chartId] || defaultChartState;

  switch (action.type) {
    case 'START_LOAD_CHART':
      return {
        ...state,
        [chartId]: {
          ...chartState,
          data: null,
          error: null,
          isLoading: true,
          params: action.payload.params || chartState.params,
        },
      };
    case 'LOAD_CHART_SUCCESS':
      return {
        ...state,
        [chartId]: {
          ...chartState,
          data: action.payload.data || [],
          currency: action.payload.currency,
          error: null,
          isLoading: false,
        },
      };
    case 'LOAD_CHART_ERROR':
      return {
        ...state,
        [chartId]: {
          ...chartState,
          data: [],
          error: action.payload.error,
          isLoading: false,
        },
      };
    case 'CLEAR_CHART':
      return { ...state, [chartId]: { ...chartState, data: null } };
    default:
      return state;
  }
};

export default Charts;
