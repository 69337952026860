import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PacingIndividualDateChart } from './PacingIndividualDateChart';
import InfoContainer from 'components/InformationBox';
import { isInclusivelyAfterDay, SingleDatePicker } from 'react-dates';

import { Loader } from 'components/Layout';

interface IndividualDateProps {
  data?: any;
  error?: any;
  fetching: boolean;
  loadIndividualDate: (date?: string) => void;
}

const IndividualDate: React.FunctionComponent<IndividualDateProps> = ({
  data,
  error,
  fetching,
  loadIndividualDate,
}) => {
  const MIN_DATE = moment.utc('2020-01-01');
  const MAX_DATE = moment.utc().add(1, 'year');
  const [date, setDate] = useState<moment.Moment>(moment.utc());
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!data && !error) loadIndividualDate(date.format('YYYY-MM-DD'));
  }, [data, error, loadIndividualDate, date]);
  const isOutsideRange = day =>
    !isInclusivelyAfterDay(day, MIN_DATE) ||
    isInclusivelyAfterDay(day, MAX_DATE);

  return (
    <div className="columns">
      <div className="column">
        <div className="box mx-4">
          <div className="is-flex is-justify-content-space-between px-4 my-3 pl-6">
            <h3 className="is-5 title is flex is-justify-content-center">
              <FormattedMessage
                id="pacing.datepicker.title"
                defaultMessage="Pacing - Individual date"
              />
            </h3>
            <div>
              <FormattedMessage
                id="pacing.datepicker.label"
                defaultMessage="Pick the date you want to compare"
              />
              <SingleDatePicker
                appendToBody={true}
                date={date}
                focused={focused}
                hideKeyboardShortcutsPanel={true}
                id="individualDatePicker"
                isOutsideRange={isOutsideRange}
                noBorder={true}
                numberOfMonths={1}
                showDefaultInputIcon={true}
                onDateChange={date => {
                  setFocused(false);
                  setDate(date);
                  if (date) {
                    loadIndividualDate(date.format('YYYY-MM-DD'));
                  }
                }}
                onFocusChange={({ focused }) => setFocused(focused)}
              />
            </div>
            <InfoContainer>
              <FormattedMessage
                id="pacing.datepicker.info"
                values={{ n: () => <p className="mt-2" /> }}
              />
            </InfoContainer>
          </div>
          <div className="column">
            <div className="px-4" style={{ height: 500 }}>
              {fetching ? (
                <Loader />
              ) : (
                <PacingIndividualDateChart data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualDate;
