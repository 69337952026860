import * as React from 'react';
import { head } from 'lodash';
import { Chart, messages, config } from 'components/Charts/AmCharts';
import { useIntl } from 'react-intl';
import moment from 'moment';

export const PacingIndividualDateChart = ({ data }) => {
  const intl = useIntl();

  const getConfig = data => {
    const { x, date, ...dataHead } = !!data && head(data);
    const series = Object.keys(dataHead).map(key => ({
      id: key,
      type: 'LineSeries',
      tooltipText: `[bold]{categoryX.formatDate('MMM dd')}[/]
                        {valueY.formatNumber('#.##%')}`,
      name: key,
      dataFields: {
        valueY: key,
        valueX: 'x',
        categoryX: 'date',
      },
      legend: {
        labels: {
          text: 'Series: [bold {stroke}]{color}[/]',
        },
      },
      legendSettings: {
        labelText: `[bold]{name.formatDate('MMM dd yyyy')}[/]`,
      },
      strokeWidth: 2,
      tensionX: 0.7,
    }));
    return {
      ...config,
      series,
      cursor: {},
      numberFormatter: {
        numberFormat: '#',
      },
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'date',
          },
          cursorTooltipEnabled: false,
          title: {
            text: intl.formatMessage(messages['date']),
          },
          renderer: {
            labels: {
              adapter: [
                {
                  key: 'text',
                  callback(_, target) {
                    if (!!target.dataItem.category) {
                      return moment(target.dataItem.category).format('MMM DD');
                    }
                  },
                },
              ],
            },
          },
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          title: {
            text: intl.formatMessage(messages['occupancy']),
          },
          numberFormatter: {
            type: 'NumberFormatter',
            forceCreate: true,
            numberFormat: '#.##%',
          },
          cursorTooltipEnabled: false,
        },
      ],
      legend: {},
      data,
    };
  };
  return (
    <>
      {data && (
        <Chart
          chartType="XYChart"
          exportMenu={true}
          config={getConfig(data)}
          chartId="PacingIndividualDate"
        />
      )}
    </>
  );
};
