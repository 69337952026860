import { Reducer } from 'redux';
import * as supplyTypes from '../types';

const ACTION_HANDLERS = {
  [supplyTypes.START_LOAD_SUPPLIES]: state => {
    return { ...state, error: null, isLoading: true, markets: null };
  },
  [supplyTypes.LOAD_SUPPLIES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      error: null,
      isLoading: false,
      markets: payload.markets,
    };
  },
  [supplyTypes.LOAD_SUPPLIES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      error: payload.error,
      isLoading: false,
      markets: [],
    };
  },
};

const initialState: supplyTypes.ISuppliesState = {
  error: null,
  isLoading: true,
  markets: null,
};

export default (
  state = initialState,
  action,
): Reducer<supplyTypes.ISuppliesState, supplyTypes.ISuppliesActions> => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
