import * as React from 'react';
import { snakeCase, truncate } from 'lodash';
import {
  createStyles,
  Theme,
  withStyles,
  StyledComponentProps,
  Icon,
  TextField,
  Typography,
  MenuItem,
  Chip,
  Paper,
} from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import ReactSelect from 'react-windowed-select';
import { ActionMeta } from 'react-select';
import { useIntl } from 'react-intl';

interface ISelectProps extends StyledComponentProps {
  icon?: typeof Icon | string;
  handleChange: (value: string | string[], action?: ActionMeta<any>) => void;
  options?: any;
  theme?: Theme;
  placeholder: string;
  isMulti: boolean;
  value: any;
  onBlur: (event: React.FocusEvent) => void;
  disabled?: boolean;
}
export type SelectProps = ISelectProps & StyledComponentProps;

const styles = (theme: Theme) =>
  createStyles({
    inputText: {
      '&:before': {
        content: 'none',
        borderBottom: '0px',
      },
    },
    root: {
      flexGrow: 1,
      height: 'auto',
      border: 'none',
    },
    input: {
      display: 'flex',
      padding: 0,
      height: '100%',
      minHeight: 40,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
    },
    chip: {
      margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
      fontSize: 16,
      paddingLeft: 0,
    },
    placeholder: {
      position: 'absolute',
      left: 10,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  });

const NoOptionsMessage: React.SFC<any> = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent: React.SFC<any> = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control: React.SFC<any> = props => {
  return (
    <TextField
      fullWidth={true}
      InputProps={{
        inputComponent,
        classes: {
          underline: props.selectProps.classes.inputText,
        },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option: React.SFC<any> = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      title={props.children}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder: React.SFC<any> = props => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue: React.SFC<any> = props => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer: React.SFC<any> = props => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const MultiValue: React.SFC<any> = props => {
  return (
    <Chip
      tabIndex={-1}
      title={props.children}
      label={truncate(props.children, { length: 16 })}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu: React.SFC<any> = props => {
  return (
    <Paper
      square={true}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
};

const MultiSelect: React.SFC<SelectProps> = ({
  classes,
  options,
  theme,
  placeholder,
  isMulti,
  value,
  handleChange,
  onBlur,
  disabled,
}) => {
  const components: { [key: string]: React.SFC<any> } = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    ...(isMulti
      ? {
          MultiValue,
          SingleValue,
          ValueContainer,
        }
      : {}),
  };
  const intl = useIntl();

  const selectStyles = {
    clearIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
    input: (base: any) => ({
      ...base,
      width: '100%',
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  return (
    <div className={classes.root}>
      <ReactSelect
        {...({ classes } as any)} //tslint:disable-line
        styles={selectStyles}
        getOptionLabel={label =>
          label
            ? intl.formatMessage({
                id: `filters_values.${snakeCase(placeholder)}.${label}`,
                defaultMessage: label,
              })
            : ''
        }
        getOptionValue={value => value}
        options={options}
        components={components}
        placeholder={intl.formatMessage({ id: `filters.${placeholder}` })}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        cacheOptions={true}
        defaultOptions={true}
        onBlur={onBlur}
        value={value}
        onChange={handleChange}
        isDisabled={disabled}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
