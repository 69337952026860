import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Event from '../../../components/Events/index';
import EventDialogTitle from '../../../components/Events/EventDialogTitle';
import EventListItem from '../../../components/Events/EventListItem';
import RightContent from '../../../components/Events/EventRigthContent';
import CalendarIcon from '../../../components/Icons/CalendarWithCheck';
import { EventType } from './types';

function getPercent(value: number, locale: string) {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
  };
  return value.toLocaleString(locale, options);
}

interface EventLayoutProps {
  event: EventType;
  isOpen: boolean;
  handleClose: () => void;
}

const EventLayout: React.SFC<EventLayoutProps> = function({
  event,
  isOpen,
  handleClose,
}) {
  if (!event) return null;
  return (
    <Event
      open={isOpen}
      handleClose={handleClose}
      titleComponent={
        <EventDialogTitle
          source={event.source}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {event.name}
        </EventDialogTitle>
      }
      rigthContent={
        <RightContent
          icon={<CalendarIcon />}
          title={<FormattedMessage id="events.occupancy" />}
          value={getPercent(Number(event.occupancy), 'en')}
        />
      }
    >
      <EventListItem
        label={<FormattedMessage id="events.type" />}
        value={event.category}
      />
    </Event>
  );
};

export default EventLayout;
