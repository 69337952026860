import * as React from 'react';
import CalendarLayout from './Layout';
import Layout from '../Layout/Layout';
import Dashboard from '../Dashboard/Dashboard';
import Filters from '../Dashboard/Filters';

const Calendar: React.FunctionComponent<any> = props => (
  <Layout>
    <Dashboard mainListItems={<Filters />}>
      <CalendarLayout {...props} />
    </Dashboard>
  </Layout>
);

export default Calendar;
