import _ from 'lodash';

export const getEntity = (state, domain, id, defaultEntity = null) =>
  _.get(state.entities, [domain, String(id)], defaultEntity);

export const getEntities = (state, domain, ids = []) =>
  _.compact(_.map(ids, id => getEntity(state, domain, id)));

export const getAllIds = (state, domain) =>
  _.keys(_.get(state.entities, domain));

export const getAllEntities = (state, domain) =>
  _.values(_.get(state.entities, domain));
