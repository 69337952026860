import * as eventTypes from './types';

const ACTION_HANDLERS = {
  [eventTypes.SET_EVENTS]: (state, { payload }) => {
    return {
      ...state,
      currency: payload.currency,
      calendar: payload.calendar,
      isLoading: false,
      error: null,
    };
  },
  [eventTypes.SELECT_EVENT]: (state, { payload }) => {
    return {
      ...state,
      selected: payload,
    };
  },
  [eventTypes.START_LOADING_EVENTS]: state => {
    return { ...state, isLoading: true };
  },

  [eventTypes.LOAD_EVENTS_ERROR]: (state, { payload }) => {
    return { ...state, isLoading: false, error: payload.error };
  },

  [eventTypes.SET_EVENTS_QUERY]: (state, { payload }) => {
    return { ...state, query: payload };
  },

  [eventTypes.TOGGLE_OPEN]: state => {
    return { ...state, isOpen: !state.isOpen };
  },
};

const initialState: eventTypes.IEventsState = {
  isLoading: true,
  query: null,
  isOpen: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
