import * as React from 'react';
import Pagination from 'bulma-pagination-react';
interface CustomPaginationParams {
  currentPage: number;
  onChange: any;
  pages: number;
}

export const CustomPagination = React.memo<CustomPaginationParams>(
  ({ currentPage, pages, onChange }) => (
    <Pagination
      currentPage={currentPage}
      pages={pages}
      onChange={onChange}
      className="is-small"
    />
  ),
);
