import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import SeriesChart, {
  SeriesChartProps,
  SeriesChartOptions,
} from './SeriesChart';

export interface BarLineChartOptions extends SeriesChartOptions {
  barValueField: string;
  lineValueField: string;
  barSeriesName?: string;
  lineSeriesName?: string;
}
export interface BarLineChartProps extends SeriesChartProps {
  options: BarLineChartOptions;
}

export default class BarLineChart extends SeriesChart<BarLineChartProps> {
  updateSeries(chart: am4charts.XYChart) {
    const { options, isPercentage, formatCurrency, currency } = this.props;
    const {
      barValueField,
      categoryField,
      horizontal,
      lineValueField,
      barSeriesName,
      lineSeriesName,
    } = options;

    chart.series.clear();

    const barSeries = chart.series.push(new am4charts.ColumnSeries());
    barSeries.name = barSeriesName || barValueField;
    if (horizontal) {
      barSeries.dataFields.categoryY = categoryField;
      barSeries.dataFields.valueX = barValueField;
    } else {
      barSeries.dataFields.categoryX = categoryField;
      barSeries.dataFields.valueY = barValueField;
    }

    const valueAxis = horizontal ? barSeries.xAxis : barSeries.yAxis;
    barSeries.columns.template.tooltipText = `{valueX}`;
    if (isPercentage) {
      const numberFormatter = (valueAxis.numberFormatter = new am4core.NumberFormatter());
      numberFormatter.numberFormat = `#.#'%'`;
      barSeries.columns.template.tooltipText = `[bold]{categoryX}[/]
                                                {valueY.formatNumber('#.0')}%`;
    }
    if (formatCurrency) {
      const numberFormatter = (valueAxis.numberFormatter = new am4core.NumberFormatter());
      numberFormatter.numberFormat = `#. ' ${currency}'`;
      barSeries.columns.template.tooltipText = `[bold]{categoryX}[/]
                                                {valueY.formatNumber('#.')} ${currency}`;
    }

    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = lineSeriesName || lineValueField;
    if (horizontal) {
      lineSeries.dataFields.categoryY = categoryField;
      lineSeries.dataFields.valueX = lineValueField;
    } else {
      lineSeries.dataFields.categoryX = categoryField;
      lineSeries.dataFields.valueY = lineValueField;
    }
    lineSeries.tensionX = 0.95;
    lineSeries.tensionY = 0.95;
    lineSeries.strokeWidth = 2;

    const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.tooltipText = `[bold]{categoryX}[/]
                                {${lineValueField}}`;
    if (isPercentage) {
      bullet.circle.tooltipText = `[bold]{categoryX}[/]
                                {${lineValueField}.formatNumber('#.0')}%`;
    }
    if (formatCurrency) {
      bullet.circle.tooltipText = `[bold]{categoryX}[/]
                                 {${lineValueField}.formatNumber('#.')} ${currency}`;
    }
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color('#fff');

    const bullethover = bullet.states.create('hover');
    bullethover.properties.scale = 1.3;
  }
}
