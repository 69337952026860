import * as React from 'react';
// @material-ui/core components
import { withStyles, Icon } from '@material-ui/core';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';

type Color = 'info' | 'success' | 'warning' | 'danger' | 'primary';
interface IProps {
  classes: any;
  message: React.ReactNode;
  color: Color;
  close: boolean;
  icon: any;
}
interface IState {
  alert: null | React.ReactNode;
}

const conatinerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
};

const container = {
  ...conatinerFluid,
  '@media (min-width: 576px)': {
    maxWidth: '540px',
  },
  '@media (min-width: 768px)': {
    maxWidth: '720px',
  },
  '@media (min-width: 992px)': {
    maxWidth: '960px',
  },
  '@media (min-width: 1200px)': {
    maxWidth: '1140px',
  },
};

const snackbarContentStyle = {
  root: {
    position: 'relative',
    padding: '20px 15px',
    lineHeight: '20px',
    marginBottom: '20px',
    fontSize: '14px',
    backgroundColor: 'white',
    color: '#555555',
    borderRadius: '0px',
    maxWidth: '100%',
    minWidth: 'auto',
    boxShadow:
      '0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)',
  },
  info: {
    backgroundColor: '#00d3ee',
    color: '#ffffff',
    // ...infoBoxShadow
  },
  success: {
    backgroundColor: '#5cb860',
    color: '#ffffff',
    // ...successBoxShadow
  },
  warning: {
    backgroundColor: '#ffa21a',
    color: '#ffffff',
    // ...warningBoxShadow
  },
  danger: {
    backgroundColor: '#f55a4e',
    color: '#ffffff',
    // ...dangerBoxShadow
  },
  primary: {
    backgroundColor: '#af2cc5',
    color: '#ffffff',
    // ...primaryBoxShadows
  },
  message: {
    padding: '0',
    display: 'block',
    maxWidth: '89%',
  },
  close: {
    width: '14px',
    height: '14px',
  },
  iconButton: {
    width: '24px',
    height: '24px',
    float: 'right',
    fontSize: '1.5rem',
    fontWeight: '500',
    lineHeight: '1',
    position: 'absolute',
    right: '-4px',
    top: '0',
    padding: '0',
  },
  icon: {
    display: 'block',
    float: 'left',
    marginRight: '1.071rem',
  },
  container: {
    ...container,
    position: 'relative',
  },
};

class NotificationBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { classes, message, color, close, icon } = props;
    let action: React.ReactNode[] = [];
    if (close !== undefined) {
      action = [
        <IconButton
          className={classes.iconButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.closeAlert}
        >
          <Close className={classes.close} />
        </IconButton>,
      ];
    }

    let snackIcon = null;
    switch (typeof icon) {
      case 'function':
        snackIcon = <props.icon className={classes.icon} />;
        break;
      case 'string':
        snackIcon = <Icon className={classes.icon}>{props.icon}</Icon>;
        break;
      default:
        snackIcon = null;
        break;
    }

    this.state = {
      alert: (
        <Snack
          message={
            <div>
              {snackIcon}
              {message}
              {close !== undefined ? action : null}
            </div>
          }
          classes={{
            root: `${classes.root} ${classes[color]}`,
            message: `${classes.message} ${classes.container}`,
          }}
        />
      ),
    };
  }
  closeAlert = () => {
    this.setState({ alert: null });
  };
  render() {
    return this.state.alert;
  }
}

export default withStyles(snackbarContentStyle as any)(NotificationBar);
