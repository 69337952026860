import React from 'react';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Redirect } from 'react-router';
import { get } from 'lodash';
import { Loader } from '../Layout';

const ValidatedRoute: React.FC<{
  component: React.FC;
  path: string;
  validationKey: string;
}> = ({ component, path, validationKey }) => {
  const user = useSelector<ApplicationState, any>(({ auth }) => auth?.user);
  const isLoading = useSelector(state => get(state, ['layout', 'isLoading']));

  if (isLoading) return <Loader />;

  if (!user || !user[validationKey]) return <Redirect to="/rates" />;

  return <AuthenticatedRoute path={path} component={component} />;
};

export default ValidatedRoute;
