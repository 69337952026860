import { upperCase, get } from 'lodash/fp';
import { Reducer } from 'redux';
import baseReducerTypes from './types';

export interface IBaseReducerState {
  error: Error;
  isLoading: boolean;
  query: string;
  extra?: object;
}

export default (_domain: string) => {
  const domain = upperCase(_domain);

  const actionTypes = baseReducerTypes(domain);

  const getQuery = state => get([_domain, 'query'])(state);

  interface IBaseReducerStartLoading {
    type: typeof actionTypes.START_LOADING;
    isLoading: true;
  }

  interface IBaseReducerSuccess {
    type: typeof actionTypes.LOAD_SUCCESS;
    payload: {
      query: object;
      isLoding: boolean;
    };
  }

  interface IBaseReducerFailed {
    type: typeof actionTypes.LOAD_FAILED;
    payload: {
      error: Error;
      isLoading: boolean;
    };
  }

  type Actions =
    | IBaseReducerFailed
    | IBaseReducerStartLoading
    | IBaseReducerSuccess;

  const ACTION_HANDLERS = {
    [actionTypes.START_LOADING]: state => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    [actionTypes.LOAD_SUCCESS]: (state, { payload }) => {
      const { query, extra = {} } = payload;
      return {
        ...state,
        ...extra,
        query,
        isLoading: false,
        error: null,
      };
    },
    [actionTypes.LOAD_FAILED]: (state, { payload }) => {
      return { ...state, isLoading: false, error: payload.error };
    },
  };

  const initialState: IBaseReducerState = {
    error: null,
    isLoading: true,
    query: null,
  };

  const reducer = (
    state = initialState,
    action,
  ): Reducer<IBaseReducerState, Actions> => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  };

  return {
    reducer,
    getQuery,
  };
};
