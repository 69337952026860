import * as React from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const styles = {
  large: {
    fontSize: 100,
  },
  st0: { fill: '#F6F7FA' },
  st1: { fill: '#B3C1CE' },
};
function CountMarketIcon({
  classes,
  fontSize,
}: SvgIconProps & StyledComponentProps) {
  return (
    <SvgIcon
      id="CountMarketIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 251.9 73.3"
      classes={{
        fontSizeLarge: classes.large,
      }}
      fontSize={fontSize}
    >
      <path
        className={classes.st0}
        d="M235.2,30.8L211.5,9.2l-23.7,21.6v30.7c0,2.4,1.9,4.3,4.3,4.3h38.6c2.4,0,4.3-1.9,4.3-4.3V30.8z"
      />
      <path
        className={classes.st0}
        d="M149.4,33.2l-23.7-21.6L102,33.2v30.7c0,2.4,1.9,4.3,4.3,4.3H145c2.4,0,4.3-1.9,4.3-4.3V33.2z"
      />
      <path
        className={classes.st0}
        d="M64.4,31.7L40.8,10.2L17.1,31.7v30.7c0,2.4,1.9,4.3,4.3,4.3h38.6c2.4,0,4.3-1.9,4.3-4.3V31.7z"
      />
      <rect x="33.1" y="36.3" className="st1" width="15.7" height="15.7" />
      <path
        className={classes.st1}
        d="M40.8,0L0,37.2l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.2-6.4,14.2-14.2V42.3l2.9,2.7	l7.1-7.7L40.8,0z M61.1,59.1c0,2.1-1.7,3.7-3.7,3.7H24.2c-2.1,0-3.7-1.7-3.7-3.7V32.7l20.3-18.5l20.3,18.5V59.1z"
      />
      <rect x="118.2" y="36.3" className="st1" width="15.7" height="15.7" />
      <path
        className={classes.st1}
        d="M125.9,0L85.2,37.2l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.2-6.4,14.2-14.2V42.3l2.9,2.7	l7.1-7.7L125.9,0z M146.2,59.1c0,2.1-1.7,3.7-3.7,3.7h-33.2c-2.1,0-3.7-1.7-3.7-3.7V32.7l20.3-18.5l20.3,18.5V59.1z"
      />
      <rect x="203.4" y="36.3" className="st1" width="15.7" height="15.7" />
      <path
        className={classes.st1}
        d="M211.1,0l-40.8,37.2l7.1,7.7l2.9-2.7v16.8c0,7.8,6.4,14.2,14.2,14.2h33.2c7.8,0,14.2-6.4,14.2-14.2V42.3	l2.9,2.7l7.1-7.7L211.1,0z M231.4,59.1c0,2.1-1.7,3.7-3.7,3.7h-33.2c-2.1,0-3.7-1.7-3.7-3.7V32.7l20.3-18.5l20.3,18.5V59.1z"
      />
    </SvgIcon>
  );
}

export default withStyles(styles)(CountMarketIcon);
