import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, connect } from 'react-redux';
import { ListItem, Collapse, List, ListItemText } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { injectIntl } from 'react-intl';
import { languagesMapping } from './languages';
import { setLocale } from '../store/actions';
import { getLocale } from '../store/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    minWidth: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function LanguageSelectorLayout({ intl, currentLocale }) {
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles({});
  const dispatch = useDispatch();
  const handleClick = () => {
    setOpen(!open);
  };
  const handleLanguageChange = lan => () => {
    dispatch(setLocale(lan));
  };

  return (
    <>
      <ListItem button={true} onClick={handleClick} className={classes.root}>
        <ListItemText
          primary={intl.formatMessage({ id: languagesMapping[currentLocale] })}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {Object.keys(languagesMapping)
            .filter(key => key !== currentLocale)
            .map(k => (
              <ListItem
                button={true}
                className={classes.nested}
                onClick={handleLanguageChange(k)}
                key={k}
              >
                <ListItemText
                  primary={intl.formatMessage({ id: languagesMapping[k] })}
                />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  currentLocale: getLocale(state),
  ...ownProps,
});
export const LanguageSelector = connect(mapStateToProps)(
  injectIntl(LanguageSelectorLayout),
);
