import * as React from 'react';
import { isEmpty, map, get, find, compact } from 'lodash/fp';
import Pagination from 'bulma-pagination-react';
import { CSVLink } from 'react-csv';
import { stringify } from 'qs';
import { IPmStatsLine } from '../types';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';
import { Title, ImgFallback } from 'components/Transparent';
import RankingIcon from 'components/Icons/RankingIcon';
import ErrorMessage from 'components/PMSConnection/PMSConnectionError';
import InfoContainer from 'components/InformationBox';
import DownloadIcon from 'components/Icons/ExcelIcon';
import ExternalIcon from 'components/Icons/ExternalIcon';
import { loadPmDomainDetails } from '../store/actions';
import {
  usePagination,
  useSortArrayByKey,
  Table,
  TableHead,
} from 'components/Tables';
import { Mixpanel } from 'services/mixpanel';

export interface StatsLineProps extends IPmStatsLine {
  idx: number;
  currency?: string;
  logo?: string;
  url?: string;
}

const PmStatsLine = injectIntl<
  'intl',
  StatsLineProps & WrappedComponentProps<'intl'>
>(
  ({
    idx,
    pm_domain_name,
    count,
    avg_rates,
    avg_occ,
    review_score,
    intl,
    currency = 'USD',
    logo = null,
    url = null,
  }) => {
    return (
      <tr>
        <td style={{ width: 77 }}>{idx}</td>
        <td style={{ height: 50 }}>
          <ImgFallback src={logo} width={20} alt={pm_domain_name} />
        </td>
        <td style={{ width: 450 }}>{pm_domain_name}</td>
        <td>{count}</td>
        <td>{intl.formatNumber(avg_rates, { style: 'currency', currency })}</td>
        <td>{intl.formatNumber(avg_occ, { style: 'percent' })}</td>
        <td>{review_score ? review_score.toFixed(2) : '-'}</td>
        <td>
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <ExternalIcon width={20} />
            </a>
          ) : (
            '-'
          )}
        </td>
      </tr>
    );
  },
);

interface RankingTableProps {
  pmStats: IPmStatsLine[];
  currency?: string;
  loadDetails: typeof loadPmDomainDetails;
  market: string;
  pmDomainDetails: any;
}

function RankingTable({
  pmStats,
  currency,
  loadDetails,
  market,
  pmDomainDetails,
}: RankingTableProps) {
  const ELEMENTS_PER_PAGE = 10;
  const [sortedBy, setSortKey] = React.useState('count');
  const [sortedArray, sortByKey] = useSortArrayByKey<IPmStatsLine>(pmStats);
  const [isAsc, setAsc] = React.useState(false);
  const [
    currentPage,
    setCurrentPage,
    currentElements,
    numberOfPages,
  ] = usePagination<IPmStatsLine>(sortedArray, ELEMENTS_PER_PAGE);

  const selectedPmDetails = compact(
    map(
      ({ pm_domain_name }) => get([pm_domain_name], pmDomainDetails),
      currentElements,
    ),
  );

  React.useEffect(() => {
    const query = stringify({
      market,
      pmDomain: map('pm_domain_name', currentElements),
    });
    loadDetails(query);
  }, [currentElements, market, loadDetails]);

  const goToPage = (i: number) => {
    if (i > 0 && i <= numberOfPages) {
      setCurrentPage(i);
    }
  };

  const setSortBy = (key: string) => () => {
    let inverted = isAsc;
    if (key === sortedBy) inverted = !inverted;
    sortByKey(key, inverted);
    setSortKey(key);
    setAsc(inverted);
    Mixpanel.track('Chart reordered', {
      name: key,
      order: inverted ? 'ascending' : 'descending',
    });
  };

  const onClickDownloadData = () => {
    Mixpanel.track('Download chart data', {
      chartId: 'ranking_top_property_managers',
      type: 'CSV',
    });
  };
  const isSortedBy = (key: string) => sortedBy === key;

  return (
    <div className="container">
      <Title Icon={RankingIcon}>
        <FormattedMessage id="ranking.top_pms_title" />
      </Title>
      <div className="table-card space-top">
        <div className="icons-right">
          <ul className="aligned-list">
            <li>
              <InfoContainer>
                <FormattedMessage id="ranking.information_box" />
              </InfoContainer>
            </li>
            <li>
              <CSVLink
                data={pmStats}
                filename="pm-ranking.csv"
                style={{
                  display: 'flex',
                  width: 40,
                  height: 40,
                  alignItems: 'center',
                }}
              >
                <DownloadIcon
                  htmlColor="#afc3cf"
                  onClick={onClickDownloadData}
                />
              </CSVLink>
            </li>
          </ul>
        </div>
        <div className="table-space">
          <Table isCentered={true}>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <FormattedMessage id="tables.headers.logo" />
                </th>
                <TableHead
                  isAsc={isAsc}
                  onClick={setSortBy('pm_domain_name')}
                  isSortedBy={isSortedBy('pm_domain_name')}
                >
                  <FormattedMessage id="tables.headers.property_manager" />
                </TableHead>
                <TableHead
                  isAsc={isAsc}
                  onClick={setSortBy('count')}
                  isSortedBy={isSortedBy('count')}
                >
                  <FormattedMessage id="tables.headers.listing_count" />
                </TableHead>
                <TableHead
                  isAsc={isAsc}
                  onClick={setSortBy('avg_rates')}
                  isSortedBy={isSortedBy('avg_rates')}
                >
                  <FormattedMessage id="tables.headers.adr" />
                </TableHead>
                <TableHead
                  isAsc={isAsc}
                  onClick={setSortBy('avg_occ')}
                  isSortedBy={isSortedBy('avg_occ')}
                >
                  <FormattedMessage id="tables.headers.average_occupancy" />
                </TableHead>
                <TableHead
                  isAsc={isAsc}
                  onClick={setSortBy('review_score')}
                  isSortedBy={isSortedBy('review_score')}
                >
                  <FormattedMessage id="tables.headers.review_score" />
                </TableHead>
                <TableHead>
                  <FormattedMessage id="tables.headers.url" />
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {currentElements.map((stats, idx) => {
                const { pm_domain_name } = stats;
                const details = find({ pm_domain_name }, selectedPmDetails);
                return (
                  <PmStatsLine
                    idx={ELEMENTS_PER_PAGE * (currentPage - 1) + (idx + 1)}
                    key={idx}
                    currency={currency}
                    logo={get('icon_url', details)}
                    url={get('website_url', details)}
                    {...stats}
                  />
                );
              })}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            pages={numberOfPages}
            onChange={goToPage}
            className="is-small"
          />
        </div>
      </div>
    </div>
  );
}

interface PropertyManagerViewProps extends RankingTableProps {
  error?: Error;
}

export const PropertyManagerView: React.SFC<PropertyManagerViewProps> = ({
  error,
  ...props
}) => {
  return error || isEmpty(props.pmStats) ? (
    <ErrorMessage error={new Error('ranking.error.generic')} />
  ) : (
    props.pmStats && <RankingTable {...props} />
  );
};
