import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Loader } from 'components/Layout';
import { ApplicationState } from 'store';
import { getFilterQuery } from 'store/filters/selectors';

import { loadAggregated } from '../store/actions';
import { DISCOUNTS_DOMAIN } from '../types';
import AggregatedLayout from './Layout';

type AggregatedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const Aggregated: React.FunctionComponent<AggregatedProps> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Loader /> : <AggregatedLayout {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loadAggregated }, dispatch),
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const { isOpen: isMenuOpen } = state.layout;
  const appliedQuery = getFilterQuery(state);
  const isLoading = !appliedQuery;
  const { aggregated, errorAggregated, fetchingAggregated } = state[
    DISCOUNTS_DOMAIN
  ];

  return {
    aggregated,
    appliedQuery,
    error: errorAggregated,
    fetching: fetchingAggregated,
    isMenuOpen,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Aggregated);
