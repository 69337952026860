import * as React from 'react';
import { Tabs, makeStyles } from '@material-ui/core';
import { ConnectedTab as Tab } from 'components/Transparent';
import { RouteComponentProps, Route } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RatesCalendar } from '../RatesTable';
import { PriceVariationCalendar } from '../PriceVariationsTable';
import RatesCalendarIcon from '../../../components/Icons/RatesCalendar';
import PriceVariations from '../../../components/Icons/PriceVariationCalendar';
import CountDown from '../../../components/CountDown';

type CalendarProps = RouteComponentProps & WrappedComponentProps;

const useStyles = makeStyles({
  tabs: {
    marginBottom: '20px',
    marginLeft: '20px',
  },
  tab: {
    flexDirection: 'row',
    marginLeft: '20px',
    justifyContent: 'flex-start',
  },
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  icon: {
    marginRight: 20,
  },
});

const RatesAndDemandContainer: React.SFC<CalendarProps> = function({
  history,
  intl,
  match,
}) {
  const classes = useStyles({});
  return (
    <div>
      <Tabs
        value={history.location.pathname}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        classes={{ root: classes.root }}
      >
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}`}
          label={intl.formatMessage({
            id: 'navigation.calendar.price_variation',
          })}
          value={`${match.url}`}
          icon={<PriceVariations className={classes.icon} />}
        />
        <Tab
          classes={{ wrapper: classes.tab }}
          to={`${match.url}/rates`}
          label={intl.formatMessage({ id: 'navigation.calendar.rates' })}
          value={`${match.url}/rates`}
          icon={<RatesCalendarIcon className={classes.icon} />}
        />
        <CountDown />
      </Tabs>
      <Route path={`${match.url}/rates`} render={() => <RatesCalendar />} />
      <Route
        exact={true}
        path={`${match.url}`}
        render={() => <PriceVariationCalendar />}
      />
    </div>
  );
};

export default injectIntl(RatesAndDemandContainer);
