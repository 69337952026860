import React from 'react';
import SelfReportChart from '../reports/SelfReportChart';
import useReport from '../hooks/use-report';
import { useIntl } from 'react-intl';
import SelfReportsSection from '../reports/SelfReportsSection';
import { IReportListItem } from '../lib/types';

const SelfReportingPacing: React.FunctionComponent<{
  report: IReportListItem;
}> = ({ report }) => {
  const { loading, error, data } = useReport(report.query);
  const intl = useIntl();
  return (
    <SelfReportsSection title={report.text} fullwidthReport={true}>
      <SelfReportChart
        id={report.id}
        loading={loading}
        error={error}
        config={report.getConfig(data, intl)}
      />
    </SelfReportsSection>
  );
};

export default SelfReportingPacing;
