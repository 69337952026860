import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { clearMessages } from '../../store/messages/actions';
import NotificationBar from '../../components/Layout/NotificationBar';
import { Check, Warning, InfoOutlined } from '@material-ui/icons';
import { ApplicationState } from '../../store';
import { IClearMessages, IMessagesState } from '../../store/messages/types';

interface MessagesProps {
  messages: IMessagesState;
}

type MessageContainerProps = ReturnType<typeof mapDispatchToProps> &
  MessagesProps;

class Messages extends React.Component<MessageContainerProps> {
  componentWillUnmount() {
    this.props.actions.clearMessages();
  }

  render() {
    const { messages } = this.props;
    return messages.success ? (
      <div>
        {messages.success.map((message, index) => (
          <NotificationBar
            key={index}
            message={message.msg}
            close={true}
            color="success"
            icon={Check}
          />
        ))}
      </div>
    ) : messages.error ? (
      <div>
        {messages.error.map((message, index) => (
          <NotificationBar
            key={index}
            message={message.msg}
            close={true}
            color="danger"
            icon={Warning}
          />
        ))}
      </div>
    ) : messages.info ? (
      <div>
        {messages.info.map((message, index) => (
          <NotificationBar
            key={index}
            message={message.msg}
            close={true}
            color="info"
            icon={InfoOutlined}
          />
        ))}
      </div>
    ) : null;
  }
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<ApplicationState, null, IClearMessages>,
) {
  return {
    actions: bindActionCreators({ clearMessages }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Messages);
