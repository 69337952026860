import { Reducer } from 'redux';
import { ILayoutActions, ILayoutState } from './types';
import { IS_LOADING, IS_READY, OPEN_ASIDE, CLOSE_ASIDE } from './types';
/* eslint-disable  no-fallthrough */
const layout: Reducer<ILayoutState> = (
  state = { isLoading: false, isOpen: false },
  action: ILayoutActions,
) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case IS_READY:
      return {
        ...state,
        isLoading: false,
      };
    case OPEN_ASIDE:
      return { ...state, isOpen: true };
    case CLOSE_ASIDE:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
export default layout;
