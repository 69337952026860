import { get, isEqual } from 'lodash/fp';
import { normalize } from 'normalizr';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { reviewScoreDomain, recentReviewsDomain } from './types';
import { ExtraArguments, ApplicationState } from 'store';
import {
  clearEntities,
  updateAllEntities,
  updateEntity,
} from 'store/entities/actions';
import baseActionTypes from 'store/base-reducer/types';
import { apiAction } from 'store/actions';
import { reviewScoreSchema, recentReviewsSchema } from './schema';
const reviewScoreActionTypes = baseActionTypes(reviewScoreDomain);
const recentReviewsActionTypes = baseActionTypes(recentReviewsDomain);
export const loadReviewScore: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { query } = state.filters;
    const savedQuery: string | null = get(`${reviewScoreDomain}.query`, state);
    if (savedQuery && isEqual(query, savedQuery)) return null;
    dispatch(clearEntities(reviewScoreDomain));
    return dispatch(
      apiAction({
        accessToken: token,
        url: '/reviews/review-score',
        method: 'GET',
        label: reviewScoreDomain,
        params: query,
        onSuccess: response => dispatch => {
          const { entities, result } = normalize(response, reviewScoreSchema);
          dispatch(updateAllEntities(entities));
          dispatch({
            type: reviewScoreActionTypes.LOAD_SUCCESS,
            payload: {
              query,
              extra: { averageScore: result.data.average_score },
            },
          });
        },
        onStart: () => dispatch =>
          dispatch({ type: reviewScoreActionTypes.START_LOADING }),
        onFailure: e => dispatch =>
          dispatch({
            type: reviewScoreActionTypes.LOAD_FAILED,
            payload: { error: e },
          }),
      }),
    );
  };
};

export const loadRecentReviews: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = ({ offset = 0, limit = 5 }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { query } = state.filters;
    const savedQuery: string | null = get(
      `${recentReviewsDomain}.query`,
      state,
    );
    const newQuery = query.concat(`&offset=${offset}&limit=${limit}`);
    if (savedQuery && isEqual(newQuery, savedQuery)) return null;
    dispatch(clearEntities(recentReviewsDomain));
    return dispatch(
      apiAction({
        accessToken: token,
        url: '/reviews/recent-reviews',
        method: 'GET',
        label: recentReviewsDomain,
        params: newQuery,
        onSuccess: response => dispatch => {
          const { entities, result } = normalize(response, recentReviewsSchema);
          dispatch(updateAllEntities(entities));
          dispatch({
            type: recentReviewsActionTypes.LOAD_SUCCESS,
            payload: {
              query,
              extra: { total: result.data._total },
            },
          });
        },
        onStart: () => dispatch =>
          dispatch({ type: recentReviewsActionTypes.START_LOADING }),
        onFailure: e => dispatch =>
          dispatch({
            type: recentReviewsActionTypes.LOAD_FAILED,
            payload: { error: e },
          }),
      }),
    );
  };
};

export const loadReviewsSample: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = ({ from = 0, to = 5, limit = 150 }, _domain) => {
  const actionTypes = baseActionTypes(_domain);
  const domain = _domain;
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { query } = state.filters;
    const savedQuery: string | null = get(
      `${recentReviewsDomain}.query`,
      state,
    );
    const newQuery = query.concat(`&from=${from}&to=${to}&limit=${limit}`);
    if (savedQuery && isEqual(newQuery, savedQuery)) return null;
    // dispatch(clearEntities(domain));
    return dispatch(
      apiAction({
        accessToken: token,
        url: '/reviews/recent-reviews-words',
        method: 'GET',
        label: domain,
        params: newQuery,
        onSuccess: response => dispatch => {
          const { data } = response;
          dispatch(updateEntity(domain, 'reviews', data.reviews));
          dispatch({
            type: actionTypes.LOAD_SUCCESS,
            payload: {
              query,
            },
          });
        },
        onStart: () => dispatch =>
          dispatch({ type: actionTypes.START_LOADING }),
        onFailure: e => dispatch =>
          dispatch({
            type: actionTypes.LOAD_FAILED,
            payload: { error: e },
          }),
      }),
    );
  };
};
