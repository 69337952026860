import * as React from 'react';
import {
  withStyles,
  CircularProgress,
  StyledComponentProps,
} from '@material-ui/core';

const styles = {
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 290,
  },
};

const Loader: React.SFC<StyledComponentProps> = function(props) {
  const { classes } = props;

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(Loader);
