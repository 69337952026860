import * as React from 'react';
import clsx from 'clsx';
import {
  ListItem,
  withStyles,
  StyledComponentProps,
  Button,
} from '@material-ui/core';
import styles from './styles';

interface FiltersActionsProps extends StyledComponentProps {
  handleClear: (event: React.MouseEvent<HTMLElement>) => void;
  onClickSubmitButton?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}

const FiltersActions: React.SFC<FiltersActionsProps> = function({
  classes,
  handleClear,
  open,
  onClickSubmitButton,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div className={classes.actionsWrapper}>
      <ListItem
        className={clsx(classes.actionsContainer, !open && classes.hidden)}
      >
        <Button
          className={classes.applyButton}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClickSubmitButton}
          color="secondary"
          variant="contained"
          size="medium"
          type="submit"
        >
          Apply Filters
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={handleClear}
          className={classes.clearButton}
        >
          Clear Filters
        </Button>
      </ListItem>
    </div>
  );
};

export default withStyles(styles)(FiltersActions);
