import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { getEntity } from 'store/entities/selectors';
import { ApplicationState } from 'store';

interface CellLayoutProps extends ReturnType<typeof mapStateToProps> {
  currency: string;
  intl: IntlShape;
}

interface PricingCellProps {
  currency: string;
  date: string;
  unifiedId: string;
  isLoading: boolean;
}

export const CellLayout = injectIntl<'intl', CellLayoutProps>(
  ({ price, currency, intl, isLoading }) => {
    if (isLoading) return null;
    return price?.available ? (
      <td style={{ background: '#FFFAE4' }}>
        {price?.price
          ? intl.formatNumber(price?.price, {
              style: 'currency',
              currency,
            })
          : '-'}
      </td>
    ) : (
      <td
        style={{
          background:
            'repeating-linear-gradient(-45deg,#08d8cb,#08d8cb 2px,#FFFAE4 2px, #FFFAE4 4px)',
          width: '100%',
          height: '100%',
        }}
      />
    );
  },
);

const mapStateToProps = (
  state: ApplicationState,
  { date, unifiedId, ...props }: PricingCellProps,
) => ({
  ...props,
  price: getEntity(state, 'listingsPricing', unifiedId)?.[date],
});

export const PricingCell = connect(mapStateToProps)(CellLayout);
