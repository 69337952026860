import { truncate } from 'lodash';
import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { makeStyles, Dialog, Link } from '@material-ui/core';

import GarbageIcon from 'components/Icons/GarbageIcon';
import { Loader } from 'components/Layout';
import { Table, TableHead } from 'components/Tables';

import { Group } from '../types';
import { Status } from '../lib/enums';

import './layout.scss';
interface MyGroupsProps {
  deleteGroup: (group: Group) => void;
  status: Status;
  fetchGroups: () => void;
  groups: Group[];
}

const styles = makeStyles({
  container: { padding: '34px' },
  deleteContainer: { cursor: 'pointer' },
  buttonContainer: { paddingTop: '45px - 1.5rem' },
  button: {
    height: '52px',
    textTransform: 'uppercase',
    border: 'none',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  buttonLink: { color: 'white' },
  dialogContainer: {
    width: '526px',
    display: 'flex',
    'flex-flow': 'column wrap',
    'align-items': 'stretch',
    padding: '100px',
    textAlign: 'center',
  },
  dialogMessage: {
    fontSize: '25px',
    lineHeight: '25px',
    fontStyle: 'bold',
    color: '#505D6E',
    marginBottom: '20px',
  },
  groupName: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
  },
});

const GroupRow: React.SFC<any> = function({
  group,
  setShowDeleteConfirmation,
  setToDelete,
  selectGroup,
}) {
  const classes = styles({});
  const { groupName, unifiedIds } = group;

  const editGroup = (): void => {
    selectGroup(group);
  };

  return (
    <tr className="group-row">
      <td onClick={editGroup}>
        <span className={classes.groupName}>{groupName}</span>
      </td>
      <td onClick={editGroup} style={{ width: '250px' }}>
        {unifiedIds.length}
      </td>
      <td>
        <span
          className={classes.deleteContainer}
          onClick={() => {
            setShowDeleteConfirmation(true);
            setToDelete(group);
          }}
        >
          <GarbageIcon width={20} fill="#FF889D" />
        </span>
      </td>
    </tr>
  );
};

const DeleteConfirmation: React.SFC<any> = ({
  deleteGroup,
  open,
  setOpen,
  toDelete,
}) => {
  const GROUP_NAME_LENGTH = 20;
  const classes = styles({});

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <div className={classes.dialogContainer}>
        <div className={classes.dialogMessage}>
          <FormattedMessage
            id="settings.pm_groups.my_groups.delete.message"
            values={{
              groupName: truncate(toDelete?.groupName, {
                length: GROUP_NAME_LENGTH,
              }),
            }}
          />
        </div>
        <div className="buttons">
          <button
            className="button is-danger"
            onClick={() => {
              deleteGroup(toDelete);
              setOpen(false);
            }}
          >
            <FormattedMessage id="settings.pm_groups.my_groups.delete.ok" />
          </button>
          <button className="button" onClick={() => setOpen(false)}>
            <FormattedMessage id="settings.pm_groups.my_groups.delete.ko" />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const MyGroups: React.SFC<MyGroupsProps &
  WrappedComponentProps &
  any> = function({
  deleteGroup,
  status,
  fetchGroups,
  groups,
  history,
  location,
}) {
  React.useEffect(() => {
    if (status !== Status.IS_LOADING) fetchGroups();
    // eslint-disable-next-line
  }, [fetchGroups]);

  const classes = styles({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<
    boolean
  >(false);
  const [toDelete, setToDelete] = React.useState<Group>(null);
  const selectGroup = async (group: Group | null) => {
    history.push(
      `/settings/pm-groups/my-groups/${encodeURIComponent(group.groupName)}${
        location.search
      }`,
    );
  };
  return status === Status.IS_LOADING ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <Table>
          <thead>
            <tr>
              <TableHead>
                <FormattedMessage id="settings.pm_groups.import.group_name.label" />
              </TableHead>
              <TableHead width="250px">
                <FormattedMessage id="settings.pm_groups.my_groups.matched_listings" />
              </TableHead>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {groups.length ? (
              groups.map(g => (
                <GroupRow
                  key={g.groupName}
                  group={g}
                  setShowDeleteConfirmation={setShowDeleteConfirmation}
                  setToDelete={setToDelete}
                  selectGroup={selectGroup}
                />
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="settings.pm_groups.my_groups.no_groups" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className={`control ${classes.buttonContainer}`}>
          <button
            className={`button is-success ${classes.button}`}
            style={{ background: '#08D8CB' }}
          >
            <Link
              component={RouterLink}
              to="/settings/pm-groups/import"
              className={classes.buttonLink}
            >
              <FormattedMessage id="settings.pm_groups.my_groups.create_new_group" />
            </Link>
          </button>
        </div>
      </div>
      <DeleteConfirmation
        deleteGroup={deleteGroup}
        open={showDeleteConfirmation}
        setOpen={setShowDeleteConfirmation}
        toDelete={toDelete}
      />
    </React.Fragment>
  );
};

export default injectIntl(withRouter(MyGroups));
