import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { normalize } from 'normalizr';
import { isEqual } from 'lodash/fp';

import { RatesSchema } from './schema';
import { ExtraArguments, ApplicationState } from '../';
import * as actionTypes from './types';
import * as selectors from './selectors';
import { apiAction } from 'store/actions';

export const loadEvents: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  actionTypes.EventActions
>> = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;
    const { query } = state.filters;
    const appliedQuery = state.rates.query;
    if (query && isEqual(query, appliedQuery)) return null;
    return dispatch(
      apiAction({
        method: 'GET',
        url: '/events',
        accessToken: token,
        params: query,
        label: 'eventsCalendar',
        onStart: () => dispatch =>
          dispatch({ type: actionTypes.START_LOADING_EVENTS }),
        onFailure: e => dispatch =>
          dispatch({
            type: actionTypes.LOAD_EVENTS_ERROR,
            payload: { error: e },
          }),
        onSuccess: response => dispatch => {
          const { entities } = normalize(response, RatesSchema);
          dispatch({ type: actionTypes.SET_EVENTS_QUERY, payload: query });

          dispatch({
            type: actionTypes.SET_EVENTS,
            payload: {
              calendar: entities.calendar,
            },
          });
        },
      }),
    );
  };
};

export const selectCurrentEvent: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  actionTypes.EventActions
>> = (date: string) => {
  return (dispatch, getState) => {
    const event = selectors.selectEvent(date, getState());
    if (!event) return false;
    dispatch({
      type: actionTypes.SELECT_EVENT,
      payload: event,
    });
  };
};

export const toggleOpen: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  actionTypes.EventActions
>> = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOGGLE_OPEN,
    });
  };
};
