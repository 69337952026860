import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ExtraArguments, ApplicationState } from 'store';
import { getToken } from 'store/auth/selectors';

import { get } from 'lodash/fp';
import { normalize } from 'normalizr';

import { apiAction } from 'store/actions';
import baseActionTypes from 'store/base-reducer/types';
import { updateEntities, clearEntities } from 'store/entities/actions';
import { getFilterQuery } from 'store/filters/selectors';
import { createSelector, getSortKey } from 'store/pagination';

import { listingsDetailsDomain, listingsPricingDomain } from './types';
import { listingsDetailsSchema, listingsPricingSchema } from './schema';

const supplyEndpoint = 'listings_stats';
const pricingEndpoint = '/listings_stats/pricing';

export const getSelector = (domain = listingsDetailsDomain) =>
  createSelector(domain);

export const loadFirstPage: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (
  startLoadListings: () => any,
  successLoadListings: () => any,
  failLoadListings: (payload: any) => any,
  paginator,
  domain = listingsDetailsDomain,
) => async (dispatch, getState) => {
  try {
    const selector = createSelector(domain);

    dispatch(startLoadListings());

    const defaultPaginationValues = {
      order: 'DESC',
      orderBy: 'reviewCount',
      limit: 15,
    };
    const state = getState();
    const statePage = 1;
    let pagParams = selector.getPaginationParams(
      statePage,
      defaultPaginationValues,
    );
    const filterQuery = getFilterQuery(state);
    const query = new URLSearchParams(filterQuery);
    Object.keys(pagParams).forEach(key => query.set(key, pagParams[key]));
    const token = getToken(state);
    dispatch(paginator.resetPagination());
    dispatch(
      paginator.requestPage({
        orderBy: pagParams.orderBy,
        order: pagParams.order,
        page: statePage,
      }),
    );
    const supplyData = await dispatch<any>(
      requestActionHandler(supplyEndpoint, 'listings', {
        token,
        filterQuery,
        params: query.toString(),
      }),
    );
    if (supplyData?.status === 'SUCCESS') {
      const { entities, result } = normalize(supplyData, listingsDetailsSchema);
      dispatch(
        paginator.receivePage(
          {
            ...pagParams,
            total: result.data._total,
            page: statePage,
          },
          result.data.listings,
        ),
      );
      dispatch(updateEntities(domain, get('listingsDetails', entities)));

      const pricingData = await dispatch<any>(
        requestActionHandler(pricingEndpoint, listingsPricingDomain, {
          token,
          filterQuery,
          params: query.toString(),
        }),
      );
      if (pricingData?.status === 'SUCCESS') {
        const { entities } = normalize(pricingData, listingsPricingSchema);
        dispatch(clearEntities(listingsPricingDomain));
        dispatch(
          updateEntities(
            listingsPricingDomain,
            get('listingsPricing', entities),
          ),
        );
      }
      dispatch(successLoadListings());
    }
  } catch (error) {
    dispatch(failLoadListings({ error }));
  }
};

export const goToPage: ActionCreator<ThunkAction<
  void,
  ApplicationState,
  ExtraArguments,
  any
>> = (
  paginationParams: any = {},
  page,
  startLoadListings: () => any,
  successLoadListings: () => any,
  failLoadListings: (payload: any) => any,
  paginator,
  domain,
) => async (dispatch, getState) => {
  try {
    const selector = getSelector(domain);
    dispatch(startLoadListings());

    const state = getState();
    const totalPages = selector.getTotalPages(state);
    let pagParams = selector.getPaginationParams(page, paginationParams);
    const statePage = selector.getCurrentPage(state).page || 1;
    let currentPage = page || statePage;
    if (currentPage > totalPages || currentPage < 1) return;
    const isNewPage = !selector.isPageCached(
      state,
      currentPage,
      getSortKey({ order: pagParams.order, orderBy: pagParams.orderBy }),
    );

    const filterQuery = getFilterQuery(state);
    const query = new URLSearchParams(filterQuery);
    Object.keys(pagParams).forEach(key => query.set(key, pagParams[key]));
    const token = getToken(state);
    if (isNewPage) {
      dispatch(
        paginator.requestPage({
          orderBy: pagParams.orderBy,
          order: pagParams.order,
          page: currentPage,
        }),
      );
      const supplyData = await dispatch<any>(
        requestActionHandler(supplyEndpoint, domain, {
          token,
          filterQuery,
          params: query.toString(),
        }),
      );

      if (supplyData?.status === 'SUCCESS') {
        const { entities, result } = normalize(
          supplyData,
          listingsDetailsSchema,
        );
        dispatch(
          paginator.receivePage(
            {
              ...pagParams,
              total: result.data._total,
              page: currentPage,
            },
            result.data.listings,
          ),
        );
        dispatch(updateEntities(domain, get('listingsDetails', entities)));
      }
    } else {
      dispatch(paginator.goToPage({ ...pagParams, page: currentPage }));
    }
    const pricingData = await dispatch<any>(
      requestActionHandler(pricingEndpoint, listingsPricingDomain, {
        token,
        filterQuery,
        params: query.toString(),
      }),
    );
    if (pricingData?.status === 'SUCCESS') {
      const { entities } = normalize(pricingData, listingsPricingSchema);
      dispatch(clearEntities(listingsPricingDomain));
      dispatch(
        updateEntities(listingsPricingDomain, get('listingsPricing', entities)),
      );
    }
    dispatch(successLoadListings());
  } catch (error) {
    dispatch(failLoadListings({ error }));
  }
};

export function requestActionHandler(
  url: string,
  domain: string,
  { params, token, filterQuery },
) {
  return apiAction({
    accessToken: token,
    url,
    method: 'GET',
    label: domain,
    params,
    onSuccess: data => dispatch => {
      dispatch({
        type: baseActionTypes(domain).LOAD_SUCCESS,
        payload: {
          query: filterQuery,
          extra: {
            currency: data?.data?.currency,
          },
        },
      });
    },
    onStart: () => dispatch =>
      dispatch({ type: baseActionTypes(domain).START_LOADING }),
    onFailure: e => dispatch =>
      dispatch({
        type: baseActionTypes(domain).LOAD_FAILED,
        payload: { error: e.message },
      }),
  });
}
