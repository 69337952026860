import { chain, isNaN } from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import BaseChart, { BaseChartProps } from './BaseChart';

export interface SeriesChartOptions {
  categoryField: string;
  categoryLabel?: string;
  horizontal?: boolean;
  valueLabel?: string;
  title?: string;
  valueMax?: number;
  valueMin?: number;
}
export interface SeriesChartProps extends BaseChartProps {
  options: SeriesChartOptions;
  isPercentage?: boolean;
  formatCurrency?: boolean;
  currency?: string;
}
/**
 *
 * @param val
 * TODO legacy function, remove it as soon a possible
 */
function formatValue(val) {
  const isNumber = parseFloat(val);
  if (isNaN(isNumber)) {
    return val;
  }
  return isNumber;
}

export default abstract class SeriesChart<
  T extends SeriesChartProps
> extends BaseChart<T> {
  private onBeforeDataValidatedEvent: any;
  buildChart() {
    const { options, isPercentage } = this.props;
    const {
      categoryField,
      categoryLabel,
      horizontal,
      valueLabel,
      title,
      valueMax,
      valueMin,
    } = options;

    const chart = am4core.create(this.props.chartId, am4charts.XYChart);
    categoryField === 'key' && (chart.legend = new am4charts.Legend()); // TODO: improve it
    chart.colors.list = [
      am4core.color('#02315E'),
      am4core.color('#723ac3'),
      am4core.color('#5b97ff'),
      am4core.color('#9aa5b8'),
      am4core.color('#83eec5'),
      am4core.color('#fa378f'),
      am4core.color('#e04a3b'),
      am4core.color('#f5d915'),
    ];
    const categoryAxis = horizontal
      ? chart.yAxes.push(new am4charts.CategoryAxis())
      : chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    categoryLabel && (categoryAxis.title.text = categoryLabel);

    const valueAxis = horizontal
      ? chart.xAxes.push(new am4charts.ValueAxis())
      : chart.yAxes.push(new am4charts.ValueAxis());
    valueLabel && (valueAxis.title.text = valueLabel);
    if (isPercentage) {
      valueAxis.min = valueMin || 0;
      if (valueMax !== null) {
        valueAxis.max = valueMax || 100;
        valueAxis.strictMinMax = true;
      }
    }
    /**
     * TODO remove event to order if possible, use ordered series instead
     */
    this.onBeforeDataValidatedEvent = chart.events.on(
      'beforedatavalidated',
      () => {
        const data = chain(chart.data)
          .compact()
          .sortBy(o => formatValue(o[categoryField]))
          .value();

        Object.assign(chart, { data });
      },
    );

    if (title) {
      const chartTitle = chart.titles.create();
      chartTitle.text = title;
    }

    return chart;
  }

  dispose() {
    if (this.chart) {
      this.onBeforeDataValidatedEvent.dispose();
      this.chart.dispose();
      this.chart = null;
    }
  }
}
