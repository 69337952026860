export type IFilter = string | number;
export const SET_FILTERS = 'SET_FILTERS';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const FILTERS_LOADING = 'FILTERS_LOADING';
export const FILTERS_LOADING_ERROR = 'FILTERS_LOADING_ERROR';
export const SET_FILTER = 'SET_FILTER';
export const SET_QUERY = 'SET_QUERY';
export const FILTERS_READY = 'FILTERS_READY';
export const ADD_DISABLED = 'SET_DISABLED';
export const REMOVE_DISABLED = 'REMOVE_DISABLED';

export type Filters =
  | 'market'
  | 'clean_url'
  | 'bedroom'
  | 'capacity'
  | 'city'
  | 'zipcode';

export interface IFilterDictionary {
  [key: string]: IFilter[] | IFilter;
}

interface DatesRange {
  from: Date;
  to: Date;
}
export interface CalendarStateDates {
  minDate: Date;
  maxDate: Date;
  selectedDates: DatesRange;
}
export interface IFiltersState {
  availableFilters?: IFilterDictionary;
  selectedFilters?: IFilterDictionary;
  dates?: CalendarStateDates;
  isLoading?: boolean;
  error?: any;
  query?: string;
  disabled: string[];
}

interface ISeFilters {
  type: typeof SET_FILTERS;
  payload?: IFiltersState;
}

interface ISetDates {
  type: typeof SET_DATE_RANGE;
  payload?: DatesRange;
}

interface IFiltersLoading {
  type: typeof FILTERS_LOADING;
  payload?: undefined;
}

interface IFiltersReady {
  type: typeof FILTERS_READY;
  payload?: undefined;
}

interface IFiltersLoadingError {
  type: typeof FILTERS_LOADING_ERROR;
  payload: { error: Error };
}

interface IFiltersUpdateFilter {
  type: typeof SET_FILTER;
  payload: {
    name: string;
    filter: IFilter[];
  };
}

interface IFiltersSetQuery {
  type: typeof SET_QUERY;
  payload: string;
}

interface IFilterSetFilters {
  type: typeof SET_FILTERS;
  payload: { selectedFilters: any[] };
}
interface IFiltersAddDisabled {
  type: typeof ADD_DISABLED;
  payload: string;
}
interface IFiltersRemoveDisabled {
  type: typeof REMOVE_DISABLED;
  payload: string;
}

export type IFiltersActions =
  | ISeFilters
  | ISetDates
  | IFiltersLoading
  | IFiltersReady
  | IFiltersLoadingError
  | IFiltersUpdateFilter
  | IFiltersSetQuery
  | IFilterSetFilters
  | IFiltersAddDisabled
  | IFiltersRemoveDisabled;
