import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { MarketLink, Supply, SupplyError } from '../../../components/Supply';
import { ApplicationState } from '../../../store/index';
import { Loader } from '../../../components/Layout/index';
import { Mixpanel } from '../../../services/mixpanel/index';

const onClickTrack = (market: string) => () => {
  Mixpanel.track('Click on Download supply', { market });
};

const SupplyDownload: React.SFC<SupplyProps> = function({
  loading,
  market,
  error,
}) {
  if (!market)
    return <SupplyError error={new Error('supply.error.emptyMarket')} />;
  return loading ? (
    <Loader />
  ) : (
    <React.Fragment key={market.market}>
      <Supply marketDoc={market} error={error} loading={loading} />
      <MarketLink
        marketUrls={market}
        onClickButton={onClickTrack(market.market)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: Pick<ApplicationState, 'supplies'>) => {
  return {
    loading: get(state, 'supplies.isLoading', true),
    market: get(state, 'supplies.markets.0', null),
    error: get(state, 'supplies.error', null),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type SupplyProps = StateProps;
export default connect(mapStateToProps)(SupplyDownload);
