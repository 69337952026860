import * as React from 'react';
import { StatusPattern, getOnboardingIconColors } from './Utils';
import { IOnboardingIconProps } from './types';

const CompetitorsIcon = ({ active, ...props }: IOnboardingIconProps) => {
  const [primary, secondary] = getOnboardingIconColors(active);
  return (
    <svg id="competitors__1" viewBox="0 0 174.9 174.9" {...props}>
      <circle cx={87.4} cy={87.1} r={87.4} opacity={0.1} fill="#aad0f5" />
      <g opacity={0.5}>
        <linearGradient
          id="competitors__SVG_1"
          gradientUnits="userSpaceOnUse"
          x1={87.45}
          y1={762.4}
          x2={87.45}
          y2={706.673}
          gradientTransform="translate(0 -666)"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          d="M106 71.6V60.8h6.4c.6 2.2 2.3 3.9 4.4 4.4v25h-3.1v5.4c0 .4.3.8.8.8h7.8c.4 0 .8-.3.8-.8v-5.4H120v-25c2.7-.7 4.7-3.1 4.7-6 0-3.4-2.8-6.2-6.2-6.2-2.9 0-5.3 2-6 4.7H106V46.8c0-3.4-2.8-6.2-6.2-6.2H75c-3.4 0-6.2 2.8-6.2 6.2v10.9h-6.4c-.7-2.7-3.1-4.7-6-4.7-3.4 0-6.2 2.8-6.2 6.2 0 2.9 2 5.3 4.7 6v25h-3.1v5.4c0 .4.3.8.8.8h7.8c.4 0 .8-.3.8-.8v-5.4H58v-25c2.2-.6 3.9-2.3 4.4-4.4h6.4v10.9c0 3.4 2.8 6.2 6.2 6.2h24.8c3.5-.1 6.2-2.8 6.2-6.3zM84.3 56.1c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6v-2.3c-1.9 1.7-3.1 4.3-3.1 6.9 0 1.7.5 3.3 1.3 4.8.4.7.2 1.7-.5 2.1-.2.2-.5.2-.8.2-.5 0-1-.3-1.3-.8-1.2-1.9-1.8-4.1-1.8-6.4 0-3.6 1.6-7 4.2-9.3h-2.7c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6h6.2c.9 0 1.6.7 1.6 1.6l.1 6.4zm14 14h-6.2c-.9 0-1.6-.7-1.6-1.6v-6.2c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6v2c1-1.5 1.6-3.3 1.6-5.1 0-1.4-.3-2.7-.9-4-.4-.8 0-1.7.7-2.1.8-.4 1.7 0 2.1.7.8 1.7 1.2 3.5 1.2 5.3 0 2.9-1 5.6-2.7 7.8h2.7c.9 0 1.6.7 1.6 1.6s-.9 1.6-1.7 1.6z"
          fill="url(#competitors__SVG_1)"
        />
        <linearGradient
          id="competitors__SVG_2"
          gradientUnits="userSpaceOnUse"
          x1={56.3}
          y1={799.558}
          x2={56.3}
          y2={790.258}
          gradientTransform="translate(0 -666)"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          d="M40.9 129c0 2.6 2.1 4.6 4.6 4.6h21.7c2.6 0 4.6-2.1 4.6-4.6v-4.7h-31c.1 0 .1 4.7.1 4.7zm12.4-1.6h6.2c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6h-6.2c-.9 0-1.6-.7-1.6-1.6s.8-1.6 1.6-1.6z"
          fill="url(#competitors__SVG_2)"
        />
        <linearGradient
          id="competitors__SVG_3"
          gradientUnits="userSpaceOnUse"
          x1={56.4}
          y1={787.161}
          x2={56.4}
          y2={756.161}
          gradientTransform="translate(0 -666)"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          d="M67.3 90.2h-3.1v5.4c0 2.1-1.7 3.9-3.9 3.9h-7.8c-2.1 0-3.9-1.7-3.9-3.9v-5.4h-3.1c-2.6 0-4.6 2.1-4.6 4.6v26.4h31V94.9c0-2.6-2-4.7-4.6-4.7z"
          fill="url(#competitors__SVG_3)"
        />
        <linearGradient
          id="competitors__SVG_4"
          gradientUnits="userSpaceOnUse"
          x1={118.3}
          y1={799.557}
          x2={118.3}
          y2={790.257}
          gradientTransform="translate(0 -666)"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          d="M102.9 129c0 2.6 2.1 4.6 4.6 4.6h21.7c2.6 0 4.6-2.1 4.6-4.6v-4.7h-31l.1 4.7zm12.4-1.6h6.2c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6h-6.2c-.9 0-1.6-.7-1.6-1.6s.8-1.6 1.6-1.6z"
          fill="url(#competitors__SVG_4)"
        />
        <linearGradient
          id="competitors__SVG_5"
          gradientUnits="userSpaceOnUse"
          x1={118.4}
          y1={787.159}
          x2={118.4}
          y2={756.159}
          gradientTransform="translate(0 -666)"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          d="M129.3 90.2h-3.1v5.4c0 2.1-1.7 3.9-3.9 3.9h-7.8c-2.1 0-3.9-1.7-3.9-3.9v-5.4h-3.1c-2.6 0-4.6 2.1-4.6 4.6v26.4h31V94.9c0-2.6-2-4.7-4.6-4.7z"
          fill="url(#competitors__SVG_5)"
        />
      </g>
      <StatusPattern done={active} />
    </svg>
  );
};

export default CompetitorsIcon;
