import * as React from 'react';
import Color from 'color';
import { getSemaphoreStyle } from './styles';
import { Typography } from '@material-ui/core';

interface ISemaphoreProps {
  color: string;
  value: number;
  locale: string;
  maxDiff?: number;
  minTransparency?: number;
  maxTransparency?: number;
}

const MAX_DIFFERENCE = 0.3;
const MAX_TRANSPARENCY = 0.9;
const MIN_TRANSPARENCY = 0.1;

function getPercent(value: number, locale: string) {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
  };
  return value.toLocaleString(locale, options);
}

function getColor(props: ISemaphoreProps) {
  const defaultColor = Color('#FFF');
  const {
    color,
    value,
    maxDiff = MAX_DIFFERENCE,
    minTransparency = MIN_TRANSPARENCY,
    maxTransparency = MAX_TRANSPARENCY,
  } = props;

  const _value = Math.abs(value);
  const intensity = _value - maxDiff > 0 ? 1 : _value / maxDiff;
  const _color = Color(color);
  if (!_color) console.warn(`No color from ${color}`);
  const transparency =
    intensity < minTransparency
      ? minTransparency
      : intensity > maxTransparency
      ? maxTransparency
      : intensity;

  const fade = 1 - transparency;
  const patternColor = _color || defaultColor;
  const backgroundColor = patternColor.fade(fade);
  const fontColor = Color('#000');

  return [fontColor.string(), backgroundColor.string(), patternColor.string()];
}

export const Semaphore: React.SFC<ISemaphoreProps> = props => {
  const { locale, value } = props;
  if (!value) {
    return (
      <div style={getSemaphoreStyle('rgb(0,0,0,0)')}>
        <Typography variant="body2">&nbsp;</Typography>
      </div>
    );
  }

  const [fontColor, color] = getColor(props);

  return (
    <div style={getSemaphoreStyle(color)}>
      <Typography variant="body2" style={{ color: fontColor }}>
        {getPercent(value, locale)}
      </Typography>
    </div>
  );
};

export default Semaphore;
