import * as React from 'react';
import { StatusPattern, getOnboardingIconColors } from './Utils';
import { IOnboardingIconProps } from './types';

const Credentials = ({ active, ...props }: IOnboardingIconProps) => {
  const [primary, secondary] = getOnboardingIconColors(active);
  return (
    <svg viewBox="0 0 174.9 174.9" {...props}>
      <circle cx={87.4} cy={87.4} r={87.4} opacity={0.1} fill="#aad0f5" />
      <linearGradient
        id="credentials__a"
        gradientUnits="userSpaceOnUse"
        x1={87.437}
        y1={131.707}
        x2={87.437}
        y2={75.068}
      >
        <stop offset={0} stopColor={primary} />
        <stop offset={1} stopColor={secondary} />
      </linearGradient>
      <path
        d="M70.5 116.9c-2.7-4.9-4.2-10.9-4.2-17.4 0-10.9 9.5-19.9 21.1-19.9 11.6 0 21.1 8.9 21.1 19.9 0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3 0-13.5-11.5-24.4-25.6-24.4S61.8 86 61.8 99.5c0 7.3 1.7 14.1 4.8 19.7 3.1 5.5 5.2 8 9.1 11.9.5.5 1 .7 1.6.7.6 0 1.2-.2 1.6-.7.9-.9.9-2.3 0-3.2-3.5-3.6-5.5-5.8-8.4-11z"
        fill="url(#credentials__a)"
      />
      <linearGradient
        id="credentials__b"
        gradientUnits="userSpaceOnUse"
        x1={87.459}
        y1={53.379}
        x2={87.459}
        y2={42.202}
      >
        <stop offset={0} stopColor={primary} />
        <stop offset={1} stopColor={secondary} />
      </linearGradient>
      <path
        d="M62.3 53.1c7.8-4.2 16.2-6.4 25.2-6.4s16.5 1.9 25.2 6.4c.3.2.7.2 1 .2.8 0 1.6-.5 2-1.2.6-1.1.1-2.5-1-3.1-9.2-4.8-17.6-6.9-27.3-6.9S68.5 44.4 60 49c-1.1.6-1.5 2-.9 3.1.7 1.2 2.1 1.6 3.2 1z"
        fill="url(#credentials__b)"
      />
      <linearGradient
        id="credentials__c"
        gradientUnits="userSpaceOnUse"
        x1={87.392}
        y1={132.68}
        x2={87.392}
        y2={64.052}
      >
        <stop offset={0} stopColor={primary} />
        <stop offset={1} stopColor={secondary} />
      </linearGradient>
      <path
        d="M87.3 64.1c-14.6 0-27.9 8.2-34 20.8-2.1 4.3-3.1 9.2-3.1 14.6 0 6.1 1.1 11.9 3.3 17.9.5 1.2 1.7 1.8 2.9 1.3s1.8-1.7 1.3-2.9c-2.7-7.2-3-12.8-3-16.3 0-4.8.9-9 2.6-12.7 5.3-11.1 17-18.2 29.9-18.2 18.1 0 32.8 13.9 32.8 30.9 0 4.9-4.2 8.8-9.4 8.8s-9.4-4-9.4-8.8c0-7.4-6.2-13.3-13.9-13.3s-13.9 6-13.9 13.3c0 8.9 3.5 17.3 9.8 23.6 4.9 4.9 9.6 7.5 16.8 9.5.2.1.4.1.6.1 1 0 1.9-.7 2.2-1.7.3-1.2-.4-2.4-1.6-2.8-6.4-1.7-10.5-4.1-14.8-8.3-5.5-5.4-8.5-12.7-8.5-20.4 0-4.9 4.2-8.8 9.4-8.8s9.4 4 9.4 8.8c0 7.4 6.3 13.3 13.9 13.3s13.9-6 13.9-13.3c.1-19.5-16.7-35.4-37.2-35.4z"
        fill="url(#credentials__c)"
      />
      <linearGradient
        id="credentials__d"
        gradientUnits="userSpaceOnUse"
        x1={87.441}
        y1={77.127}
        x2={87.441}
        y2={53.232}
      >
        <stop offset={0} stopColor={primary} />
        <stop offset={1} stopColor={secondary} />
      </linearGradient>
      <path
        d="M127.7 73.4c-4.5-6.3-10.2-11.2-17-14.7-14.3-7.3-32.4-7.3-46.7 0-6.8 3.5-12.5 8.5-17 14.8-.7 1-.5 2.4.5 3.1.4.3.9.4 1.3.4.7 0 1.4-.3 1.9-.9 4-5.7 9.2-10.2 15.4-13.4 13-6.7 29.5-6.7 42.5 0 6.1 3.1 11.3 7.6 15.3 13.3.7 1 2.1 1.2 3.2.5s1.4-2.1.6-3.1z"
        fill="url(#credentials__d)"
      />
      <linearGradient
        id="credentials__e"
        gradientUnits="userSpaceOnUse"
        x1={101.005}
        y1={123.338}
        x2={101.005}
        y2={97.213}
      >
        <stop offset={0} stopColor={primary} />
        <stop offset={1} stopColor={secondary} />
      </linearGradient>
      <StatusPattern done={active} />
    </svg>
  );
};

export default Credentials;
