import { Status } from './lib/enums';
import { Status as ListingsStatus } from 'store/listings/types';

export const PM_GROUPS_DOMAIN = 'pmGroups';

export const ACTIONS = {
  FETCH_PM_GROUPS_START: 'FETCH_PM_GROUPS_START',
  FETCH_PM_GROUPS_SUCCESS: 'FETCH_PM_GROUPS_SUCCESS',
  FETCH_PM_GROUPS_FAILURE: 'FETCH_PM_GROUPS_FAILURE',
  CREATE_PM_GROUP_START: 'CREATE_PM_GROUP_START',
  CREATE_PM_GROUP_SUCCESS: 'CREATE_PM_GROUP_SUCCESS',
  CREATE_PM_GROUP_FAILURE: 'CREATE_PM_GROUPS_FAILURE',
  HIDE_CREATE_PM_GROUP_RESULTS: 'HIDE_CREATE_PM_GROUP_RESULTS',
  DELETE_PM_GROUP_START: 'DELETE_PM_GROUP_START',
  DELETE_PM_GROUP_SUCCESS: 'DELETE_PM_GROUP_SUCCESS',
  DELETE_PM_GROUP_FAILURE: 'DELETE_PM_GROUPS_FAILURE',
  UPDATE_PM_GROUP_SUCCESS: 'UPDATE_PM_GROUP_SUCCESS',
  UPDATE_LISTINGS_START: 'UPDATE_LISTINGS_START',
  UPDATE_LISTINGS_SUCCESS: 'UPDATE_LISTINGS_SUCCESS',
  UPDATE_LISTINGS_FAILURE: 'UPDATE_LISTINGS_FAILURE',
  IS_LOADING: 'PM_GROUPS.IS_LOADING',
  ADD_LISTING_SELECTION: 'ADD_LISTING_TO_SELECTION',
  REMOVE_LISTING_SELECTION: 'REMOVE_LISTING_TO_SELECTION',
  REMOVE_ALL_LISTING_SELECTION: 'REMOVE_ALL_LISTING_SELECTION',
  CREATE_PM_GROUP_START_LS: 'CREATE_PM_GROUP_START_LS',
  CREATE_PM_GROUP_SUCCESS_LS: 'CREATE_PM_GROUP_SUCCESS_LS',
  UPDATE_PM_GROUP_SUCCESS_LS: 'UPDATE_PM_GROUP_SUCCESS_LS',
  UPDATE_GROUPS_STATUS: 'UPDATE_GROUPS_STATUS',
  UPDATE_SELECTED_GROUP: 'UPDATE_SELECTED_GROUP',
  DELETE_FROM_GROUP_START: 'DELETE_FROM_GROUP_START',
  DELETE_FROM_GROUP_SUCCESS: 'DELETE_FROM_GROUP_SUCCESS',
  DELETE_FROM_GROUP_FAIL: 'DELETE_FROM_GROUP_FAIL',
  RESET_DELETE_COUNT: 'RESET_DELETE_COUNT',
};

export interface RawGroup {
  name: string;
  urls: string[];
}

export interface Group {
  groupName: string;
  unifiedIds: string[];
}
export interface PmGroupsState {
  added: number;
  error: any;
  groups: Group[];
  listingsSelected: any[];
  koUrls: string[];
  matched: number;
  showResults: boolean;
  status: Status;
  selectedGroup: Group | null;
  deleteCount: number | null;
  downloading: boolean;
  query: string;
  savedFilters: any;
  extra?: object;
  listingsStatus: ListingsStatus;
  listingsError: any;
  group: any;
  currentListingsDomain: string;
}
