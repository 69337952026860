import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { StyledComponentProps, CssBaseline, Grid } from '@material-ui/core';
import AppBar, { IProps as AppBarProps } from '../Layout/Header';

const styles = createStyles({
  root: {},
  content: {
    marginTop: 64,
    width: '100%',
  },
});
const Layout: React.SFC<StyledComponentProps & AppBarProps> = function({
  classes,
  children,
  ...props
}) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid className={classes.root} container={true}>
        <AppBar {...props} />
        <div className={classes.content}>{children}</div>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(Layout);
