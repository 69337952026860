import * as React from 'react';
import * as moment from 'moment';
import clsx from 'clsx';
import { withStyles, Grid, Paper } from '@material-ui/core';
import styles from '../styles';
import EmptyItem from './EmptyItem';
import {
  DAY_NAME_FORMAT,
  MONTH_NAME_FORMAT,
  DAY_NUMBER_FORMAT,
} from '../utils';

function ItemContainer({ classes, date, inactive, children }) {
  const _date = moment.utc(date);
  const blank = inactive || !_date.isValid();

  if (blank)
    return <EmptyItem inactive={inactive} classes={classes} date={_date} />;

  return (
    <Grid item={true} xs={true} className={classes.calendarItemContainer}>
      <Paper
        className={clsx(classes.calendarItem, inactive && classes.inactive)}
      >
        <Grid
          container={true}
          direction="column"
          spacing={0}
          justify="flex-end"
          alignItems="stretch"
        >
          <Grid
            item={true}
            xs={12}
            container={true}
            spacing={0}
            justify="space-between"
          >
            <Grid item={true} className={classes.datesLabel}>
              {_date.format(MONTH_NAME_FORMAT)}
            </Grid>
            <Grid item={true} className={classes.datesLabel}>
              {_date.format(DAY_NAME_FORMAT)}
            </Grid>
            <Grid item={true} className={classes.datesLabel}>
              {_date.format(DAY_NUMBER_FORMAT)}
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default withStyles(styles)(ItemContainer);
