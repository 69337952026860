import * as React from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  Typography,
  Button,
  withStyles,
  StyledComponentProps,
  CssBaseline,
  Paper,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form } from '../Forms';
import styles from './styles';

interface ProfileProps {
  confirm: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (event: React.FormEvent) => void;
  password: string;
}

const Profile: React.SFC<ProfileProps & StyledComponentProps> = ({
  confirm,
  handleChange,
  handleChangePassword,
  password,
  classes,
}) => {
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography variant="body1">
          <FormattedMessage id="account.change_password_message" />
        </Typography>
        <Form onSubmit={handleChangePassword}>
          <FormControl margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="password">
              <FormattedMessage id="account.new_password" />
            </InputLabel>
            <Input
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus={true}
              value={password}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="confirm-password">
              <FormattedMessage id="account.new_password" />
            </InputLabel>
            <Input
              name="confirm"
              type="password"
              autoComplete="off"
              value={confirm}
              onChange={handleChange}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            <FormattedMessage id="account.change_password" />
          </Button>
        </Form>
      </Paper>
    </main>
  );
};

export default withStyles(styles)(Profile);
