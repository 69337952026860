import * as React from 'react';
import { StatusPattern, getOnboardingIconColors } from './Utils';
import { IOnboardingIconProps } from './types';

const OnboardingRatesIcon = ({ active, ...props }: IOnboardingIconProps) => {
  const [primary, secondary] = getOnboardingIconColors(active);
  return (
    <svg id="rates__1" viewBox="0 0 174.9 174.9" {...props}>
      <circle cx={87.4} cy={87.4} r={87.4} fill="#aad0f5" opacity={0.1} />
      <g opacity={0.5}>
        <linearGradient
          id="rates__SVG_1"
          x1={87.441}
          x2={87.441}
          y1={667.58}
          y2={720.38}
          gradientTransform="matrix(1 0 0 -1 0 795.971)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#rates__SVG_1)"
          d="M122 125.6v-50h-11.1v49.9h-8.3V95.1H91.5v30.5h-8.3V81.2H72.1v44.4h-8.3v-25H52.7v25h-6.9v2.8H129v-2.8h-7z"
        />
        <linearGradient
          id="rates__SVG_2"
          x1={87.466}
          x2={87.466}
          y1={713.33}
          y2={749.395}
          gradientTransform="matrix(1 0 0 -1 0 795.971)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={primary} />
          <stop offset={1} stopColor={secondary} />
        </linearGradient>
        <path
          fill="url(#rates__SVG_2)"
          d="M116.5 46.5c-3.1 0-5.5 2.5-5.5 5.5 0 1 .3 2 .8 2.8L99.9 66.7c-2.2-1.3-4.9-.9-6.7.8 0 0 0 .1-.1.1l-10.2-8.3c.8-2.4-.2-5.1-2.3-6.4-2.2-1.3-5-1-6.8.7-1.8 1.8-2.2 4.6-.9 6.8L61 72.3c-2.4-1.4-5.5-.8-7.2 1.4s-1.5 5.3.5 7.3 5.1 2.2 7.3.5 2.8-4.8 1.4-7.2l11.9-11.9c2.1 1.2 4.8 1 6.6-.7l10.3 8.4c-.6 2.4.5 5 2.7 6.2s4.9.8 6.7-1c1.8-1.8 2.1-4.5.8-6.7l11.9-11.9c.8.5 1.8.8 2.8.8 3.1 0 5.5-2.5 5.5-5.5-.2-3-2.7-5.5-5.7-5.5z"
        />
      </g>
      <StatusPattern done={active} />
    </svg>
  );
};

export default OnboardingRatesIcon;
