import * as React from 'react';
import { FormattedMessage } from 'react-intl';

function Step({ Icon, children, active, step }) {
  return (
    <div className="column">
      <div className="steps">
        {Icon}
        <p style={{ paddingTop: '10px' }}>
          <span className="title is-6">{step}</span>
          <br />
          {active ? (
            <span
              style={{ color: '#08d8cb', fontSize: '14px', fontWeight: 700 }}
            >
              <FormattedMessage id="done" />
            </span>
          ) : (
            children
          )}
        </p>
      </div>
    </div>
  );
}

export default Step;
