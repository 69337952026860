import { keys, omit } from 'lodash';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import SeriesChart, {
  SeriesChartOptions,
  SeriesChartProps,
} from './SeriesChart';

export interface BarChartOptions extends SeriesChartOptions {}
export interface BarChartProps extends SeriesChartProps {
  options: BarChartOptions;
}

export default class BarChart extends SeriesChart<BarChartProps> {
  updateSeries(chart: am4charts.XYChart) {
    const {
      data,
      options,
      isPercentage,
      formatCurrency,
      currency,
    } = this.props;
    const { categoryField, horizontal } = options;

    chart.series.clear();

    const series = keys(omit(data[0], categoryField));
    if (series.length > 1) chart.legend = new am4charts.Legend();
    for (const valueField of series) {
      const barSeries = chart.series.push(new am4charts.ColumnSeries());
      barSeries.name = valueField;

      const valueAxis = horizontal ? barSeries.xAxis : barSeries.yAxis;

      if (horizontal) {
        barSeries.dataFields.categoryY = categoryField;
        barSeries.dataFields.valueX = valueField;
        barSeries.columns.template.tooltipText = `{valueX}`;
      } else {
        barSeries.dataFields.categoryX = categoryField;
        barSeries.dataFields.valueY = valueField;
        barSeries.columns.template.tooltipText = `[bold]{categoryX}[/]
                                                  {valueY}`;
      }

      if (isPercentage) {
        const numberFormatter = (valueAxis.numberFormatter = new am4core.NumberFormatter());
        numberFormatter.numberFormat = `#.#'%'`;
        barSeries.columns.template.tooltipText = `[bold]{categoryX}[/]
                                                  {valueY.formatNumber('#.0')}%`;
      }
      if (formatCurrency) {
        const numberFormatter = (valueAxis.numberFormatter = new am4core.NumberFormatter());
        numberFormatter.numberFormat = `#. ' ${currency}'`;
        barSeries.columns.template.tooltipText = `[bold]{categoryX}[/]
                                                  {valueY.formatNumber('#.')} ${currency}`;
      }
    }
  }
}
