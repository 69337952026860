import Pagination from 'bulma-pagination-react';
import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
  WrappedComponentProps,
} from 'react-intl';

import { Link } from '@material-ui/core';

import { Loader } from 'components/Layout';
import { Table, TableHead } from 'components/Tables';
import { Box, ImgFallback, ExportButton } from 'components/Transparent';

import InfoContainer from 'components/InformationBox';

import styles from './supply.module.scss';
import { abbreviateNumber, getCurrencySymbol } from 'utils';
import PreTable from 'components/Tables/PreTable';

const InfoBox = () => (
  <FormattedMessage
    id="discounts.info_box"
    values={{ n: () => <div style={{ marginTop: '10px' }} /> }}
  />
);

const ELEMENTS_PER_PAGE = 10;

interface TableProps {
  appliedQuery: string;
  currency: string;
  currentPage: number;
  downloadCSV: (params: any) => void;
  downloadingCSV: boolean;
  error: any;
  fetching: boolean;
  loadSupplyFirstPage: (params: any) => void;
  loadSupplyPage: (params: any, page: number) => void;
  cleanUrls: any[];
  numberOfPages: number;
  order: string;
  orderBy: string;
}

const TableRow: React.FunctionComponent<any> = ({
  avgCleaningFee,
  avgMonthlyDiscount,
  avgWeeklyDiscount,
  avgServiceFee,
  cleanUrl,
  currencySymbol,
  idx,
  listingCount,
  logoUrl,
  websiteUrl,
  ADR,
}) => (
  <tr>
    <td>{idx + 1}</td>
    <td>
      <ImgFallback src={logoUrl} alt={cleanUrl} width={24} />
    </td>
    <td className={styles.title}>
      <Link href={websiteUrl || `http://${cleanUrl}`} target="_blank">
        {cleanUrl}
      </Link>
    </td>
    <td>{abbreviateNumber(listingCount, 0)}</td>
    <td>
      {currencySymbol}
      <FormattedNumber value={ADR} maximumFractionDigits={0} />
    </td>
    <td>
      {currencySymbol}
      <FormattedNumber value={avgCleaningFee} maximumFractionDigits={0} />
    </td>
    <td>
      <FormattedNumber
        value={avgMonthlyDiscount}
        // eslint-disable-next-line
        style="percent"
        maximumFractionDigits={0}
      />
    </td>
    <td>
      <FormattedNumber
        value={avgWeeklyDiscount}
        // eslint-disable-next-line
        style="percent"
        maximumFractionDigits={0}
      />
    </td>
    <td>
      <FormattedNumber
        value={avgServiceFee}
        // eslint-disable-next-line
        style="percent"
        maximumFractionDigits={0}
      />
    </td>
  </tr>
);

const EmptyRow: React.FunctionComponent<any> = () => (
  <tr>
    <td colSpan={8}>
      <FormattedMessage id="discounts.table.no_cleanUrls" />
    </td>
  </tr>
);

export const CustomPagination = React.memo<any>(
  ({ currentPage, pages, onChange }) => (
    <Pagination
      currentPage={currentPage}
      pages={pages}
      onChange={onChange}
      className="is-small"
    />
  ),
);

const SupplyError: React.FunctionComponent<any> = () => (
  <div className={styles.error}>
    <FormattedMessage id="discounts.table.error" />
  </div>
);

const Discounts: React.FunctionComponent<TableProps &
  WrappedComponentProps> = ({
  appliedQuery,
  currency,
  currentPage,
  downloadCSV,
  downloadingCSV,
  error,
  fetching,
  intl,
  loadSupplyFirstPage,
  loadSupplyPage,
  cleanUrls,
  numberOfPages,
  order,
  orderBy,
}) => {
  const sortBy = (key: string) => {
    let sortOrder = order;
    if (key === orderBy) {
      sortOrder = order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOrder = 'ASC';
    }
    loadSupplyPage(
      { order: sortOrder, orderBy: key, limit: ELEMENTS_PER_PAGE },
      1,
    );
  };
  const loadPage = (i: number) => {
    loadSupplyPage({ order, orderBy, limit: ELEMENTS_PER_PAGE }, i);
  };

  React.useEffect(() => {
    loadSupplyFirstPage({ limit: ELEMENTS_PER_PAGE });
  }, [appliedQuery, loadSupplyFirstPage]);

  if (fetching) return <Loader />;

  const currencySymbol = getCurrencySymbol(intl, currency);

  return (
    <Box className={styles.container}>
      {error ? (
        <SupplyError />
      ) : (
        <React.Fragment>
          <Table isCentered={true} isBordered={true}>
            <PreTable className={styles.preTable}>
              <div className="icons-right">
                <ul className="aligned-list">
                  <li>
                    <InfoContainer placement="left-start">
                      <InfoBox />
                    </InfoContainer>
                  </li>
                  <li>
                    <ExportButton
                      downloadCSV={downloadCSV}
                      downloading={downloadingCSV}
                    />
                  </li>
                </ul>
              </div>
            </PreTable>
            <thead>
              <tr>
                <TableHead>#</TableHead>
                <TableHead>
                  <FormattedMessage id="discounts.table.header.logo" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isCentered={false}
                  isSortedBy={orderBy === 'cleanUrl'}
                  onClick={() => sortBy('cleanUrl')}
                >
                  <FormattedMessage id="discounts.table.header.cleanUrl" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'listingCount'}
                  onClick={() => sortBy('listingCount')}
                >
                  <FormattedMessage id="discounts.table.header.listingCount" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'ADR'}
                  onClick={() => sortBy('ADR')}
                >
                  <FormattedMessage id="discounts.table.header.ADR" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'avgCleaningFee'}
                  onClick={() => sortBy('avgCleaningFee')}
                >
                  <FormattedMessage id="discounts.table.header.avgCleaningFee" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'avgMonthlyDiscount'}
                  onClick={() => sortBy('avgMonthlyDiscount')}
                >
                  <FormattedMessage id="discounts.table.header.avgMonthlyDiscount" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'avgWeeklyDiscount'}
                  onClick={() => sortBy('avgWeeklyDiscount')}
                >
                  <FormattedMessage id="discounts.table.header.avgWeeklyDiscount" />
                </TableHead>
                <TableHead
                  isAsc={order === 'ASC'}
                  isSortedBy={orderBy === 'avgServiceFee'}
                  onClick={() => sortBy('avgServiceFee')}
                >
                  <FormattedMessage id="discounts.table.header.avgServiceFee" />
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {cleanUrls.length === 0 ? <EmptyRow /> : null}
              {cleanUrls.map((cleanUrl, i) => (
                <TableRow
                  key={i}
                  idx={i}
                  currencySymbol={currencySymbol}
                  {...cleanUrl}
                />
              ))}
            </tbody>
          </Table>
          <CustomPagination
            currentPage={currentPage}
            pages={numberOfPages}
            onChange={loadPage}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default injectIntl(Discounts);
