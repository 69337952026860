import { schema } from 'normalizr';

import { PERFORMANCE_DOMAIN, AGGREGATED_PERFORMANCE_DOMAIN } from '../types';

const performanceEntity = new schema.Entity(PERFORMANCE_DOMAIN, undefined, {
  idAttribute: ({ unified_id }) => unified_id,
});

export const performanceSchema = {
  data: {
    listings: [performanceEntity],
  },
};

const aggregatedPerformanceEntity = new schema.Entity(
  AGGREGATED_PERFORMANCE_DOMAIN,
  undefined,
  {
    idAttribute: ({ year }) => year,
  },
);

export const aggregatedPerformanceSchema = {
  data: {
    metrics: [aggregatedPerformanceEntity],
  },
};
