import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const ByBeedroom: React.SFC<SvgIconProps> = function(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 134 134"
      {...props}
    >
      <path className="st0" d="M7.4,8.1h16.4V0.3H-0.5v24h7.9V8.1z" />
      <path className="st0" d="M109.3,0.3v7.9h16.4v16.1h7.9v-24H109.3z" />
      <path className="st0" d="M7.4,110.3h-7.9v24h24.3v-7.9H7.4V110.3z" />
      <path className="st0" d="M125.7,126.4h-16.4v7.9h24.3v-24h-7.9V126.4z" />
      <g>
        <path
          className="st0"
          d="M106.9,85.6H26.2c-1,0-1.8,0.8-1.8,1.8v14.7c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8V89.3h77.1v12.9 c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V87.5C108.8,86.5,107.9,85.6,106.9,85.6z"
        />
        <path
          className="st0"
          d="M106.9,96.6H26.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h80.8c1,0,1.8-0.8,1.8-1.8S107.9,96.6,106.9,96.6z"
        />
        <path
          className="st0"
          d="M106.9,71c-1,0-1.8,0.8-1.8,1.8v6L93.9,53.7c-0.4-0.9-1.5-1.4-2.4-0.9c-0.9,0.4-1.3,1.5-0.9,2.4l14.7,33 c0.3,0.7,1,1.1,1.7,1.1c0.1,0,0.3,0,0.4,0c0.8-0.2,1.4-0.9,1.4-1.8V72.8C108.8,71.8,107.9,71,106.9,71z"
        />
        <path
          className="st0"
          d="M46.4,52.6h-5.5c-0.7,0-1.4,0.4-1.7,1.1L28,78.8v-6c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v14.7 c0,0.9,0.6,1.6,1.4,1.8c0.1,0,0.3,0,0.4,0c0.7,0,1.4-0.4,1.7-1.1l14.2-32h4.3c1,0,1.8-0.8,1.8-1.8C48.2,53.4,47.4,52.6,46.4,52.6 z"
        />
        <path
          className="st0"
          d="M84.9,30.6H48.2c-5.1,0-9.2,4.1-9.2,9.2v14.7c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8V39.7 c0-3,2.5-5.5,5.5-5.5h36.7c3,0,5.5,2.5,5.5,5.5v12.9h-3.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h5.5c1,0,1.8-0.8,1.8-1.8 V39.7C94.1,34.7,90,30.6,84.9,30.6z"
        />
        <path
          className="st0"
          d="M59.2,45.2h-7.8c-2.8,0-5.1,2.1-5.5,4.8l-0.9,7.3c-0.2,1.6,0.3,3.1,1.3,4.3c1,1.2,2.5,1.9,4.1,1.9h8.7 c3,0,5.5-2.5,5.5-5.5v-7.3C64.7,47.7,62.2,45.2,59.2,45.2z M61,58.1c0,1-0.8,1.8-1.8,1.8h-8.7c-0.5,0-1-0.2-1.4-0.6 c-0.3-0.4-0.5-0.9-0.4-1.4l0.9-7.3c0.1-0.9,0.9-1.6,1.8-1.6h7.8c1,0,1.8,0.8,1.8,1.8V58.1L61,58.1z"
        />
        <path
          className="st0"
          d="M88,57.4l-0.9-7.3c-0.3-2.8-2.7-4.8-5.5-4.8h-7.8c-3,0-5.5,2.5-5.5,5.5v7.3c0,3,2.5,5.5,5.5,5.5h8.7 c1.6,0,3.1-0.7,4.1-1.9C87.8,60.6,88.2,59,88,57.4z M83.9,59.3c-0.3,0.4-0.8,0.6-1.4,0.6h-8.7c-1,0-1.8-0.8-1.8-1.8v-7.3 c0-1,0.8-1.8,1.8-1.8h7.8c0.9,0,1.7,0.7,1.8,1.6l0.9,7.3C84.5,58.4,84.3,58.9,83.9,59.3z"
        />
        <path
          className="st0"
          d="M70.2,52.6h-7.3c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h7.3c1,0,1.8-0.8,1.8-1.8 C72.1,53.4,71.2,52.6,70.2,52.6z"
        />
      </g>
    </SvgIcon>
  );
};

export default ByBeedroom;
