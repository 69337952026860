import { Reducer } from 'redux';
import { omitBy, isEmpty } from 'lodash/fp';
import {
  IFiltersState,
  IFiltersActions,
  FILTERS_LOADING,
  FILTERS_LOADING_ERROR,
  FILTERS_READY,
  SET_DATE_RANGE,
  SET_FILTER,
  SET_FILTERS,
  SET_QUERY,
  ADD_DISABLED,
  REMOVE_DISABLED,
} from './types';

const ACTION_HANDLERS = {
  [FILTERS_LOADING]: state => ({
    ...state,
    isLoading: true,
  }),

  [FILTERS_READY]: state => ({
    ...state,
    isLoading: false,
  }),

  [SET_FILTERS]: (state, { payload }) => ({
    ...state,
    ...payload,
    error: null,
  }),
  [SET_DATE_RANGE]: (state, { payload }) => ({
    ...state,
    dates: {
      ...state.dates,
      selectedDates: {
        ...state.dates.selectedDates,
        ...payload,
      },
    },
  }),
  [FILTERS_LOADING_ERROR]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  }),
  [SET_FILTER]: (state, { payload }) => {
    const selectedFilters = omitBy(isEmpty)({
      ...state.selectedFilters,
      [payload.name]: payload.filter,
    });
    return {
      ...state,
      selectedFilters,
    };
  },
  [SET_QUERY]: (state, { payload }) => ({
    ...state,
    query: payload,
  }),
  [ADD_DISABLED]: (state, { payload }) => ({
    ...state,
    disabled: state.disabled.includes(payload)
      ? state.disabled
      : [...state.disabled, payload],
  }),
  [REMOVE_DISABLED]: (state, { payload }) => ({
    ...state,
    disabled: state.disabled.filter(d => d !== payload),
  }),
};

const initialState = {
  isLoading: false,
  error: null,
  disabled: [],
};

export default (
  state: IFiltersState = initialState,
  action,
): Reducer<IFiltersState, IFiltersActions> => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
