import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const RatesCalendar: React.SFC<SvgIconProps> = function(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 212.8 212.8"
      {...props}
    >
      <path
        className="st0"
        d="M106.4,0C47.7,0,0,47.7,0,106.4s47.7,106.4,106.4,106.4S212.8,165,212.8,106.4S165,0,106.4,0z M106.4,198.8 c-50.9,0-92.4-41.4-92.4-92.4S55.4,14,106.4,14s92.4,41.4,92.4,92.4S157.3,198.8,106.4,198.8z"
      />
      <path
        className="st0"
        d="M113.4,100.1V60.4c4,1.5,7.4,4.2,9.8,7.8c2.1,3.2,6.5,4.1,9.7,2c3.2-2.1,4.1-6.5,2-9.7 c-5-7.6-12.8-12.8-21.5-14.6V34.7c0-3.9-3.1-7-7-7s-7,3.1-7,7v11.1c-15.5,3.2-27.2,17-27.2,33.4s11.7,30.2,27.2,33.4v39.7 c-4-1.5-7.4-4.2-9.8-7.8c-2.1-3.2-6.5-4.1-9.7-2c-3.2,2.1-4.1,6.5-2,9.7c5,7.6,12.8,12.8,21.5,14.6V178c0,3.9,3.1,7,7,7s7-3.1,7-7 V167c15.5-3.2,27.2-17,27.2-33.4S128.9,103.3,113.4,100.1L113.4,100.1z M86.2,79.2c0-8.7,5.5-16,13.2-18.9v37.7 C91.7,95.3,86.2,87.9,86.2,79.2z M113.4,152.4v-37.7c7.7,2.8,13.2,10.2,13.2,18.9S121.1,149.6,113.4,152.4z"
      />
    </SvgIcon>
  );
};

export default RatesCalendar;
