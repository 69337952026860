import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox, Link } from '@material-ui/core';
export const ListingLevelLine = injectIntl<'intl', any>(
  ({
    title,
    zipcode,
    bedrooms,
    review_count,
    review_score,
    unified_id,
    url,
    platform,
    handleListingSelection,
    checked,
    pm_domain_name,
    adr,
    avg_occ,
    intl,
    currency = 'USD',
  }) => {
    return (
      <tr>
        <td>
          <Checkbox
            value={unified_id}
            checked={checked}
            onClick={handleListingSelection}
          />
        </td>
        <td style={{ width: 400 }}>
          <Link href={url} target="_blank">
            {title || 'No title available'}
          </Link>
        </td>
        <td>{pm_domain_name}</td>
        <td>
          <img
            src={`/img/${platform.toLowerCase()}-icon.png`}
            width={30}
            alt={platform}
          />
        </td>
        <td style={{ width: 100 }}>{zipcode}</td>
        <td>{bedrooms}</td>
        <td>{review_score}</td>
        <td>{review_count}</td>
        <td>
          {intl.formatNumber(adr, {
            style: 'currency',
            currency,
          })}
        </td>
        <td>
          {intl.formatNumber(avg_occ, {
            style: 'percent',
          })}
        </td>
      </tr>
    );
  },
);
