export const START_LOAD_SUPPLIES = 'START_LOAD_SUPPLIES';
export const LOAD_SUPPLIES_SUCCESS = 'LOAD_SUPPLIES_SUCCESS';
export const LOAD_SUPPLIES_ERROR = 'LOAD_SUPPLIES_ERROR';

export interface MarketUrl {
  market: string;
  url: string;
  signedUrl: string;
}

export interface ISuppliesState {
  error: any;
  isLoading: boolean;
  markets: MarketUrl[];
}

interface IStartLoadSupplies {
  type: typeof START_LOAD_SUPPLIES;
  payload: { markets: string[] };
}

interface ILoadSuppliesSuccess {
  type: typeof LOAD_SUPPLIES_SUCCESS;
  payload: { markets: MarketUrl[] };
}

interface ILoadSuppliesError {
  type: typeof LOAD_SUPPLIES_ERROR;
  payload: { error: any };
}

export type ISuppliesActions =
  | IStartLoadSupplies
  | ILoadSuppliesSuccess
  | ILoadSuppliesError;
