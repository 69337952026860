import * as React from 'react';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default () => {
  const shouldHideOnboarding = useSelector(state =>
    get(state, ['auth', 'user', 'hide_onboarding'], true),
  );
  const isLoading = useSelector(state => get(state, ['layout', 'isLoading']));
  if (isLoading) return null;
  return shouldHideOnboarding ? (
    <Redirect to="/rates" />
  ) : (
    <Redirect to="/onboarding" />
  );
};
