import moment from 'moment';
import { SET_LOCALE } from './types';

const setLocaleActionType = (locale: string) => ({
  type: SET_LOCALE,
  payload: {
    locale,
  },
});

export const setLocale = (locale: string) => {
  return (dispatch, _, { cookies }) => {
    dispatch(setLocaleActionType(locale));
    cookies.set('locale', locale, {
      expires: moment()
        .add(1, 'year')
        .toDate(),
    });
  };
};

export const getInitialLocale = () => {
  return (dispatch, _, { cookies }) => {
    const locale = cookies.get('locale') || 'en';
    dispatch(setLocaleActionType(locale));
  };
};
