import * as React from 'react';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'components/Layout';
import Layout from 'containers/Layout/Layout';
import Dashboard from 'containers/Dashboard/Dashboard';
import Filters from 'containers/Dashboard/Filters';
import { ApplicationState } from 'store';
import { PropertyManagerView } from './layout/composition';
import { loadPmDomainDetails } from './store/actions';

type PropertyManagersProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export function PropertyManagersComposition({
  actions,
  pmStats,
  isLoading,
  currency,
  error,
  market,
  pmDomainDetails,
}: PropertyManagersProps) {
  return (
    <Layout>
      <Dashboard mainListItems={<Filters />}>
        {isLoading ? (
          <Loader />
        ) : (
          <PropertyManagerView
            pmStats={pmStats}
            currency={currency}
            error={error}
            loadDetails={actions.loadPmDomainDetails}
            market={market}
            pmDomainDetails={pmDomainDetails}
          />
        )}
      </Dashboard>
    </Layout>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ loadPmDomainDetails }, dispatch),
  };
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    pmStats: get('pmStats.pm_stats', state),
    currency: get('pmStats.currency', state),
    isLoading: get('pmStats.isLoading', state),
    error: get('pmStats.error', state),
    market: get('filters.selectedFilters.market.value', state),
    pmDomainDetails: get('entities.pmDomainDetails', state),
  };
};

export const PropertyManagers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyManagersComposition);
