import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { range } from 'lodash';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reviewScoreDomain, recentReviewsDomain } from '../store/types';
import { loadReviewScore, loadRecentReviews } from '../store/actions';
import { getEntities, getAllEntities } from 'store/entities/selectors';
import { WordCloudComposition } from '../charts/ReviewsWordCloud';
import { Loader } from 'components/Layout';
import { ReviewScore } from '../charts/ReviewScore';
import { RecentReviews } from './RecentReviews';
import { reviewsNegativeDomain, reviewsPositiveDomain } from '../store/types';

type ReviewGrowthProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const Layout: React.SFC<ReviewGrowthProps> = React.memo(
  ({ reviewScore, loading, actions, appliedQuery }) => {
    React.useEffect(() => {
      if (appliedQuery) {
        actions.loadReviewScore();
      }
    }, [actions, appliedQuery]);

    if (loading) {
      return <Loader />;
    }
    return (
      <section className="section">
        <div className="columns is-multiline">
          <div className="column is-half">
            <ReviewScore data={reviewScore} />
          </div>
          <div className="column is-half">
            <WordCloudComposition
              domain={reviewsNegativeDomain}
              title={<FormattedMessage id="reviews.bad_reviews.title" />}
              reviewsThreshold={[0, 3.5]}
              infoBoxContent={
                <FormattedMessage id="reviews.bad_reviews.info" />
              }
            />
          </div>
          <div className="column is-half">
            <RecentReviews itemsPerPage={4} />
          </div>

          <div className="column is-half">
            <WordCloudComposition
              domain={reviewsPositiveDomain}
              title={<FormattedMessage id="reviews.good_reviews.title" />}
              reviewsThreshold={[4.5, 5]}
              infoBoxContent={
                <FormattedMessage id="reviews.good_reviews.info" />
              }
            />
          </div>
        </div>
      </section>
    );
  },
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loadReviewScore, loadRecentReviews }, dispatch),
});

const mapStateToProps = state => {
  return {
    reviewScore: getEntities(state, reviewScoreDomain, range(0, 5.5, 0.5)),
    recentReviews: getAllEntities(state, recentReviewsDomain),
    error: get([reviewScoreDomain, 'error'], state),
    loading: get([reviewScoreDomain, 'isLoading'], state),
    appliedQuery: get(['filters', 'query'], state),
  };
};

export const ReviewsComposition = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
