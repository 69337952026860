import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
  WrappedComponentProps,
} from 'react-intl';

import { Loader } from 'components/Layout';
import {
  Box,
  Columns,
  Column,
  ColumnSizes,
  MediaObject,
  GapSizes,
} from 'components/Transparent';

import ListingsCountIcon from 'assets/img/listings.png';
import CleaningFeeIcon from '../CleaningFeeIcon';
import DiscountsIcon from '../DiscountIcon';
import ServiceFeeIcon from '../ServiceFeeIcon';

import { abbreviateNumber, getCurrencySymbol } from 'utils';
import { AggregatedDiscountsInfo } from '../../types';

import styles from './aggregated.module.scss';

interface AggregatedProps extends WrappedComponentProps {
  aggregated: AggregatedDiscountsInfo;
  appliedQuery: string;
  fetching: boolean;
  isMenuOpen: boolean;
  error: Error;
  loadAggregated: () => void;
}

const Aggregate: React.FunctionComponent<any> = ({ id, children }) => (
  <div className={styles.info}>
    <div className={styles.title}>
      <FormattedMessage id={id} />
    </div>
    <div className={styles.value}>{children}</div>
  </div>
);

const AggregatedError: React.FunctionComponent<any> = () => {
  return (
    <Box>
      <Columns className={styles.noStats} isVCentered={true}>
        <Column columnSize={ColumnSizes.Full}>
          <FormattedMessage id="discounts.stats.no_stats" />
        </Column>
      </Columns>
    </Box>
  );
};

const AggregatedInfo: React.FunctionComponent<any> = ({
  avgCleaningFee,
  avgMonthlyDiscount,
  avgServiceFee,
  currencySymbol,
  listingCount,
}) => (
  <Box className={styles.boxContainer}>
    <Columns
      className={styles.aggregatedInfoContainer}
      isCentered={true}
      isVCentered={true}
      isMultiline={true}
      gapSize={GapSizes.Two}
    >
      <Column className={`${styles.borderRight} ${styles.card}`}>
        <MediaObject
          image={() => (
            <img alt="listings count icon" src={ListingsCountIcon} />
          )}
        >
          <Aggregate id="discounts.stats.listings_count">
            {abbreviateNumber(listingCount)}
          </Aggregate>
        </MediaObject>
      </Column>
      <Column className={`${styles.borderRight} ${styles.card}`}>
        <MediaObject image={() => <CleaningFeeIcon height={60} />}>
          <Aggregate id="discounts.stats.avg_cleaning_fee">
            {avgCleaningFee ? (
              `${currencySymbol}${abbreviateNumber(avgCleaningFee, 0)}`
            ) : (
              <span>-</span>
            )}
          </Aggregate>
        </MediaObject>
      </Column>
      <Column className={`${styles.borderRight} ${styles.card}`}>
        <MediaObject image={() => <DiscountsIcon height={60} />}>
          <Aggregate id="discounts.stats.avg_monthly_discount">
            {avgMonthlyDiscount === null ? (
              <span>-</span>
            ) : (
              <FormattedNumber
                value={avgMonthlyDiscount}
                // eslint-disable-next-line
                style="percent"
                maximumFractionDigits={0}
              />
            )}
          </Aggregate>
        </MediaObject>
      </Column>
      <Column className={styles.card}>
        <MediaObject image={() => <ServiceFeeIcon height={60} />}>
          <Aggregate id="discounts.stats.avg_service_fee">
            {avgServiceFee === null ? (
              <span>-</span>
            ) : (
              <FormattedNumber
                value={avgServiceFee}
                // eslint-disable-next-line
                style="percent"
                maximumFractionDigits={0}
              />
            )}
          </Aggregate>
        </MediaObject>
      </Column>
    </Columns>
  </Box>
);

const Aggregated: React.FunctionComponent<AggregatedProps> = ({
  aggregated,
  appliedQuery,
  error,
  fetching,
  intl,
  isMenuOpen,
  loadAggregated,
}) => {
  React.useEffect(() => {
    loadAggregated();
  }, [appliedQuery, loadAggregated]);

  const { currency } = aggregated;
  const currencySymbol = getCurrencySymbol(intl, currency);

  return fetching ? (
    <Loader />
  ) : (
    <Columns
      className={`${styles.container}${
        isMenuOpen ? ` ${styles.menuOpened}` : ''
      }`}
      isCentered={true}
      isMultiline={true}
      isVCentered={true}
    >
      <Column>
        {error ? (
          <AggregatedError />
        ) : (
          <AggregatedInfo currencySymbol={currencySymbol} {...aggregated} />
        )}
      </Column>
    </Columns>
  );
};

export default injectIntl(Aggregated);
