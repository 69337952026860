import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import { Loader } from '../../../components/Layout/index';
import Display from '../../../components/Onboarding/index';

function OnboardingPage({ user }: OnboardingPageProps) {
  return user ? <Display user={user} /> : <Loader />;
}

const mapStateToProps = (state: Pick<ApplicationState, 'supplies'>) => {
  return {
    user: get(state, 'auth.user', null),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type OnboardingPageProps = StateProps;
export default connect(mapStateToProps)(OnboardingPage);
