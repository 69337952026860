import React from 'react';
import './ErrorMessage.scss';
import ErrorIcon from 'components/Icons/ErrorIcon';
import WarningIcon from 'components/Icons/WarningIcon';
import { IErrorMessageProps } from '../lib/types';

const ErrorMessage: React.FunctionComponent<IErrorMessageProps> = ({
  errorMessage,
  icon,
}) => {
  return (
    <section className="chart-error-message">
      <div>
        {icon === `error` ? <ErrorIcon /> : <WarningIcon />}
        <span className="error-message-text">{errorMessage}</span>
      </div>
    </section>
  );
};

export default ErrorMessage;
