import { keys, omit } from 'lodash';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import BaseChart, { BaseChartProps } from './BaseChart';

export interface PieChartOptions {
  categoryField: string;
  title?: string;
}
export interface PieChartProps extends BaseChartProps {
  options: PieChartOptions;
  currency?: string;
}

am4core.useTheme(am4themes_animated);

export default class PieChart extends BaseChart<PieChartProps> {
  buildChart() {
    return am4core.create(this.props.chartId, am4charts.PieChart);
  }

  updateSeries(chart: am4charts.PieChart) {
    const { data, options } = this.props;
    const { categoryField, title } = options;

    chart.series.clear();

    const series = keys(omit(data[0], categoryField));
    if (series.length > 1) chart.legend = new am4charts.Legend();
    for (const valueField of series) {
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.colors.list = [
        am4core.color('#0c1968'),
        am4core.color('#723ac3'),
        am4core.color('#5b97ff'),
        am4core.color('#9aa5b8'),
        am4core.color('#83eec5'),
        am4core.color('#fa378f'),
        am4core.color('#e04a3b'),
        am4core.color('#f5d915'),
      ];
      pieSeries.name = valueField;
      pieSeries.dataFields.category = categoryField;
      pieSeries.dataFields.value = valueField;
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      // change the cursor on hover to make it apparent the object can be interacted with
      pieSeries.slices.template.cursorOverStyle = [
        {
          property: 'cursor',
          value: 'pointer',
        },
      ];

      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      const shadow = pieSeries.slices.template.filters.push(
        new am4core.DropShadowFilter(),
      );
      shadow.opacity = 0;

      // Create hover state
      const hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

      // Slightly shift the shadow and make it more prominent on hover
      const hoverShadow = hoverState.filters.push(
        new am4core.DropShadowFilter(),
      );
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;
    }
    chart.innerRadius = am4core.percent(30);
    chart.legend = new am4charts.Legend();
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
    chart.colors.step = 2;

    if (title) {
      const chartTitle = chart.titles.create();
      chartTitle.text = title;
    }
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
}
